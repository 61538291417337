import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Fragment } from "react";

import { joinName } from "@packages/utils";

import { IAgent } from "~/api";

import {
  UpdateUserStatusAction,
  UpdateUserStatusActionMapping,
  useUpdateUserStatus,
} from "./useUpdateUserStatus";

export interface UpdateUserStatusModalProps {
  action?: UpdateUserStatusAction;
  open?: boolean;
  onClose?: () => void;
  user?: IAgent;
}

export function UpdateUserStatusModal(props: UpdateUserStatusModalProps) {
  const { action, open = false, onClose, user } = props;

  const { onUpdate } = useUpdateUserStatus(action);

  const { title, messages, destructive, permanent } =
    (action ? UpdateUserStatusActionMapping[action] : null) ?? {};

  const name = joinName(user?.firstName, user?.lastName);
  const tokens = (messages?.confirm.split("{name}") ?? []) as React.ReactNode[];

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText paragraph={permanent}>
            {tokens
              // Insert the user's name at every {name} token in the given message string.
              .map((token, index) => [
                index === 0 ? null : (
                  <Typography component="span" fontWeight="medium" key={`${index}.name`}>
                    {name}
                  </Typography>
                ),
                <Fragment key={`${index}.token`}>{token}</Fragment>,
              ])
              .flat()}
          </DialogContentText>
          {permanent && (
            <DialogContentText color="text.primary" fontWeight="medium">
              This action is irreversible.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            color={destructive ? "error" : "primary"}
            onClick={() => {
              if (user) onUpdate?.(user);
              onClose?.();
            }}
          >
            {title}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

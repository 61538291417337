import {
  AccordionDetails,
  Divider,
  Grid,
  Hidden,
  Skeleton,
  Typography,
} from "@mui/material";

import { ProfileCard } from "~/components/applicant/ProfileCard";

interface DetailLabel {
  label: string;
  value?: React.ReactNode;
  error?: boolean;
}

export interface ApplicationDetailsCardProps {
  personalDetails?: DetailLabel[];
  contactDetails?: DetailLabel[];
  addressDetails?: DetailLabel[];
  /** Controls whether the accordion is expanded by default if set. */
  defaultExpanded?: boolean;
}

export function ApplicantDetailsCard(props: ApplicationDetailsCardProps) {
  const { personalDetails, contactDetails, addressDetails, defaultExpanded } = props;
  return (
    <ProfileCard.Accordion title="Applicant details" defaultExpanded={defaultExpanded}>
      <Grid component={AccordionDetails} container>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Personal Details
          </Typography>
          {personalDetails?.map((props) => (
            <ApplicantDetailLabel key={props.label} {...props} />
          ))}
          <Hidden mdUp>
            <Divider sx={{ my: 1 }} />
          </Hidden>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Contact Details
          </Typography>
          {contactDetails?.map((props) => (
            <ApplicantDetailLabel key={props.label} {...props} />
          ))}
          <Divider sx={{ my: 1 }} />
          {addressDetails?.map((props) => (
            <ApplicantDetailLabel key={props.label} {...props} />
          ))}
        </Grid>
      </Grid>
    </ProfileCard.Accordion>
  );
}

interface ApplicantDetailLabelProps extends DetailLabel {
  loading?: boolean;
}

export function ApplicantDetailLabel(props: ApplicantDetailLabelProps) {
  const { label: key, value, error, loading } = props;

  return (
    <Grid item xs={12} key={key} container>
      <Grid item xs={4}>
        <Typography
          variant="body1"
          color="text.secondary"
          noWrap
          fontWeight="bold"
          gutterBottom
        >
          {key}
        </Typography>
      </Grid>
      <Grid item xs={8} pr={5}>
        <Typography
          variant="body1"
          color={error ? "error" : "secondary"}
          gutterBottom
          sx={{ overflowWrap: "break-word" }}
        >
          {loading ? <Skeleton width="75%" /> : value}
        </Typography>
      </Grid>
    </Grid>
  );
}

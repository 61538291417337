import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { joinName } from "@packages/utils";

import { IApplicationSearchResult } from "~/api";
import { useBackLink } from "~/components/core/BackLink";
import { Timestamp } from "~/components/core/Timestamp";

import { DashboardTable } from "./DashboardTable";

const PLACEHOLDERS = Array.from({ length: 10 }).map((_, index) => (
  <ApplicationsTableRow key={index} loading />
));

const headings = [
  { id: "id", label: "Application" },
  { id: "name", label: "Applicant" },
  { id: "courseCode", label: "Primary Course Preference" },
  { id: "lastUpdated", label: "Last Activity" },
  { id: "owner", label: "Assigned Agent" },
];

interface ApplicationsTableProps {
  loading?: boolean;
  namesLoading?: boolean;
  results?: IApplicationSearchResult[] | undefined;
}

/**
 * Table for displaying applications available in the dashboard
 */
export function ApplicationsTable(props: ApplicationsTableProps) {
  const { loading, namesLoading, results } = props;

  return (
    <DashboardTable headings={headings} sx={{ px: 3, pb: 3 }}>
      {loading
        ? PLACEHOLDERS
        : results?.map((result) => (
            <ApplicationsTableRow
              key={result.applicationId}
              nameLoading={namesLoading}
              result={result}
            />
          ))}
    </DashboardTable>
  );
}

type ApplicationsTableRowProps = {
  loading?: boolean;
  nameLoading?: boolean;
  result?: Partial<IApplicationSearchResult>;
};

function ApplicationsTableRow(props: ApplicationsTableRowProps) {
  const { loading, nameLoading, result } = props;
  const {
    applicationId,
    applicantId,
    applicantFirstName,
    applicantLastName,
    applicantPreferredName,
    applicationStatus,
    applicationCoursePreferenceStatus,
    lastModifiedDate,
    owner,
    studentId,
    campusLocation,
    primaryCoursePreference,
  } = result ?? {};

  const backLink = useBackLink();

  const name = applicantLastName
    ? joinName(applicantFirstName, applicantLastName, applicantPreferredName)
    : "Name not provided";

  return (
    <DashboardTable.Row key={applicationId} disableHighlight={loading}>
      <DashboardTable.Cell skeleton={loading} skeletonRows={2}>
        <Link
          component={RouterLink}
          to={`/applications/${applicationId}`}
          state={{ backLink }}
          underline="hover"
        >
          <Typography fontWeight="bold" noWrap>
            {applicationId}
          </Typography>
        </Link>
        <Typography variant="body2" color="text.secondary" noWrap>
          {campusLocation?.code}
        </Typography>
      </DashboardTable.Cell>
      <DashboardTable.Cell skeleton={loading || nameLoading} skeletonRows={2}>
        <>
          {applicantId ? (
            <Link
              component={RouterLink}
              to={`/applicants/${applicantId}`}
              state={{ backLink }}
              underline="hover"
            >
              <Typography fontWeight="bold">{name}</Typography>
            </Link>
          ) : (
            <DashboardTable.Placeholder noWrap>
              Name not provided
            </DashboardTable.Placeholder>
          )}
          <Typography variant="body2" color="text.secondary" noWrap>
            {studentId || "Student ID not generated"}
          </Typography>
        </>
      </DashboardTable.Cell>
      <DashboardTable.Cell skeleton={loading} skeletonRows={2}>
        {primaryCoursePreference ? (
          <>
            <Typography variant="body1">
              {primaryCoursePreference.course.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {primaryCoursePreference.course.code}
            </Typography>
          </>
        ) : (
          <DashboardTable.Placeholder noWrap>Not selected</DashboardTable.Placeholder>
        )}
      </DashboardTable.Cell>
      <DashboardTable.Cell skeleton={loading} skeletonRows={2}>
        <Typography variant="body1">
          {applicationCoursePreferenceStatus || applicationStatus}
        </Typography>
        <Typography variant="body2" color="text.secondary" noWrap>
          <Timestamp format="d/MM/yyyy h:mm aaa">{lastModifiedDate}</Timestamp>
        </Typography>
      </DashboardTable.Cell>
      <DashboardTable.Cell skeleton={loading}>
        <Typography variant="body1">{owner}</Typography>
      </DashboardTable.Cell>
    </DashboardTable.Row>
  );
}

import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Card,
  CardContent,
  CardActions,
  SxProps,
  Box,
} from "@mui/material";

import BackIcon from "@mui/icons-material/ArrowBack";
import RefreshIcon from "@mui/icons-material/Refresh";

import { useSignIn } from "~/components/auth/AuthProvider";
import { ErrorAction } from "~/utils/errors";

import { ErrorCorrelationId } from "./ErrorCorrelationId";

export interface ErrorMessageCardProps {
  code?: string | number;
  title: React.ReactNode;
  titleSize?: "normal" | "large";
  message?: React.ReactNode;
  correlationId?: string;
  actions?: ErrorAction[];
  sx?: SxProps;
}

export function ErrorMessageCard(props: ErrorMessageCardProps) {
  const {
    code,
    title,
    titleSize = "normal",
    message,
    correlationId,
    actions = ["refresh"],
    sx,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const signIn = useSignIn();

  const back = () => navigate(-1);
  const refresh = () => window.location.reload();
  const reauthenticate = () => signIn(location.pathname);

  return (
    <>
      <Card sx={sx}>
        <CardContent>
          <Typography
            variant={titleSize === "large" ? "h4" : "h5"}
            color="primary"
            mb={1}
          >
            {code && (
              <Box component="span" mr={1.5}>
                {code}
              </Box>
            )}
            <b>{title}</b>
          </Typography>
          {message && (
            <Typography variant="body1" color="text.secondary" component="pre">
              {message}
            </Typography>
          )}
        </CardContent>
        {actions.length > 0 && (
          <CardActions sx={{ mt: -2 }}>
            {actions.includes("back") && (
              <Button onClick={back} startIcon={<BackIcon />}>
                Go Back
              </Button>
            )}
            {actions.includes("refresh") && (
              <Button onClick={refresh} startIcon={<RefreshIcon />}>
                Refresh
              </Button>
            )}
            {actions.includes("signin") && (
              <Button onClick={reauthenticate}>Sign In</Button>
            )}
          </CardActions>
        )}
      </Card>
      <ErrorCorrelationId correlationId={correlationId} mt={1.5} />
    </>
  );
}

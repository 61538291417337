import { Box, BoxProps, Typography } from "@mui/material";
import { alpha, lighten } from "@mui/system";
import { DropzoneProps, useDropzone } from "react-dropzone";

import UploadIcon from "@mui/icons-material/Upload";

export interface FormDropzoneProps extends DropzoneProps {
  /**
   * Main text shown when dropzone is active.
   */
  titleText?: string;

  /**
   * Smaller text displayed below the main text in the dropzone.
   * Used to display for e.g. info about what file types are accepted, etc.
   */
  subtitleText?: string;

  /**
   * Styles to apply to the parent container.
   */
  sx?: BoxProps["sx"];
}

export function FormDropzone(props: FormDropzoneProps) {
  const {
    children,
    multiple,
    disabled,
    titleText,
    subtitleText,
    sx,
    ...otherDropzoneProps
  } = props;

  const state = useDropzone({
    multiple: multiple,
    disabled: disabled,
    ...otherDropzoneProps,
  });

  const { getRootProps, getInputProps, isDragActive } = state;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onClick: _, ...rootProps } = getRootProps();
  const inputProps = getInputProps();

  return (
    <Box {...rootProps} sx={sx}>
      <input {...inputProps} />
      <Box
        sx={(theme) => ({
          position: "absolute",
          inset: 0,
          pointerEvents: isDragActive ? "all" : "none",
          background: alpha(lighten(theme.palette.primary.main, 0.9), 0.8),
          zIndex: 1000,
          display: "flex",
          opacity: isDragActive ? 100 : 0,
          transition: theme.transitions.create("opacity"),
          backdropFilter: "blur(4px)",
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            gap: 1,
            m: 3,
            p: 8,
            borderWidth: 2,
            borderRadius: 2,
            borderStyle: "dashed",
            width: "100%",
            borderColor: (theme) => theme.palette.grey[500],
          }}
        >
          <Typography
            display="flex"
            gap={1}
            flexDirection="column"
            alignItems="center"
            variant="body1"
          >
            <UploadIcon fontSize="large" color="action" />
            {titleText}
          </Typography>
          {subtitleText && (
            <Typography variant="body2" color="text.secondary">
              {subtitleText}
            </Typography>
          )}
        </Box>
      </Box>
      {children?.(state)}
    </Box>
  );
}

import { useState } from "react";
import { Button, Typography } from "@mui/material";
import { Control, useFormState } from "react-hook-form";

import { doesObjectHaveTrueValues } from "~/utils";
import { FormSection } from "~/components/form/FormSection";
import { ApplicationDocumentsModal } from "~/components/application/ApplicationDocumentsModal";
import { ButtonLoadingSpinner } from "~/components/core/ButtonLoadingSpinner";
import { useNotification } from "~/components/core/NotificationProvider";

import { ApplicationFields } from "./useApplicationForm";

export interface SupportingDocumentsFormProps {
  control: Control<ApplicationFields>;
  onSave: () => Promise<{ valid: boolean; success: boolean }>;
  applicationId: string;
  readOnly?: boolean;
}

export function SupportingDocumentsForm(props: SupportingDocumentsFormProps) {
  const { applicationId, control, onSave, readOnly } = props;

  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const { showNotification } = useNotification();

  // We need to save the application if fields in the form have changed as
  // each form section could trigger changes to the document checklist.
  const formState = useFormState({ control });
  const isDirty = doesObjectHaveTrueValues(formState.dirtyFields);

  async function onOpenSupportingDocuments() {
    if (readOnly || !isDirty) setOpen(true);
    else {
      setSaving(true);
      const { valid, success } = await onSave();
      setSaving(false);

      if (!valid) {
        showNotification({
          type: "warning",
          message:
            "There are incomplete sections in this application that need to be filled in before the draft can be saved.",
        });
        return;
      }

      // An error message will be shown from the save error so we can just abort
      if (!success) return;

      // Otherwise, open the modal
      setOpen(true);
    }
  }

  return (
    <>
      <FormSection.Well>
        <Typography variant="body1">
          To speed up processing of the application, upload supporting evidence below. If
          the applicant doesn't have them ready right away, this step may be skipped and
          completed later.
        </Typography>
      </FormSection.Well>
      <Button
        variant="contained"
        sx={{ alignSelf: "start" }}
        disabled={saving}
        endIcon={<ButtonLoadingSpinner show={saving} />}
        onClick={onOpenSupportingDocuments}
      >
        {readOnly ? "View" : "Manage"} supporting documents
      </Button>
      {!readOnly && isDirty && (
        // Use &nbsp; to avoid having orphans (typesetting)
        <Typography variant="body2" color="text.secondary">
          To ensure we have up-to-date document requirements, clicking this button will
          save your application before displaying the document&nbsp;checklist.
        </Typography>
      )}
      <ApplicationDocumentsModal
        applicationId={applicationId}
        open={open}
        onClose={() => setOpen(false)}
        isEditable={!readOnly}
      />
    </>
  );
}

import { Controller, FieldValues, ControllerProps } from "react-hook-form";
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  RadioGroup,
  RadioGroupProps,
} from "@mui/material";

import { ValueSatisfiesType, GetFieldPathToType } from "~/types";
import { FormRadio } from "~/components/form/FormRadioGroup/FormRadio";

export interface FormYesNoProps<TFieldValues extends FieldValues = FieldValues>
  extends Pick<ControllerProps<TFieldValues>, "control">,
    FormControlProps {
  name: GetFieldPathToType<boolean | null, TFieldValues>;
  label?: React.ReactNode;
}

export function FormYesNo<TFieldValues extends FieldValues = FieldValues>(
  props: FormYesNoProps<TFieldValues>,
) {
  const { name, control, label, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      rules={{}}
      render={({ field: { onChange, ...field }, fieldState }) => (
        <YesNo
          id={name}
          {...rest}
          error={fieldState.invalid}
          label={label}
          checked={field.value}
          radioGroupProps={{
            ...field,
            onChange: (e) =>
              onChange(
                (e.target.value === "true") as ValueSatisfiesType<TFieldValues, boolean>,
              ),
          }}
          helperText={fieldState.error?.message}
        />
      )}
    />
  );
}

export interface YesNoProps extends Omit<FormControlProps, "radioGroup"> {
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  checked?: boolean;
  radioGroupProps?: RadioGroupProps;
}

export function YesNo(props: YesNoProps) {
  const { label, checked, helperText, radioGroupProps, ...rest } = props;
  return (
    <FormControl {...rest}>
      <FormLabel sx={{ mb: 2 }}>{label}</FormLabel>
      <RadioGroup row sx={{ gap: 2 }} {...radioGroupProps}>
        <FormRadio value={true} label="Yes" checked={checked === true} />
        <FormRadio value={false} label="No" checked={checked === false} />
      </RadioGroup>
      <FormHelperText sx={{ mt: 1, ml: 1 }}>{helperText}</FormHelperText>
    </FormControl>
  );
}

import { Grid } from "@mui/material";
import { Control } from "react-hook-form";

import { SafeStringForProse } from "@packages/types";

import { FormTextField } from "~/components/form/FormTextField";

import { ApplicationFields } from "./useApplicationForm";

export interface NotesFormProps {
  control: Control<ApplicationFields>;
  disabled?: boolean;
}

export function NotesForm(props: NotesFormProps) {
  const { control, disabled } = props;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormTextField
            control={control}
            name="notes"
            label="Please add any additional notes or comments"
            fullWidth
            multiline
            minRows={5}
            maxRows={12}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </>
  );
}

NotesForm.draftSchema = SafeStringForProse.schema();

NotesForm.submitSchema = NotesForm.draftSchema;

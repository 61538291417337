export const MONASH_PALETTE = {
  PORTAL_BACKGROUND: "#E3E3E3",
  MONASH_BLUE: "#006DAE",
  MONASH_DARK_GREY: "#3C3C3C",
  MONASH_MEDIUM_GREY: "#505050",
  MONASH_LIGHT_GREY: "#F6F6F6",
  FOOTER_TEXT: "#EAEAEA",
  ERROR: "#d32f2f",
  SUCCESS: "#2e7d32",
  PURPLE: "#746FB2",
  FUSCHIA: "#9651A0",
  RUBY: "#C8008F",
  ORANGE: "#D93F00",
  UMBER: "#795549",
  OLIVE: "#6F7C4D",
  GREEN: "#008A25",
};

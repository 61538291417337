import { Control } from "react-hook-form";
import { Button, Grid, Typography } from "@mui/material";

import { FormCountryISOSelect } from "~/components/form/FormCountrySelect";
import { FormSection } from "~/components/form/FormSection";
import { FormTextField } from "~/components/form/FormTextField";
import { WorkExperience } from "~/components/application/ApplicationForm/WorkExperienceForm";

import { GetFieldPathToType } from "~/types";
import { ApplicationFields } from "../ApplicationForm";

export const EmptyWorkExperience: WorkExperience = {
  position: "",
  employer: "",
  startDate: "",
  endDate: "",
  countryObtained: null,
  contact: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  },
};

export interface WorkExperienceFieldsProps {
  control: Control<ApplicationFields>;
  path: GetFieldPathToType<WorkExperience, ApplicationFields>;
  onConfirm: () => void;
  onCancel: () => void;
  title?: React.ReactNode;
  disabled?: boolean;
}

export function WorkExperienceFields(props: WorkExperienceFieldsProps) {
  const {
    title = "Work Experience",
    control,
    path,
    onConfirm,
    onCancel,
    disabled,
  } = props;

  return (
    <>
      <Grid item xs={12} mt={1}>
        <FormSection.Subheading gutterBottom={false}>{title}</FormSection.Subheading>
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          control={control}
          name={`${path}.position`}
          label="Position"
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          control={control}
          name={`${path}.employer`}
          label="Employer"
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          control={control}
          name={`${path}.startDate`}
          label="Start date"
          disabled={disabled}
          type="date"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          control={control}
          name={`${path}.endDate`}
          label="End Date (leave blank if continuing)"
          disabled={disabled}
          type="date"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormCountryISOSelect
          control={control}
          name={`${path}.countryObtained`}
          label="Country"
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormSection.Subheading>Referee Details</FormSection.Subheading>
        <Typography color="text.secondary">Leave blank if not applicable</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          control={control}
          name={`${path}.contact.firstName`}
          label="Referee first name"
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          control={control}
          name={`${path}.contact.lastName`}
          label="Referee last name"
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          control={control}
          name={`${path}.contact.email`}
          label="Email"
          disabled={disabled}
          // type="email" Not included as error handling is done by zod/RHF
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          control={control}
          name={`${path}.contact.phone`}
          label="Contact Number"
          disabled={disabled}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} container gap={2}>
        <Button variant="contained" onClick={onConfirm}>
          Confirm
        </Button>
        <Button variant="contained" color="error" onClick={onCancel}>
          Cancel
        </Button>
      </Grid>
    </>
  );
}

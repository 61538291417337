/**
 * Returns an array of the specified length.
 * Pre-filled with the index.
 *
 * @param length The length of the list.
 */
export function list(length: number): number[] {
  return Array.from({ length }).map((_, index) => index);
}

/**
 * Removes duplicates from a list.
 *
 * @param list The list to remove duplicates from.
 * @param getKey A function that extracts a key from each object in the list. Defaults to an identity function.
 */
export function unique<T>(list: T[], getKey: (a: T) => unknown = (a) => a): T[] {
  const keys = new Set();
  return list.filter((x) => {
    const key = getKey(x);
    if (keys.has(key)) return false;
    keys.add(key);
    return true;
  });
}

import { useState } from "react";
import {
  Control,
  UseFormTrigger,
  useFieldArray,
  useWatch,
  UseFormSetValue,
  FieldPath,
} from "react-hook-form";

import { TableFieldArrayPath, TableFieldArrayRecord } from "~/types";
import { ApplicationFields } from "~/components/application/ApplicationForm/useApplicationForm";

/**
 * Utility hook that handles state and triggers validation for tables in the form with array fields.
 *
 * @param path The path to the array field.
 * @param form References to the form's {@link Control control}, {@link UseFormSetValue setValue} and {@link UseFormTrigger trigger} values.
 * @param defaultValue The default value to use when a new record is added to the array field.
 */
export function useTableStateHelpers<
  TPath extends TableFieldArrayPath,
  TRecord extends TableFieldArrayRecord<TPath>,
>(
  path: TPath,
  form: {
    control: Control<ApplicationFields>;
    setValue: UseFormSetValue<ApplicationFields>;
    trigger: UseFormTrigger<ApplicationFields>;
  },
  defaultValue: TRecord,
) {
  const { control, setValue, trigger } = form;

  const recordsList = useWatch({ control, name: path }) as TRecord[];
  const { fields, append, remove } = useFieldArray({ control, name: path });

  const [editIndex, setEditIndex] = useState<number>();
  const [previous, setPrevious] = useState<TRecord>();
  const isEditing = editIndex !== undefined;

  const addNew = () => {
    append(defaultValue);
    setEditIndex(fields.length);
  };

  const edit = (index: number) => {
    // save existing data to state in case it needs to be restored
    trigger(`${path}.${index}`);
    setEditIndex(index);
    setPrevious(recordsList[index]);
  };

  const confirm = async () => {
    if (editIndex === undefined || !recordsList[editIndex]) return;

    const valid = await trigger(`${path}.${editIndex}`, { shouldFocus: true });
    if (!valid) return;

    // HACK: need to cast to a looser type to prevent this error during a Docker build:
    // TS2590: Expression produces a union type that is too complex to represent.
    const targetPath = `${path}.${editIndex}` as FieldPath<ApplicationFields>;

    setValue(targetPath, recordsList[editIndex]);

    // cleanup: clear the editIndex and previous data state
    setEditIndex(undefined);
    setPrevious(undefined);
  };

  const cancel = () => {
    if (editIndex === undefined || !recordsList[editIndex]) return;

    // HACK: need to cast to a looser type to prevent this error during a Docker build:
    // TS2590: Expression produces a union type that is too complex to represent.
    const targetPath = `${path}.${editIndex}` as FieldPath<ApplicationFields>;

    // If a previously saved state for this index exists, restore that value.
    // If there is no old data, that means this index is a newly added item, so delete it.
    if (isEditing && previous) setValue(targetPath, previous);
    else remove(editIndex);

    // cleanup: trigger validation to clear error states
    // and clear the editIndex and previous data state
    trigger(targetPath, { shouldFocus: true });
    setEditIndex(undefined);
    setPrevious(undefined);
  };

  return {
    /** Adds a new empty record to the table state and sets it as the record currently being edited. */
    addNew,
    /** Marks a record as currently being edited. Also saves the previous state of the record in case it needs to be restored. */
    edit,
    /** Validates and updates the data of the record currently being edited. */
    confirm,
    /** Cancels pending changes to a record and restores its previous state. */
    cancel,
    /** Contains the `fields` object and `remove` method from {@link useFieldArray}. */
    fieldArray: { fields, remove },
    /** Contains the index of the record current being edited and the stored state of the record from the hook state. */
    record:
      editIndex === undefined
        ? ({ isEditing: false, index: undefined, previous } as const)
        : ({ isEditing: true, index: editIndex, previous } as const),
  };
}

import { Alert, Link, Skeleton, Typography } from "@mui/material";

import { CampusLocation } from "@packages/types";
import { AUDFormat } from "@packages/utils";

import { useApplicationFee } from "~/hooks/payment";
import { FormSection } from "~/components/form/FormSection";

export interface PreambleProps {
  campusLocation: string;
}

export function Preamble(props: PreambleProps) {
  const { campusLocation } = props;

  const fee = useApplicationFee(campusLocation as CampusLocation);
  const formattedFee = fee ? (
    AUDFormat.format(fee.amount)
  ) : (
    <>
      $<Skeleton variant="text" width={50} sx={{ display: "inline-block" }} />
    </>
  );

  if (campusLocation === CampusLocation.MALAYSIA)
    return (
      <FormSection.Well>
        <FormSection.List>
          <Typography variant="body1" component="li">
            Complete this form to submit an application for an undergraduate or
            postgraduate course at Monash University's Malaysian campus.
          </Typography>
          <Typography variant="body1" component="li">
            A non-refundable application fee of {formattedFee} must be paid when you
            submit this form.
          </Typography>
          <Typography variant="body1" component="li">
            <strong>Do not</strong> use this form for Monash research degrees. The correct
            form is available in the future students{" "}
            <Link
              href="http://www.monash.edu/migr/future-students/apply"
              target="_blank"
              rel="noopener"
            >
              how to apply
            </Link>{" "}
            page.
          </Typography>
        </FormSection.List>
      </FormSection.Well>
    );
  else if (campusLocation === CampusLocation.AUSTRALIA)
    return (
      <FormSection.Well>
        <FormSection.List>
          <Typography variant="body1" component="li">
            Complete this form to submit an application for an undergraduate or
            postgraduate course at one of Monash University's Australian campuses.
          </Typography>
          <Typography variant="body1" component="li">
            A non-refundable application fee of {formattedFee} must be paid when you
            submit this form.
          </Typography>
          <Typography variant="body1" component="li">
            <strong>Do not</strong> use this form for Monash research degrees. The correct
            form is available in the future students{" "}
            <Link
              href="http://www.monash.edu/migr/future-students/apply"
              target="_blank"
              rel="noopener"
            >
              how to apply
            </Link>{" "}
            page.
          </Typography>
        </FormSection.List>
      </FormSection.Well>
    );

  return <Alert severity="error">Unknown application type {campusLocation}</Alert>;
}

import { styled, TableContainer, TableRow, TableCell } from "@mui/material";

export function DisplayTable() {}

DisplayTable.Container = styled(TableContainer)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.primary.main,
  borderRadius: theme.spacing(0.5),
}));

DisplayTable.HeaderRow = styled(TableRow)(({ theme }) => ({
  background: theme.palette.primary.main,
}));

DisplayTable.HeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
}));

import { Divider, ListSubheader, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

import ReceiptIcon from "@mui/icons-material/Receipt";

interface ExternalReceiptMenuProps {
  onClick?: () => void;
}

export function ExternalReceiptMenu({ onClick }: ExternalReceiptMenuProps) {
  return (
    <>
      <Divider />
      <ListSubheader sx={{ lineHeight: 3 }}>Receipt Generation</ListSubheader>
      <MenuItem
        component={Link}
        to="/support/generate-external-receipt"
        onClick={onClick}
      >
        <ReceiptIcon sx={{ mr: 1 }} color="action" />
        Generate external application receipt
      </MenuItem>
    </>
  );
}

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IApplicant,
  IApplicantCreatePayload,
  IApplicantCreateResponse,
  IApplicantNamesAndStudentIds,
  IApplicantUpdatePayload,
  IApplicantsPage,
  IApplicationEnquiryList,
  IErrorResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ApplicantApi<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * @description Search for applicants by name.
   *
   * @tags Applicant API
   * @name SearchApplicants
   * @summary Search for applicants
   * @request GET:/api/applicants
   * @response `200` `IApplicantsPage`
   * @response `401` `IErrorResponse` Not signed in.
   */
  searchApplicants = (
    query?: {
      /** The name to search for. */
      name?: string;
      /** @example 1 */
      page?: number;
      /** @example 10 */
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<IApplicantsPage, IErrorResponse>({
      path: `/api/applicants`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * @description Create an applicant. If the applicant already exists, the existing applicant ID is retrned.
   *
   * @tags Applicant API
   * @name CreateApplicant
   * @summary Create applicant
   * @request POST:/api/applicants
   * @response `201` `IApplicantCreateResponse`
   * @response `401` `IErrorResponse` Not signed in.
   */
  createApplicant = (data: IApplicantCreatePayload, params: RequestParams = {}) =>
    this.request<IApplicantCreateResponse, IErrorResponse>({
      path: `/api/applicants`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Gets a mapping of the given applicant IDs to their names and student IDs (smsContactId).
   *
   * @tags Applicant API
   * @name GetApplicantNamesAndIDs
   * @summary Get applicant names and student IDs
   * @request GET:/api/applicants/names
   * @response `200` `IApplicantNamesAndStudentIds`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getApplicantNamesAndIDs = (
    query: {
      /** The list of applicants to get names for. */
      applicantIds: string[];
    },
    params: RequestParams = {},
  ) =>
    this.request<IApplicantNamesAndStudentIds, IErrorResponse>({
      path: `/api/applicants/names`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * @description Get an applicant by their ID.
   *
   * @tags Applicant API
   * @name GetApplicant
   * @summary Get applicant
   * @request GET:/api/applicants/{applicantId}
   * @response `200` `IApplicant`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getApplicant = (applicantId: string, params: RequestParams = {}) =>
    this.request<IApplicant, IErrorResponse>({
      path: `/api/applicants/${applicantId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Update an applicant by their ID.
   *
   * @tags Applicant API
   * @name UpdateApplicant
   * @summary Update applicant
   * @request PUT:/api/applicants/{applicantId}
   * @response `200` `IApplicant`
   * @response `401` `IErrorResponse` Not signed in.
   */
  updateApplicant = (
    applicantId: string,
    data: IApplicantUpdatePayload,
    params: RequestParams = {},
  ) =>
    this.request<IApplicant, IErrorResponse>({
      path: `/api/applicants/${applicantId}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Get a list of the applicant's enquiries, grouped by application ID.
   *
   * @tags Applicant API
   * @name GetApplicantEnquiries
   * @summary Get an applicant's enquiries
   * @request GET:/api/applicants/{applicantId}/enquiries
   * @response `200` `(IApplicationEnquiryList)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getApplicantEnquiries = (applicantId: string, params: RequestParams = {}) =>
    this.request<IApplicationEnquiryList[], IErrorResponse>({
      path: `/api/applicants/${applicantId}/enquiries`,
      method: "GET",
      format: "json",
      ...params,
    });
}

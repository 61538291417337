import { TableCell, Tooltip, Box, Typography } from "@mui/material";
import { Merge, FieldError, FieldErrorsImpl } from "react-hook-form";

import ErrorIcon from "@mui/icons-material/Error";

import { CoursePreference } from "~/components/application/ApplicationForm/CoursePreferencesForm";
import { AcademicQualification } from "~/components/application/ApplicationForm/AcademicQualificationsForm";
import { WorkExperience } from "~/components/application/ApplicationForm/WorkExperienceForm";

import { getErrorStringArray } from "~/utils";

export interface ErrorIndicatorCellProps {
  errors?: Merge<
    FieldError,
    FieldErrorsImpl<CoursePreference | AcademicQualification | WorkExperience>
  >;
}

export function ErrorIndicatorCell(props: ErrorIndicatorCellProps) {
  const { errors } = props;
  const errorMessages: string[] = errors ? getErrorStringArray(errors) : [];
  return (
    <TableCell>
      {errors && (
        <Tooltip
          sx={{ verticalAlign: "middle" }}
          title={<TooltipContent errorMessages={errorMessages} />}
          placement="left-start"
          arrow
        >
          <ErrorIcon color="error" />
        </Tooltip>
      )}
    </TableCell>
  );
}

function TooltipContent(props: { errorMessages: string[] }) {
  const { errorMessages } = props;

  if (!errorMessages?.length)
    return (
      <>
        This record could not be submitted. Please remove or edit it to ensure all fields
        have been correctly entered.
      </>
    );

  return (
    <>
      <Typography variant="inherit">
        This record could not be submitted due to the following errors:
      </Typography>
      <Box component="ul" sx={{ my: 0.5, pl: 3 }}>
        {errorMessages.map((errorMessage, index) => (
          <Typography variant="inherit" component="li" key={index} fontStyle="italic">
            {errorMessage}
          </Typography>
        ))}
      </Box>
      <Typography variant="inherit">
        Please remove or edit it to ensure all fields have been correctly entered.
      </Typography>
    </>
  );
}

import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
  Theme,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  Box,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import { atom, useAtomValue } from "jotai";
import { useWatch } from "react-hook-form";

import { joinName } from "@packages/utils";
import { isEnquiryRecordType, isEnquiryType } from "@packages/types";

import { IApplication, IEnquiryGroup } from "~/api";
import { ApplicantDetailLabel } from "~/components/applicant/ApplicantDetailsCard";
import { useAuthState } from "~/components/auth/AuthProvider";
import { FormSelect } from "~/components/form/FormSelect";
import { FormTextField } from "~/components/form/FormTextField";
import { EnquiryTopicSelectModal } from "~/components/enquiry/EnquiryTopicSelectModal";
import { EnquiryDocumentUploadModal } from "~/components/enquiry/EnquiryDocumentUploadModal";
import { DocumentUploadItem } from "~/components/core/DocumentUploadModal";

import { CreateEnquiryFields, useCreateEnquiryForm } from "./useCreateEnquiryForm";
import { CreateEnquirySchema } from "./useCreateEnquiryForm"; // eslint-disable-line @typescript-eslint/no-unused-vars

export type EnquiryApplicant = {
  name: string;
  email: string;
  studentId: string;
};

/** Stores details about the current applicant to create an enquiry for. */
export const enquiryApplicantAtom = atom<EnquiryApplicant | null>(null);

export type SelectedEnquiryTopic = {
  recordType: string;
  enquiryType: string;
  category: {
    level1: string;
    level2: string;
  };
  subject: string;
  additionalFields: IEnquiryGroup["topics"][number]["additionalFields"];
  allowAttachments?: boolean;
  /**
   * Customise the label for the main enquiry message.
   *
   * NOTE: Use a short phrase that describes the content
   * of the message field, e.g. "Reason for late arrival".
   * Do not use an imperative (command) sentence, such as
   * "Please enter a reason for late arrival".
   *
   * @see `superRefine` in {@link CreateEnquirySchema}
   */
  messageLabel?: string;
};

/** Stores details about the selected enquiry group topic. */
export const selectedEnquiryTopicAtom = atom<SelectedEnquiryTopic | null>(null);

enum ModalState {
  CLOSED = "CLOSED",
  NEW = "NEW",
  EDITING = "EDITING",
  CHANGE_SUBJECT = "CHANGE_SUBJECT",
}

export interface NewEnquiryModalProps {
  open?: boolean;
  onClose?: () => void;
  applicationId: string;
  applicantId?: string;
  campusLocation?: string;
  coursePreferences?: IApplication["application"]["coursePreferences"];
  coursePreferencesLoading?: boolean;
}

/**
 * Modal dialog box interface to create a new enquiry
 */
export function NewEnquiryModal(props: NewEnquiryModalProps) {
  const {
    applicationId,
    applicantId,
    campusLocation,
    coursePreferences,
    coursePreferencesLoading = false,
    open = false,
    onClose,
  } = props;
  const [user] = useAuthState();
  const applicant = useAtomValue(enquiryApplicantAtom);

  const [modalState, setModalState] = useState<ModalState>(ModalState.CLOSED);
  const [uploadDocumentOpen, setUploadDocumentOpen] = useState(false);

  const { loading, form, createEnquiry, documentUpload } = useCreateEnquiryForm(
    applicationId,
    applicantId,
  );

  const { uploads, uploadInfo, addFiles, removeUpload, clearUploads } = documentUpload;

  const { control, setValue, handleSubmit, reset } = form;
  const subject = useWatch({ control, name: "subject" });

  const selectedEnquiryTopic = useAtomValue(selectedEnquiryTopicAtom);
  const { allowAttachments } = selectedEnquiryTopic ?? {};

  // Update form values when selected enquiry topic changes
  useEffect(() => {
    if (!open) return;
    if (!selectedEnquiryTopic) return;

    const {
      enquiryType,
      recordType,
      category,
      subject,
      additionalFields = [],
      messageLabel,
      allowAttachments,
    } = selectedEnquiryTopic;

    setValue("subject", subject);
    setValue("category1", category.level1);
    setValue("category2", category.level2);
    setValue("enquiryType", isEnquiryType(enquiryType) ? enquiryType : null);
    setValue("recordType", isEnquiryRecordType(recordType) ? recordType : null);
    setValue(
      "additionalFields",
      additionalFields.map((field) => ({ ...field, value: "" })),
    );
    if (messageLabel) setValue("_messageLabel", messageLabel);

    // Reset attachments if they are not permitted for the selected topic
    if (!allowAttachments) clearUploads();

    // Close the enquiry topic modal when subject changes
    setModalState(ModalState.EDITING);
  }, [open, selectedEnquiryTopic]);

  // Reset the form on modal open
  useEffect(() => {
    if (open) {
      reset();
      clearUploads();
      setModalState(ModalState.NEW);
    }
  }, [open]);

  function closeModal() {
    setModalState(ModalState.CLOSED);
    onClose?.();
  }

  async function onSubmit(data: CreateEnquiryFields) {
    const { success } = await createEnquiry(data);
    if (success) closeModal();
  }

  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const validUploads = uploads.filter((upload) => upload.userErrors.length === 0);

  return (
    <>
      <Dialog
        open={
          modalState === ModalState.EDITING || modalState === ModalState.CHANGE_SUBJECT
        }
        maxWidth="lg"
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle>Create New Enquiry</DialogTitle>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers>
            <Stack gap={2}>
              <FormTextField
                control={control}
                name="subject"
                label="Subject"
                fullWidth
                disabled={loading}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={() => setModalState(ModalState.CHANGE_SUBJECT)}
                      disabled={loading}
                    >
                      <EditIcon />
                    </IconButton>
                  ),
                }}
                // disables the inner input element without any visual style changes,
                // which is what we want in this instance.
                inputProps={{ disabled: true }}
              />
              <FormSelect
                control={control}
                name="coursePreference"
                label="Course Preference"
                loading={coursePreferencesLoading}
                disabled={loading}
              >
                {coursePreferences?.map(({ recordId, course, courseOffering }) => (
                  <MenuItem key={recordId} value={recordId}>
                    {course.code} {course.title} (
                    {courseOffering?.studyPeriod?.description})
                  </MenuItem>
                ))}
              </FormSelect>
              {selectedEnquiryTopic?.additionalFields?.map((field, index) => (
                <FormTextField
                  key={field.name}
                  control={control}
                  name={`additionalFields.${index}.value`}
                  label={field.label}
                  fullWidth
                  type={field.type === "date" ? "date" : "text"}
                  multiline={field.type === "text" && field.multiline}
                  minRows={5}
                  maxRows={12}
                  disabled={loading}
                />
              ))}
              <FormTextField
                control={control}
                name="message"
                label={selectedEnquiryTopic?.messageLabel ?? "Type your message..."}
                fullWidth
                multiline
                minRows={5}
                maxRows={12}
                disabled={loading}
              />
              <Grid container spacing={2} px={2} pt={1} pb={1.5}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    Raised by
                  </Typography>
                  <ApplicantDetailLabel
                    label="Agent Name"
                    value={joinName(user?.agent.givenNames, user?.agent.familyName)}
                  />
                  <ApplicantDetailLabel label="Agent Email" value={user?.email} />
                  <ApplicantDetailLabel label="Agency" value={user?.agency.name} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    On Behalf Of
                  </Typography>
                  <ApplicantDetailLabel label="Applicant Name" value={applicant?.name} />
                  <ApplicantDetailLabel
                    label="Applicant Email"
                    value={applicant?.email}
                  />
                  <ApplicantDetailLabel label="Application ID" value={applicationId} />
                </Grid>
                {allowAttachments && (
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Attached files
                    </Typography>
                    {validUploads.length === 0 && (
                      <Typography variant="body1" color="text.secondary" gutterBottom>
                        No files attached
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                      {validUploads.map((upload) => (
                        <Grid key={upload.id} item xs={12} sm={6} md={4}>
                          <DocumentUploadItem
                            upload={upload}
                            disabled={loading}
                            loading={documentUpload.uploading}
                            onRemove={() => removeUpload(upload.id)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              py: 1.5,
              px: 3,
              justifyContent: allowAttachments ? "space-between" : "flex-end",
            }}
          >
            {allowAttachments && (
              <Button
                variant="contained"
                disabled={loading}
                onClick={() => setUploadDocumentOpen(true)}
              >
                Attach files
              </Button>
            )}
            <Stack direction="row" gap={2}>
              <Button onClick={closeModal} disabled={loading}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Create
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>
      <EnquiryTopicSelectModal
        open={modalState === ModalState.NEW || modalState === ModalState.CHANGE_SUBJECT}
        onClose={() => {
          // Return to editing when there is a subject selected
          if (subject) setModalState(ModalState.EDITING);
          else closeModal();
        }}
        fullScreen={fullScreen}
        campusLocation={campusLocation}
      />
      {allowAttachments && (
        <EnquiryDocumentUploadModal
          open={uploadDocumentOpen}
          onClose={() => setUploadDocumentOpen(false)}
          uploads={uploads}
          uploadInfo={uploadInfo}
          addFiles={addFiles}
          removeUpload={removeUpload}
        />
      )}
    </>
  );
}

import { ThemeProvider, CssBaseline, LinearProgress } from "@mui/material";
import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";
import { setDefaultOptions } from "date-fns";
import enAU from "date-fns/locale/en-AU";

import { queryClient } from "~/utils";
import { defaultTheme } from "~/config/theme";
import { AppLoadingBar } from "~/components/core/AppLoadingBar";
import { NotificationProvider } from "~/components/core/NotificationProvider";

import { router } from "./routes";

setDefaultOptions({ locale: enAU });

export function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <AppLoadingBar />
        <RouterProvider
          router={router}
          fallbackElement={
            <LinearProgress
              sx={{ position: "fixed", top: 0, zIndex: 10000, width: "100%" }}
            />
          }
        />
      </QueryClientProvider>
      <NotificationProvider />
    </ThemeProvider>
  );
}

import { GCPAdminPermission } from "@packages/types";
import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";

import { GetApi, IGetLogEntriesResponseDTO, SupportApi } from "~/api";
import { useAuthState } from "~/components/auth/AuthProvider";
import { REFERENCE_DATA_STALE_TIME } from "~/config/constants";

function useHasPermission(permission: GCPAdminPermission) {
  const [user] = useAuthState();
  const roles = user?.agent.roles ?? [];
  return roles.includes(permission);
}

export function useIsDeveloper() {
  return useHasPermission(GCPAdminPermission.Developer);
}

export function useIsSupport() {
  return useHasPermission(GCPAdminPermission.Support);
}

export function useCanImpersonate() {
  return useHasPermission(GCPAdminPermission.Impersonate);
}

export function useCanGenerateExternalReceipt() {
  return useHasPermission(GCPAdminPermission.GenerateExternalReceipt);
}

export function useErrorLogs(options: {
  correlationId?: string;
  before?: Date;
  after?: Date;
  pageSize?: number;
  pageToken?: string;
}) {
  const { correlationId, before, after, pageSize = 25, pageToken } = options;
  return useInfiniteQuery<
    IGetLogEntriesResponseDTO,
    Error,
    InfiniteData<
      IGetLogEntriesResponseDTO,
      { correlationId?: string; pageToken?: string }
    >,
    unknown[],
    string | undefined
  >({
    queryKey: ["errorLogs", { correlationId, before, after, pageToken }],
    queryFn: ({ pageParam }) =>
      GetApi(SupportApi).getErrorLogs({
        correlationId,
        before: before?.toISOString(),
        after: after?.toISOString(),
        pageSize,
        pageToken: pageParam,
      }),
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage.nextPageToken,
    staleTime: REFERENCE_DATA_STALE_TIME,
  });
}

export function useMixLogs(options: {
  correlationId?: string;
  before?: Date;
  after?: Date;
  pageSize?: number;
  pageToken?: string;
}) {
  const { correlationId, before, after, pageSize = 25, pageToken } = options;
  return useInfiniteQuery<
    IGetLogEntriesResponseDTO,
    Error,
    InfiniteData<
      IGetLogEntriesResponseDTO,
      { correlationId?: string; pageToken?: string }
    >,
    unknown[],
    string | undefined
  >({
    queryKey: ["mixLogs", { correlationId, before, after, pageToken }],
    queryFn: ({ pageParam }) =>
      GetApi(SupportApi).getMixLogs({
        correlationId,
        before: before?.toISOString(),
        after: after?.toISOString(),
        pageSize,
        pageToken: pageParam,
      }),
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage.nextPageToken,
    staleTime: REFERENCE_DATA_STALE_TIME,
  });
}

import {
  Box,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import OptionsIcon from "@mui/icons-material/MoreVert";
import ActiveIcon from "@mui/icons-material/CheckCircle";
import InactiveIcon from "@mui/icons-material/Pending";
import SuspendedIcon from "@mui/icons-material/PauseCircle";
import DeactivatedIcon from "@mui/icons-material/RemoveCircle";

import { sentenceCase } from "@packages/utils";
import { AgentRole } from "@packages/types";

import { IAgent } from "~/api";
import { useCallbackFactory, useMenuAnchor } from "~/hooks/state";

const ROLES_TO_SHOW = [AgentRole.Counsellor, AgentRole.DelegatedAdmin];

export interface UsersTableRowProps {
  agent?: IAgent;
  onEdit?: () => void;
  onSuspend?: () => void;
  onUnsuspend?: () => void;
  onDeactivate?: () => void;
}

export function UsersTableRow(props: UsersTableRowProps) {
  const { agent, onEdit, onSuspend, onUnsuspend, onDeactivate } = props;
  const {
    contactId,
    firstName,
    lastName = <Skeleton />,
    email = <Skeleton />,
    roles,
    status,
  } = agent ?? {};

  const [menu, openMenu, closeMenu] = useMenuAnchor();

  const onMenuClick = useCallbackFactory(closeMenu);

  const canEdit = status === "active" || status === "inactive";
  const canSuspend = status === "active";
  const canUnsuspend = status === "suspended";
  const canDeactivate = status !== "inactive" && status !== "deactivated";

  return (
    <TableRow
      key={contactId}
      sx={{
        background: (theme) =>
          status && status !== "active" ? theme.palette.action.hover : undefined,
      }}
    >
      <TableCell>
        {firstName} {lastName}
      </TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {ROLES_TO_SHOW.filter((x) => roles?.includes(x)).map((role) =>
            role === AgentRole.DelegatedAdmin ? (
              <DelegatedAdminRoleChip key={role} />
            ) : role === AgentRole.Counsellor ? (
              <CounsellorRoleChip key={role} />
            ) : null,
          )}
          {!roles && <Skeleton variant="text" width={100} />}
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {!status && <Skeleton variant="text" width={100} />}
          {status === "active" && <ActiveIcon color="success" />}
          {status === "inactive" && <InactiveIcon color="disabled" />}
          {status === "suspended" && <SuspendedIcon color="warning" />}
          {status === "deactivated" && <DeactivatedIcon color="error" />}
          {typeof status === "string" && sentenceCase(status)}
        </Box>
      </TableCell>
      <TableCell padding="none">
        {status !== "deactivated" && (
          <IconButton disabled={!agent} onClick={openMenu}>
            <OptionsIcon />
          </IconButton>
        )}
      </TableCell>
      <Menu open={menu.open} anchorEl={menu.anchor} onClose={closeMenu}>
        {canEdit && onEdit && (
          <MenuItem onClick={onMenuClick(onEdit)}>Edit user</MenuItem>
        )}
        {canSuspend && onSuspend && (
          <MenuItem onClick={onMenuClick(onSuspend)}>Suspend user</MenuItem>
        )}
        {canUnsuspend && onUnsuspend && (
          <MenuItem onClick={onMenuClick(onUnsuspend)}>Unsuspend user</MenuItem>
        )}
        {canDeactivate && onDeactivate && (
          <MenuItem onClick={onMenuClick(onDeactivate)}>Deactivate user</MenuItem>
        )}
      </Menu>
    </TableRow>
  );
}

function DelegatedAdminRoleChip() {
  return (
    <Tooltip title="Responsible for managing users">
      <Chip
        label={<Typography variant="body2">{AgentRole.DelegatedAdmin}</Typography>}
        size="small"
        color="primary"
      />
    </Tooltip>
  );
}

function CounsellorRoleChip() {
  return (
    <Tooltip title="Creates and submits applications">
      <Chip
        label={<Typography variant="body2">{AgentRole.Counsellor}</Typography>}
        size="small"
      />
    </Tooltip>
  );
}

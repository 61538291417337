import { useQuery } from "@tanstack/react-query";

import { CourseCampusCountry, CourseType } from "@packages/types";
import { GetFunctionNamesThatReturn, TrimStringStart } from "@packages/utils";

import {
  ReferenceDataApi,
  ICountryResidencyStatuses,
  ICampusCountry,
  IInstitutionType,
  IPicklistValue,
  GetApi,
} from "~/api";
import { REFERENCE_DATA_STALE_TIME, REALTIME_DATA_STALE_TIME } from "~/config/constants";

// --- fetchers ---

function useCountryResidencyStatus() {
  return useQuery({
    queryKey: ["reference", "country-residency-status"],
    queryFn: () => GetApi(ReferenceDataApi).getCountryResidencyStatus(),
    staleTime: REFERENCE_DATA_STALE_TIME,
    retry: false,
  });
}

export function useApplicationFees() {
  return useQuery({
    queryKey: ["reference", "application-fees"],
    queryFn: () => GetApi(ReferenceDataApi).getApplicationFees(),
    staleTime: REFERENCE_DATA_STALE_TIME,
    retry: false,
  });
}

/**
 * Get a generic reference data list.
 */
export function useReferenceData<
  T extends TrimStringStart<
    GetFunctionNamesThatReturn<ReferenceDataApi, Promise<IPicklistValue[]>>,
    "get"
  >,
>(id: T) {
  return useQuery({
    queryKey: ["reference", id],
    queryFn: () => GetApi(ReferenceDataApi)[`get${id}`](),
    staleTime: REFERENCE_DATA_STALE_TIME,
    retry: false,
  });
}

export function useCountriesSACC() {
  return useQuery({
    queryKey: ["reference", "countries", "SACC"],
    queryFn: () => GetApi(ReferenceDataApi).getCountriesSacc(),
    staleTime: REFERENCE_DATA_STALE_TIME,
    retry: false,
  });
}

export function useCountriesISO() {
  return useQuery({
    queryKey: ["reference", "countries", "ISO"],
    queryFn: () => GetApi(ReferenceDataApi).getCountriesIso(),
    staleTime: REFERENCE_DATA_STALE_TIME,
    retry: false,
  });
}

export function useCourses(options: {
  campusCountry: ICampusCountry | null;
  citizenshipType: string | null;
  courseType: CourseType;
  courseYear: number;
  courseName: string;
  pathwayCourseCode?: string;
  disabled?: boolean;
}) {
  const {
    campusCountry,
    citizenshipType,
    courseType,
    courseYear,
    courseName,
    pathwayCourseCode,
    disabled,
  } = options;
  return useQuery({
    queryKey: [
      "reference",
      "courses",
      {
        courseYear,
        campusCountry,
        citizenshipType,
        courseName,
        pathwayCourseCode,
      },
    ],
    queryFn: () =>
      GetApi(ReferenceDataApi).getCourses({
        campusCountry: campusCountry || "Australia",
        citizenshipTypes: citizenshipType ? [citizenshipType] : [],
        courseType,
        courseYear,
        courseName,
        pathwayCourseCode,
      }),
    staleTime: REALTIME_DATA_STALE_TIME,
    retry: false,
    enabled:
      !disabled && Boolean(courseYear > 0 && courseName.length >= 3 && campusCountry),
  });
}

export function useCourse(options: {
  courseYear: number;
  courseCode: string;
  disabled?: boolean;
}) {
  const { courseYear, courseCode, disabled } = options;
  return useQuery({
    queryKey: ["reference", "courses", { courseYear, courseCode }],
    queryFn: () =>
      GetApi(ReferenceDataApi).getCourseByCourseCode(courseCode, { courseYear }),
    staleTime: REALTIME_DATA_STALE_TIME,
    retry: false,
    enabled: !disabled && Boolean(courseCode),
  });
}

export function useAdmissionTestTypes() {
  return useQuery({
    queryKey: ["reference", "admission-test-types"],
    queryFn: () => GetApi(ReferenceDataApi).getAdmissionTestTypes(),
    staleTime: REFERENCE_DATA_STALE_TIME,
    retry: false,
  });
}

export function useSecondaryQualificationTypes(options: { isOverseas?: boolean } = {}) {
  const { isOverseas = false } = options;
  return useQuery({
    queryKey: ["reference", "secondary-qualification-types", { isOverseas }],
    queryFn: () =>
      GetApi(ReferenceDataApi).getSecondaryQualificationTypes({ isOverseas }),
    staleTime: REFERENCE_DATA_STALE_TIME,
    retry: false,
  });
}

export function useInstitutions(options: {
  type?: IInstitutionType;
  country?: string;
  disabled?: boolean;
}) {
  const { country, type, disabled } = options;
  return useQuery({
    queryKey: ["reference", "institutions-list", { type, country }],
    queryFn: () =>
      GetApi(ReferenceDataApi).getInstitutionsList({
        country: country ?? "",
        type: type || "Tertiary",
      }),
    staleTime: REALTIME_DATA_STALE_TIME,
    retry: false,
    enabled: !disabled && !!type && !!country,
  });
}

// --- helpers ---

type ICountryResidencyStatusMap = {
  [value: string]: ICountryResidencyStatuses["residencyStatuses"][number];
};

export function useSelectedResidencyStatuses(country: string) {
  const residency = useCountryResidencyStatus();
  return {
    ...residency,
    countries: residency.data?.map((x) => x.country),
    statuses:
      residency.data
        ?.find((x) => x.country === country)
        ?.residencyStatuses?.reduce?.<ICountryResidencyStatusMap>((map, status) => {
          map[status.value] = status;
          return map;
        }, {}) ?? {},
  };
}

export function useFindResidencyStatusCountry(residencySubStatus: string) {
  const residency = useCountryResidencyStatus();
  const country = residency.data?.find((statuses) =>
    statuses.residencyStatuses.find((status) => status.value === residencySubStatus),
  )?.country;
  if (!country) return null;
  return Object.values(CourseCampusCountry).find((x) => x === country) ?? null;
}

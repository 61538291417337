import { TableContainer, TableRow, TableCell, styled } from "@mui/material";

export function InnerTable() {}

InnerTable.Container = styled(TableContainer)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.secondary.light,
  borderRadius: theme.spacing(0.5),
}));

InnerTable.HeaderRow = styled(TableRow)(({ theme }) => ({
  background: theme.palette.secondary.light,
}));

InnerTable.HeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
}));

import { Alert, AlertTitle, Grid, Link, MenuItem, Typography } from "@mui/material";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import { useMemo } from "react";
import { z } from "zod";

import {
  DateStringSchema,
  EmailStringSchema,
  parseDateString,
  PhoneStringSchema,
  asNonMonashEmail,
  SafeNameString,
} from "@packages/types";

import { createAddIssue } from "~/utils";
import { useSelectedResidencyStatuses } from "~/hooks/reference-data";
import { FormPhoneField } from "~/components/form/FormPhoneField";
import { FormSelect } from "~/components/form/FormSelect";
import { FormSwitch } from "~/components/form/FormSwitch";
import { FormTextField } from "~/components/form/FormTextField";

import { NewApplicationFields } from "./useNewApplicationForm";

export interface ApplicantDetailsFormProps {
  control: Control<NewApplicationFields>;
  setValue: UseFormSetValue<NewApplicationFields>;
  disabled?: boolean;
  isNewApplicant?: boolean;
}

export function ApplicantDetailsForm(props: ApplicantDetailsFormProps) {
  const { control, setValue, disabled, isNewApplicant } = props;

  const [hasMononymousName, campusLocation, residencyStatus] = useWatch({
    control,
    name: [
      "applicantDetails.hasMononymousName",
      "applicantDetails.campusLocation",
      "applicantDetails.residencyStatus",
    ],
  });

  // Fetch reference data
  const residency = useSelectedResidencyStatuses(campusLocation);

  const isBlocked = useMemo(
    () => residency.statuses?.[residencyStatus]?.blocked ?? false,
    [residencyStatus, residency],
  );

  return (
    <Grid container spacing={2}>
      {!hasMononymousName && (
        <Grid item xs={12} md={6}>
          <FormTextField
            control={control}
            name="applicantDetails.legalGivenNames"
            label="Legal given names"
            disabled={disabled || !isNewApplicant}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12} md={hasMononymousName ? 12 : 6}>
        <FormTextField
          control={control}
          name="applicantDetails.legalFamilyNames"
          label={hasMononymousName ? "Legal name" : "Legal family names"}
          disabled={disabled || !isNewApplicant}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormSwitch
          control={control}
          name="applicantDetails.hasMononymousName"
          label="Applicant has a mononymous name"
          disabled={disabled || !isNewApplicant}
          sx={{ ml: 2 }}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <FormTextField
          control={control}
          name="applicantDetails.dateOfBirth"
          label="Date of birth"
          disabled={disabled || !isNewApplicant}
          type="date"
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <FormPhoneField
          control={control}
          name="applicantDetails.mobile"
          label="Mobile Number"
          disabled={disabled || !isNewApplicant}
          defaultCountry="AU"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          control={control}
          name="applicantDetails.email"
          label="Email Address (must be applicant's email address)"
          disabled={disabled || !isNewApplicant}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Alert severity="warning">
          <Typography variant="inherit" paragraph>
            <Typography variant="inherit" component="span" fontWeight="bold">
              IMPORTANT!
            </Typography>{" "}
            Ensure you check your system to see if there is an existing application or
            draft for this applicant. Creating a new application for the same applicant
            will increase the time it takes for our staff to process this application.
          </Typography>
          <Typography variant="inherit">
            If you have previously created an application for this applicant and are
            facing an issue with that application, please reach out to Monash for support.
          </Typography>
        </Alert>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormSelect
          control={control}
          name="applicantDetails.campusLocation"
          label="Destination Campus"
          loading={residency.isPending}
          disabled={disabled}
          onBeforeChange={() => setValue("applicantDetails.residencyStatus", "")}
        >
          {residency.countries?.map?.((country) => (
            <MenuItem key={country} value={country}>
              {country}
            </MenuItem>
          ))}
        </FormSelect>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormSelect
          control={control}
          name="applicantDetails.residencyStatus"
          label="Citizenship Status"
          loading={residency.isPending}
          disabled={!campusLocation || disabled}
        >
          {Object.values(residency.statuses ?? [])?.map?.(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </FormSelect>
      </Grid>
      {isBlocked && (
        <Grid item xs={12}>
          <Alert severity="error">
            <AlertTitle>
              <strong>Stop</strong>
            </AlertTitle>
            If you are a citizen or permanent resident,{" "}
            <strong>do not fill out this form</strong>. Instead, refer to:{" "}
            <Link href="http://vtac.edu.au" target="_blank" rel="noreferrer">
              vtac.edu.au
            </Link>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
}

ApplicantDetailsForm.schema = z
  .object({
    legalGivenNames: SafeNameString(40).schema(),
    legalFamilyNames: SafeNameString().schema(),
    hasMononymousName: z.boolean(),
    dateOfBirth: DateStringSchema(),
    mobile: PhoneStringSchema(),
    email: asNonMonashEmail(EmailStringSchema()),
    campusLocation: z.string(),
    residencyStatus: z.string(),
  })
  .superRefine(
    (
      {
        legalGivenNames,
        legalFamilyNames,
        hasMononymousName,
        dateOfBirth,
        campusLocation,
        residencyStatus,
      },
      ctx,
    ) => {
      const addIssue = createAddIssue(ctx);
      if (hasMononymousName && !legalFamilyNames)
        addIssue("Please provide legal name", "legalLastName");

      if (!hasMononymousName) {
        if (!legalGivenNames)
          addIssue("Please provide legal given names", "legalGivenNames");
        if (!legalFamilyNames)
          addIssue("Please provide legal family names", "legalFamilyNames");
      }

      const dob = parseDateString(dateOfBirth);
      if (dob.getTime() > Date.now())
        addIssue("Date of birth must be in the past", "dateOfBirth");

      if (!campusLocation)
        addIssue("Please select a destination campus", "campusLocation");

      if (!residencyStatus)
        addIssue("Please select a citizenship status", "residencyStatus");
    },
  );

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Alert, Button, Grid, Link, Paper, Typography } from "@mui/material";

import { useDocumentTitle } from "~/hooks/title";
import { useAuthState, useSignIn } from "~/components/auth/AuthProvider";
import { ButtonLoadingSpinner } from "~/components/core/ButtonLoadingSpinner";
import { Loading } from "~/components/core/Loading";
import { Heading } from "~/components/core/Heading";
import { ErrorCorrelationId } from "~/components/core/ErrorMessage";
import { NotificationMessage } from "~/components/core/Notification";

export function SignInPage() {
  useDocumentTitle("Sign in");

  const { state, search } = useLocation();
  const navigate = useNavigate();
  const signIn = useSignIn();
  const [user, loading] = useAuthState();

  const [disableButton, setDisableButton] = useState(false);
  const { from, reason } = state ?? {};

  useEffect(() => {
    if (loading) return;
    let path = "/";
    if (typeof from === "string" && from.startsWith("/")) path = from;
    if (user) navigate(path, { replace: true });
  }, [loading, from, user]);

  if (loading || user) return <Loading />;

  const params = new URLSearchParams(search);
  const error = params.get("error");
  const message = params.get("message");
  const correlationId = params.get("correlationId");

  const shutdownNotice = (
    <Alert severity="info" sx={{ my: 1 }}>
      <Heading variant="body1" fontWeight="bold">
        Need Help?
      </Heading>
      <Typography variant="inherit" gutterBottom>
        Thank you for making the transition to our new Monash Partner Portal. We
        appreciate you working with us to protect our student data now and into the
        future.
      </Typography>
      <Typography variant="inherit">
        If you need help please go to the{" "}
        <Link href="https://www.monash.edu/study/why-choose-monash/information-for-agents/agent-portal-migration/_nocache">
          Agent Portal Migration Information page
        </Link>{" "}
        or call Monash Helpdesk on{" "}
        <Link href="tel:+61399051777">+61&nbsp;3&nbsp;9905&nbsp;1777</Link> for support.
      </Typography>
    </Alert>
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      minHeight="90vh"
    >
      {/* maxWidth should be 500 when the shutdown notice banner is removed */}
      <Grid item sx={{ width: "100%", maxWidth: 740, px: 3 }}>
        <Paper sx={{ p: 2, borderRadius: 0.5 }}>
          <Heading textAlign="center">Partner Sign In</Heading>
          <Typography variant="body1" color="secondary" textAlign="center">
            Please sign in to access the Partner Portal.
          </Typography>
          {shutdownNotice}
          {typeof reason === "string" && (
            <Alert severity="info" sx={{ mt: 1 }}>
              {reason}
            </Alert>
          )}
          {error && message && (
            <Alert severity="error" sx={{ mt: 1 }}>
              <NotificationMessage
                message={message}
                subtitle={<ErrorCorrelationId correlationId={correlationId} mt={0.5} />}
              />
            </Alert>
          )}
          <Button
            sx={{ mt: 2 }}
            fullWidth
            variant="contained"
            type="submit"
            disabled={disableButton}
            onClick={() => {
              setDisableButton(true);
              signIn(from);
            }}
          >
            Sign In
            <ButtonLoadingSpinner show={disableButton} sx={{ ml: 1 }} />
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}

import { Typography, TypographyProps } from "@mui/material";

export interface HeadingProps extends TypographyProps {}

export function Heading(props: HeadingProps) {
  return (
    <Typography
      variant="h3"
      textTransform="uppercase"
      fontWeight="bold"
      fontFamily="Roboto Condensed"
      {...props}
    />
  );
}

import { z } from "zod";
import { format } from "date-fns";

export const ISO_DATE_REGEX =
  /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}([+-][0-2]\d:[0-5]\d|Z)/;

export const DATE_STRING_REGEX = /\d{4}(\/|-)\d{2}(\/|-)\d{2}/;

/**
 * Attempts to parse a date string in one of the following formats:
 *  - yyyy-MM-ddThh:mm:ss.SSSZ (ISO8601)
 *  - yyyy-MM-dd
 *  - yyyy/MM/dd
 */
export function parseDateString(value: string) {
  return ISO_DATE_REGEX.test(value)
    ? new Date(value)
    : DATE_STRING_REGEX.test(value)
      ? new Date(value.replaceAll("/", "-"))
      : new Date(value);
}

/**
 * Transforms a `Date` or ISO8601 date string into yyyy-MM-dd format.
 * Returns an empty string if the given date is invalid.
 */
export function toDateString(date: Date | string | null | undefined): string {
  if (!date) return "";
  const dateObject = typeof date === "string" ? new Date(date) : date;
  if (isNaN(dateObject.getTime())) return "";
  return format(dateObject, "yyyy-MM-dd");
}

/**
 * Returns `true` if the given string is a valid date string in one of the following formats:
 *  - yyyy-MM-ddThh:mm:ss.SSSZ (ISO8601)
 *  - yyyy-MM-dd
 *  - yyyy/MM/dd
 */
export function isDateString(value: string) {
  const date = parseDateString(value);
  return !isNaN(date.getTime());
}

/**
 * Create a validator function which will return `true` if the given
 * {@link isDateString date string is valid} and between the given range.
 */
export function isDateStringWithinRange(options: {
  min: Date;
  max: Date;
}): (value: string) => boolean {
  return (value) => {
    if (!isDateString(value)) return false;
    const { min, max } = options;
    const date = parseDateString(value);
    return date.getTime() >= min.getTime() && date.getTime() <= max.getTime();
  };
}

/**
 * Validates date strings in the following format:
 *  - yyyy-MM-ddThh:mm:ss.SSSZ (ISO8601)
 *  - yyyy-MM-dd
 *  - yyyy/MM/dd
 */
export function DateStringSchema(message: string = "Please provide a valid date") {
  return z
    .string()
    .trim()
    .refine(
      isDateStringWithinRange({
        min: new Date("1900-01-01"),
        max: new Date("2099-12-31"),
      }),
      message,
    );
}

DateStringSchema.allowBlank = (message?: string) => {
  return z.union([DateStringSchema(message), z.literal("")]);
};

import { Controller, FieldValues, ControllerProps, useWatch } from "react-hook-form";
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Skeleton,
} from "@mui/material";

import { GetFieldPathToType } from "~/types";

import { FormRadio, FormRadioProps } from "./FormRadio";

export interface FormRadioGroupProps<
  TValue = unknown,
  TFieldValues extends FieldValues = FieldValues,
> extends Pick<ControllerProps<TFieldValues>, "control">,
    Omit<FormControlProps, "children"> {
  name: GetFieldPathToType<TValue | null, TFieldValues>;
  label?: React.ReactNode;
  options?: FormRadioProps<TValue>[];
  loading?: boolean;
  value?: TValue;
}

export function FormRadioGroup<
  TValue = unknown,
  TFieldValues extends FieldValues = FieldValues,
>(props: FormRadioGroupProps<TValue, TFieldValues>) {
  const { name, control, label, options, loading, disabled, ...rest } = props;
  const value = useWatch({ control, name });
  return (
    <Controller
      name={name}
      control={control}
      rules={{}}
      render={({ field, fieldState }) => (
        <FormControl
          id={name}
          error={fieldState.invalid}
          disabled={disabled || loading}
          {...rest}
        >
          <FormLabel sx={{ mb: 2 }}>{label}</FormLabel>
          <RadioGroup row {...field} sx={{ gap: 2 }}>
            {!loading &&
              options?.map((option) => (
                <FormRadio
                  key={String(option.value)}
                  checked={option.value === value}
                  {...option}
                />
              ))}
            {loading && <FormRadio label={<Skeleton />} />}
            {loading && <FormRadio label={<Skeleton />} />}
          </RadioGroup>
          <FormHelperText sx={{ mt: 1, ml: 1 }}>
            {fieldState.error?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}

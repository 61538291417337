import { Grid, MenuItem } from "@mui/material";
import { Control } from "react-hook-form";
import { z } from "zod";

import { SafeString } from "@packages/types";

import { IAgentInfo } from "~/api";
import { FormSelect } from "~/components/form/FormSelect";
import { FormSection } from "~/components/form/FormSection";

import { NewApplicationFields } from "./useNewApplicationForm";

export interface DelegatedAgencyFormProps {
  control: Control<NewApplicationFields>;
  disabled?: boolean;
  agencyPartners?: IAgentInfo["agencyPartners"];
}

export function DelegatedAgencyForm(props: DelegatedAgencyFormProps) {
  const { control, disabled, agencyPartners = [] } = props;

  return (
    <>
      <FormSection.Well>
        If you require another agency branch to manage this application after submission,
        please select that branch from the dropdown below. If you're not delegating this
        application, please select "None".
      </FormSection.Well>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormSelect
            control={control}
            name="delegator.delegatorOrgUnitCode"
            label="Delegated Agency Branch"
            disabled={disabled}
            displayEmpty
          >
            <MenuItem value="">None</MenuItem>
            {agencyPartners.map((partner) => (
              <MenuItem key={partner.orgUnitCode} value={partner.orgUnitCode}>
                {partner.name}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
      </Grid>
    </>
  );
}

DelegatedAgencyForm.schema = z.object({
  delegatorOrgUnitCode: SafeString.schema(),
});

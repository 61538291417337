import { useEffect } from "react";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { Container, Stack } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import { usePage } from "~/hooks/query-param";

import {
  DashboardFilterButtons,
  useDashboardFilter,
  useDashboardSearch,
} from "./DashboardFilterButtons";
import { isApplicationId, SearchBox, SearchBoxFields } from "./SearchBox";
import { ApplicantNameSearch } from "./ApplicantNameSearch";
import { ApplicationStatusSearch } from "./ApplicationStatusSearch";
import { ApplicationIdSearch } from "./ApplicationIdSearch";

const DEFAULT_FILTER = "Draft";
const DEFAULT_SEARCH = "";
const DEFAULT_PAGE = 1;

const lastDashboardStateAtom = atom({
  filter: DEFAULT_FILTER,
  search: DEFAULT_SEARCH,
  page: DEFAULT_PAGE,
});

/**
 * Get the last dashboard state and return a URL with the appropriate
 * parameters that will restore the state when navigated to.
 */
export function useLastDashboardPath() {
  const { filter, search, page } = useAtomValue(lastDashboardStateAtom);

  const params = new URLSearchParams();
  if (filter && filter !== DEFAULT_FILTER) params.append("filter", filter);
  if (search && search !== DEFAULT_SEARCH) params.append("search", search);
  if (page && page !== DEFAULT_PAGE) params.append("page", String(page));

  const query = params.toString();
  return query ? `/?${query}` : "/";
}

export function DashboardPage() {
  const [filter, setFilter] = useDashboardFilter(DEFAULT_FILTER);
  const [search, setSearch] = useDashboardSearch(DEFAULT_SEARCH);
  const [page, setPage] = usePage({ defaultValue: DEFAULT_PAGE });

  const setLastDashboardState = useSetAtom(lastDashboardStateAtom);

  // Save the last dashboard state for when we need to navigate back here
  useEffect(() => {
    setLastDashboardState({ filter, search, page });
  }, [filter, search, page]);

  const searchForm = useForm<SearchBoxFields>({
    resolver: zodResolver(SearchBox.schema),
    defaultValues: { search },
  });

  return (
    <Container>
      <Stack my={3} gap={3}>
        <DashboardFilterButtons
          selectedFilter={filter}
          onClick={(filter) => {
            setFilter(filter);
            setSearch("");
            setPage(1);
            searchForm.resetField("search", { defaultValue: DEFAULT_SEARCH });
          }}
        />
        <SearchBox
          form={searchForm}
          onSearch={({ search }) => {
            setSearch(search);
            setFilter(isApplicationId(search) ? "ApplicationId" : "Applicants");
            setPage(1);
          }}
        />
        {filter === "ApplicationId" && <ApplicationIdSearch applicationId={search} />}
        {filter === "Applicants" && (
          <ApplicantNameSearch name={search} page={page} setPage={setPage} />
        )}
        {(filter === "Draft" || filter === "InProgress" || filter === "Completed") && (
          <ApplicationStatusSearch statusType={filter} page={page} setPage={setPage} />
        )}
      </Stack>
    </Container>
  );
}

import { z } from "zod";
import { format } from "date-fns";

export const YEAR_STRING_REGEX = /\d{4}/;

/**
 * Attempts to parse a year string into a number.
 */
export function parseYearString(value: string) {
  return Number(value);
}

/**
 * Transforms a `Date` or ISO8601 date string into yyyy format.
 * Returns an empty string if the given date is invalid.
 */
export function toYearString(date: Date | string | null | undefined) {
  if (!date) return "";
  const dateObject = typeof date === "string" ? new Date(date) : date;
  if (isNaN(dateObject.getTime())) return "";
  return format(dateObject, "yyyy");
}

/**
 * Returns `true` if the given string is a valid year (YYYY).
 */
export function isYearString(value: string) {
  return YEAR_STRING_REGEX.test(value);
}

/**
 * Create a validator function which will return `true` if the
 * given string is a valid year (YYYY) between the given range.
 */
export function isYearStringWithinRange(options: {
  min: number;
  max: number;
}): (value: string) => boolean {
  return (value: string) => {
    if (!isYearString(value)) return false;
    const { min, max } = options;
    const year = Number(value);
    return year >= min && year <= max;
  };
}

/**
 * Validates that a string is a valid year (YYYY).
 */
export function YearStringSchema(message: string = "Please provide a valid year") {
  return z
    .string()
    .trim()
    .refine(isYearStringWithinRange({ min: 1900, max: 2099 }), message);
}

YearStringSchema.allowBlank = (message?: string) => {
  return z.union([YearStringSchema(message), z.literal("")]);
};

import { useQuery } from "@tanstack/react-query";

import { DATA_STALE_TIME } from "~/config/constants";
import { ApplicantApi, GetApi } from "~/api";

export function useApplicant(applicantId?: string) {
  return useQuery({
    queryKey: ["applicants", applicantId],
    queryFn: () => GetApi(ApplicantApi).getApplicant(applicantId ?? ""),
    retry: false,
    enabled: Boolean(applicantId),
    staleTime: DATA_STALE_TIME,
  });
}

export function useApplicantEnquiries(applicantId?: string) {
  return useQuery({
    queryKey: ["applicants", applicantId, "enquiries"],
    queryFn: () => GetApi(ApplicantApi).getApplicantEnquiries(applicantId ?? ""),
    retry: false,
    enabled: Boolean(applicantId),
    staleTime: DATA_STALE_TIME,
  });
}

import { atom, WritableAtom } from "jotai";

/**
 * Wraps an atom that writes a {@link Date} as an ISO string
 * and parses a {@link Date} from a string when read.
 */
export function dateStringAtom<TAtom extends WritableAtom<string, [string], void>>(
  config: TAtom,
) {
  return atom(
    (get) => {
      // Parse date string when reading the value
      const dateString = get(config);
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return null;
      return date;
    },
    // Stringify date when setting the value
    (_, set, newText: Date | null) => set(config, newText?.toISOString() ?? ""),
  );
}

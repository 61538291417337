import { DurationFormat, formatSeconds } from "@packages/utils";

export interface DurationProps {
  /**
   * The duration in seconds.
   */
  children?: number | undefined | null;

  /**
   * Format to ouptut the duration in.
   */
  format?: DurationFormat;

  defaultValue?: string;
}

export function Duration(props: DurationProps) {
  const { children: value, format = "s", defaultValue = null } = props;
  // If the value is empty, return the default value
  if (!value) return <>{defaultValue}</>;
  return <>{formatSeconds(value, format)}</>;
}

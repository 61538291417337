import { FieldPath, FieldValues, PathValue } from "react-hook-form";

/**
 * Returns the value in an object using a string path.
 * If there is nothing at the specified path, `undefined` will be returned.
 */
export function getObjectValueByPath<
  TValue extends FieldValues,
  TPath extends FieldPath<TValue>,
>(object: TValue, path: TPath): PathValue<TValue, TPath> | undefined {
  const segments = path.split(".");

  let segment: string | undefined = segments.shift();
  let value: unknown = object;

  // While there is a segment to get and the value is not falsy
  while (segment && value) {
    // Get the value at the path
    if (typeof value === "object") value = (value as Record<string, unknown>)[segment];
    // Otherwise, set it to undefined as we don't want to return something from a partial path
    else value = undefined;
    // Get the next segment in the path
    segment = segments.shift();
  }

  return value as PathValue<TValue, TPath> | undefined;
}

/**
 * Returns `true` if any of the fields in the given object have a value of `true`.
 */
export function doesObjectHaveTrueValues(object: object): boolean {
  const stack: unknown[] = [object];
  while (stack.length > 0) {
    const target = stack.pop()!;
    if (typeof target === "object" && target !== null)
      stack.push(...Object.values(target));
    if (target === true) return true;
  }
  return false;
}
/**
 * Recurse through a nested react-hook-form errors object and return a string of error messages.
 */
export function getErrorStringArray(object: object): string[] {
  const errors: string[] = [];

  for (const [key, value] of Object.entries(object)) {
    if (key === "message") errors.push(value);
    if (typeof value === "object" && value) {
      errors.push(...getErrorStringArray(value));
    }
  }

  return errors;
}

import { EError } from "exceptional-errors";
import { isAxiosError } from "axios";
import { z } from "zod";

export type ErrorAction = "refresh" | "back" | "signin";

/** Route error is thrown by `react-router-dom` */
const RouteErrorSchema = z.object({
  data: z.string(),
  status: z.number(),
  statusText: z.string(),
});

/** Standard error payload shape from the server */
export type RequestError = z.infer<typeof RequestErrorSchema>;
const RequestErrorSchema = z.object({
  error: z.string(),
  statusCode: z.number(),
  message: z.string(),
  details: z.string().optional(),
  correlationId: z.string().optional(),
});

export type AppErrorInfo = {
  code: string | number;
  title: string;
  actions?: ErrorAction[];
};

export class AppError extends EError<AppErrorInfo> {
  override info: AppErrorInfo;
  constructor(message: string, info?: AppErrorInfo) {
    super(message);
    this.info = {
      code: info?.code ?? "",
      title: info?.title ?? "",
      actions: info?.actions,
    };
  }
}

export class AppNotFoundError extends AppError {
  constructor(message: string = "", info?: AppErrorInfo) {
    super(message, info);
  }
}

export function parseRequestError(error: unknown) {
  if (isAxiosError(error)) {
    const result = RequestErrorSchema.safeParse(error.response?.data);
    if (result.success) return result.data;
  }
  return null;
}

export function parseRouteError(error: unknown) {
  const routeError = RouteErrorSchema.safeParse(error);
  return routeError.success ? routeError.data : null;
}

export function parseAppError(error: unknown) {
  return error instanceof AppError ? error : null;
}

/**
 * Scroll to the given HTML element or the HTML element with the given id.
 */
export function smoothScrollTo(elementOrId: string | HTMLElement | null) {
  const element =
    typeof elementOrId === "string" ? document.getElementById(elementOrId) : elementOrId;
  element?.scrollIntoView({ behavior: "smooth" });
}

/**
 * Given a list of HTML element ids, find the element that appears
 * first in the DOM and scroll to it.
 *
 * @param elementIds The ids of HTML elements to check.
 */
export function smoothScrollToTopElement(elementIds: string[]) {
  const top = { element: null as HTMLElement | null, y: Infinity };
  for (const elementId of elementIds) {
    const element = document.getElementById(elementId);
    if (!element) continue;
    const { y } = element.getBoundingClientRect();
    if (y < top.y) {
      top.y = y;
      top.element = element;
    }
  }
  smoothScrollTo(top.element);
}

import ReactJsonView from "react-json-view";
import { QueryKey, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";

import SuccessIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Warning";
import LoadingIcon from "@mui/icons-material/HourglassEmpty";
import PauseIcon from "@mui/icons-material/PauseCircleOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";

import { useDeveloperTool } from "~/components/admin/DeveloperMenu";

export function DeveloperQueryInspector() {
  const client = useQueryClient();

  const { open = false, setOpen } = useDeveloperTool({ key: "query", enable: true });

  const [queries, setQueries] = useState(client.getQueryCache().getAll());

  const refresh = useCallback(
    () => setQueries([...client.getQueryCache().getAll()]),
    [client],
  );

  useEffect(() => {
    if (open) refresh();
  }, [open, refresh]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <Box sx={{ position: "absolute", mr: 1, mt: 1, right: 0, top: 0 }}>
        <IconButton onClick={refresh}>
          <RefreshIcon />
        </IconButton>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle>Query Inspector</DialogTitle>
      <DialogContent sx={{ paddingLeft: 0, paddingRight: 0 }}>
        <List disablePadding>
          {queries
            .filter((query) => query.getObserversCount() > 0 || query.state.data)
            .map((query) => (
              <QueryPanel
                key={query.queryHash}
                queryKey={query.queryKey}
                queryHash={query.queryHash}
              />
            ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}

function QueryPanel({ queryKey, queryHash }: { queryKey: QueryKey; queryHash: string }) {
  const [open, setOpen] = useState(false);

  const {
    status,
    fetchStatus,
    failureReason,
    error,
    errorUpdatedAt,
    data,
    dataUpdatedAt,
  } =
    // fetch the query using just the key to observe it, don't attempt to refetch it
    useQuery({ queryKey, retry: false, enabled: false });

  const icon = useMemo(() => {
    switch (fetchStatus) {
      case "fetching":
        return <CircularProgress size={16} thickness={6} sx={{ mr: 0.5 }} color="info" />;
      case "paused":
        return <PauseIcon color="action" fontSize="small" />;
    }
    switch (status) {
      case "pending":
        return <LoadingIcon color="action" fontSize="small" />;
      case "success":
        return <SuccessIcon color="success" fontSize="small" />;
      case "error":
        return <ErrorIcon color="error" fontSize="small" />;
    }
  }, [fetchStatus, status]);

  return (
    <>
      <ListItem disablePadding onClick={() => setOpen((open) => !open)}>
        <ListItemButton disableRipple>
          <Typography variant="body1" display="flex" gap={1} alignItems="center">
            {icon}
            {queryHash}
          </Typography>
        </ListItemButton>
      </ListItem>
      {open && (
        <Stack pl={5} pr={2} gap={1} mt={1} mb={2}>
          <ReactJsonView
            name="query"
            style={{ fontSize: "0.9rem", lineHeight: "1rem" }}
            src={{
              status,
              fetchStatus,
              dataUpdatedAt: dataUpdatedAt
                ? new Date(dataUpdatedAt).toLocaleString()
                : null,
              failureReason,
              error,
              errorUpdatedAt: errorUpdatedAt
                ? new Date(errorUpdatedAt).toLocaleString()
                : null,
            }}
            shouldCollapse={(field) => {
              const path = field.namespace.join("/");
              // These fields typically contain verbose information that isn't helpful
              // So we keep them collapsed by default
              return [
                "query/error/config",
                "query/error/response/config",
                "query/failureReason/config",
                "query/failureReason/response/config",
              ].some((x) => path.startsWith(x));
            }}
            displayDataTypes={false}
            enableClipboard={(copy) =>
              navigator.clipboard.writeText(JSON.stringify(copy.src, null, 2))
            }
          />
          <ReactJsonView
            name="data"
            style={{ fontSize: "0.9rem", lineHeight: "1rem" }}
            src={data as object}
            shouldCollapse={(field) => field.namespace.length === 1}
            displayDataTypes={false}
            enableClipboard={(copy) =>
              navigator.clipboard.writeText(JSON.stringify(copy.src, null, 2))
            }
          />
        </Stack>
      )}
    </>
  );
}

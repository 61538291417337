/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { IAgentInfo, IErrorResponse, IStartImpersonationPayload } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ImpersonationApi<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * @description Start an impersonation session to act as another user
   *
   * @tags Impersonation API
   * @name Start
   * @request POST:/api/admin/impersonate
   * @response `201` `IAgentInfo`
   * @response `401` `IErrorResponse` Not signed in.
   */
  start = (data: IStartImpersonationPayload, params: RequestParams = {}) =>
    this.request<IAgentInfo, IErrorResponse>({
      path: `/api/admin/impersonate`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description End an impersonation session
   *
   * @tags Impersonation API
   * @name End
   * @request DELETE:/api/admin/impersonate
   * @response `201` `IAgentInfo`
   * @response `401` `IErrorResponse` Not signed in.
   */
  end = (params: RequestParams = {}) =>
    this.request<IAgentInfo, IErrorResponse>({
      path: `/api/admin/impersonate`,
      method: "DELETE",
      format: "json",
      ...params,
    });
}

import { Stack } from "@mui/material";
import { SystemProps } from "@mui/system";

import { MONASH_PALETTE } from "@packages/utils";

export interface FormSectionWellProps extends SystemProps {
  list?: boolean;
  children?: React.ReactNode;
}

export function FormSectionWell(props: FormSectionWellProps) {
  const { children, ...rest } = props;
  return (
    <Stack bgcolor={MONASH_PALETTE.MONASH_LIGHT_GREY} px={3} py={2} {...rest}>
      {children}
    </Stack>
  );
}

import { useCallback, useEffect } from "react";
import {
  addMilliseconds,
  differenceInMilliseconds,
  millisecondsToSeconds,
  minutesToMilliseconds,
  subMilliseconds,
} from "date-fns";
import {
  alpha,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import { useIdleTimer, IIdleTimer } from "react-idle-timer";
import { useAtom, useAtomValue } from "jotai";

import { formatSeconds } from "@packages/utils";

import { useRerender } from "~/hooks/state";
import { Duration } from "~/components/core/Duration";
import { Countdown } from "~/components/core/Countdown";
import { useDeveloperTool } from "~/components/admin/DeveloperMenu";
import {
  idleExpiryAtom,
  sessionExpiryAtom,
  useAuthState,
  useRefreshSession,
  useSignOut,
} from "~/components/auth/AuthProvider";

// Buffer before the user session expires to trigger a session refresh
const REFRESH_BUFFER = minutesToMilliseconds(1);

// Show the idle dialog if the user is idle for this amount of time
const IDLE_TIMEOUT = minutesToMilliseconds(30);

// Give a buffer before an idle user is signed out
const IDLE_BUFFER = minutesToMilliseconds(5);

export function SessionGuard() {
  const [user] = useAuthState();

  const refreshSession = useRefreshSession();
  const sessionExpiry = useAtomValue(sessionExpiryAtom);

  // Set up session refresh in the background
  useEffect(() => {
    if (!user) return;
    if (!sessionExpiry) return;

    // Calculate how long until the session should refresh
    const refreshTime = subMilliseconds(sessionExpiry, REFRESH_BUFFER);
    // Don't let the refresh timer go below 1 minute
    const timeToRefresh = Math.max(
      differenceInMilliseconds(refreshTime, new Date()),
      minutesToMilliseconds(1),
    );

    // Set up a timer to refresh the session
    // When the session refreshes, the user object will be updated so a new timer will be created
    const timeout = setTimeout(refreshSession, timeToRefresh);
    const seconds = millisecondsToSeconds(timeToRefresh);
    console.debug(
      `Refreshing session in ${formatSeconds(seconds, "m:ss")}`,
      `(${refreshTime.toLocaleString()})`,
    );

    return () => clearTimeout(timeout);
  }, [user, sessionExpiry]);

  const signOut = useSignOut();
  const [idleExpiry, setIdleExpiry] = useAtom(idleExpiryAtom);

  // Run when the user is considered idle
  const onIdle = useCallback(() => {
    const date = new Date();
    console.debug("User idle at", date.toLocaleString());
    setIdleExpiry(addMilliseconds(date, IDLE_BUFFER));
  }, []);

  const idleTimer = useIdleTimer({
    disabled: !user || idleExpiry !== null,
    timeout: IDLE_TIMEOUT,
    crossTab: true,
    syncTimers: 1000,
    debounce: 500,
    onIdle,
  });

  // Reset the idle timer
  const onReset = useCallback(() => {
    idleTimer.reset();
    setIdleExpiry(null);
  }, [idleTimer.reset]);

  // Sign the user out
  const onSignOut = useCallback(
    (message?: string) => {
      signOut(message).then(() => onReset());
    },
    [signOut, onReset],
  );

  // If an expiry date is set, sign out automatically when the date is reached
  useEffect(() => {
    if (!idleExpiry) return;
    const timeToExpiry = differenceInMilliseconds(idleExpiry, new Date());
    const timeout = setTimeout(
      () => onSignOut("You have been signed out to protect your data."),
      timeToExpiry,
    );
    return () => clearTimeout(timeout);
  }, [idleExpiry, onSignOut]);

  return (
    <>
      {user && (
        <SessionGuardDeveloperTools
          idleExpiry={idleExpiry}
          idleTimer={idleTimer}
          sessionExpiry={sessionExpiry}
        />
      )}
      <Dialog open={Boolean(user && idleExpiry)} maxWidth="xs">
        <DialogTitle>Do you wish to continue working?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You will be signed out automatically{" "}
            {idleExpiry && (
              <>
                in <Countdown to={idleExpiry} format="m:ss" />
              </>
            )}{" "}
            to protect your data if you do not take action.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onSignOut()}>Sign out</Button>
          <Button variant="contained" onClick={onReset}>
            Continue working
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function SessionGuardDeveloperTools(props: {
  idleExpiry?: Date | null;
  idleTimer?: IIdleTimer;
  sessionExpiry?: Date | null;
}) {
  const { idleExpiry, idleTimer, sessionExpiry } = props;

  const idleTool = useDeveloperTool({ key: "idle", enable: true });

  useRerender({ enabled: idleTool.open });

  if (!idleTool.open) return null;

  const remainingTime = idleTimer?.getRemainingTime() ?? 0;

  return (
    <Stack
      position="fixed"
      // https://mui.com/material-ui/customization/z-index
      zIndex={2000}
      bottom={0}
      left={0}
      m={1}
      gap={1}
    >
      <Box px={1} py={0.5} borderRadius={1.5} bgcolor={alpha("#ff8", 0.9)}>
        <Typography variant="body1">
          <b>Time to idle: </b>
          <Duration format="h:mm:ss" defaultValue="-">
            {idleExpiry ? null : millisecondsToSeconds(remainingTime)}
          </Duration>
        </Typography>
      </Box>
      <Box px={1} py={0.5} borderRadius={1.5} bgcolor={alpha("#ff8", 0.9)}>
        <Typography variant="body1">
          <b>Session expiry: </b>
          <Countdown format="m:ss" to={sessionExpiry} />
        </Typography>
      </Box>
    </Stack>
  );
}

import { atom, useAtomValue, useSetAtom } from "jotai";
import { useCallback } from "react";
import { isAxiosError } from "axios";
import { DialogProps, useTheme } from "@mui/material";

import { parseRequestError } from "~/utils/errors";
import { ErrorNotificationMessage } from "~/components/core/ErrorMessage";
import {
  NotificationDialog,
  NotificationSnackbar,
  NotificationMessage,
  NotificationMessageProps,
} from "~/components/core/Notification";

export interface Notification {
  show: boolean;
  message: React.ReactNode;
  type: "none" | "loading" | "success" | "warning" | "error";
}

export type ShowNotificationOptions = Pick<Notification, "message" | "type"> & {
  title?: string;
  context?: NotificationMessageProps["context"];
};

const snackbarAtom = atom<Notification>({
  show: false,
  message: null,
  type: "none",
});

const alertAtom = atom<Notification & { maxWidth?: DialogProps["maxWidth"] }>({
  show: false,
  message: null,
  type: "none",
});

export function useNotification() {
  const theme = useTheme();

  const setSnackbar = useSetAtom(snackbarAtom);
  const setAlert = useSetAtom(alertAtom);

  const show = useCallback(
    function <T extends ShowNotificationOptions>(options: T) {
      const { context, type, message, title, ...rest } = options;

      const notification: Notification = {
        show: true,
        type,
        message: (
          <NotificationMessage title={title} message={message} context={context} />
        ),
        ...rest,
      };

      if (context === "dialog") {
        setAlert(notification);
        setSnackbar((notification) => ({ ...notification, show: false }));
      } else {
        setSnackbar(notification);
        setAlert((notification) => ({ ...notification, show: false }));
      }
    },
    [setSnackbar, setAlert, theme],
  );

  const closeNotification = useCallback(() => {
    setSnackbar((notification) => ({ ...notification, show: false }));
    setAlert((alert) => ({ ...alert, show: false }));
  }, [setSnackbar, setAlert]);

  const showError = useCallback(
    async (options: {
      error: unknown;
      context?: NotificationMessageProps["context"];
      message?: string;
    }) => {
      const { error, context = "default", message } = options;

      const requestError = parseRequestError(error);
      if (requestError)
        show({
          type: "error",
          context,
          message: (
            <ErrorNotificationMessage
              error={requestError}
              message={message}
              context={context}
            />
          ),
        });
      else if (isAxiosError(error) && error.response)
        show({
          type: "error",
          context,
          message: message ?? error.response.data.message ?? error.response.statusText,
        });
      else show({ type: "error", context: context, message: message ?? String(error) });
    },
    [show, closeNotification],
  );

  const showNotification = useCallback(
    (options: ShowNotificationOptions) => show({ ...options, context: "default" }),
    [setSnackbar],
  );

  const showNotificationAlert = useCallback(
    (options: ShowNotificationOptions & { maxWidth?: DialogProps["maxWidth"] }) =>
      show({ ...options, context: "dialog" }),
    [setSnackbar],
  );

  const showErrorNotification = useCallback(
    (error: unknown, message?: string) =>
      showError({ error, message, context: "default" }),
    [showError],
  );

  const showErrorAlert = useCallback(
    (error: unknown, message?: string) =>
      showError({ error, message, context: "dialog" }),
    [showError],
  );

  return {
    showNotification,
    showNotificationAlert,
    showErrorNotification,
    showErrorAlert,
    closeNotification,
  };
}

export function NotificationProvider() {
  const { closeNotification } = useNotification();

  const snackbar = useAtomValue(snackbarAtom);
  const alert = useAtomValue(alertAtom);

  return (
    <>
      <NotificationSnackbar
        open={snackbar.show}
        autoHideDuration={snackbar.type === "loading" ? undefined : 5000}
        disableTypography
        message={snackbar.message}
        icon={snackbar.type}
        closable={snackbar.type !== "loading"}
        onClose={closeNotification}
        sx={{ maxWidth: "50%" }}
      />
      <NotificationDialog
        open={alert.show}
        disableTypography
        message={alert.message}
        icon={alert.type}
        closable={alert.type !== "loading"}
        maxWidth={alert.maxWidth}
        onClose={closeNotification}
      />
    </>
  );
}

import { useQuery } from "@tanstack/react-query";

import { CampusLocation } from "@packages/types";

import { REFERENCE_DATA_STALE_TIME } from "~/config/constants";
import { EnquiryApi, GetApi } from "~/api";

export function useEnquiryGroups(
  campusLocation: CampusLocation = CampusLocation.AUSTRALIA,
) {
  return useQuery({
    queryKey: ["enquiries", "groups", campusLocation],
    queryFn: () => GetApi(EnquiryApi).getGroups({ campusLocation }),
    retry: false,
    staleTime: REFERENCE_DATA_STALE_TIME,
  });
}

import { useCallback, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  BoxProps,
  Collapse,
  Divider,
  IconButton,
  Link,
  Paper,
  Stack,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

import {
  ApplicationStatus,
  isApplicationCompleteCoursePreferenceStatus,
} from "@packages/types";

import { IApplicationsPage } from "~/api";

import {
  useApplication,
  useApplicationDocumentChecklistItems,
} from "~/hooks/application";
import { useApplicantEnquiries } from "~/hooks/applicant";
import { parseRequestError } from "~/utils/errors";
import { Timestamp } from "~/components/core/Timestamp";
import { useBackLink } from "~/components/core/BackLink";
import { useLoadResource } from "~/components/core/AppLoadingBar";
import { ExpandChevron } from "~/components/core/ExpandChevronIcon";
import { ApplicationDocumentsTable } from "~/components/applicant/ApplicationDocumentsTable";
import { ApplicationEnquiriesSection } from "~/components/applicant/ApplicationEnquiriesSection";
import { ApplicationCoursePreferencesTable } from "~/components/applicant/ApplicationCoursePreferencesTable";

const TableHeaderRow = styled(TableRow)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const TableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  fontFamily: theme.typography.body1.fontFamily,
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.body1.fontSize,
}));

const ApplicationRowTab = styled(Tab)(({ theme }) => ({
  textTransform: "capitalize",
  fontFamily: theme.typography.body2.fontFamily,
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.body2.fontSize,
  marginRight: theme.spacing(4),
}));

export interface ApplicantApplicationsTableProps {
  applications?: IApplicationsPage["results"];
}

export function ApplicantApplicationsTable(props: ApplicantApplicationsTableProps) {
  const { applications } = props;
  return (
    <TableContainer component={Paper} elevation={1}>
      <Table>
        <TableHead>
          <TableHeaderRow>
            <TableHeaderCell>Application</TableHeaderCell>
            <TableHeaderCell>Location</TableHeaderCell>
            <TableHeaderCell sx={{ whiteSpace: "nowrap" }}>
              Status
              <Tooltip
                title="Expand each application to see the status of the application's individual course preferences."
                placement="top"
                componentsProps={{ tooltip: { sx: { textAlign: "center" } } }}
                arrow
              >
                <HelpIcon fontSize="small" />
              </Tooltip>
            </TableHeaderCell>
            <TableHeaderCell sx={{ whiteSpace: "nowrap" }}>Last Modified</TableHeaderCell>
            <TableHeaderCell sx={{ whiteSpace: "nowrap" }}>
              Assigned Agent
            </TableHeaderCell>
            <TableHeaderCell />
          </TableHeaderRow>
        </TableHead>
        <TableBody>
          {applications?.map((application) => (
            <ApplicationRow key={application.applicationId} {...application} />
          ))}
        </TableBody>
      </Table>
      {(!applications || applications.length === 0) && (
        <Typography color="text.secondary" p={2} textAlign="center">
          No applications
        </Typography>
      )}
    </TableContainer>
  );
}

function ApplicationRow(props: IApplicationsPage["results"][number]) {
  const {
    applicationId,
    applicantId,
    owner,
    applicationStatus,
    applicationCoursePreferenceStatus,
    campusLocation,
    lastModifiedDate,
  } = props;

  const [open, setOpen] = useState(false);

  const backLink = useBackLink();

  return (
    <>
      <TableRow sx={{ transition: "all 0.2s", borderBottom: "unset" }}>
        <TableCell scope="row">
          <Link
            component={RouterLink}
            to={`/applications/${applicationId}`}
            state={{ backLink }}
          >
            <Typography variant="body1" color="primary" noWrap>
              {applicationId}
            </Typography>
          </Link>
        </TableCell>
        <TableCell>
          <Typography variant="body1">{campusLocation?.code}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="body1" noWrap>
            {applicationCoursePreferenceStatus || applicationStatus}
          </Typography>
        </TableCell>
        <TableCell width={240}>
          <Typography variant="body1" noWrap>
            <Timestamp>{lastModifiedDate}</Timestamp>
          </Typography>
        </TableCell>
        <TableCell width={240}>
          <Typography variant="body1" noWrap>
            {owner}
          </Typography>
        </TableCell>
        <TableCell width={1}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            <ExpandChevron expanded={open} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          "& > td": { borderBottom: open ? undefined : "unset" },
          "& > td:last-child": { borderBottom: open ? undefined : "unset" },
        }}
      >
        <TableCell sx={{ p: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <ApplicationRowExpanded
              open={open}
              applicationId={applicationId}
              campusLocation={campusLocation?.code}
              applicantId={applicantId}
              applicationStatus={applicationStatus}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function ApplicationRowExpanded(props: {
  open: boolean;
  applicationId: string;
  applicantId?: string;
  campusLocation?: string;
  applicationStatus?: ApplicationStatus | null;
}) {
  const { open, applicationId, applicantId, campusLocation, applicationStatus } = props;

  const application = useApplication(open ? applicationId : undefined);
  const documentChecklistItems = useApplicationDocumentChecklistItems(
    open ? applicationId : undefined,
  );
  const enquiries = useApplicantEnquiries(open ? applicantId : undefined);
  const applicationEnquiryList = enquiries?.data?.find(
    (enquiryList) => enquiryList.applicationId === applicationId,
  );

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  useLoadResource(
    useCallback(
      () => application.isFetching || documentChecklistItems.isFetching,
      [application.isFetching, documentChecklistItems.isFetching],
    ),
    `${ApplicationRowExpanded.name}:${applicationId}`,
  );

  return (
    <Stack sx={{ background: (theme) => theme.palette.action.hover }}>
      <Tabs value={selectedTabIndex} onChange={handleTabChange}>
        <ApplicationRowTab label="Course Preferences" />
        <ApplicationRowTab label="Supporting Documents" />
        <ApplicationRowTab label="Enquiries" />
      </Tabs>
      <Divider />
      <Box>
        <ApplicationTabPanel value={selectedTabIndex} index={0}>
          <ApplicationCoursePreferencesTable
            loading={!application.data && application.isPending}
            error={parseRequestError(application.error)}
            applicationCoursePreferences={application.data?.application.coursePreferences}
          />
        </ApplicationTabPanel>
        <ApplicationTabPanel value={selectedTabIndex} index={1}>
          <ApplicationDocumentsTable
            applicationId={applicationId}
            loading={!documentChecklistItems.data && documentChecklistItems.isPending}
            error={parseRequestError(documentChecklistItems.error)}
            documentChecklistItems={documentChecklistItems.data}
            // Allow documents to be uploaded unless the application has been accepted/rejected
            isEditable={
              !isApplicationCompleteCoursePreferenceStatus(
                application.data?.application.applicationCoursePreferenceStatus,
              )
            }
          />
        </ApplicationTabPanel>
        <ApplicationTabPanel value={selectedTabIndex} index={2}>
          <ApplicationEnquiriesSection
            applicationId={applicationId}
            applicantId={applicantId}
            campusLocation={campusLocation}
            applicationStatus={applicationStatus}
            applicationCoursePreferences={application.data?.application.coursePreferences}
            applicationEnquiryList={applicationEnquiryList}
            loading={!enquiries.data && enquiries.isPending}
            coursePreferencesLoading={!application.data && application.isPending}
            error={parseRequestError(enquiries.error)}
          />
        </ApplicationTabPanel>
      </Box>
    </Stack>
  );
}

interface TabPanelProps extends Omit<BoxProps, "children" | "role" | "hidden" | "id"> {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function ApplicationTabPanel(props: TabPanelProps) {
  const { children, value, index, px = 2, pt = 2, pb = 3, ...rest } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`application-tabpanel-${index}`}
      px={px}
      pt={pt}
      pb={pb}
      {...rest}
    >
      {value === index && children}
    </Box>
  );
}

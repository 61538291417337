import { Link as RouterLink } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Link,
  Drawer,
  Stack,
  ListItem,
  ListItemButton,
  List,
  ListItemText,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { AgentRole } from "@packages/types";

import monashLogo from "~/assets/images/monash-logo.svg";

import { useMenuAnchor } from "~/hooks/state";
import { useAuthState } from "~/components/auth/AuthProvider";

import { UserMenu } from "./UserMenu";
import { AlertNotificationButton } from "./AlertNotificationButton";
import { ImpersonationButton } from "./ImpersonationButton";

function getLinks(roles?: string[]) {
  const links: { label: string; path: string }[] = [];

  if (roles?.includes(AgentRole.Counsellor))
    links.push({ label: "Applications", path: "/" });

  if (roles?.includes(AgentRole.DelegatedAdmin)) {
    links.push(
      { label: "User management", path: "/manage/users" },
      { label: "Agency management", path: "/manage/agency" },
    );
  }

  return links;
}

export function Navigation() {
  const [user] = useAuthState();
  const [menu, openMenu, closeMenu] = useMenuAnchor();
  const links = getLinks(user?.agent.roles);

  return (
    <AppBar position="sticky" elevation={1} sx={{}} color="default">
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box display="flex" alignItems="center" gap={{ xs: 1, sm: 2 }}>
          {user && (
            <IconButton
              size="large"
              onClick={openMenu}
              color="inherit"
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Drawer anchor="left" open={menu.open} onClose={closeMenu}>
            <Stack my={2} minWidth={250}>
              <Box
                component="img"
                width={{ xs: 110, sm: 130 }}
                mx={2}
                mb={2}
                src={monashLogo}
                alt="Monash University Logo"
              />
              <Divider />
              <List>
                {links.map((item) => (
                  <ListItem key={item.label} disableGutters disablePadding>
                    <ListItemButton
                      component={RouterLink}
                      to={item.path}
                      onClick={closeMenu}
                    >
                      <ListItemText primary={item.label} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Drawer>
          <Box component={RouterLink} to="/" display="flex">
            <Box
              component="img"
              width={{ xs: 110, sm: 130 }}
              src={monashLogo}
              alt="Monash University Logo"
            />
          </Box>
          <Box display={{ xs: "none", md: "flex" }} gap={4} ml={3}>
            {links.map((menuItem) => (
              <Link
                key={menuItem.label}
                component={RouterLink}
                to={menuItem.path}
                underline="hover"
              >
                {menuItem.label}
              </Link>
            ))}
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <AlertNotificationButton />
          <ImpersonationButton />
          <UserMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

import { useWatch } from "react-hook-form";

import { CourseType } from "@packages/types";

import { CoursePreferencesFormProps } from "~/components/application/ApplicationForm/CoursePreferencesForm";
import { useFindResidencyStatusCountry } from "~/hooks/reference-data";

import { CourseSelectionForm } from "./CourseSelectionForm";
import { createCourseFields } from "./_utils";

export function EnglishCourseSelectionForm(props: CoursePreferencesFormProps) {
  const { control, disabled, setValue } = props;

  const [englishCourse, residencyStatus, residencySubStatus] = useWatch({
    control,
    name: [
      "coursePreferences.englishCourse",
      "citizenship.residencyStatus",
      "citizenship.residencySubStatus",
    ],
  });

  const campusCountry = useFindResidencyStatusCountry(residencySubStatus);

  return (
    <CourseSelectionForm
      title="Monash English Language Centre Course"
      control={control}
      setValue={setValue}
      fields={createCourseFields("englishCourse")}
      coursePreference={englishCourse}
      residencyStatus={residencyStatus}
      campusCountry={campusCountry}
      courseType={CourseType.English}
      disabled={disabled}
    />
  );
}

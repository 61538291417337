import { Outlet, useNavigation } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import qs from "query-string";

import { useIsDeveloper } from "~/hooks/admin";
import { AuthProvider } from "~/components/auth/AuthProvider";
import { Navigation } from "~/components/core/Navigation";
import { AppLoadingIndicator } from "~/components/core/AppLoadingBar";
import { SessionGuard } from "~/components/auth/SessionGuard";
import { DeveloperQueryInspector } from "~/components/admin/DeveloperQueryInspector";
import { AlertNotificationBanner } from "~/components/core/AlertNotificationBanner";

export function AppShell() {
  const { state } = useNavigation();
  const isDeveloper = useIsDeveloper();
  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: qs.parse,
        objectToSearchString: qs.stringify,
        removeDefaultsFromUrl: true,
      }}
    >
      <AppLoadingIndicator resourceKey="navigation" loading={state === "loading"} />
      <AuthProvider />
      <SessionGuard />
      <Navigation />
      <AlertNotificationBanner />
      <Outlet />
      {isDeveloper && <DeveloperQueryInspector />}
    </QueryParamProvider>
  );
}

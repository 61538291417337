import { Controller, FieldValues, ControllerProps } from "react-hook-form";
import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
} from "@mui/material";

import { GetFieldPathToType, ValueSatisfiesType } from "~/types";

export interface FormCheckBoxProps<TFieldValues extends FieldValues = FieldValues>
  extends Pick<ControllerProps<TFieldValues>, "control">,
    Omit<FormControlLabelProps, "name" | "control" | "onChange"> {
  name: GetFieldPathToType<boolean, TFieldValues>;
  onBeforeChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checkboxProps?: CheckboxProps;
}

export function FormCheckbox<TFieldValues extends FieldValues = FieldValues>(
  props: FormCheckBoxProps<TFieldValues>,
) {
  const { name, control, label, onBeforeChange, checkboxProps, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field }, fieldState }) => (
        <>
          <FormControlLabel
            control={
              <Checkbox
                id={name}
                checked={field.value}
                onChange={(e, checked) => {
                  onBeforeChange?.(e, checked);
                  onChange(checked as ValueSatisfiesType<TFieldValues, boolean>);
                }}
                {...field}
                {...checkboxProps}
                sx={{ alignSelf: "flex-start", ...checkboxProps?.sx }}
              />
            }
            label={
              <Box>
                {label}
                <FormHelperText error sx={{ m: 0 }}>
                  {fieldState.error?.message}
                </FormHelperText>
              </Box>
            }
            {...rest}
          />
        </>
      )}
    />
  );
}

import { useCallback, useEffect } from "react";

import { useLoadResource } from "./state";

export interface AppLoadingIndicatorProps {
  resourceKey: string;
  loading?: boolean;
}

export function AppLoadingIndicator(props: AppLoadingIndicatorProps) {
  const { resourceKey, loading } = props;

  const [, unload] = useLoadResource(
    useCallback(() => loading || false, [loading]),
    resourceKey,
  );

  useEffect(() => {
    return () => unload();
  }, [unload]);

  return null;
}

import {
  ListSubheader,
  ListItemText,
  Typography,
  ListItem,
  List,
  Grid,
  Divider,
  Alert,
} from "@mui/material";

import { IEnquiryGroup } from "~/api";
import { EnquiryListOption } from "~/components/enquiry/EnquiryListOption";

export interface EnquiryGroupColumnProps {
  enquiryGroup: IEnquiryGroup;
}

export function EnquiryGroupColumn(props: EnquiryGroupColumnProps) {
  const { enquiryGroup } = props;
  const { info, colour, name, description, topics = [] } = enquiryGroup ?? {};

  return (
    <Grid
      item
      xs={12}
      md={4}
      sx={(theme) => ({
        pb: 4,
        [theme.breakpoints.down("sm")]: {
          pb: 0,
          "&:last-child": { pb: 4 },
        },
      })}
    >
      <List disablePadding>
        <ListSubheader>
          <ListItemText
            sx={{ my: 0, pt: 3.5, pb: 1 }}
            primary={
              <Typography color={colour} variant="h6">
                {name}
              </Typography>
            }
            secondary={description}
          />
          <Divider sx={{ pt: 1 }} />
        </ListSubheader>
        {info && (
          <ListItem sx={{ mb: 1, mt: 1 }}>
            <Alert severity="info">{info}</Alert>
          </ListItem>
        )}
        <List
          disablePadding
          sx={{
            listStyleType: "disc",
            mt: 2,
            ml: 2,
            pl: 4,
            border: 0,
            borderLeft: 10,
            borderStyle: "solid",
            borderColor: colour,
            height: "100%",
          }}
        >
          {topics.map((topic) => (
            <EnquiryListOption
              key={topic.name}
              topic={topic}
              enquiryGroup={enquiryGroup}
            />
          ))}
        </List>
      </List>
    </Grid>
  );
}

import { createBrowserRouter } from "react-router-dom";

import { AgentRole, GCPAdminPermission } from "@packages/types";

import {
  useApplicantRouteData,
  useApplicationRouteData,
  useEnquiryRouteData,
} from "./hooks/route-data";
import { AppShell } from "./components/core/AppShell";
import { RouteGuard } from "./components/auth/RouteGuard";
import { ErrorBoundary } from "./components/core/ErrorBoundary";

import { SignInPage } from "./pages/root/SignInPage";
import { AgencyInfoPage } from "./pages/manage/AgencyInfoPage";
import { DashboardPage } from "./pages/root/DashboardPage";
import { ApplicantProfilePage } from "./pages/applicant/ApplicantProfilePage";
import { NewApplicationPage } from "./pages/application/NewApplicationPage";
import { AgentDeclarationPage } from "./pages/application/AgentDeclarationPage";
import { EditApplicationPage } from "./pages/application/EditApplicationPage";
import { UserManagementPage } from "./pages/manage/UserManagementPage";
import { AgentProfilePage } from "./pages/root/AgentProfilePage";
import { EnquiryDetailPage } from "./pages/enquiry/EnquiryDetailPage";

export const router = createBrowserRouter([
  {
    element: <AppShell />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/signin",
        element: <SignInPage />,
      },
      {
        path: "/",
        element: <RouteGuard />,
        children: [
          {
            index: true,
            element: <DashboardPage />,
          },
          {
            path: "applicants",
            children: [
              {
                path: ":applicantId",
                id: useApplicantRouteData.id,
                loader: useApplicantRouteData.loader,
                children: [
                  {
                    index: true,
                    element: <ApplicantProfilePage />,
                  },
                  {
                    path: "new",
                    element: <NewApplicationPage />,
                  },
                ],
              },
            ],
          },
          {
            path: "applications",
            children: [
              {
                path: "new",
                element: <NewApplicationPage />,
              },
              {
                path: ":applicationId",
                id: useApplicationRouteData.id,
                loader: useApplicationRouteData.loader,
                children: [
                  {
                    index: true,
                    element: <EditApplicationPage />,
                  },
                  {
                    path: "declaration",
                    element: <AgentDeclarationPage />,
                  },
                  {
                    path: "enquiries/:enquiryNumber",
                    id: useEnquiryRouteData.id,
                    loader: useEnquiryRouteData.loader,
                    children: [
                      {
                        index: true,
                        element: <EnquiryDetailPage />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: "manage",
            element: <RouteGuard role={AgentRole.DelegatedAdmin} />,
            children: [
              {
                path: "agency",
                element: <AgencyInfoPage />,
              },
              {
                path: "users",
                element: <UserManagementPage />,
              },
            ],
          },
          {
            path: "profile",
            children: [
              {
                index: true,
                element: <AgentProfilePage />,
              },
            ],
          },
          {
            path: "support",
            element: <RouteGuard role={GCPAdminPermission.Support} forbiddenAsNotFound />,
            children: [
              {
                path: "error-logs",
                lazy: () => import("./pages/admin/ErrorLogsPage"),
              },
              {
                path: "mix-logs",
                lazy: () => import("./pages/admin/MixLogsPage"),
              },
              {
                path: "generate-external-receipt",
                lazy: () => import("./pages/admin/ApplicationReceiptGenerationPage"),
              },
            ],
          },
          {
            path: "developer",
            element: (
              <RouteGuard role={GCPAdminPermission.Developer} forbiddenAsNotFound />
            ),
            children: [
              {
                path: "token-generator",
                lazy: () => import("./pages/admin/TokenGeneratorPage"),
              },
              {
                path: "credential-generator",
                lazy: () => import("./pages/admin/CredentialGeneratorPage"),
              },
            ],
          },
          {
            path: "impersonate",
            element: (
              <RouteGuard
                guard={(user) =>
                  Boolean(user.impersonator) ||
                  user.agent.roles.includes(GCPAdminPermission.Impersonate)
                }
                forbiddenAsNotFound
              />
            ),
            children: [
              {
                index: true,
                lazy: () => import("./pages/admin/ImpersonatePage"),
              },
            ],
          },
        ],
      },
    ],
  },
]);

import { Controller, FieldValues, ControllerProps } from "react-hook-form";
import { TextField, BaseTextFieldProps, InputProps } from "@mui/material";

import { GetFieldPathToType, ValueSatisfiesType } from "~/types";

export interface FormTextFieldProps<TFieldValues extends FieldValues = FieldValues>
  extends Pick<ControllerProps<TFieldValues>, "control">,
    Omit<BaseTextFieldProps, "name" | "error"> {
  name: GetFieldPathToType<string, TFieldValues>;
  readOnly?: boolean;
  onBeforeChange?: InputProps["onChange"];
  InputProps?: Omit<InputProps, "readOnly" | "onChange">;
}

export function FormTextField<TFieldValues extends FieldValues = FieldValues>(
  props: FormTextFieldProps<TFieldValues>,
) {
  const {
    name,
    control,
    helperText,
    type,
    readOnly,
    InputProps,
    onBeforeChange,
    disabled,
    ...rest
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          id={name}
          error={fieldState.invalid}
          helperText={fieldState.error?.message || helperText}
          type={type}
          InputProps={{
            readOnly,
            onChange: (e) => {
              onBeforeChange?.(e);
              field.onChange(e.target.value as ValueSatisfiesType<TFieldValues, string>);
            },
            ...InputProps,
          }}
          {...rest}
          {...field}
          disabled={field.disabled || disabled}
          // Extract the date out of a ISO date string
          {...(type === "date" && {
            value: field.value?.match?.(/\d{4}-\d{2}-\d{2}/)?.[0] ?? "",
          })}
          InputLabelProps={{ shrink: type === "date" || undefined }}
        />
      )}
    />
  );
}

import { z } from "zod";

class SafeStringValidator {
  constructor(
    public readonly regex: RegExp,
    public readonly defaultMessage: string,
    private defaultSchema: z.ZodString,
  ) {}

  isValid(value: string) {
    return !this.regex.test(value);
  }

  schema(
    options: {
      message?: string;
      addValidation?: (schema: z.ZodString) => z.ZodString;
    } = {},
  ) {
    const { message = this.defaultMessage, addValidation = (schema) => schema } = options;
    return addValidation(this.defaultSchema).refine(
      (value) => this.isValid(value),
      message,
    );
  }
}

/**
 * Validates name strings, ensuring they are the correct length and do not include invalid characters.
 *
 * @param length The maximum allowed length for the field. Defaults to `30` if not specified; however,
 * certain fields, for e.g. Legal Given Name(s), may allow longer strings.
 */
export const SafeNameString = (length: number = 30) =>
  new SafeStringValidator(
    /[`0-9=~!@#$%^&*()_+\[\]\\;,\./{}|:"<>?]/,
    `Please do not use numbers, symbols or special characters: \`=~!@#$%^&*()_+[]\\;,./{}|:"<>?`,
    z.string().trim().max(length, `Cannot be longer than ${length} characters`),
  );

export const SafeString = new SafeStringValidator(
  /[`=~$%^\\;/{}|:<>]/,
  "Please do not use symbols or special characters: `=~$%^\\;/{}|:<>",
  z.string().trim(),
);

export const SafeStringWithSlashAndColon = new SafeStringValidator(
  /[`=~$%^\\;{}|<>]/, // Like SafeString but allows / and :
  "Please do not use symbols or special characters: `=~$%^\\;{}|<>",
  z.string().trim(),
);

export const SafeStringForProse = new SafeStringValidator(
  /[`^\\{}|<>]/,
  "Please do not use symbols or special characters: `^\\{}|<>",
  z.string().trim(),
);

export const SafeNumberString = new SafeStringValidator(
  /[^0-9]/,
  "Only numbers are permitted in this field",
  z.string().trim(),
);

import { CampusLocation } from "@packages/types";
import { useMutation } from "@tanstack/react-query";

import { GetApi, PaymentApi } from "~/api";
import { useApplicationFees } from "./reference-data";

/**
 * Returns the application fee for an application at the specified campus location.
 */
export function useApplicationFee(campusLocation?: CampusLocation) {
  const feeAmounts = useApplicationFees();
  return feeAmounts.data?.find((fee) => fee.campusLocation === campusLocation);
}

/**
 * Create a payment url for an application.
 */
export function useCreatePaymentUrl() {
  return useMutation({
    mutationKey: ["payment", "url"],
    mutationFn: (applicationId: string) =>
      GetApi(PaymentApi).createPaymentUrl(applicationId),
  });
}

import { Alert, Button, Grid, Typography } from "@mui/material";
import { Control, UseFormTrigger, useFormState, UseFormSetValue } from "react-hook-form";
import { z } from "zod";

import {
  CountryISOSchema,
  DateStringSchema,
  EmailStringSchema,
  parseDateString,
  PhoneStringSchema,
  SafeNameString,
  SafeString,
} from "@packages/types";

import { createAddIssue } from "~/utils";
import { useTableStateHelpers } from "~/hooks/table-helpers";
import { FormSection } from "~/components/form/FormSection";
import {
  EmptyWorkExperience,
  WorkExperienceFields,
  WorkExperienceTable,
} from "~/components/application/WorkExperience";

import { ApplicationFields } from "./useApplicationForm";

export interface WorkExperienceFormProps {
  control: Control<ApplicationFields>;
  trigger: UseFormTrigger<ApplicationFields>;
  setValue: UseFormSetValue<ApplicationFields>;
  disabled?: boolean;
}

export function WorkExperienceForm(props: WorkExperienceFormProps) {
  const { control, disabled } = props;

  const {
    addNew,
    edit,
    confirm,
    cancel,
    fieldArray: { fields, remove },
    record,
  } = useTableStateHelpers("workExperience", props, EmptyWorkExperience);

  // get errors from form state and display empty alert
  const { errors } = useFormState({ control, name: "workExperience" });
  const showEmptyAlert = errors.workExperience?.type === "custom";

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormSection.Well>
          <Typography variant="body1">
            List any relevant work experience for the applicant's course choices,
            particularly if their course allows recognition of prior learning.
          </Typography>
        </FormSection.Well>
      </Grid>
      {showEmptyAlert && (
        <Grid item xs={12}>
          <Alert severity="error">
            <Typography variant="body2">{errors.workExperience?.message}</Typography>
          </Alert>
        </Grid>
      )}
      <WorkExperienceTable
        control={control}
        fields={fields}
        remove={remove}
        onEdit={edit}
        isEditing={record.isEditing}
        editIndex={record.index}
        oldData={record.previous}
        disabled={disabled}
      />
      {!disabled && record.isEditing && (
        <WorkExperienceFields
          title={`Work Experience ${record.index + 1}`}
          control={control}
          path={`workExperience.${record.index}`}
          onConfirm={confirm}
          onCancel={cancel}
          disabled={disabled}
        />
      )}
      {!disabled && !record.isEditing && (
        <Grid item xs={12}>
          <Button variant="contained" onClick={addNew}>
            Add work experience
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export type WorkExperience = z.infer<typeof WorkExperienceSchema>;
export const WorkExperienceSchema = z
  .object({
    recordId: z.string().optional(),
    position: SafeString.schema(),
    employer: SafeString.schema(),
    startDate: DateStringSchema(),
    endDate: DateStringSchema.allowBlank(),
    countryObtained: CountryISOSchema.nullable(),
    contact: z.object({
      firstName: SafeNameString(50).schema(),
      lastName: SafeNameString(100).schema(),
      phone: PhoneStringSchema.allowBlank(),
      email: EmailStringSchema.allowBlank(),
    }),
  })
  .superRefine((workExperience, ctx) => {
    const addIssue = createAddIssue(ctx);

    const { position, employer, countryObtained, contact } = workExperience;

    if (!position) addIssue("Please specify a position", "position");
    if (!employer) addIssue("Please specify the employer", "employer");

    if (!countryObtained) addIssue("Please select a country", "countryObtained");

    const startDate = parseDateString(workExperience.startDate);
    if (startDate.getTime() > Date.now())
      addIssue("Start date must be in the past", "startDate");

    if (workExperience.endDate) {
      const endDate = parseDateString(workExperience.endDate);
      if (endDate.getTime() > Date.now())
        addIssue("End date must be in the past", "endDate");
      if (endDate.getTime() < startDate.getTime())
        addIssue("End date must be after start date", "endDate");
    }

    // If an email or phone is provided, ensure that the referee has a name
    if (contact.email || contact.phone) {
      if (!contact.firstName && !contact.lastName) {
        addIssue(
          "Please provide either a first or last name for the referee",
          "contact.firstName",
        );
        addIssue(
          "Please provide either a first or last name for the referee",
          "contact.lastName",
        );
      }
    }
  });

WorkExperienceForm.draftSchema = z.array(WorkExperienceSchema);

WorkExperienceForm.submitSchema = WorkExperienceForm.draftSchema;

import { SetStateAction, useCallback } from "react";
import { useSetAtom } from "jotai";

import { ApplicationSectionKey } from "~/components/application/ApplicationForm";

import { ApplicationSectionState, sidebarAtom } from "./state";

type SidebarItemState = Omit<ApplicationSectionState, "key">;

export function useSetApplicationFormSidebar(key: ApplicationSectionKey) {
  const setAtom = useSetAtom(sidebarAtom({ key }));
  return useCallback(
    (state?: SetStateAction<SidebarItemState>) =>
      setAtom(
        typeof state === "function"
          ? (prevState) => ({ key, ...state(prevState) })
          : { key, ...state },
      ),
    [setAtom],
  );
}

export function useSetApplicationFormSidebarMap() {
  const map: Record<ApplicationSectionKey, (state: SidebarItemState) => void> = {
    delegatedAgency: useSetApplicationFormSidebar("delegatedAgency"),
    personalDetails: useSetApplicationFormSidebar("personalDetails"),
    monashStudies: useSetApplicationFormSidebar("monashStudies"),
    citizenship: useSetApplicationFormSidebar("citizenship"),
    coursePreferences: useSetApplicationFormSidebar("coursePreferences"),
    englishProficiency: useSetApplicationFormSidebar("englishProficiency"),
    disabilities: useSetApplicationFormSidebar("disabilities"),
    academicQualifications: useSetApplicationFormSidebar("academicQualifications"),
    workExperience: useSetApplicationFormSidebar("workExperience"),
    scholarshipSponsorship: useSetApplicationFormSidebar("scholarshipSponsorship"),
    creditTransfer: useSetApplicationFormSidebar("creditTransfer"),
    notes: useSetApplicationFormSidebar("notes"),
    fees: useSetApplicationFormSidebar("fees"),
    documents: useSetApplicationFormSidebar("documents"),
  };
  return useCallback(
    (key: ApplicationSectionKey, state: SidebarItemState) => map[key](state),
    [],
  );
}

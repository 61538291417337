import {
  Alert,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { IApplication } from "~/api";
import { RequestError } from "~/utils/errors";
import { Timestamp } from "~/components/core/Timestamp";
import { ErrorNotificationMessage } from "~/components/core/ErrorMessage";
import { InnerTable } from "~/components/core/TableStyles";

const PLACEHOLDER = (
  <>
    <ApplicationCoursePreferenceTableRow />
    <ApplicationCoursePreferenceTableRow />
    <ApplicationCoursePreferenceTableRow />
  </>
);

export interface ApplicationCoursePreferencesTableProps {
  loading?: boolean;
  error?: RequestError | null;
  applicationCoursePreferences?: IApplication["application"]["coursePreferences"];
}

export function ApplicationCoursePreferencesTable(
  props: ApplicationCoursePreferencesTableProps,
) {
  const { loading, error, applicationCoursePreferences = [] } = props;
  return (
    <InnerTable.Container>
      <Table>
        <TableHead>
          <InnerTable.HeaderRow>
            <InnerTable.HeaderCell size="small">#</InnerTable.HeaderCell>
            <InnerTable.HeaderCell size="small">Course</InnerTable.HeaderCell>
            <InnerTable.HeaderCell size="small">Intake</InnerTable.HeaderCell>
            <InnerTable.HeaderCell size="small">Status</InnerTable.HeaderCell>
            <InnerTable.HeaderCell size="small">Last Modified</InnerTable.HeaderCell>
          </InnerTable.HeaderRow>
        </TableHead>
        <TableBody>
          {loading && PLACEHOLDER}
          {applicationCoursePreferences.map((preference, index) => (
            <ApplicationCoursePreferenceTableRow
              key={preference.recordId}
              index={index}
              data={preference}
            />
          ))}
        </TableBody>
      </Table>
      {!loading && !error && applicationCoursePreferences.length === 0 && (
        <Typography variant="body2" color="text.secondary" textAlign="center" p={6}>
          No preferences available
        </Typography>
      )}
      {!loading && error && (
        <Alert severity="error">
          <ErrorNotificationMessage
            error={error}
            message="Failed to load course preferences"
          />
        </Alert>
      )}
    </InnerTable.Container>
  );
}

interface ApplicationCoursePreferenceTableRowProps {
  index?: number;
  data?: IApplication["application"]["coursePreferences"][number];
}

function ApplicationCoursePreferenceTableRow(
  props: ApplicationCoursePreferenceTableRowProps,
) {
  const { index = 0, data } = props;
  const { course, courseOffering, lastModifiedDate, status } = data ?? {};
  return (
    <TableRow>
      <TableCell width={1}>
        {!data && <Skeleton width={8} />}
        {data && index + 1}
      </TableCell>
      <TableCell sx={{ whiteSpace: { xs: "inherit", lg: "nowrap" } }}>
        <Typography variant="body2" color="secondary">
          {!data && <Skeleton width="75%" />}
          {data && (
            <>
              <Typography variant="inherit" component="span" fontWeight="bold" mr={0.5}>
                {course?.code}
              </Typography>{" "}
              {course?.title}
            </>
          )}
        </Typography>
        <Typography variant="caption" color="secondary">
          {!data && <Skeleton />}
          {courseOffering?.specialisation?.title}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="secondary">
          {!data && <Skeleton />}
          {courseOffering?.studyPeriod?.description}
        </Typography>
        <Typography variant="caption" color="secondary">
          {!data && <Skeleton width="50%" />}
          {courseOffering?.location?.code}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="secondary">
          {!data && <Skeleton />}
          {data && (status || "-")}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="secondary">
          {!data && <Skeleton />}
          {data && <Timestamp>{lastModifiedDate}</Timestamp>}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

import { z } from "zod";

import { CampusLocation } from "../CampusLocation";

export type ApplicationStatus = z.infer<typeof ApplicationStatusSchema>;
export const ApplicationStatusSchema = z.enum([
  "Draft",
  "Sent Draft Application to Applicant",
  "Applicant Declaration Accepted",
  "Agent Declaration Accepted",
  "Review",
  "Sent for Submission",
  "Submission on Hold (Duplicate Contact)",
  "Submitted",
  "Cancelled",
]);

export function isApplicationStatus(
  applicationStatus?: string | null,
): applicationStatus is ApplicationStatus {
  return ApplicationStatusSchema.safeParse(applicationStatus).success;
}

export function isSubmittedApplicationStatus(status: ApplicationStatus) {
  const validStatuses: ApplicationStatus[] = [
    "Agent Declaration Accepted",
    "Sent for Submission",
    "Submission on Hold (Duplicate Contact)",
    "Review",
    "Submitted",
  ];
  return validStatuses.includes(status);
}

/** This function checks whether the supplied application status is one in which the application's fee waiver code can be updated. */
export function isApplicationStatusValidForFeeWaiverCodeUpdate(
  status: ApplicationStatus,
) {
  const validStatuses: ApplicationStatus[] = [
    // "Draft" is also correct, but we don't need to include it here since in Draft all fields are updatable anyway
    "Sent Draft Application to Applicant",
    "Applicant Declaration Accepted",
  ];
  return validStatuses.includes(status);
}

/** This function checks whether the supplied application status is one in which enquiries can be created. */
export function isApplicationStatusValidForEnquiryCreation(status: ApplicationStatus) {
  const validStatuses: ApplicationStatus[] = [
    "Sent for Submission",
    "Submission on Hold (Duplicate Contact)",
    "Review",
    "Submitted",
  ];
  return validStatuses.includes(status);
}

export type ApplicationCampusLocation = z.infer<typeof ApplicationCampusLocationSchema>;
export const ApplicationCampusLocationSchema = z.nativeEnum(CampusLocation);

export type ApplicationType = z.infer<typeof ApplicationTypeSchema>;
export const ApplicationTypeSchema = z.enum(["Default", "Pathway"]);

export const ApplicationCoursePreferenceStatusOrdering = [
  "Full Offer - Accepted",
  "Conditional Offer - Accepted",
  "Full Offer",
  "Packaged Conditional Offer",
  "Conditional Offer",
  "Pending",
  "In Progress",
  "Offer Declined",
  "Conditional Offer Declined",
  "Offer Lapsed",
  "Ineligible",
  "Application Lapsed",
  "Application Withdrawn",
  "Not processed yet",
  "Full Offer Withdrawn",
  "Conditional Offer Withdrawn",
  "Error",
] as const;

export type ApplicationCoursePreferenceStatus = z.infer<
  typeof ApplicationCoursePreferenceStatusSchema
>;
export const ApplicationCoursePreferenceStatusSchema = z.enum(
  ApplicationCoursePreferenceStatusOrdering,
);

export function isApplicationCoursePreferenceStatus(
  status?: string | null,
): status is ApplicationCoursePreferenceStatus {
  return ApplicationCoursePreferenceStatusSchema.safeParse(status).success;
}

export function isApplicationCompleteCoursePreferenceStatus(
  status: ApplicationCoursePreferenceStatus | undefined | null,
) {
  const validStatuses: ApplicationCoursePreferenceStatus[] = [
    "Full Offer - Accepted",
    "Conditional Offer - Accepted",
    "Offer Declined",
    "Conditional Offer Declined",
    "Offer Lapsed",
    "Ineligible",
    "Application Lapsed",
    "Application Withdrawn",
    "Full Offer Withdrawn",
    "Conditional Offer Withdrawn",
    "Error",
  ];
  if (!status) return false;
  return validStatuses.includes(status);
}

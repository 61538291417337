import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useCallback, useState } from "react";
import * as Sentry from "@sentry/react";

import {
  ApplicantApi,
  ApplicationApi,
  GetApi,
  IApplicant,
  IApplicantCreatePayload,
  IApplicationCreatePayload,
} from "~/api";
import { useLoadResource } from "~/components/core/AppLoadingBar";
import { useNotification } from "~/components/core/NotificationProvider";

import { ApplicantDetailsForm } from "./ApplicantDetailsForm";
import { MonashStudiesForm } from "./MonashStudiesForm";
import { DelegatedAgencyForm } from "./DelegatedAgencyForm";

export type NewApplicationFields = z.infer<typeof NewApplicationSchema>;

const NewApplicationSchema = z.object({
  applicantDetails: ApplicantDetailsForm.schema,
  monashStudies: MonashStudiesForm.schema,
  delegator: DelegatedAgencyForm.schema,
});

export function mapApplicantToForm(data?: IApplicant): NewApplicationFields {
  const {
    firstName,
    lastName,
    hasMononymousName,
    dateOfBirth,
    phone,
    email,
    isPreviousMonashStudent,
    previousMonashId,
    previousName,
  } = data ?? {};
  return {
    applicantDetails: {
      legalGivenNames: firstName ?? "",
      legalFamilyNames: lastName ?? "",
      hasMononymousName: hasMononymousName ?? false,
      dateOfBirth: dateOfBirth ?? "",
      mobile: phone?.find((x) => x.type === "Mobile")?.phoneNumber ?? "",
      email: email?.find((x) => x.type === "Personal")?.email ?? "",
      campusLocation: "",
      residencyStatus: "",
    },
    monashStudies: {
      isPreviousMonashStudent: isPreviousMonashStudent ?? null,
      previousMonashId: previousMonashId ?? "",
      // If there was an applicant object passed, set this to `false`
      // since it means the existing applicant had no previous name
      // (null means the question is not answered)
      hasChangedName: previousName ? true : data ? false : null,
      previousName: previousName ?? "",
    },
    delegator: {
      delegatorOrgUnitCode: "",
    },
  };
}

function createApplicationCreatePayload(data: NewApplicationFields): {
  applicant: IApplicantCreatePayload;
  createApplicationPayload: (options: {
    applicantId: string;
  }) => IApplicationCreatePayload;
} {
  const {
    applicantDetails: {
      legalGivenNames,
      legalFamilyNames,
      hasMononymousName,
      dateOfBirth,
      mobile,
      email,
      campusLocation,
      residencyStatus,
    },
    monashStudies: {
      isPreviousMonashStudent = false,
      previousMonashId,
      hasChangedName = false,
      previousName,
    },
    delegator: { delegatorOrgUnitCode },
  } = data;
  return {
    applicant: {
      firstName: hasMononymousName ? "" : legalGivenNames,
      lastName: legalFamilyNames,
      hasMononymousName,
      dateOfBirth: dateOfBirth,
      phone: [{ type: "Mobile", phoneNumber: mobile }],
      email: [{ type: "Personal", email }],
      isPreviousMonashStudent: isPreviousMonashStudent ?? false,
      previousMonashId: isPreviousMonashStudent ? previousMonashId : "",
      previousName: hasChangedName ? previousName : "",
    },
    createApplicationPayload: ({ applicantId }) => ({
      applicantId,
      campusLocation,
      residencyStatus,
      email: { type: "Personal", email },
      phone: { type: "Mobile", phoneNumber: mobile },
      delegatorOrgUnitCode,
    }),
  };
}

export type UseNewApplicationForm = ReturnType<typeof useNewApplicationForm>;

export function useNewApplicationForm(applicant?: IApplicant) {
  const [loading, setLoading] = useState<
    "idle" | "applicant-create" | "applicant-update" | "application"
  >("idle");
  const [created, setCreated] = useState(false);

  const form = useForm<NewApplicationFields>({
    resolver: zodResolver(NewApplicationSchema),
    defaultValues: mapApplicantToForm(applicant),
  });

  const { showErrorAlert } = useNotification();

  const onValidSubmit = async (data: NewApplicationFields) => {
    // Create application
    try {
      const { applicant: applicantPayload, createApplicationPayload } =
        createApplicationCreatePayload(data);

      const isEditable = applicant?.isEditable ?? true;

      let applicantId = "";
      let applicantUpdated = false;

      if (applicant) {
        applicantId = applicant.applicantId;
      } else {
        // Create applicant if they do not exist
        setLoading("applicant-create");
        const response = await GetApi(ApplicantApi).createApplicant(applicantPayload);
        applicantId = response.applicantId;
        applicantUpdated = true;
      }

      // Create application
      setLoading("application");
      const { applicationId } = await GetApi(ApplicationApi).createApplication(
        createApplicationPayload({ applicantId }),
      );

      // Check if applicant needs updating
      if (
        !applicantUpdated &&
        isEditable &&
        form.getFieldState("monashStudies").isDirty
      ) {
        setLoading("applicant-update");
        await GetApi(ApplicantApi).updateApplicant(applicantId, applicantPayload);
      }

      setCreated(true);

      return { applicantId, applicationId };
    } catch (error) {
      // send error to Sentry
      Sentry.captureException(error, {
        tags: { source: "useNewApplicationForm.onValidSubmit" },
      });

      showErrorAlert(error);
    } finally {
      setLoading("idle");
    }

    return null;
  };

  // Show loading app bar
  useLoadResource(
    useCallback(() => loading !== "idle", [loading]),
    useNewApplicationForm.name,
  );

  return { form, onValidSubmit, loading, created };
}

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IAgent,
  IAgentCreatePayload,
  IAgentCreateResponse,
  IAgentUpdatePayload,
  IAgentUpdateResponse,
  IErrorResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class AgencyApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Agency API
   * @name GetAgents
   * @summary Get all agents in the current agency
   * @request GET:/api/agents
   * @response `200` `(IAgent)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getAgents = (
    query?: {
      /** @example "all" */
      filter?: "all" | "inactive" | "active" | "suspended" | "deactivated";
    },
    params: RequestParams = {},
  ) =>
    this.request<IAgent[], IErrorResponse>({
      path: `/api/agents`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Agency API
   * @name CreateAgent
   * @summary Create a new agent
   * @request POST:/api/agents
   * @response `201` `IAgentCreateResponse`
   * @response `401` `IErrorResponse` Not signed in.
   */
  createAgent = (data: IAgentCreatePayload, params: RequestParams = {}) =>
    this.request<IAgentCreateResponse, IErrorResponse>({
      path: `/api/agents`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Agency API
   * @name GetAgentByEmail
   * @summary Get an existing agent's details
   * @request GET:/api/agents/{email}
   * @response `200` `IAgent`
   */
  getAgentByEmail = (email: string, params: RequestParams = {}) =>
    this.request<IAgent, any>({
      path: `/api/agents/${email}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Agency API
   * @name ActivateAgent
   * @summary Activate an agent
   * @request POST:/api/agents/{agentId}
   * @response `201` `IAgentCreateResponse`
   */
  activateAgent = (
    agentId: string,
    data: IAgentUpdatePayload,
    params: RequestParams = {},
  ) =>
    this.request<IAgentCreateResponse, any>({
      path: `/api/agents/${agentId}`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Agency API
   * @name UpdateAgent
   * @summary Update an agent
   * @request PATCH:/api/agents/{agentId}
   * @response `200` `IAgentUpdateResponse`
   */
  updateAgent = (
    agentId: string,
    data: IAgentUpdatePayload,
    params: RequestParams = {},
  ) =>
    this.request<IAgentUpdateResponse, any>({
      path: `/api/agents/${agentId}`,
      method: "PATCH",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Agency API
   * @name DeactivateAgent
   * @summary Deactivate an agent
   * @request DELETE:/api/agents/{agentId}
   * @response `200` `IAgentUpdateResponse`
   */
  deactivateAgent = (agentId: string, params: RequestParams = {}) =>
    this.request<IAgentUpdateResponse, any>({
      path: `/api/agents/${agentId}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
}

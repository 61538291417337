import {
  Control,
  FieldArrayWithId,
  UseFieldArrayRemove,
  useWatch,
  useFormState,
} from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  lighten,
  Tooltip,
} from "@mui/material";

import { DisplayTable } from "~/components/core/TableStyles";
import { ErrorIndicatorCell } from "~/components/core/ErrorIndicatorCell";
import { ApplicationFields } from "~/components/application/ApplicationForm";

import { AcademicQualification } from "../ApplicationForm/AcademicQualificationsForm";
import { EmptyQualificationType } from "./AcademicQualificationFields";
import { checkIfErrorsInTable, getTableContent } from "~/utils";

function getDisplayValues(qualification: AcademicQualification | EmptyQualificationType) {
  if (!qualification) return null;
  const type = qualification.type;
  let institutionName = "";
  let assessmentOrQualification = "";
  if (type === "Tertiary Education") {
    institutionName = qualification.institution?.institutionName ?? "";
    assessmentOrQualification = qualification.name;
  }
  if (type === "Secondary Education") {
    institutionName = qualification.institutionName;
    assessmentOrQualification = qualification.assessmentType?.description ?? "";
  }
  if (type === "Other Qualification") {
    assessmentOrQualification = qualification.name;
  }
  return { type, institutionName, assessmentOrQualification };
}

export interface AcademicQualificationsTableProps {
  control: Control<ApplicationFields>;
  fields: FieldArrayWithId<ApplicationFields>[];
  remove: UseFieldArrayRemove;
  onEdit: (index: number) => void;
  isEditing: boolean;
  editIndex?: number;
  oldData?: AcademicQualification | EmptyQualificationType;
  disabled?: boolean;
}

export function AcademicQualificationsTable(props: AcademicQualificationsTableProps) {
  const { control, fields, remove, onEdit, disabled, editIndex, isEditing, oldData } =
    props;
  // up-to-date values for display in table
  const qualifications = useWatch({
    control,
    name: "academicQualifications.qualifications",
  });
  // up-to-date values for errors
  const { errors } = useFormState({
    control,
    name: "academicQualifications.qualifications",
  });
  const errorList = errors.academicQualifications?.qualifications;

  // Check if there are errors in the table that need to be displayed.
  const haveErrorsInTable = checkIfErrorsInTable(isEditing, oldData, errorList);

  const createTableRow = (
    fieldValues: AcademicQualification | EmptyQualificationType,
    index: number,
    fieldId: string,
  ) => {
    const displayValues = getDisplayValues(fieldValues);
    const fieldErrors = errorList?.[index];

    // NOTE: optional chaining operators used below to avoid potential issues with displayValues being undefined
    // this may occur while values are being appended to the table, etc.
    return (
      <TableRow
        key={fieldId}
        sx={
          fieldErrors && {
            backgroundColor: (theme) => lighten(theme.palette.error.light, 0.9),
          }
        }
      >
        {haveErrorsInTable && <ErrorIndicatorCell errors={fieldErrors} />}
        <TableCell>{displayValues?.type}</TableCell>
        <TableCell>{displayValues?.institutionName}</TableCell>
        <TableCell>{displayValues?.assessmentOrQualification}</TableCell>
        <Tooltip
          title={
            isEditing &&
            "To modify this record, please first confirm any changes to the record currently being edited."
          }
        >
          <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
            <Tooltip title="Edit">
              <span>
                <IconButton
                  size="large"
                  onClick={() => onEdit(index)}
                  disabled={isEditing || disabled}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <span>
                <IconButton
                  size="large"
                  onClick={() => remove(index)}
                  disabled={isEditing || disabled}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </TableCell>
        </Tooltip>
      </TableRow>
    );
  };

  const tableContent = getTableContent(
    fields,
    EmptyTableContent,
    qualifications,
    createTableRow,
    editIndex,
    oldData,
  );

  return (
    <Grid item xs={12}>
      <DisplayTable.Container>
        <Table size="small">
          <TableHead>
            <DisplayTable.HeaderRow>
              {haveErrorsInTable && <DisplayTable.HeaderCell />}
              <DisplayTable.HeaderCell>Qualification Type</DisplayTable.HeaderCell>
              <DisplayTable.HeaderCell>Institution/School</DisplayTable.HeaderCell>
              <DisplayTable.HeaderCell>
                Assessment Type/Qualification Name
              </DisplayTable.HeaderCell>
              <DisplayTable.HeaderCell />
            </DisplayTable.HeaderRow>
          </TableHead>
          <TableBody>{tableContent}</TableBody>
        </Table>
      </DisplayTable.Container>
    </Grid>
  );
}

const EmptyTableContent = (
  <TableRow>
    <TableCell colSpan={5} align="center" sx={{ py: 2, color: "text.secondary" }}>
      No academic qualifications listed.
    </TableCell>
  </TableRow>
);

import { differenceInSeconds } from "date-fns";

import { Duration, DurationProps } from "~/components/core/Duration";
import { useRerender } from "~/hooks/state";

export interface CountdownProps {
  prefix?: React.ReactNode;
  format?: DurationProps["format"];
  to?: Date | null;
}

export function Countdown(props: CountdownProps) {
  const { prefix, format = "s", to } = props;

  useRerender();

  let value = 0;
  const now = new Date();

  if (to && to.getTime() > now.getTime()) value = differenceInSeconds(to, now);

  return (
    <>
      {prefix} <Duration format={format}>{value}</Duration>
    </>
  );
}

import { Fragment, useMemo } from "react";
import { format } from "date-fns";
import { UseQueryResult } from "@tanstack/react-query";
import {
  Paper,
  Grid,
  Typography,
  Divider,
  Box,
  Stack,
  Skeleton,
  Chip,
} from "@mui/material";

import { MONASH_PALETTE, joinName } from "@packages/utils";

import { IAgent, IEnquiryDetails } from "~/api";
import { Timestamp } from "~/components/core/Timestamp";
import { FormSection } from "~/components/form/FormSection";

function mapCommentsByDate(comments: IEnquiryDetails["comments"]) {
  const uniqueDates = Array.from(
    new Set(
      comments.map((comment) => format(new Date(comment.lastModifiedDate), "d/MM/yyyy")),
    ),
  );

  return uniqueDates.map((uniqueDate) => {
    return {
      date: uniqueDate,
      comments: comments.filter(
        (comment) =>
          format(new Date(comment.lastModifiedDate), "d/MM/yyyy") === uniqueDate,
      ),
    };
  });
}

export interface EnquiryUpdatesCardProps {
  enquiry: IEnquiryDetails;
  authorAgent: UseQueryResult<IAgent, unknown>;
}

export function EnquiryUpdatesCard(props: EnquiryUpdatesCardProps) {
  const { enquiry, authorAgent } = props;

  const commentsByDate = mapCommentsByDate(enquiry.comments);

  return (
    <FormSection>
      <FormSection.Title>Updates</FormSection.Title>
      <Box
        bgcolor={MONASH_PALETTE.MONASH_LIGHT_GREY}
        boxShadow="inset 0px 0px 25px -4px rgba(0, 0, 0, 0.25);"
        borderRadius={2}
        px={3}
        py={3.5}
        component={Stack}
        gap={3}
      >
        <EnquiryClientQuestionCard enquiry={enquiry} authorAgent={authorAgent} />
        {commentsByDate.map(({ date, comments }) => (
          <Fragment key={date}>
            <DividerWithDate date={date} />
            {comments.map((comment) => (
              <EnquiryFeedResponseCard key={comment.id} comment={comment} />
            ))}
          </Fragment>
        ))}
      </Box>
    </FormSection>
  );
}

export function EnquiryClientQuestionCard(props: EnquiryUpdatesCardProps) {
  const {
    enquiry: {
      subject,
      agency,
      createdDate,
      description,
      agent: { email },
    },
    authorAgent,
  } = props;

  const { clientQuestion, attachments } = useMemo(() => {
    let clientQuestion = description;
    let attachments: string[] = [];

    const parsed = /([\s\S]+)\n\[Attachments: \d+\]\n([\s\S]+)/.exec(description);
    if (parsed) {
      clientQuestion = parsed[1];
      attachments = parsed[2]?.split("\n");
    }

    return { clientQuestion, attachments };
  }, [description]);

  return (
    <Paper elevation={1} sx={{ p: 3, mb: 1 }}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item xs>
          {email && authorAgent.isPending ? (
            <>
              <Skeleton width={100} />
              <Skeleton width={150} />
            </>
          ) : authorAgent.data ? (
            <>
              <Typography variant="body1">
                {joinName(authorAgent.data.firstName, authorAgent.data.lastName)}
              </Typography>
              <Typography variant="body2" color="secondary">
                {agency.name}
              </Typography>
            </>
          ) : (
            <Typography variant="body1">Initial Enquiry</Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" color="secondary" align="right">
            <Timestamp>{createdDate}</Timestamp>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" color="primary" fontWeight="medium" gutterBottom>
            {subject}
          </Typography>
          <Typography variant="body2" whiteSpace="break-spaces">
            {clientQuestion}
          </Typography>
          {attachments.length > 0 && (
            <Box mt={2}>
              <Typography variant="body2" color="secondary" mb={1}>
                Attachments ({attachments.length})
              </Typography>
              <Stack direction="row" gap={1} flexWrap="wrap">
                {attachments.map((attachment) => (
                  <Chip key={attachment} label={attachment} />
                ))}
              </Stack>
            </Box>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

export function DividerWithDate(props: { date: string }) {
  const { date } = props;
  return (
    <Divider>
      <Typography variant="body2" color="secondary" align="right">
        <Timestamp format="d/MM/yyyy">{date}</Timestamp>
      </Typography>
    </Divider>
  );
}

export function EnquiryFeedResponseCard(props: {
  comment: IEnquiryDetails["comments"][number];
}) {
  const { comment } = props;
  return (
    <Paper elevation={1} sx={{ p: 3, width: "75%" }}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item xs>
          <Typography variant="body1">{comment.createdBy}</Typography>
          <Typography variant="body2" color="secondary">
            Monash University
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body2" color="secondary" align="right">
            <Timestamp format="h:mm aaa">{comment.lastModifiedDate}</Timestamp>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">{comment.body}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

import { z } from "zod";
import { Alert, Box, Divider, Grid, Link, Stack, Typography } from "@mui/material";
import {
  Control,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormTrigger,
  useFormState,
  useWatch,
} from "react-hook-form";

import { CampusLocation } from "@packages/types";

import { createAddIssue } from "~/utils";
import { FormRadioGroup } from "~/components/form/FormRadioGroup";
import { FormSection } from "~/components/form/FormSection";
import { YesNo } from "~/components/form/FormYesNo";
import {
  DirectApplicationCourseSelectionForm,
  EnglishCourseSelectionForm,
  MonashCollegePathwayCourseSelectionForm,
} from "~/components/application/CourseSelectionForm";

import { ApplicationFields } from "./useApplicationForm";

export interface CoursePreferencesFormProps {
  control: Control<ApplicationFields>;
  setValue: UseFormSetValue<ApplicationFields>;
  clearErrors: UseFormClearErrors<ApplicationFields>;
  trigger: UseFormTrigger<ApplicationFields>;
  disabled?: boolean;
  campusLocation: string;
}

export function CoursePreferencesForm(props: CoursePreferencesFormProps) {
  const { control, setValue, clearErrors, disabled, campusLocation } = props;

  const [englishCourse, applicationType] = useWatch({
    control,
    name: ["coursePreferences.englishCourse", "coursePreferences.applicationType"],
  });

  const formState = useFormState({ control, name: "coursePreferences" });
  const coursePreferenceError =
    formState.errors.coursePreferences?.preferenceList?.root?.message ||
    formState.errors.coursePreferences?.preferenceList?.message;

  function onDirectApplication() {
    if (disabled) return;
    if (applicationType !== "Direct") {
      clearErrors("coursePreferences");
      setValue("coursePreferences.preferenceList", []);
      setValue("coursePreferences.pathwayCourse", null);
    }
    if (applicationType === "English") setValue("coursePreferences.englishCourse", null);
  }

  function onPathwayApplication() {
    if (disabled) return;
    if (applicationType !== "Pathway") {
      clearErrors("coursePreferences");
      setValue("coursePreferences.pathwayCourse", EmptyCoursePreference);
      setValue("coursePreferences.preferenceList", [EmptyCoursePreference]);
    }
    if (applicationType === "English") setValue("coursePreferences.englishCourse", null);
  }

  function onEnglishApplication() {
    if (disabled) return;
    if (applicationType !== "English") {
      clearErrors("coursePreferences");
      setValue("coursePreferences.preferenceList", []);
      setValue("coursePreferences.pathwayCourse", null);
    }
    if (!englishCourse)
      setValue("coursePreferences.englishCourse", EmptyCoursePreference);
  }

  return (
    <>
      <FormSection.Well>
        <FormSection.List>
          <Typography variant="body1" component="li">
            Choose up to three courses that the applicant would like to study and list
            them in order of preference.
          </Typography>
          <Typography variant="body1" component="li">
            Check the entry requirements and prerequisite subjects for each to see if the
            applicant qualifies.
          </Typography>
          <Typography variant="body1" component="li">
            If they meet the requirements of their first choice, they will not be assessed
            for their second and third choices.
          </Typography>
          <Typography variant="body1" component="li">
            If they do not meet the entry requirements of any of their preferences, their
            application will be automatically assessed for alternative or pathway courses
            within the relevant study discipline.
          </Typography>
          <Typography variant="body1" component="li">
            Use the appropriate Find a Course page to assist in your selection:
          </Typography>
          <Stack component="ul">
            <Typography variant="body1" component="li">
              <Link
                href="https://www.monash.edu.my/study/undergraduate"
                target="_blank"
                rel="noopener"
              >
                Undergraduate courses
              </Link>
            </Typography>
            <Typography variant="body1" component="li">
              <Link
                href="https://www.monash.edu.my/study/postgraduate-and-research"
                target="_blank"
                rel="noopener"
              >
                Postgraduate and Research courses
              </Link>
            </Typography>
          </Stack>
          <Typography variant="body1" component="li">
            If the selected course has multiple course durations available, please specify
            the duration in the form below. Please note that durations are subject to
            meeting the course's designated entry requirements.
          </Typography>
        </FormSection.List>
      </FormSection.Well>
      <Grid container spacing={2}>
        {campusLocation !== CampusLocation.MALAYSIA && (
          <Grid item xs={12}>
            <FormRadioGroup
              control={control}
              name="coursePreferences.applicationType"
              label="Please select an application type"
              disabled={disabled}
              options={[
                {
                  value: "Direct",
                  label: "Direct Application",
                  checked: applicationType === "Direct",
                  onClick: onDirectApplication,
                },
                {
                  value: "Pathway",
                  label: "Monash College Pathway",
                  checked: applicationType === "Pathway",
                  onClick: onPathwayApplication,
                },
                {
                  value: "English",
                  label: "Monash English Only",
                  checked: applicationType === "English",
                  onClick: onEnglishApplication,
                },
              ]}
            />
          </Grid>
        )}
        {campusLocation !== CampusLocation.MALAYSIA && applicationType !== "English" && (
          <Grid item xs={12}>
            <YesNo
              label="Does the applicant wish to apply for a Monash English Language Centre course?"
              checked={Boolean(englishCourse)}
              radioGroupProps={{
                onChange: () =>
                  setValue(
                    "coursePreferences.englishCourse",
                    englishCourse ? null : EmptyCoursePreference,
                  ),
              }}
              disabled={disabled}
            />
          </Grid>
        )}
        {coursePreferenceError && (
          <Box pl={2} pt={2} width="100%">
            <Alert severity="error">{coursePreferenceError}</Alert>
          </Box>
        )}
        {englishCourse && <EnglishCourseSelectionForm {...props} />}
        {englishCourse && applicationType !== "English" && (
          <Grid item xs={12}>
            <Divider />
          </Grid>
        )}
        {applicationType === "Direct" && (
          <DirectApplicationCourseSelectionForm {...props} />
        )}
        {applicationType === "Pathway" && (
          <MonashCollegePathwayCourseSelectionForm {...props} />
        )}
      </Grid>
    </>
  );
}

export type SelectedCourse = z.infer<typeof SelectedCourseSchema>;
const SelectedCourseSchema = z.object({
  title: z.string(),
  code: z.string(),
  courseOfferings: z.array(
    z.object({
      id: z.string(),
      offerringAcademicYear: z.string(),
      location: z.object({
        code: z.string(),
        value: z.string(),
      }),
      calendarDetail: z.array(
        z.object({
          code: z.string(),
          type: z.string(),
          description: z.string().optional(),
        }),
      ),
      unitSets: z.array(
        z.object({
          code: z.string(),
          title: z.string(),
        }),
      ),
    }),
  ),
});

export type CoursePreference = z.infer<typeof CoursePreferenceSchema>;
export const CoursePreferenceSchema = z.object({
  recordId: z.string().optional(),
  offeringAcademicYear: z.string(),
  courseInfo: SelectedCourseSchema.nullable(),
  courseCode: z.string(),
  stream: z.string(),
  studyPeriod: z.string(),
  location: z.string(),
});

CoursePreferencesForm.draftSchema = z
  .object({
    applicationType: z.enum(["Direct", "Pathway", "English"]),
    englishCourse: CoursePreferenceSchema.nullable(),
    pathwayCourse: CoursePreferenceSchema.nullable(),
    preferenceList: z.array(CoursePreferenceSchema),
  })
  .superRefine(
    ({ applicationType, englishCourse, pathwayCourse, preferenceList }, ctx) => {
      const addIssue = createAddIssue(ctx);

      if (applicationType === "Pathway") {
        if (!pathwayCourse) addIssue("Please select a pathway course", "preferenceList");
        if (preferenceList.length !== 1)
          addIssue("Please select a destination course", "preferenceList");
      }

      if (applicationType === "English") {
        if (!englishCourse) addIssue("Please select an English course", "preferenceList");
      }

      const preferences = [
        { path: "englishCourse", course: englishCourse },
        { path: "pathwayCourse", course: pathwayCourse },
        ...preferenceList.map((course, index) => ({
          path: `preferenceList.${index}`,
          course,
        })),
      ];

      preferences.forEach(({ path, course }) => {
        if (!course) return;
        if (!course.courseInfo) addIssue("Please select a course", path, "courseInfo");
        else if (!course.studyPeriod)
          addIssue("Please select a semester", path, "studyPeriod");
        else if (!course.location) addIssue("Please select a location", path, "location");
      });
    },
  );

CoursePreferencesForm.submitSchema = CoursePreferencesForm.draftSchema.superRefine(
  ({ applicationType, preferenceList }, ctx) => {
    const addIssue = createAddIssue(ctx);

    if (applicationType === "Direct") {
      if (preferenceList.length === 0)
        addIssue("Please add at least one course preference", "preferenceList");
      if (preferenceList.length > 3)
        addIssue("Do not add more than three course preferences", "preferenceList");
    }
  },
);

export const EmptyCoursePreference: CoursePreference = {
  offeringAcademicYear: new Date().getFullYear().toString(),
  studyPeriod: "",
  courseCode: "",
  stream: "",
  location: "",
  courseInfo: null,
};

import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Hidden,
  Skeleton,
  useTheme,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import { list } from "@packages/utils";

import { IApplicationDocumentChecklistItem } from "~/api";
import { ApplicationDocumentUploadModal } from "~/components/application/ApplicationDocumentUploadModal";
import { SanitisedHTML } from "~/components/core/SanitisedHTML";

import { ApplicationDocumentCard } from "./ApplicationDocumentCard";

const PLACEHOLDER = list(3).map((index) => (
  <Grid key={index} item xs={12} sm={6} md={4}>
    <ApplicationDocumentCard loading />
  </Grid>
));

export interface ApplicationDocumentsModalItemProps {
  loading?: boolean;
  isEditable?: boolean;
  applicationId?: string;
  checklistItem?: IApplicationDocumentChecklistItem;
}

export function ApplicationDocumentsModalItem(props: ApplicationDocumentsModalItemProps) {
  const { loading, isEditable, applicationId, checklistItem } = props;

  const { breakpoints } = useTheme();
  const [uploadOpen, setUploadOpen] = useState(false);

  const documents = checklistItem?.supportedDocumentTypes
    .map(({ type, documents }) =>
      documents.map((document) => {
        document.type = { label: type.name, value: type.code };
        return document;
      }),
    )
    .flat();

  const uploadButton = (
    <Button
      variant="contained"
      startIcon={<FileUploadIcon />}
      sx={{ ml: "auto", alignSelf: "baseline", flexShrink: 0, mt: 0.5 }}
      disabled={loading}
      onClick={(e) => {
        e.stopPropagation();
        setUploadOpen(true);
      }}
    >
      Upload
    </Button>
  );

  return (
    <>
      <Accordion defaultExpanded sx={{ pr: 1 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="primary" />}
          sx={{ flexDirection: "row-reverse" }}
        >
          <Typography
            variant="h6"
            color="primary"
            alignSelf="center"
            sx={{ minWidth: "50%", ml: 1.5, mr: 2 }}
          >
            {loading ? <Skeleton /> : checklistItem?.name || checklistItem?.id}
            <Typography variant="body2" color="text.secondary">
              {loading ? (
                <Skeleton />
              ) : (
                <SanitisedHTML html={checklistItem?.description} />
              )}
            </Typography>
          </Typography>
          <Hidden mdDown>{!loading && isEditable && uploadButton}</Hidden>
        </AccordionSummary>
        <AccordionDetails sx={{ pl: 6.5, pb: 3, [breakpoints.down("md")]: { pb: 2 } }}>
          <Grid container spacing={2}>
            {loading && PLACEHOLDER}
            {documents?.map((document) => (
              <Grid key={document.id} item sm={12} md={6} lg={4} sx={{ width: "100%" }}>
                <ApplicationDocumentCard loading={loading} document={document} />
              </Grid>
            ))}
            {!loading && documents?.length === 0 && (
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary">
                  No documents uploaded
                </Typography>
              </Grid>
            )}
          </Grid>
          {!loading && isEditable && (
            <Hidden mdUp>
              <Grid item xs={12} pt={2} container justifyContent="flex-end">
                {uploadButton}
              </Grid>
            </Hidden>
          )}
        </AccordionDetails>
      </Accordion>
      <ApplicationDocumentUploadModal
        open={uploadOpen}
        title={`Upload ${checklistItem?.name ?? "Documents"}`}
        applicationId={applicationId}
        checklistItemId={checklistItem?.id}
        onClose={() => {
          setUploadOpen(false);
        }}
        documentTypes={checklistItem?.supportedDocumentTypes.map(({ type }) => ({
          label: type.name,
          value: type.code,
        }))}
      />
    </>
  );
}

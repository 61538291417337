import { Alert, Collapse } from "@mui/material";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { format } from "date-fns";

import { ISO_DATE_REGEX } from "@packages/types";
import { getLocalTimeZoneName } from "@packages/utils";

import { useAuthState } from "~/components/auth/AuthProvider";
import { useLatestNotification } from "~/hooks/notification";

// Stores the ID of a notification the user has hidden
// If a new notification with a new ID is available, it will be shown
const hideNotificationAtom = atomWithStorage("notification", "");

export function useHideNotification() {
  return useAtom(hideNotificationAtom);
}

export function AlertNotificationBanner() {
  const notification = useLatestNotification();
  const [user] = useAuthState();

  const [hideNotification, setHideNotification] = useAtom(hideNotificationAtom);

  if (!notification.data) return null;

  const { id, message } = notification.data ?? {};

  return (
    // Notifications should always show on the sign in page
    <Collapse in={!user || id !== hideNotification} timeout={{ enter: 150, exit: 250 }}>
      <Alert
        severity="warning"
        variant="filled"
        // Only allow signed in users to hide the notification
        onClose={user ? () => setHideNotification(id) : undefined}
        square
      >
        {message
          // Replace any ISO date strings in the message with a
          // nicely formatted date in the user's current timezone
          .replace(new RegExp(ISO_DATE_REGEX, "g"), (dateString) => {
            const date = new Date(dateString);

            // Don't modify the string if the date is invalid
            if (isNaN(date.getTime())) return dateString;

            // Otherwise, return a formatted date
            return format(
              date,
              `cccc h:mm aa, do MMMM yyyy '${getLocalTimeZoneName("long")}'`,
            );
          })}
      </Alert>
    </Collapse>
  );
}

/**
 * Download a file on the user's browser.
 *
 * @param blob The data to download.
 * @param fileName The name to give the file being downlaoded.
 */
export function download(blob: Blob, fileName: string) {
  // Create blob link to download
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  // Start download
  link.click();

  // Clean up
  window.URL.revokeObjectURL(url);
}

import { useState } from "react";
import { useQueryParam } from "use-query-params";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { IAgentStatusFilter } from "~/api";
import { useDocumentTitle } from "~/hooks/title";
import { useAgents } from "~/hooks/agency";
import { usePage, usePageSize } from "~/hooks/query-param";
import { parseRequestError } from "~/utils/errors";
import { UsersTable } from "~/components/user/UsersTable";
import { EditUserModal } from "~/components/user/EditUserModal";

function useAgentStatusFilter(defaultValue: IAgentStatusFilter = "all") {
  return useQueryParam<IAgentStatusFilter>("filter", {
    default: defaultValue,
    encode: (value) => value,
    decode: (value) =>
      (typeof value === "string" ? value : defaultValue) as IAgentStatusFilter,
  });
}

export function UserManagementPage() {
  useDocumentTitle("User management");

  const [filter, setFilter] = useAgentStatusFilter();
  const [page, setPage] = usePage({ offset: 1 });
  const [pageSize, setPageSize] = usePageSize({ defaultValue: 20 });

  const agents = useAgents(filter);
  const [open, setOpen] = useState(false);

  return (
    <Container sx={{ my: 6 }}>
      <Box
        mb={3}
        gap={2}
        display="flex"
        justifyContent="space-between"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "left", sm: "center" }}
      >
        <Typography variant="h4" fontWeight="bold" color="primary">
          User management
        </Typography>
        <Button variant="contained" startIcon={<AddIcon />} onClick={() => setOpen(true)}>
          Add new user
        </Button>
      </Box>
      <Stack gap={2}>
        <UsersTable
          filter={filter}
          setFilter={setFilter}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageSizes={[10, 20, 50]}
          agents={agents.data}
          loading={agents.isPending}
          error={parseRequestError(agents.error)}
        />
      </Stack>
      <EditUserModal open={open} onClose={() => setOpen(false)} />
    </Container>
  );
}

import ReactJsonView, { ReactJsonViewProps } from "react-json-view";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { sentenceCase } from "@packages/utils";

import { useDeveloperTool } from "~/components/admin/DeveloperMenu";

type JSONInspectorIds = "form" | "applicant" | "application" | "application-list";

export interface DeveloperJSONInspectorProps {
  id: JSONInspectorIds;
  enable?: boolean;
  title?: React.ReactNode;
  name?: ReactJsonViewProps["name"];
  collapsed?: ReactJsonViewProps["collapsed"];
  disableClipboard?: boolean;
  data?: object | (() => object);
}

export function DeveloperJSONInspector(props: DeveloperJSONInspectorProps) {
  const {
    id,
    enable = true,
    title = `${sentenceCase(id.replace(/-/g, " "))} inspector`,
    name = id,
    collapsed = 1,
    disableClipboard,
    data,
  } = props;

  const { open = false, setOpen, isDeveloper } = useDeveloperTool({ key: id, enable });

  if (!isDeveloper) return null;

  const onClose = () => setOpen(false);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", mr: 1, mt: 1, right: 0, top: 0 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <ReactJsonView
          name={name}
          style={{ fontSize: "0.9rem", lineHeight: "1rem" }}
          src={typeof data === "function" ? data() : {}}
          displayDataTypes={false}
          collapsed={collapsed}
          enableClipboard={
            disableClipboard
              ? false
              : (copy) => navigator.clipboard.writeText(JSON.stringify(copy.src, null, 2))
          }
        />
      </DialogContent>
    </Dialog>
  );
}

import { Alert, Button } from "@mui/material";

import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { PicklistValue } from "@packages/types";
import { pluralise } from "@packages/utils";

import {
  DocumentUploadModal,
  useUploadDocuments,
} from "~/components/core/DocumentUploadModal";

export interface ApplicationDocumentUploadModalProps {
  open?: boolean;
  title?: React.ReactNode;
  applicationId?: string;
  checklistItemId?: string;
  documentTypes?: PicklistValue[];
  onClose?: () => void;
}

export function ApplicationDocumentUploadModal(
  props: ApplicationDocumentUploadModalProps,
) {
  const {
    open = false,
    title,
    applicationId,
    checklistItemId,
    documentTypes,
    onClose,
  } = props;

  const {
    uploads,
    uploadInfo,
    setUploadInfo,
    addFiles,
    removeUpload,
    uploadDocuments,
    uploading,
    clearUploads,
    clearSuccessfulUploads,
  } = useUploadDocuments(documentTypes?.[0]);

  async function onUploadDocuments() {
    if (!applicationId) return;
    await uploadDocuments({ uploads, applicationId, checklistItemId });
  }

  const { hasUserErrors, pendingUploads, unsuccessfulUploads, successfulUploads } =
    uploadInfo;

  return (
    <DocumentUploadModal
      open={open}
      title={title}
      loading={uploading}
      uploads={uploads}
      documentTypes={documentTypes}
      onExited={clearUploads}
      onDropAll={addFiles}
      onRemoveUpload={removeUpload}
      onSetDocumentType={(uploadId, type) => setUploadInfo(uploadId, { type })}
      content={
        <>
          {hasUserErrors && (
            <Alert severity="error" icon={<CancelIcon />} sx={{ alignItems: "center" }}>
              Please remove files with errors.
            </Alert>
          )}
          {successfulUploads.length > 0 && (
            <Alert
              severity="success"
              icon={<CheckCircleIcon />}
              sx={{ alignItems: "center" }}
              action={
                <Button
                  sx={{ mt: -0.4 }}
                  color="inherit"
                  onClick={clearSuccessfulUploads}
                >
                  Clear uploaded
                </Button>
              }
            >
              Successfully uploaded {pluralise(successfulUploads.length, "file")}.
            </Alert>
          )}
          {!hasUserErrors && !uploading && unsuccessfulUploads.length > 0 && (
            <Alert
              severity="warning"
              icon={<WarningIcon />}
              sx={{ alignItems: "center" }}
              action={
                <Button
                  sx={{ mt: -0.4 }}
                  color="inherit"
                  onClick={onUploadDocuments}
                  disabled={hasUserErrors}
                >
                  Retry upload
                </Button>
              }
            >
              {pluralise(unsuccessfulUploads.length, "file")} failed to upload.
            </Alert>
          )}
        </>
      }
      actions={
        <>
          <Button onClick={onClose} disabled={uploading}>
            {successfulUploads.length > 0 ? "Close" : "Cancel"}
          </Button>
          <Button
            variant="contained"
            onClick={onUploadDocuments}
            disabled={
              !applicationId || uploading || pendingUploads.length === 0 || hasUserErrors
            }
          >
            Upload
          </Button>
        </>
      }
    />
  );
}

import Fade from "@mui/material/Fade";
import LinearProgress from "@mui/material/LinearProgress";

import { useIsResourceLoading } from "./state";

export function AppLoadingBar() {
  const loading = useIsResourceLoading();
  return (
    <Fade in={loading}>
      <LinearProgress sx={{ position: "fixed", top: 0, zIndex: 10000, width: "100%" }} />
    </Fade>
  );
}

import { useQuery } from "@tanstack/react-query";

import { DATA_STALE_TIME } from "~/config/constants";
import { GetApi, NotificationApi } from "~/api";

export function useLatestNotification() {
  return useQuery({
    queryKey: ["notifications", "latest"],
    queryFn: () => GetApi(NotificationApi).getLatestNotification(),
    retry: false,
    staleTime: DATA_STALE_TIME,
  });
}

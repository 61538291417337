import { useCallback, useMemo } from "react";
import { Alert } from "@mui/material";

import { MONASH_PALETTE } from "@packages/utils";

import { IApplicationSearchResult } from "~/api";
import { useApplicant } from "~/hooks/applicant";
import { useApplication } from "~/hooks/application";
import { useLoadResource } from "~/components/core/AppLoadingBar";
import { ApplicationsTable, SearchResultsContainer } from "~/components/dashboard";

export interface ApplicationIdSearchProps {
  applicationId?: string;
}

export function ApplicationIdSearch(props: ApplicationIdSearchProps) {
  const { applicationId } = props;

  const results = useApplication(applicationId);
  const applicantId = results.data?.applicant.applicantId;
  const applicant = useApplicant(applicantId);

  useLoadResource(
    useCallback(
      () => results.isFetching || applicant.isFetching,
      [results.isFetching, applicant.isFetching],
    ),
    ApplicationIdSearch.name,
  );

  const empty = !results.isPending && !results.data;

  const result = useMemo(() => {
    if (!results.data) return null;
    const application = results.data;
    return {
      applicantId: applicantId ?? "",
      applicantFirstName: applicant.data?.firstName ?? "",
      applicantLastName: applicant.data?.lastName ?? "",
      applicantPreferredName: applicant.data?.preferredName ?? "",
      applicationId: application.application.applicationId,
      applicationStatus: application.application.applicationStatus ?? null,
      applicationCoursePreferenceStatus: application.applicationCoursePreferenceStatus,
      campusLocation: application.applicant.campusLocation,
      primaryCoursePreference: application.application.primaryCoursePreference,
      coursePreferences: application.application.coursePreferences,
      lastModifiedDate: application.application.lastModifiedDate,
      owner: application.application.owner,
      studentId: application.applicant.studentId,
      delegatorOrgUnitCode: application.application.delegatorOrgUnitCode,
    } satisfies IApplicationSearchResult;
  }, [results.data, applicantId, applicant.data]);

  return (
    <SearchResultsContainer
      title="Application by ID"
      titleBackground={MONASH_PALETTE.MONASH_BLUE}
    >
      {empty ? (
        <Alert severity="warning" sx={{ m: 3 }}>
          Application <b>{applicationId}</b> not found.
        </Alert>
      ) : (
        <ApplicationsTable
          loading={results.isPending}
          namesLoading={Boolean(applicantId) && applicant.isPending}
          results={result ? [result] : []}
        />
      )}
    </SearchResultsContainer>
  );
}

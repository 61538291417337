import { Tooltip, Paper, Skeleton, Grid, Typography } from "@mui/material";

import { IApplicationDocument } from "~/api";
import { Timestamp } from "~/components/core/Timestamp";

export interface ApplicationDocumentCardProps {
  loading?: boolean;
  document?: IApplicationDocument;
}

export function ApplicationDocumentCard(props: ApplicationDocumentCardProps) {
  const { loading, document } = props;
  return (
    <Paper
      elevation={1}
      component={Grid}
      container
      wrap="nowrap"
      sx={{
        justifyContent: "space-between",
        px: 2,
        py: 1.5,
        borderRadius: 2,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: (theme) => theme.palette.background.default,
        height: "100%",
      }}
    >
      <Grid item xs zeroMinWidth>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ textTransform: "uppercase" }}
          noWrap
        >
          {loading ? <Skeleton /> : <Timestamp>{document?.lastModifiedDate}</Timestamp>}
        </Typography>
        <Tooltip title={document?.filename}>
          <Typography variant="body1" fontWeight="bold" noWrap>
            {loading ? <Skeleton /> : document?.filename}
          </Typography>
        </Tooltip>
        <Typography variant="body2" color="secondary">
          {loading ? <Skeleton /> : document?.type?.label}
        </Typography>
      </Grid>
    </Paper>
  );
}

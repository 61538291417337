/**
 * Transform the search parameters from a URL into key-value pairs.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function urlSearchParamsToObject<T extends Record<any, any>>(url: string) {
  const search = new URL(url).search;
  const params = new URLSearchParams(search);
  const data: Record<string, string> = {};
  for (const [key, value] of params.entries()) {
    data[key] = value;
  }
  return data as T;
}

/**
 * Unwraps the name of a parameter from a URL segment and adds it to the accumulator.
 * Internal helper type.
 * @see https://stackoverflow.com/a/72331909
 */
type ExtractURLSegmentParameterName<
  TSegment extends string,
  TParameterAccumulator extends string[],
> = TSegment extends `:${infer TParameter}`
  ? [TParameter, ...TParameterAccumulator]
  : TParameterAccumulator;

/**
 * Extracts the list of parameters from a URL and adds it to the accumulator.
 * Internal helper type.
 * @see https://stackoverflow.com/a/72331909
 */
type ExtractURLSegmentParameters<
  TUrl extends string,
  TParameterAccumulator extends string[],
> = TUrl extends `${infer TSegmentA}/${infer TSegmentB}`
  ? [
      ...ExtractURLSegmentParameterName<TSegmentA, TParameterAccumulator>,
      ...ExtractURLSegmentParameters<TSegmentB, TParameterAccumulator>,
    ]
  : ExtractURLSegmentParameterName<TUrl, TParameterAccumulator>;

/**
 * Extract the names of the parameters from a URL.
 * A parameter is denoted by a path segment starting with a colon.
 *
 * For example:
 * ```ts
 * type P = UrlParameters<"/api/users/:userId/files/:fileId">
 * // P = "userId" | "fileId"
 * ```
 *
 * @see https://stackoverflow.com/a/72331909
 */
export type URLParameters<TUrl extends string> = ExtractURLSegmentParameters<
  TUrl,
  []
>[number];

/**
 * Extracts the names of the parameters from a URL and returns a map of the parameters.
 *
 * For example:
 * ```ts
 * type P = UrlParameterMap<"/api/users/:userId/files/:fileId">
 * // P = { userId: string; fileId: string; }
 * ```
 *
 * @see https://stackoverflow.com/a/72331909
 */
export type URLParameterMap<TUrl extends string> = {
  [K in URLParameters<TUrl>]: string;
};

/**
 * Given a dynamic URL with parameter placeholders, replace each placeholder with the
 * appropriate value from the parameter map.
 *
 * If a parameter is not present in the given parameter map, it will not be replaced.
 *
 * For example:
 * ```ts
 * const url = applyURLParameters("/api/users/:userId", { userId: "john" })
 * // url = "/api/users/john"
 * ```
 */
export function applyURLParameters<TUrl extends string>(
  url: TUrl,
  parameterMap: URLParameterMap<TUrl>,
): string {
  return url.replace(
    /:([^/]+)/g,
    (segment, match: string) =>
      parameterMap[match as keyof typeof parameterMap] ?? segment,
  );
}

/**
 * Return the singular or plural form of a word based on the given count,
 * with the count prepended by default.
 *
 * ```
 * pluralise(0, "apple") // 0 apples
 * pluralise(1, "apple") // 1 apple
 * pluralise(2, "apple") // 2 apples
 * pluralise(4, "person", { plural: "people" }) // 4 people
 * pluralise(4, "person", { hideCount: true }) // people
 * ```
 *
 * @param count The count.
 * @param word The singular form of the word.
 */
export function pluralise(
  count: number,
  word: string,
  options: {
    /** The plural form of the word. By defaults, it appends an "s" to `word`. */
    plural?: string;
    /** If `true`, the value of `count` will not be included in the output. */
    hideCount?: boolean;
  } = {},
) {
  const { plural = `${word}s`, hideCount = false } = options;
  const output = count === 1 ? word : plural;
  if (hideCount) return output;
  return `${count} ${output}`;
}

/**
 * Capitalise the first word in the phrase.
 */
export function sentenceCase(phrase: string) {
  const first = phrase.at(0);
  const rest = phrase.slice(1);
  return (first?.toUpperCase() ?? "") + rest.toLowerCase();
}

/**
 * Returns a single string with the provided names, joined with a space.
 * Any undefined or empty strings are omitted.
 */
export function joinName(firstName?: string, lastName?: string, preferredName?: string) {
  if (isNonEmptyString(preferredName)) preferredName = `(${preferredName})`;
  const names = (
    isNonEmptyString(firstName)
      ? [firstName, preferredName, lastName]
      : [lastName, preferredName]
  ).filter(isNonEmptyString);
  return names.join(" ");
}

/** Takes a value that may be `string` or `undefined` and checks that the value is a `string`.  */
function isNonEmptyString(value?: string): value is string {
  return Boolean(value);
}

import { useQuery } from "@tanstack/react-query";

import { DATA_STALE_TIME } from "~/config/constants";
import { AgencyApi, GetApi, IAgentStatusFilter } from "~/api";

export function useAgents(filter?: IAgentStatusFilter) {
  return useQuery({
    queryKey: ["agents", { filter }],
    queryFn: () => GetApi(AgencyApi).getAgents({ filter }),
    retry: false,
    staleTime: DATA_STALE_TIME,
  });
}

export function useAgent(email?: string) {
  return useQuery({
    queryKey: ["agent", email],
    queryFn: () => GetApi(AgencyApi).getAgentByEmail(email ?? ""),
    retry: false,
    enabled: Boolean(email),
    staleTime: DATA_STALE_TIME,
  });
}

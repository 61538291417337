import { useWatch } from "react-hook-form";
import { Stack } from "@mui/material";

import { ApplicationCoursePreferenceStatus } from "@packages/types";

import { IAgentInfo, IApplicationPayment } from "~/api";
import { FormSection } from "~/components/form/FormSection";
import { ApplicationFormActions } from "~/components/application/ApplicationFormActions";
import { WatchApplicationFormSection } from "~/components/application/ApplicationFormSidebar";

import { Preamble } from "./Preamble";
import { DelegatedAgencyForm } from "./DelegatedAgencyForm";
import { PersonalDetailsForm } from "./PersonalDetailsForm";
import { MonashStudiesForm } from "./MonashStudiesForm";
import { CitizenshipDetailsForm } from "./CitizenshipDetailsForm";
import { CoursePreferencesForm } from "./CoursePreferencesForm";
import { EnglishLanguageProficiencyForm } from "./EnglishLanguageProficiencyForm";
import { DisabilitiesForm } from "./DisabilitiesForm";
import { AcademicQualificationsForm } from "./AcademicQualificationsForm";
import { WorkExperienceForm } from "./WorkExperienceForm";
import { ScholarshipSponsorshipForm } from "./ScholarshipSponsorshipForm";
import { CreditTransferForm } from "./CreditTransferForm";
import { SupportingDocumentsForm } from "./SupportingDocumentsForm";
import { UseApplicationForm } from "./useApplicationForm";
import { ApplicationFeesForm } from "./ApplicationFeesForm";
import { ApplicationStatusInfo } from "./ApplicationStatusInfo";
import { NotesForm } from "./NotesForm";

import { ApplicationSectionKey } from "./useApplicationForm";

const ApplicationTitle: Record<ApplicationSectionKey, string> = {
  delegatedAgency: "Nominate delegate agency branch",
  personalDetails: "Personal Details",
  monashStudies: "Current/previous Monash studies",
  citizenship: "Citizenship and Other Information",
  coursePreferences: "Course Preferences",
  englishProficiency: "English Language Proficiency",
  disabilities: "Students with Disabilities",
  academicQualifications: "Academic Qualifications",
  workExperience: "Work Experience",
  scholarshipSponsorship: "Scholarship and Sponsorship Details",
  creditTransfer: "Credit Transfer",
  notes: "Notes",
  fees: "Application Fees",
  documents: "Supporting Documents",
};

export type ApplicationSectionInfo = { key: ApplicationSectionKey; title: string };

export const ApplicationSection = Object.entries(ApplicationTitle).reduce(
  (map, entry) => {
    const key = entry[0] as ApplicationSectionKey;
    map[key] = { key, title: entry[1] };
    return map;
  },
  {} as Record<ApplicationSectionKey, ApplicationSectionInfo>,
);

export interface ApplicationFormProps {
  form: UseApplicationForm;
  applicationId: string;
  applicationCoursePreferenceStatus: ApplicationCoursePreferenceStatus | null;
  campusLocation?: string;
  payment?: IApplicationPayment;
  disabled?: boolean;
  onlyValidateIfDirty?: boolean;
  agencyPartners?: IAgentInfo["agencyPartners"];
}

export function ApplicationForm(props: ApplicationFormProps) {
  const {
    applicationId,
    applicationCoursePreferenceStatus,
    campusLocation,
    payment,
    onlyValidateIfDirty,
    agencyPartners = [],
  } = props;
  const {
    formRef,
    form,
    onValidSubmit,
    onInvalidSubmit,
    onSave,
    setToDraft,
    refreshForm,
  } = props.form;
  const { control, setValue, handleSubmit, clearErrors, trigger } = form;

  const [applicationStatus, paymentMode] = useWatch({
    control,
    name: ["applicationStatus", "fees.paymentMethod.code"],
  });

  const disabled = props.disabled || applicationStatus !== "Draft";

  if (!campusLocation) throw new Error("Campus location is empty");

  return (
    <Stack
      component="form"
      ref={formRef}
      gap={3}
      onSubmit={handleSubmit(onValidSubmit, onInvalidSubmit)}
      mb="25vh"
    >
      {applicationStatus === "Draft" && (
        <FormSection>
          <FormSection.Title>
            Application {applicationId} (Monash {campusLocation})
          </FormSection.Title>
          <Preamble campusLocation={campusLocation} />
        </FormSection>
      )}
      {applicationStatus !== "Draft" && (
        <FormSection>
          <FormSection.Title>Application for Monash {campusLocation}</FormSection.Title>
          <ApplicationStatusInfo
            applicationId={applicationId}
            applicationStatus={applicationStatus}
            applicationCoursePreferenceStatus={applicationCoursePreferenceStatus}
            paymentMode={paymentMode}
            setToDraft={setToDraft}
          />
        </FormSection>
      )}
      {agencyPartners.length > 0 && (
        <FormSection id={ApplicationSection.delegatedAgency.key}>
          <FormSection.Title>
            {ApplicationSection.delegatedAgency.title}
          </FormSection.Title>
          <DelegatedAgencyForm
            control={control}
            disabled={disabled}
            agencyPartners={agencyPartners}
          />
          <WatchApplicationFormSection
            section={ApplicationSection.delegatedAgency.key}
            control={control}
            schema={DelegatedAgencyForm.submitSchema}
          />
        </FormSection>
      )}
      <FormSection id={ApplicationSection.personalDetails.key}>
        <FormSection.Title>{ApplicationSection.personalDetails.title}</FormSection.Title>
        <PersonalDetailsForm
          control={control}
          disabled={disabled}
          campusLocation={campusLocation}
        />
        <WatchApplicationFormSection
          section={ApplicationSection.personalDetails.key}
          control={control}
          schema={PersonalDetailsForm.submitSchema}
        />
      </FormSection>
      <FormSection id={ApplicationSection.monashStudies.key}>
        <FormSection.Title>{ApplicationSection.monashStudies.title}</FormSection.Title>
        <MonashStudiesForm control={control} disabled={disabled} />
        <WatchApplicationFormSection
          section={ApplicationSection.monashStudies.key}
          control={control}
          schema={MonashStudiesForm.submitSchema}
        />
      </FormSection>
      <FormSection id={ApplicationSection.citizenship.key}>
        <FormSection.Title>{ApplicationSection.citizenship.title}</FormSection.Title>
        <CitizenshipDetailsForm
          control={control}
          setValue={setValue}
          disabled={disabled}
          campusLocation={campusLocation}
        />
        <WatchApplicationFormSection
          section={ApplicationSection.citizenship.key}
          control={control}
          schema={CitizenshipDetailsForm.submitSchema}
          onlyValidateIfDirty={onlyValidateIfDirty}
        />
      </FormSection>
      <FormSection id={ApplicationSection.coursePreferences.key}>
        <FormSection.Title>
          {ApplicationSection.coursePreferences.title}
        </FormSection.Title>
        <CoursePreferencesForm
          control={control}
          setValue={setValue}
          clearErrors={clearErrors}
          trigger={trigger}
          campusLocation={campusLocation}
          disabled={disabled}
        />
        <WatchApplicationFormSection
          section={ApplicationSection.coursePreferences.key}
          control={control}
          schema={CoursePreferencesForm.submitSchema}
          onlyValidateIfDirty={onlyValidateIfDirty}
        />
      </FormSection>
      <FormSection id={ApplicationSection.englishProficiency.key}>
        <FormSection.Title>
          {ApplicationSection.englishProficiency.title}
        </FormSection.Title>
        <EnglishLanguageProficiencyForm
          control={control}
          setValue={setValue}
          disabled={disabled}
          campusLocation={campusLocation}
        />
        <WatchApplicationFormSection
          section={ApplicationSection.englishProficiency.key}
          control={control}
          schema={EnglishLanguageProficiencyForm.submitSchema}
          onlyValidateIfDirty={onlyValidateIfDirty}
        />
      </FormSection>
      <FormSection id={ApplicationSection.disabilities.key}>
        <FormSection.Title>{ApplicationSection.disabilities.title}</FormSection.Title>
        <DisabilitiesForm
          control={control}
          disabled={disabled}
          campusLocation={campusLocation}
        />
        <WatchApplicationFormSection
          section={ApplicationSection.disabilities.key}
          control={control}
          schema={DisabilitiesForm.submitSchema}
          onlyValidateIfDirty={onlyValidateIfDirty}
        />
      </FormSection>
      <FormSection id={ApplicationSection.academicQualifications.key}>
        <FormSection.Title>
          {ApplicationSection.academicQualifications.title}
        </FormSection.Title>
        <AcademicQualificationsForm
          control={control}
          setValue={setValue}
          trigger={trigger}
          disabled={disabled}
        />
        <WatchApplicationFormSection
          section={ApplicationSection.academicQualifications.key}
          control={control}
          schema={AcademicQualificationsForm.submitSchema}
          onlyValidateIfDirty={onlyValidateIfDirty}
        />
      </FormSection>
      <FormSection id={ApplicationSection.workExperience.key}>
        <FormSection.Title>{ApplicationSection.workExperience.title}</FormSection.Title>
        <WorkExperienceForm
          control={control}
          trigger={trigger}
          setValue={setValue}
          disabled={disabled}
        />
        <WatchApplicationFormSection
          section={ApplicationSection.workExperience.key}
          control={control}
          schema={WorkExperienceForm.submitSchema}
          onlyValidateIfDirty={onlyValidateIfDirty}
        />
      </FormSection>
      <FormSection id={ApplicationSection.scholarshipSponsorship.key}>
        <FormSection.Title>
          {ApplicationSection.scholarshipSponsorship.title}
        </FormSection.Title>
        <ScholarshipSponsorshipForm control={control} disabled={disabled} />
        <WatchApplicationFormSection
          section={ApplicationSection.scholarshipSponsorship.key}
          control={control}
          schema={ScholarshipSponsorshipForm.submitSchema}
          onlyValidateIfDirty={onlyValidateIfDirty}
        />
      </FormSection>
      <FormSection id={ApplicationSection.creditTransfer.key}>
        <FormSection.Title>{ApplicationSection.creditTransfer.title}</FormSection.Title>
        <CreditTransferForm control={control} disabled={disabled} />
        <WatchApplicationFormSection
          section={ApplicationSection.creditTransfer.key}
          control={control}
          schema={CreditTransferForm.submitSchema}
          onlyValidateIfDirty={onlyValidateIfDirty}
        />
      </FormSection>
      <FormSection id={ApplicationSection.notes.key}>
        <FormSection.Title>{ApplicationSection.notes.title}</FormSection.Title>
        <NotesForm control={control} disabled={disabled} />
        <WatchApplicationFormSection
          section={ApplicationSection.notes.key}
          control={control}
          schema={NotesForm.submitSchema}
          onlyValidateIfDirty={onlyValidateIfDirty}
        />
      </FormSection>
      <FormSection id={ApplicationSection.fees.key}>
        <FormSection.Title>{ApplicationSection.fees.title}</FormSection.Title>
        <ApplicationFeesForm
          control={control}
          disabled={disabled}
          setValue={setValue}
          clearErrors={clearErrors}
          onSave={onSave}
          refreshForm={refreshForm}
          applicationId={applicationId}
          campusLocation={campusLocation}
          payment={payment}
        />
        <WatchApplicationFormSection
          section={ApplicationSection.fees.key}
          control={control}
          schema={ApplicationFeesForm.submitSchema}
          onlyValidateIfDirty={onlyValidateIfDirty}
        />
      </FormSection>
      <FormSection id={ApplicationSection.documents.key}>
        <FormSection.Title>{ApplicationSection.documents.title}</FormSection.Title>
        <SupportingDocumentsForm
          applicationId={applicationId}
          control={control}
          onSave={onSave}
          // Allow documents to be uploaded unless the application has been accepted/rejected
          readOnly={props.disabled || !!applicationCoursePreferenceStatus}
        />
      </FormSection>
      <ApplicationFormActions
        gap={1}
        pb={2}
        display={{ xs: "flex", md: "none" }}
        direction="column"
        form={props.form}
      />
    </Stack>
  );
}

import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { isFuture, isPast } from "date-fns";
import * as Sentry from "@sentry/react";

import {
  DateStringSchema,
  EnquiryRecordTypeEnum,
  EnquiryTopicFieldSchema,
  EnquiryTypeEnum,
  SafeStringForProse,
} from "@packages/types";
import { pluralise } from "@packages/utils";

import { createAddIssue } from "~/utils";
import { ApplicationApi, GetApi } from "~/api";
import { useNotification } from "~/components/core/NotificationProvider";
import { useLoadResource } from "~/components/core/AppLoadingBar";
import { useUploadDocuments } from "~/components/core/DocumentUploadModal";

const DateSchema = DateStringSchema("Please provide a valid date.");

export type CreateEnquiryFields = z.infer<typeof CreateEnquirySchema>;
export const CreateEnquirySchema = z
  .object({
    subject: z.string().min(1, "Please select a subject."),
    coursePreference: z.string().min(1, "Please select a course preference."),
    message: SafeStringForProse.schema(),
    _messageLabel: z.string().optional(),
    category1: z.string(),
    category2: z.string(),
    enquiryType: EnquiryTypeEnum.nullable(),
    recordType: EnquiryRecordTypeEnum.nullable(),
    additionalFields: z.array(
      EnquiryTopicFieldSchema.and(z.object({ value: z.string() })),
    ),
  })
  .superRefine(({ message, _messageLabel, additionalFields }, ctx) => {
    const addIssue = createAddIssue(ctx);

    // We validate the message here so that we can customise the error message if required
    if (!message)
      // IMPORTANT: messageLabel should not be imperative, otherwise this message will not make sense
      addIssue(
        _messageLabel ? `${_messageLabel} is required.` : "Please enter a message.",
        "message",
      );

    // Iterate through all the dynamic fields to validate
    for (let index = 0; index < additionalFields.length; index++) {
      const field = additionalFields[index];
      const addFieldIssue = (message: string) =>
        addIssue(message, `additionalFields.${index}.value`);

      if (!field.optional && !field.value.length) {
        addFieldIssue(`${field.label} is required.`);
        continue;
      }

      // Validate text fields
      if (field.type === "text") {
        if (!SafeStringForProse.isValid(field.value))
          addFieldIssue(SafeStringForProse.defaultMessage);
      }

      // Validate date fields
      else if (field.type === "date") {
        const dateString = DateSchema.safeParse(field.value);
        if (!dateString.success)
          addFieldIssue(dateString.error.issues.map((issue) => issue.message).join(", "));
        else {
          const date = new Date(dateString.data);
          if (field.rejectFutureDates && isFuture(date))
            addFieldIssue(`${field.label} must be in the past.`);
          if (field.rejectPastDates && isPast(date))
            addFieldIssue(`${field.label} must be in the future.`);
        }
      }
    }
  });

export function useCreateEnquiryForm(applicationId: string, applicantId?: string) {
  const { showErrorAlert, showNotificationAlert, showNotification } = useNotification();
  const client = useQueryClient();

  const [loading, setLoading] = useState(false);

  const form = useForm<CreateEnquiryFields>({
    resolver: zodResolver(CreateEnquirySchema),
    defaultValues: {
      subject: "",
      coursePreference: "",
      message: "",
      category1: "",
      category2: "",
      enquiryType: null,
    },
  });

  const documentUpload = useUploadDocuments();
  const { uploads, uploadDocuments } = documentUpload;

  const createEnquiry = async (data: CreateEnquiryFields) => {
    setLoading(true);

    const {
      subject,
      coursePreference,
      message,
      _messageLabel,
      category1,
      category2,
      enquiryType,
      recordType,
      additionalFields,
    } = data;

    const description: string[] = [];
    additionalFields.forEach((field) => {
      if (field.value) description.push([field.label, field.value].join(": "));
    });

    if (_messageLabel) description.push([_messageLabel, message].join(": "));
    else description.push(message);

    try {
      if (uploads.length > 0) {
        showNotificationAlert({
          type: "loading",
          title: "We're creating your enquiry",
          message: "Uploading attachments...",
        });

        const { unsuccessful } = await uploadDocuments({
          uploads,
          applicationId,
        });

        if (unsuccessful.length > 0) {
          const count = pluralise(unsuccessful.length, "attachment");
          showNotificationAlert({
            type: "warning",
            title: "Attachments failed to upload",
            maxWidth: "sm",
            message: `${count} failed to upload. Please retry the upload or remove failed uploads to create your enquiry.`,
          });
          return { success: false };
        }

        // Add uploads to the enquiry description
        // Only successful uploads will be left in the upload list at this point
        description.push(
          [`[Attachments: ${uploads.length}]`, uploads.map((upload) => upload.file.name)]
            .flat()
            .join("\n"),
        );
      }

      const api = GetApi(ApplicationApi);

      showNotificationAlert({
        type: "loading",
        title: "We're creating your enquiry",
        message: "Creating new enquiry...",
      });

      const { enquiryNumber } = await api.createApplicationEnquiry(applicationId, {
        enquiryType,
        recordType,
        category: {
          level1: category1,
          level2: category2,
        },
        acpId: coursePreference,
        subject,
        description: description.join("\n\n"),
      });

      showNotification({
        type: "success",
        message: `Created enquiry ${enquiryNumber}`,
      });

      // Refetch enquiries if applicantId passed
      if (applicantId)
        client.refetchQueries({ queryKey: ["applicants", applicantId, "enquiries"] });

      return { success: true };
    } catch (error) {
      // send error to Sentry
      Sentry.captureException(error, {
        tags: { source: "useCreateEnquiryForm.createEnquiry" },
      });

      showErrorAlert(error);
      return { success: false };
    } finally {
      setLoading(false);
    }
  };

  useLoadResource(
    useCallback(() => loading, [loading]),
    useCreateEnquiryForm.name,
  );

  return { form, loading, createEnquiry, documentUpload };
}

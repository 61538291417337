import { useQueryClient } from "@tanstack/react-query";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { AuthApi, IAgentInfo, OAuth2Api, GetApi } from "~/api";
import { dateStringAtom } from "~/utils";

const loadingAtom = atom(true);
const userAtom = atom<IAgentInfo | null>(null);

// Store expiry dates in local storage to share it across tabs
export const idleExpiryAtom = dateStringAtom(atomWithStorage<string>("idle", ""));
export const sessionExpiryAtom = dateStringAtom(atomWithStorage<string>("session", ""));

export function useAuthState() {
  const loading = useAtomValue(loadingAtom);
  const user = useAtomValue(userAtom);
  return [user, loading] as const;
}

export function useGetCredentials() {
  const setUser = useSetAtom(userAtom);
  const setExpiry = useSetAtom(sessionExpiryAtom);
  const setLoading = useSetAtom(loadingAtom);
  return useCallback(async () => {
    try {
      const user = await GetApi(AuthApi).getUser();
      setUser(user);
      setExpiry(new Date(user.expiry));
      return true;
    } catch (error) {
      return false;
    } finally {
      setLoading(false);
    }
  }, [setUser, setLoading]);
}

export function useSignIn() {
  return useCallback((from?: string) => {
    window.location.href = `/api/auth/oauth2/authenticate?from=${from ?? "/"}`;
  }, []);
}

export function useSignOut() {
  const setUser = useSetAtom(userAtom);
  const setIdleExpiry = useSetAtom(idleExpiryAtom);
  const setSessionExpiry = useSetAtom(sessionExpiryAtom);
  const navigate = useNavigate();
  const client = useQueryClient();

  return useCallback(
    async (reason?: string) => {
      console.debug("Signing out", { reason });
      try {
        await GetApi(AuthApi).signOut();
      } catch (error) {
        console.warn(error);
      } finally {
        client.clear();
        setUser(null);
        setIdleExpiry(null);
        setSessionExpiry(null);
        navigate("/signin", { state: { reason } });
      }
    },
    [setUser],
  );
}

export function useRefreshSession() {
  const getCredentials = useGetCredentials();
  return useCallback(async () => {
    await GetApi(OAuth2Api).refreshSession();
    await getCredentials();
    console.debug("Refreshed user session at", new Date().toLocaleString());
  }, []);
}

export function AuthProvider() {
  const getCredentials = useGetCredentials();

  useEffect(() => {
    getCredentials();
  }, [getCredentials]);

  return null;
}

import { useWatch } from "react-hook-form";
import { Button, Grid } from "@mui/material";

import { CourseType } from "@packages/types";

import { useFindResidencyStatusCountry } from "~/hooks/reference-data";
import { useTableStateHelpers } from "~/hooks/table-helpers";
import {
  CoursePreferencesFormProps,
  EmptyCoursePreference,
} from "~/components/application/ApplicationForm/CoursePreferencesForm";
import { CoursePreferencesTable } from "~/components/application/CoursePreferencesTable";

import { createCourseFields } from "./_utils";
import { CourseSelectionForm } from "./CourseSelectionForm";

export function DirectApplicationCourseSelectionForm(props: CoursePreferencesFormProps) {
  const { control, disabled, setValue } = props;

  const [residencyStatus, residencySubStatus, preferenceList] = useWatch({
    control,
    name: [
      "citizenship.residencyStatus",
      "citizenship.residencySubStatus",
      "coursePreferences.preferenceList",
    ],
  });

  const campusCountry = useFindResidencyStatusCountry(residencySubStatus);

  const {
    addNew,
    edit,
    confirm,
    cancel,
    fieldArray: { fields, remove },
    record,
  } = useTableStateHelpers(
    "coursePreferences.preferenceList",
    props,
    EmptyCoursePreference,
  );

  return (
    <>
      <CoursePreferencesTable
        control={control}
        fields={fields}
        remove={remove}
        onEdit={edit}
        isEditing={record.isEditing}
        editIndex={record.index}
        oldData={record.previous}
        disabled={disabled}
      />
      {!disabled && record.isEditing && (
        <>
          <CourseSelectionForm
            title={`Course preference ${record.index + 1}`}
            control={control}
            setValue={setValue}
            fields={createCourseFields(record.index)}
            coursePreference={preferenceList[record.index]}
            residencyStatus={residencyStatus}
            campusCountry={campusCountry}
            courseType={CourseType.Direct}
            disabled={disabled}
          />
          <Grid item xs={12} container gap={2}>
            <Button variant="contained" onClick={confirm}>
              Confirm
            </Button>
            <Button variant="contained" color="error" onClick={cancel}>
              Cancel
            </Button>
          </Grid>
        </>
      )}
      {!disabled && !record.isEditing && fields.length < 3 && (
        <Grid item xs={12}>
          <Button variant="contained" onClick={addNew}>
            Add course preference {fields.length + 1}
          </Button>
        </Grid>
      )}
    </>
  );
}

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from "@mui/material";
import { useEffect } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useBlocker } from "react-router-dom";

import { doesObjectHaveTrueValues } from "~/utils";

const CONFIRM_MESSAGE =
  "This form has been modified since it was last saved. If you navigate away now, you may lose some changes.";

function onBeforeUnload(event: BeforeUnloadEvent) {
  // Prevent the navigation to ask user for confirmation
  event.preventDefault();
  // Some browsers require the below to activate (note that the message isn't actually displayed)
  // See https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#browser_compatibility
  // (Browsers no longer support a custom message - https://stackoverflow.com/a/38880926)
  event.returnValue = CONFIRM_MESSAGE;
  return CONFIRM_MESSAGE;
}

export interface FormDirtyModalProps<TFieldValues extends FieldValues = FieldValues> {
  form: UseFormReturn<TFieldValues>;
}

export function FormDirtyModal<TFieldValues extends FieldValues = FieldValues>(
  props: FormDirtyModalProps<TFieldValues>,
) {
  const { form } = props;

  const isDirty = doesObjectHaveTrueValues(form.formState.dirtyFields);
  const blocker = useBlocker(isDirty);

  // This event listener will capture any navigation events not managed by React Router
  useEffect(() => {
    if (!isDirty) return;
    // If the blocker condition is true, set up a listener to capture the document unload event
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    addEventListener("beforeunload", onBeforeUnload);
    return () => removeEventListener("beforeunload", onBeforeUnload);
  }, [isDirty]);

  return (
    <Dialog
      open={blocker.state === "blocked"}
      maxWidth="xs"
      fullWidth
      disableRestoreFocus
    >
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>{CONFIRM_MESSAGE}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => blocker.reset?.()}>Cancel</Button>
        <Button color="error" onClick={() => blocker.proceed?.()}>
          Proceed without saving
        </Button>
      </DialogActions>
    </Dialog>
  );
}

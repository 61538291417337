import { Divider, ListSubheader, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

import KeyIcon from "@mui/icons-material/Key";
import PasswordIcon from "@mui/icons-material/Password";
import TimerIcon from "@mui/icons-material/Timer";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import DownloadIcon from "@mui/icons-material/Download";
import FillFormIcon from "@mui/icons-material/EditNote";
import DynamicFeed from "@mui/icons-material/DynamicFeed";

import { useCallbackFactory } from "~/hooks/state";

import { useDeveloperTool } from "./useDeveloperTool";

interface DeveloperMenuProps {
  onClick?: () => void;
}

export function DeveloperMenu({ onClick }: DeveloperMenuProps) {
  const idleTimerInspector = useDeveloperTool("idle");
  const queryInspector = useDeveloperTool("query");
  const applicantInspector = useDeveloperTool("applicant");
  const applicationListInspector = useDeveloperTool("application-list");
  const applicationInspector = useDeveloperTool("application");
  const formInspector = useDeveloperTool("form");
  const applicationReceiptDownload = useDeveloperTool("application-receipt-download");
  const applicationReceiptEnqueue = useDeveloperTool("application-receipt-enqueue");
  const prefillNewApplication = useDeveloperTool("prefill-new-application");
  const prefillEditApplication = useDeveloperTool("prefill-edit-application");

  const onMenuClick = useCallbackFactory(onClick);

  const developerTools: React.ReactNode[] = [];

  if (idleTimerInspector.enabled)
    developerTools.push(
      <MenuItem
        key="idleTimerInspector"
        onClick={onMenuClick(idleTimerInspector.setOpen, !idleTimerInspector.open)}
      >
        {idleTimerInspector.open ? (
          <TimerOffIcon sx={{ mr: 1 }} color="action" />
        ) : (
          <TimerIcon sx={{ mr: 1 }} color="action" />
        )}
        {idleTimerInspector.open ? "Hide idle timer" : "Show idle timer"}
      </MenuItem>,
    );

  if (queryInspector.enabled)
    developerTools.push(
      <MenuItem key="formInspector" onClick={onMenuClick(queryInspector.setOpen, true)}>
        <QueryStatsIcon sx={{ mr: 1 }} color="action" />
        Query inspector
      </MenuItem>,
    );

  if (prefillNewApplication.enabled)
    developerTools.push(
      <MenuItem key="queryInspector" onClick={onMenuClick(prefillNewApplication.action)}>
        <FillFormIcon sx={{ mr: 1 }} color="action" />
        Fill in form
      </MenuItem>,
    );

  if (prefillEditApplication.enabled)
    developerTools.push(
      <MenuItem
        key="prefillNewApplication"
        onClick={onMenuClick(prefillEditApplication.action)}
      >
        <FillFormIcon sx={{ mr: 1 }} color="action" />
        Fill in application
      </MenuItem>,
    );

  if (applicantInspector.enabled)
    developerTools.push(
      <MenuItem
        key="prefillEditApplication"
        onClick={onMenuClick(applicantInspector.setOpen, true)}
      >
        <ManageSearchIcon sx={{ mr: 1 }} color="action" />
        Inspect applicant
      </MenuItem>,
    );

  if (applicationListInspector.enabled)
    developerTools.push(
      <MenuItem
        key="applicantInspector"
        onClick={onMenuClick(applicationListInspector.setOpen, true)}
      >
        <ManageSearchIcon sx={{ mr: 1 }} color="action" />
        Inspect application list
      </MenuItem>,
    );

  if (applicationInspector.enabled)
    developerTools.push(
      <MenuItem
        key="applicationListInspector"
        onClick={onMenuClick(applicationInspector.setOpen, true)}
      >
        <ManageSearchIcon sx={{ mr: 1 }} color="action" />
        Inspect application
      </MenuItem>,
    );

  if (formInspector.enabled)
    developerTools.push(
      <MenuItem
        key="applicationInspector"
        onClick={onMenuClick(formInspector.setOpen, true)}
      >
        <ManageSearchIcon sx={{ mr: 1 }} color="action" />
        Inspect form
      </MenuItem>,
    );

  if (applicationReceiptDownload.enabled)
    developerTools.push(
      <MenuItem
        key="applicationReceiptDownload"
        onClick={onMenuClick(applicationReceiptDownload.action)}
      >
        <DownloadIcon sx={{ mr: 1 }} color="action" />
        Download application receipt
      </MenuItem>,
    );

  if (applicationReceiptEnqueue.enabled)
    developerTools.push(
      <MenuItem
        key="applicationReceiptEnqueue"
        onClick={onMenuClick(applicationReceiptEnqueue.action)}
      >
        <DynamicFeed sx={{ mr: 1 }} color="action" />
        Enqueue receipt generation
      </MenuItem>,
    );

  return (
    <>
      <Divider />
      <ListSubheader sx={{ lineHeight: 3 }}>Token tools</ListSubheader>
      <MenuItem component={Link} to="/developer/token-generator" onClick={onClick}>
        <KeyIcon sx={{ mr: 1 }} color="action" />
        Token generator
      </MenuItem>
      <MenuItem component={Link} to="/developer/credential-generator" onClick={onClick}>
        <PasswordIcon sx={{ mr: 1 }} color="action" />
        API credential generator
      </MenuItem>
      {developerTools.length > 0 && (
        <>
          <Divider />
          <ListSubheader sx={{ lineHeight: 3 }}>Developer tools</ListSubheader>
          {developerTools}
        </>
      )}
    </>
  );
}

import { useMemo } from "react";
import { useAtomValue } from "jotai";
import {
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  SvgIconProps,
} from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";

import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

import { ApplicationSectionInfo } from "~/components/application/ApplicationForm";

import { ApplicationSectionState, sidebarAtom } from "./state";

function useSidebarSectionInfo(section: ApplicationSectionState) {
  const { key, valid, touched } = useAtomValue(sidebarAtom({ key: section.key }));
  return useMemo<{
    StatusIcon: SvgIconComponent;
    statusColor?: SvgIconProps["color"];
    color?: string;
  }>(() => {
    // We don't actually validate the documents section at this stage of the application.
    // So display a custom icon in a neutral colour to visually differentiate this section.
    if (key === "documents")
      return {
        StatusIcon: AssignmentIndIcon,
        statusColor: "secondary",
      };

    if (valid)
      return {
        StatusIcon: CheckCircleIcon,
        statusColor: "success",
      };

    if (touched && !valid)
      return {
        StatusIcon: CancelIcon,
        statusColor: "error",
      };

    return {
      StatusIcon: CircleIcon,
      color: "lightgray",
    };
  }, [key, valid, touched]);
}

export interface ApplicationFormSidebarItemProps {
  section: ApplicationSectionInfo;
}

export function ApplicationFormSidebarItem(props: ApplicationFormSidebarItemProps) {
  const { section } = props;
  const { StatusIcon, statusColor, color } = useSidebarSectionInfo(section);
  return (
    <ListItem key={section.key} disableGutters disablePadding>
      <ListItemButton
        sx={{ py: 0.25, borderRadius: 1 }}
        onClick={() =>
          document.getElementById(section.key)?.scrollIntoView({ behavior: "smooth" })
        }
      >
        <ListItemAvatar>
          <StatusIcon
            sx={{
              mt: 1,
              mx: -1,
              bgcolor: "white",
              borderRadius: "100%",
              color,
            }}
            fontSize="large"
            color={statusColor}
          />
        </ListItemAvatar>
        <ListItemText sx={{ mx: -1 }} primary={section.title} />
      </ListItemButton>
    </ListItem>
  );
}

import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import {
  ApplicantApi,
  ApplicationApi,
  GetApi,
  IApplicantNamesAndStudentIds,
  IApplicationStatusType,
} from "~/api";
import { REALTIME_DATA_STALE_TIME } from "~/config/constants";

export function useApplicantSearchResults(options: {
  name?: string;
  page?: number;
  enabled?: boolean;
}) {
  const { name, page, enabled } = options;
  return useQuery({
    queryKey: ["applicants", { name, page }],
    queryFn: () =>
      GetApi(ApplicantApi).searchApplicants({
        name: name ?? "",
        page: page ?? 1,
        pageSize: 15,
      }),
    enabled,
    staleTime: REALTIME_DATA_STALE_TIME,
    placeholderData: keepPreviousData,
    retry: false,
  });
}

export function useApplicationSearchResults(options: {
  statusType?: IApplicationStatusType;
  page?: number;
  enabled?: boolean;
  getApplicantNamesAndIds?: boolean;
}) {
  const { statusType, page, enabled, getApplicantNamesAndIds } = options;

  // Get applications
  const applications = useQuery({
    queryKey: ["applications", { statusType, page }],
    queryFn: () =>
      GetApi(ApplicationApi).getApplications({
        applicationStatusType: statusType,
        page: page ?? 1,
        pageSize: 10,
      }),
    enabled,
    staleTime: REALTIME_DATA_STALE_TIME,
    retry: false,
  });

  // Get applicants from applications
  const applicantIds = useMemo(
    () =>
      applications.data
        ? applications.data.results.map((x) => x.applicantId).filter((x) => x)
        : [],
    [applications.data],
  );

  // Get applicant names and student IDs (smsContactId)
  const applicantNamesAndIds = useQuery({
    queryKey: ["applicantNamesAndIds", { applicantIds }],
    queryFn: () =>
      applicantIds.length > 0
        ? GetApi(ApplicantApi).getApplicantNamesAndIDs({ applicantIds })
        : ({} as IApplicantNamesAndStudentIds),
    enabled: getApplicantNamesAndIds && Boolean(applications.data),
    staleTime: REALTIME_DATA_STALE_TIME,
    retry: false,
  });

  // Augment results with applicant names and student IDs (smsContactId)
  const data = useMemo(() => {
    if (applications.data && applicantNamesAndIds.data) {
      applications.data?.results.forEach((applicant) => {
        const person = applicantNamesAndIds.data[applicant.applicantId];
        if (!person) return;
        applicant.applicantFirstName = person.firstName ?? "";
        applicant.applicantLastName = person.lastName ?? "";
        applicant.applicantPreferredName = person.preferredName ?? "";
        applicant.studentId = person.smsContactId ?? "";
      });
    }
    return applications;
  }, [applications, applicantNamesAndIds, statusType, page, enabled]);

  return { ...data, applicantNamesAndIds };
}

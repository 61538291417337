export const MAX_UPLOAD_FILENAME_LENGTH = 100;
export const MAX_UPLOAD_FILE_SIZE = 5 * 1000 * 1000; // 5MB

// the type indicated below is equivalent to the "Accept" interface from react-dropzone
export const ACCEPTED_FILE_TYPES: { [key: string]: string[] } = {
  "image/png": [".png"],
  "image/jpeg": [".jpeg", ".jpg"],
  "image/gif": [".gif"],
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "application/rtf": [".rtf"],
  "text/plain": [".txt"],
};

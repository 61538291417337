import { z } from "zod";
import { useMemo } from "react";
import { Alert, Grid, MenuItem, Stack, Typography } from "@mui/material";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";

import {
  CodeStringNullableSchema,
  CountryISOSchema,
  DateStringSchema,
  parseDateString,
  PicklistValueSchema,
  SafeString,
} from "@packages/types";

import { createAddIssue } from "~/utils";
import { useReferenceData, useSelectedResidencyStatuses } from "~/hooks/reference-data";
import { FormSelect } from "~/components/form/FormSelect";
import { FormTextField } from "~/components/form/FormTextField";
import { FormRadioGroup } from "~/components/form/FormRadioGroup";
import { FormAutocomplete } from "~/components/form/FormAutocomplete";
import { FormCountryISOSelect } from "~/components/form/FormCountrySelect";

import { ApplicationFields } from "./useApplicationForm";
import { getCitizenshipFields } from "./useApplicationForm.utils";

export interface CitizenshipDetailsFormProps {
  control: Control<ApplicationFields>;
  setValue: UseFormSetValue<ApplicationFields>;
  disabled?: boolean;
  campusLocation: string;
}

export function CitizenshipDetailsForm(props: CitizenshipDetailsFormProps) {
  const { control, setValue, disabled, campusLocation } = props;

  const [residencyStatus, residencySubStatus, visaType, ...documentationFlags] = useWatch(
    {
      control,
      name: [
        "citizenship.residencyStatus",
        "citizenship.residencySubStatus",
        "citizenship.visaType",
        "citizenship.hasRefusedEntry",
        "citizenship.hasBreachedVisa",
        "citizenship.hasMedicalIssues",
        "citizenship.hasProtectionVisa",
        "citizenship.hasConvictedOfCrime",
      ],
    },
  );

  const residencyStatuses = useSelectedResidencyStatuses(campusLocation);
  const visaTypes = useReferenceData("VisaTypes");
  const isStudentPassAvailableOptions = useReferenceData("StudentPassAvailability");
  const hasRefusedEntryOptions = useReferenceData("RefusedEntryVisaToAnyCountry");
  const hasBreachedVisaOptions = useReferenceData("BreachedVisaCoundition");
  const hasMedicalIssuesOptions = useReferenceData("MedicalHealthPreventVisa");
  const hasProtectionVisaOptions = useReferenceData("ProtectionVisaInAnyCountry");
  const hasConvictedOfCrimeOptions = useReferenceData("ConvictedOfCrimeOffence");

  const fields = useMemo(
    () => getCitizenshipFields({ campusLocation, residencyStatus, residencySubStatus }),
    [campusLocation, residencyStatus, residencySubStatus],
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormSelect
            control={control}
            name="citizenship.residencySubStatus"
            label="Citizenship Status"
            loading={residencyStatuses.isPending}
            disabled={!campusLocation || disabled}
            onBeforeChange={(e) => {
              setValue(
                "citizenship.residencyStatus",
                (e.target.value as string).split(".")[0],
              );
            }}
          >
            {Object.values(residencyStatuses?.statuses ?? {})
              .filter((x) => !x.blocked)
              .map(({ label, value }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
          </FormSelect>
        </Grid>
        {fields.has("countryOfCitizenship") && (
          <Grid item xs={12}>
            <FormCountryISOSelect
              control={control}
              name="citizenship.countryOfCitizenship"
              label="Country of citizenship (as in travel document)"
              disabled={disabled}
              fullWidth
            />
          </Grid>
        )}
        {fields.has("singleEntryVisa") && (
          <Grid item xs={12}>
            <FormCountryISOSelect
              control={control}
              name="citizenship.singleEntryVisaCountry"
              label="Country where the applicant obtained their single entry visa"
              disabled={disabled}
              fullWidth
            />
          </Grid>
        )}
        {fields.has("visaType") && (
          <Grid item xs={12}>
            <FormAutocomplete
              control={control}
              name="citizenship.visaType"
              label="Visa type"
              loading={visaTypes.isPending}
              disabled={disabled}
              options={visaTypes?.data ?? []}
              getOptionLabel={(x) =>
                x?.label ||
                (visaTypes?.data?.find((x) => x.value === visaType?.value)?.label ?? "")
              }
              isOptionEqualToValue={(option, value) => option?.value === value?.value}
              autoHighlight
            />
          </Grid>
        )}
        {fields.has("immigrationOfficeIssued") && (
          <Grid item xs={12}>
            <FormTextField
              control={control}
              name="citizenship.immigrationOfficeIssued"
              label="Which Australian Immigration Office issued the applicant's visa?"
              disabled={disabled}
              fullWidth
            />
          </Grid>
        )}
        {fields.has("visaNumber") && (
          <Grid item xs={12} sm={6}>
            <FormTextField
              control={control}
              name="citizenship.visaNumber"
              label="Visa number"
              disabled={disabled}
              fullWidth
            />
          </Grid>
        )}
        {fields.has("passportNumber") && (
          <Grid item xs={12} sm={6}>
            <FormTextField
              control={control}
              name="citizenship.passportNumber"
              label="Passport number"
              disabled={disabled}
              fullWidth
            />
          </Grid>
        )}
        {fields.has("visaStartDate") && (
          <Grid item xs={12} sm={6}>
            <FormTextField
              control={control}
              name="citizenship.visaStartDate"
              label="Visa start date"
              disabled={disabled}
              type="date"
              fullWidth
            />
          </Grid>
        )}
        {fields.has("visaEndDate") && (
          <Grid item xs={12} sm={6}>
            <FormTextField
              control={control}
              name="citizenship.visaEndDate"
              label="Visa expiry date"
              disabled={disabled}
              type="date"
              fullWidth
            />
          </Grid>
        )}
        {fields.has("isStudentPassAvailable") && (
          <Grid item xs={12}>
            <FormRadioGroup
              control={control}
              name="citizenship.isStudentPassAvailable.code"
              label="Has the applicant obtained a student pass in Malaysia before?"
              disabled={disabled}
              loading={isStudentPassAvailableOptions.isPending}
              options={isStudentPassAvailableOptions.data}
            />
          </Grid>
        )}
        {fields.has("yesNoQuestions") && (
          <>
            <Grid item xs={12}>
              <FormRadioGroup
                control={control}
                name="citizenship.hasRefusedEntry.code"
                label="Has the applicant been refused an entry visa to any country?"
                disabled={disabled}
                loading={hasRefusedEntryOptions.isPending}
                options={hasRefusedEntryOptions.data}
              />
            </Grid>
            <Grid item xs={12}>
              <FormRadioGroup
                control={control}
                name="citizenship.hasBreachedVisa.code"
                label="Has the applicant breached any visa conditions in any country?"
                disabled={disabled}
                loading={hasBreachedVisaOptions.isPending}
                options={hasBreachedVisaOptions.data}
              />
            </Grid>
            <Grid item xs={12}>
              <FormRadioGroup
                control={control}
                name="citizenship.hasMedicalIssues.code"
                label="Does the applicant have any medical or health issues that may prevent them from obtaining their visa?"
                disabled={disabled}
                loading={hasMedicalIssuesOptions.isPending}
                options={hasMedicalIssuesOptions.data}
              />
            </Grid>
            <Grid item xs={12}>
              <FormRadioGroup
                control={control}
                name="citizenship.hasProtectionVisa.code"
                label="Has the applicant been issued a protection visa in any country to date?"
                disabled={disabled}
                loading={hasProtectionVisaOptions.isPending}
                options={hasProtectionVisaOptions.data}
              />
            </Grid>
            <Grid item xs={12}>
              <FormRadioGroup
                control={control}
                name="citizenship.hasConvictedOfCrime.code"
                label="Has the applicant been convicted of any crime or offence in any country?"
                disabled={disabled}
                loading={hasConvictedOfCrimeOptions.isPending}
                options={hasConvictedOfCrimeOptions.data}
              />
            </Grid>
          </>
        )}
        {(fields.has("supportingDocumentation") ||
          documentationFlags.some((x) => x?.code === "Yes")) && (
          <Grid item xs={12}>
            <Stack gap={2}>
              <Typography variant="h6">Supporting documentation</Typography>
              <Alert severity="info">
                Please attach the required evidence at the bottom of this form.
              </Alert>
            </Stack>
          </Grid>
        )}
      </Grid>
    </>
  );
}

CitizenshipDetailsForm.draftSchema = z
  .object({
    _campusLocation: z.string(),
    residencyStatus: z.string(),
    residencySubStatus: z.string(),
    countryOfCitizenship: CountryISOSchema.nullable(),
    singleEntryVisaCountry: CountryISOSchema.nullable(),
    visaType: PicklistValueSchema.nullable(),
    immigrationOfficeIssued: SafeString.schema(),
    visaNumber: SafeString.schema(),
    passportNumber: SafeString.schema(),
    visaStartDate: DateStringSchema.allowBlank(),
    visaEndDate: DateStringSchema.allowBlank(),
    isStudentPassAvailable: CodeStringNullableSchema,
    hasRefusedEntry: CodeStringNullableSchema,
    hasBreachedVisa: CodeStringNullableSchema,
    hasMedicalIssues: CodeStringNullableSchema,
    hasProtectionVisa: CodeStringNullableSchema,
    hasConvictedOfCrime: CodeStringNullableSchema,
  })
  .superRefine(({ visaStartDate, visaEndDate }, ctx) => {
    const addIssue = createAddIssue(ctx);

    if (visaStartDate) {
      const startDate = parseDateString(visaStartDate);
      if (startDate.getTime() > Date.now())
        addIssue("Visa start date cannot be in the future", "visaStartDate");

      if (visaEndDate) {
        const endDate = parseDateString(visaEndDate);
        if (endDate.getTime() < startDate.getTime())
          addIssue("Visa end date cannot be before visa start date", "visaEndDate");
      }
    }
  });

CitizenshipDetailsForm.submitSchema = CitizenshipDetailsForm.draftSchema.superRefine(
  (
    {
      _campusLocation,
      residencyStatus,
      residencySubStatus,
      countryOfCitizenship,
      singleEntryVisaCountry,
      visaType,
      immigrationOfficeIssued,
      visaNumber,
      passportNumber,
      visaStartDate,
      visaEndDate,
      isStudentPassAvailable,
      hasRefusedEntry,
      hasBreachedVisa,
      hasMedicalIssues,
      hasProtectionVisa,
      hasConvictedOfCrime,
    },
    ctx,
  ) => {
    const addIssue = createAddIssue(ctx);

    if (!residencyStatus) addIssue("Please select a residency status", "residencyStatus");

    const fields = getCitizenshipFields({
      residencyStatus,
      campusLocation: _campusLocation,
      residencySubStatus,
    });

    if (fields.has("countryOfCitizenship") && !countryOfCitizenship)
      addIssue("Please select a country of citizenship", "countryOfCitizenship");

    if (fields.has("singleEntryVisa") && !singleEntryVisaCountry)
      addIssue("Please select a country", "singleEntryVisaCountry");

    if (fields.has("visaType") && !visaType)
      addIssue("Please select a visa type", "visaType");

    if (fields.has("immigrationOfficeIssued") && !immigrationOfficeIssued)
      addIssue("Please specify an immigration office", "immigrationOfficeIssued");

    if (fields.has("visaNumber") && !visaNumber)
      addIssue("Please provide a visa number", "visaNumber");

    if (fields.has("passportNumber") && !passportNumber)
      addIssue("Please provide a passport number", "passportNumber");

    if (fields.has("visaStartDate") && !visaStartDate)
      addIssue("Please provide a valid date", "visaStartDate");

    if (fields.has("visaEndDate") && !visaEndDate)
      addIssue("Please provide a valid date", "visaEndDate");

    if (fields.has("isStudentPassAvailable") && !isStudentPassAvailable?.code)
      addIssue("Please select an answer", "isStudentPassAvailable.code");

    if (fields.has("yesNoQuestions")) {
      if (!hasRefusedEntry?.code)
        addIssue("Please select an answer", "hasRefusedEntry.code");
      if (!hasBreachedVisa?.code)
        addIssue("Please select an answer", "hasBreachedVisa.code");
      if (!hasMedicalIssues?.code)
        addIssue("Please select an answer", "hasMedicalIssues.code");
      if (!hasProtectionVisa?.code)
        addIssue("Please select an answer", "hasProtectionVisa.code");
      if (!hasConvictedOfCrime?.code)
        addIssue("Please select an answer", "hasConvictedOfCrime.code");
    }
  },
);

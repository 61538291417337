import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogProps,
  IconButton,
  Typography,
  TypographyProps,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";

export interface NotificationDialogProps {
  open?: boolean;
  message?: React.ReactNode;
  disableTypography?: boolean;
  variant?: TypographyProps["variant"];
  maxWidth?: DialogProps["maxWidth"];
  icon?: "loading" | "success" | "error" | "warning" | "none";
  closable?: boolean;
  onClose?: () => void;
}

export function NotificationDialog(props: NotificationDialogProps) {
  const {
    open = false,
    message,
    disableTypography,
    variant,
    maxWidth = "xs",
    icon,
    closable,
    onClose,
  } = props;

  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth>
      {closable && onClose && (
        <IconButton
          onClick={onClose}
          sx={(theme) => ({
            position: "absolute",
            top: theme.spacing(2),
            right: theme.spacing(2),
          })}
        >
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent>
        <Box display="flex" alignItems="center" gap={1.5} pr={6}>
          {icon && (
            <Box
              component="span"
              sx={{ my: 0.5, alignSelf: "start", display: "flex", alignItems: "center" }}
            >
              {icon === "loading" && <CircularProgress size={20} thickness={6} />}
              {icon === "success" && (
                <CheckCircleIcon sx={{ color: (theme) => theme.palette.success.light }} />
              )}
              {icon === "warning" && (
                <WarningIcon sx={{ color: (theme) => theme.palette.warning.light }} />
              )}
              {icon === "error" && (
                <CancelIcon sx={{ color: (theme) => theme.palette.error.light }} />
              )}
            </Box>
          )}
          {disableTypography ? (
            message
          ) : (
            <Typography variant={variant} lineHeight="initial">
              {message}
            </Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

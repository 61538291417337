import { alpha, FormControlLabel, FormControlLabelProps, Radio } from "@mui/material";

export interface FormRadioProps<T = unknown>
  extends Omit<FormControlLabelProps, "control" | "value"> {
  value?: T;
}

export function FormRadio(props: FormRadioProps) {
  const { sx, checked, value, ...rest } = props;
  return (
    <FormControlLabel
      {...rest}
      control={
        <Radio
          value={value}
          checked={checked}
          sx={{
            // https://stackoverflow.com/a/69855858
            "&, &.Mui-checked": (theme) => ({
              color: checked ? "white" : theme.palette.primary.main,
            }),
          }}
        />
      }
      sx={{
        ...sx,
        cursor: "pointer",
        border: "1px solid",
        borderRadius: 20,
        height: "2.5rem",
        mx: 0,
        color: (theme) => (checked ? "white" : theme.palette.primary.main),
        backgroundColor: (theme) => (checked ? theme.palette.primary.main : "white"),
        "&:not(.Mui-disabled):hover": (theme) => ({
          backgroundColor: checked
            ? alpha(theme.palette.primary.main, 0.9)
            : alpha(theme.palette.primary.main, 0.1),
        }),
        "&.Mui-disabled": (theme) => ({
          backgroundColor: checked ? alpha(theme.palette.primary.main, 0.5) : "white",
          borderColor: alpha(theme.palette.primary.main, 0.2),
        }),
      }}
      componentsProps={{ typography: { sx: { minWidth: "2.5rem", mr: 3 } } }}
    />
  );
}

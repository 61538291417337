import { useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import { IAgent } from "~/api";
import { FormCheckbox } from "~/components/form/FormCheckbox";
import { FormTextField } from "~/components/form/FormTextField";

import { useEditUserForm } from "./useEditUserForm";

export interface EditUserModalProps {
  open?: boolean;
  onClose?: () => void;
  user?: IAgent;
}

export function EditUserModal(props: EditUserModalProps) {
  const { open = false, onClose, user } = props;

  const { form, onValidSubmit, resetForm } = useEditUserForm(user);
  const { control, handleSubmit } = form;

  useEffect(() => {
    if (open) resetForm();
  }, [open, resetForm]);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
        <Box
          component="form"
          onSubmit={handleSubmit((data) => {
            onClose?.();
            onValidSubmit(data);
          })}
        >
          <DialogTitle>{user ? "Edit" : "New"} User</DialogTitle>
          <DialogContent>
            <Stack gap={2}>
              <FormTextField
                control={control}
                name="firstName"
                label="Preferred first name"
                fullWidth
              />
              <FormTextField
                control={control}
                name="lastName"
                label="Last name"
                fullWidth
              />
              <FormTextField
                control={control}
                name="email"
                label="Email"
                fullWidth
                disabled={user !== undefined}
              />
              <Stack>
                <Typography variant="body1" fontWeight="bold">
                  Roles
                </Typography>
                {/* Counsellor role is always selected */}
                <FormCheckbox
                  disabled
                  control={control}
                  name="isCounsellor"
                  label={
                    <ListItemText
                      primary="Counsellor"
                      secondary="Creates and submits applications"
                      // Reduce opacity as checkbox is always disabled
                      secondaryTypographyProps={{ sx: { opacity: 0.5 } }}
                    />
                  }
                />
                <FormCheckbox
                  control={control}
                  name="isDelegatedAdmin"
                  label={
                    <ListItemText
                      primary="Delegated Admin"
                      secondary="Responsible for managing users"
                    />
                  }
                />
              </Stack>
              {user?.status === "inactive" && (
                <Alert severity="info">
                  Activating this user will grant them access to sign in to Monash Partner
                  Portal.
                </Alert>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button variant="contained" color="primary" type="submit">
              {!user ? "Create" : user?.status === "inactive" ? "Activate user" : "Save"}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export enum AgentRole {
  BranchManager = "Branch Manager",
  PrincipalAgent = "Principal Agent",
  Counsellor = "Counsellor",
  DelegatedAdmin = "Delegated Admin",
}

/**
 * These permissions are assigned through GCP (Firestore).
 */
export enum GCPAdminPermission {
  Support = "gcp:support",
  Developer = "gcp:developer",
  Impersonate = "gcp:impersonate",
  GenerateExternalReceipt = "gcp:generatereceipt",
}

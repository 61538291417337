import { Stack } from "@mui/material";
import { SystemProps } from "@mui/system";

export interface ApplicationSectionListProps extends SystemProps {
  children?: React.ReactNode;
}

export function FormSectionList(props: ApplicationSectionListProps) {
  const { children, ...rest } = props;
  return (
    <Stack component="ul" gap={1} m={0} pl={2} {...rest}>
      {children}
    </Stack>
  );
}

import { Pagination, Paper, Stack, Typography } from "@mui/material";

interface SearchResultsContainerProps {
  title?: React.ReactNode;
  titleBackground?: string;
  children?: React.ReactNode;
  currentPage?: number;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
  pageInfo?: {
    page: number | null;
    pageSize: number | null;
    totalPages: number | null;
    totalRecords: number | null;
  };
}

export function SearchResultsContainer(props: SearchResultsContainerProps) {
  const { title, titleBackground, children, currentPage, setCurrentPage, pageInfo } =
    props;
  return (
    <>
      <Paper>
        <Typography
          variant="h6"
          fontWeight="bold"
          color="white"
          py={1}
          px={3}
          sx={{ background: titleBackground }}
        >
          {title}
        </Typography>
        {children}
      </Paper>
      <Stack alignItems="center">
        <Pagination
          count={pageInfo?.totalPages ?? 0}
          page={currentPage ?? 1}
          size="large"
          onChange={(event, page) => setCurrentPage?.(page)}
        />
      </Stack>
    </>
  );
}

import { useMemo, useState } from "react";
import { Box, Button, Collapse, List, Typography } from "@mui/material";

import { IAgentInfo } from "~/api";
import { FormSection } from "~/components/form/FormSection";
import { ExpandChevron } from "~/components/core/ExpandChevronIcon";
import {
  UseApplicationForm,
  ApplicationSection,
} from "~/components/application/ApplicationForm";
import { ApplicationFormActions } from "~/components/application/ApplicationFormActions";

import { ThroughLine } from "./ThroughLine";
import { ApplicationFormSidebarItem } from "./ApplicationFormSidebarItem";

const APPLICATION_SECTIONS = Object.values(ApplicationSection);

export interface ApplicationFormSidebarProps {
  form: UseApplicationForm;
  agencyPartners?: IAgentInfo["agencyPartners"];
}

export function ApplicationFormSidebar(props: ApplicationFormSidebarProps) {
  const { form, agencyPartners = [] } = props;

  const [expanded, setExpanded] = useState(true);

  const sections = useMemo(
    () =>
      APPLICATION_SECTIONS.filter((section) => {
        // Do not show delegatedAgency section if there are no agency partners
        if (section.key === "delegatedAgency") return agencyPartners.length > 0;
        // Always show other sections
        return true;
      }),
    [agencyPartners.length],
  );

  return (
    <Box sx={{ position: "sticky", top: 24 + 64 }}>
      <FormSection sx={{ mb: 3, p: 2, position: "relative" }} gap={0}>
        <Button
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={() => setExpanded((x) => !x)}
        >
          <Typography
            sx={{ pl: 0.5 }}
            color="primary"
            fontWeight="medium"
            textTransform="initial"
          >
            In this form
          </Typography>
          <ExpandChevron expanded={expanded} />
        </Button>
        <Collapse in={expanded} sx={{ position: "relative" }}>
          <ThroughLine />
          <List dense disablePadding>
            {sections.map((section) => (
              <ApplicationFormSidebarItem key={section.key} section={section} />
            ))}
          </List>
        </Collapse>
      </FormSection>
      <ApplicationFormActions gap={1} form={form} />
    </Box>
  );
}

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { IEnquiryGroup, IErrorResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class EnquiryApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Cached for 1 hour.
   *
   * @tags Enquiry API
   * @name GetGroups
   * @summary Get the enquiry groups for the specified campus
   * @request GET:/api/enquiries/groups
   * @response `200` `(IEnquiryGroup)[]`
   * @response `401` `IErrorResponse` Not signed in.
   */
  getGroups = (
    query: {
      campusLocation: "Australia" | "Malaysia";
    },
    params: RequestParams = {},
  ) =>
    this.request<IEnquiryGroup[], IErrorResponse>({
      path: `/api/enquiries/groups`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
}

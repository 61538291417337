import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";

export function Loading() {
  return (
    <Container
      sx={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Container>
  );
}

import { RequestError } from "~/utils/errors";
import {
  NotificationMessage,
  NotificationMessageProps,
} from "~/components/core/Notification";

import { ErrorCorrelationId } from "./ErrorCorrelationId";

export interface ErrorNotificationMessageProps {
  /** The error to show. */
  error: RequestError;
  /** A custom message to show to override the error message. */
  message?: string;
  /** Where the message is being displayed (helps to set some styling defaults). Defaults to "default". */
  context?: NotificationMessageProps["context"];
}

export function ErrorNotificationMessage(props: ErrorNotificationMessageProps) {
  const { error, message, context } = props;

  const hasDetails = Boolean(error.details);
  const title = message || (hasDetails ? error.message : error.error);
  const description = hasDetails ? error.details : error.message;

  return (
    <NotificationMessage
      title={title}
      message={description}
      subtitle={<ErrorCorrelationId correlationId={error.correlationId} mt={1} />}
      context={context}
    />
  );
}

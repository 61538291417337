/**
 * Removes any fields from an object where the value is falsy except for `false`.
 * i.e. values such as an empty string, `null` and `undefined`.
 */
export function stripEmptyFields<T extends { [key: string]: any }>( // eslint-disable-line @typescript-eslint/no-explicit-any
  object: T,
): Partial<T> {
  const output: Partial<T> = {};
  for (const [key, value] of Object.entries(object)) {
    if (typeof value !== "boolean" && !value) continue;
    output[key as keyof T] = value;
  }
  return output;
}

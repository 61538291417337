/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IApplication,
  IApplicationCreatePayload,
  IApplicationCreateResponse,
  IApplicationDeclarationPayload,
  IApplicationDocumentChecklistItem,
  IApplicationDocumentUpload,
  IApplicationDocumentUploadResponse,
  IApplicationFeeWaiverCodeUpdatePayload,
  IApplicationUpdatePayload,
  IApplicationUpdateResponse,
  IApplicationsPage,
  IEnquiryCreatePayload,
  IEnquiryCreateResponse,
  IEnquiryDetails,
  IErrorResponse,
  IStartImpersonationPayload,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ApplicationApi<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * @description Get applications by status or applicant ID.
   *
   * @tags Application API
   * @name GetApplications
   * @summary Get applications
   * @request GET:/api/applications
   * @response `200` `IApplicationsPage`
   * @response `400` `IErrorResponse` Either `applicationStatus` or `applicantId` is required.
   * @response `401` `IErrorResponse` Not signed in.
   */
  getApplications = (
    query?: {
      /**
       * If `true`, applicant names and student IDs will be fetched and populated in the response. This information can also be fetched separately.
       * @example false
       */
      includeApplicantNameAndStudentId?: boolean;
      /** @example 1 */
      page?: number;
      /** @example 10 */
      pageSize?: number;
      /** Provide `applicantId` to search for applications that belong to the specified applicant. If provided, `applicationStatus` is ignored. */
      applicantId?: any;
      /** Search for applications with the specified status type. This value is ignored if `applicationId` is provided. */
      applicationStatusType?: "Draft" | "InProgress" | "Completed";
    },
    params: RequestParams = {},
  ) =>
    this.request<IApplicationsPage, IErrorResponse>({
      path: `/api/applications`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * @description Create a new application associated with the given applicant.
   *
   * @tags Application API
   * @name CreateApplication
   * @summary Create a new application
   * @request POST:/api/applications
   * @response `200` `IApplicationCreateResponse`
   * @response `201` `void` New application created for applicant.
   * @response `400` `IErrorResponse` Invalid applicant creation parameters.
   * @response `401` `IErrorResponse` Not signed in.
   */
  createApplication = (data: IApplicationCreatePayload, params: RequestParams = {}) =>
    this.request<IApplicationCreateResponse, IErrorResponse>({
      path: `/api/applications`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Application API
   * @name GetApplication
   * @summary Get an existing application
   * @request GET:/api/applications/{applicationId}
   * @response `200` `IApplication`
   * @response `401` `IErrorResponse` Not signed in.
   * @response `404` `IErrorResponse` The application does not exist.
   */
  getApplication = (applicationId: string, params: RequestParams = {}) =>
    this.request<IApplication, IErrorResponse>({
      path: `/api/applications/${applicationId}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description This method is used to update the application content and its status. Ensure that `declaration` is provided when updating the state to "Agent Declaration Accepted" to trigger the generation of an application receipt PDF.
   *
   * @tags Application API
   * @name UpdateApplication
   * @summary Update an existing application
   * @request PUT:/api/applications/{applicationId}
   * @response `200` `IApplicationUpdateResponse`
   * @response `400` `IErrorResponse` Invalid application update parameters.
   * @response `401` `IErrorResponse` Not signed in.
   * @response `404` `IErrorResponse` The application does not exist.
   */
  updateApplication = (
    applicationId: string,
    data: IApplicationUpdatePayload,
    params: RequestParams = {},
  ) =>
    this.request<IApplicationUpdateResponse, IErrorResponse>({
      path: `/api/applications/${applicationId}`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Application API
   * @name GetApplicationDocuments
   * @summary Get an application's documents, grouped by document checklist item
   * @request GET:/api/applications/{applicationId}/documents
   * @response `200` `(IApplicationDocumentChecklistItem)[]`
   * @response `401` `IErrorResponse` Not signed in.
   * @response `404` `IErrorResponse` The application does not exist.
   */
  getApplicationDocuments = (applicationId: string, params: RequestParams = {}) =>
    this.request<IApplicationDocumentChecklistItem[], IErrorResponse>({
      path: `/api/applications/${applicationId}/documents`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Application API
   * @name UploadApplicationDocument
   * @summary Upload a document to an application document checklist item
   * @request POST:/api/applications/{applicationId}/documents
   * @response `200` `IApplicationDocumentUploadResponse`
   * @response `401` `IErrorResponse` Not signed in.
   * @response `404` `IErrorResponse` The application or document checklist item does not exist.
   */
  uploadApplicationDocument = (
    applicationId: string,
    data: IApplicationDocumentUpload,
    params: RequestParams = {},
  ) =>
    this.request<IApplicationDocumentUploadResponse, IErrorResponse>({
      path: `/api/applications/${applicationId}/documents`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * @description This method is used to update only the application's fee waiver code. Used only when modifying the application fee waiver code after sending the application to the applicant for declaration.
   *
   * @tags Application API
   * @name UpdateFeeWaiverCode
   * @summary Update an existing application's fee waiver code
   * @request PUT:/api/applications/{applicationId}/fee-waiver-code
   * @response `200` `IApplicationUpdateResponse`
   * @response `400` `IErrorResponse` Invalid application update parameters.
   * @response `401` `IErrorResponse` Not signed in.
   * @response `404` `IErrorResponse` The application does not exist.
   */
  updateFeeWaiverCode = (
    applicationId: string,
    data: IApplicationFeeWaiverCodeUpdatePayload,
    params: RequestParams = {},
  ) =>
    this.request<IApplicationUpdateResponse, IErrorResponse>({
      path: `/api/applications/${applicationId}/fee-waiver-code`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Create an enquiry for an application.
   *
   * @tags Application API
   * @name CreateApplicationEnquiry
   * @summary Create an enquiry for an application
   * @request POST:/api/applications/{applicationId}/enquiries
   * @response `201` `IEnquiryCreateResponse`
   * @response `400` `IErrorResponse` Invalid enquiry creation parameters.
   * @response `401` `IErrorResponse` Not signed in.
   */
  createApplicationEnquiry = (
    applicationId: string,
    data: IEnquiryCreatePayload,
    params: RequestParams = {},
  ) =>
    this.request<IEnquiryCreateResponse, IErrorResponse>({
      path: `/api/applications/${applicationId}/enquiries`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Get an application enquiry.
   *
   * @tags Application API
   * @name GetApplicationEnquiry
   * @summary Get an application enquiry
   * @request GET:/api/applications/{applicationId}/enquiries/{enquiryNumber}
   * @response `200` `IEnquiryDetails`
   * @response `401` `IErrorResponse` Not signed in.
   * @response `404` `IErrorResponse` Application or enquiry not found.
   */
  getApplicationEnquiry = (
    applicationId: string,
    enquiryNumber: string,
    params: RequestParams = {},
  ) =>
    this.request<IEnquiryDetails, IErrorResponse>({
      path: `/api/applications/${applicationId}/enquiries/${enquiryNumber}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * @description Developer use only.
   *
   * @tags Application API
   * @name GenerateApplicationReceipt
   * @summary Generate and return application receipt
   * @request POST:/api/applications/{applicationId}/application-receipt
   * @response `200` `File`
   * @response `401` `IErrorResponse` Not signed in.
   */
  generateApplicationReceipt = (
    applicationId: string,
    data: IApplicationDeclarationPayload,
    params: RequestParams = {},
  ) =>
    this.request<File, IErrorResponse>({
      path: `/api/applications/${applicationId}/application-receipt`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Generate the application receipt of an application owned by an external agency by impersonating an agent from that agency. Developer/Admin use only.
   *
   * @tags Application API
   * @name GenerateExternalApplicationReceipt
   * @summary Generate and return receipt for an external agency's application
   * @request POST:/api/applications/{applicationId}/application-receipt/external
   * @response `200` `File`
   * @response `401` `IErrorResponse` Not signed in.
   */
  generateExternalApplicationReceipt = (
    applicationId: string,
    data: IStartImpersonationPayload,
    params: RequestParams = {},
  ) =>
    this.request<File, IErrorResponse>({
      path: `/api/applications/${applicationId}/application-receipt/external`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * @description Developer use only.
   *
   * @tags Application API
   * @name EnqueueApplicationReceiptGenerationTask
   * @summary Enqueue an application receipt generation task
   * @request POST:/api/applications/{applicationId}/tasks/application-receipt
   * @response `401` `IErrorResponse` Not signed in.
   */
  enqueueApplicationReceiptGenerationTask = (
    applicationId: string,
    data: IApplicationDeclarationPayload,
    params: RequestParams = {},
  ) =>
    this.request<any, IErrorResponse>({
      path: `/api/applications/${applicationId}/tasks/application-receipt`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
}

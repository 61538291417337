/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IDeveloperCreateTokenResponse,
  IDeveloperCredentialCreatePayload,
  IDeveloperCredentialCreateResponse,
  IDeveloperTokenCreatePayload,
  IErrorResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class TokenApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Generate a token that can be used to test MIX.
   *
   * @tags Token API
   * @name CreateToken
   * @request POST:/api/admin/token
   * @response `201` `IDeveloperCreateTokenResponse`
   * @response `401` `IErrorResponse` Not signed in.
   */
  createToken = (data: IDeveloperTokenCreatePayload, params: RequestParams = {}) =>
    this.request<IDeveloperCreateTokenResponse, IErrorResponse>({
      path: `/api/admin/token`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Generate a credential that can be used to call other developer endpoints.
   *
   * @tags Token API
   * @name CreateCredential
   * @request POST:/api/admin/credential
   * @response `201` `IDeveloperCredentialCreateResponse`
   * @response `401` `IErrorResponse` Not signed in.
   */
  createCredential = (
    data: IDeveloperCredentialCreatePayload,
    params: RequestParams = {},
  ) =>
    this.request<IDeveloperCredentialCreateResponse, IErrorResponse>({
      path: `/api/admin/credential`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * @description Generate a token that can be used to test MIX using a `generate-token` credential.
   *
   * @tags Token API
   * @name CreateTokenFromCredential
   * @request POST:/api/admin/credential/generate-token
   * @response `201` `IDeveloperCreateTokenResponse`
   */
  createTokenFromCredential = (
    data: IDeveloperTokenCreatePayload,
    params: RequestParams = {},
  ) =>
    this.request<IDeveloperCreateTokenResponse, any>({
      path: `/api/admin/credential/generate-token`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}

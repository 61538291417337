import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import * as Sentry from "@sentry/react";

import { AgencyApi, GetApi, IAgent } from "~/api";
import { useNotification } from "~/components/core/NotificationProvider";

export type UpdateUserStatusAction = "deactivate" | "suspend" | "unsuspend";

export const UpdateUserStatusActionMapping: Record<
  UpdateUserStatusAction,
  {
    title: string;
    messages: {
      confirm: string;
      loading: string;
      done: string;
    };
    destructive?: boolean;
    permanent?: boolean;
  }
> = {
  deactivate: {
    title: "Deactivate user",
    messages: {
      confirm: `Are you sure you want to deactivate {name}? This user will not be able to sign in to Monash Partner Portal after deactivation.`,
      loading: "Deactivating user...",
      done: "User deactivated.",
    },
    destructive: true,
    permanent: true,
  },
  suspend: {
    title: "Suspend user",
    messages: {
      confirm: `Are you sure you want to suspend {name}? This user will not be able to sign in to Monash Partner Portal when suspended.`,
      loading: "Suspending user...",
      done: "User suspended.",
    },
    destructive: true,
  },
  unsuspend: {
    title: "Unsuspend user",
    messages: {
      confirm: `Are you sure you want to unsuspend {name}? This will give the user access to sign in to Monash Partner Portal.`,
      loading: "Unsuspending user...",
      done: "User unsuspended.",
    },
  },
};

export function useUpdateUserStatus(action?: UpdateUserStatusAction) {
  const [loading, setLoading] = useState(false);
  const { showNotification, showErrorNotification } = useNotification();
  const client = useQueryClient();

  const { messages } = (action ? UpdateUserStatusActionMapping[action] : null) ?? {};

  const onUpdate = useCallback(async (user: IAgent) => {
    setLoading(true);
    showNotification({ type: "loading", message: messages?.loading });

    try {
      if (action === "deactivate") {
        if (!user.userId) throw new Error("Cannot deactivate user");
        const result = await GetApi(AgencyApi).deactivateAgent(user.userId);
        if (result.status === "success")
          showNotification({ type: "success", message: messages?.done });
        else showNotification({ type: "error", message: "Failed to deactivate user" });
      } else {
        showNotification({ type: "warning", message: "Not implemented" });
      }

      // Refetch agent list
      client.refetchQueries({ queryKey: ["agents"] });
    } catch (error) {
      // send error to Sentry
      Sentry.captureException(error, {
        tags: { source: "useUpdateUserStatus.onUpdate" },
      });

      showErrorNotification(error);
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, onUpdate };
}

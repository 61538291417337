import { Typography, TypographyProps } from "@mui/material";

export interface ApplicationSectionTitleProps extends TypographyProps {
  children?: React.ReactNode;
}

export function FormSectionSubheading(props: ApplicationSectionTitleProps) {
  const { children, ...typographyProps } = props;
  return (
    <Typography variant="h6" gutterBottom {...typographyProps}>
      {children}
    </Typography>
  );
}

import { useAtom } from "jotai";
import { useCallback, useEffect } from "react";

import { useIsDeveloper } from "~/hooks/admin";

import { developerToolActionAtom, developerToolAtom, DeveloperToolKey } from "./state";

type UseDeveloperTool = {
  setOpen: (open: boolean) => void;
  action: (() => void) | undefined;
  isDeveloper: boolean;
  key: DeveloperToolKey;
  enabled?: boolean | undefined;
  open?: boolean | undefined;
};

type UseDeveloperToolOptions = {
  /** Specify the developer tool to get the state for. */
  key: DeveloperToolKey;
  /** If set to `true`, the developer tool will be enabled. If the user does not have the correct permissions, this has no effect. */
  enable?: boolean;
  /** Provide an action handler that will be run when the tool is activated. */
  action?: () => void;
};

/**
 * Get a developer tool's state.
 *
 * @param options The key of the developer tool to get.
 */
export function useDeveloperTool(key: DeveloperToolKey): UseDeveloperTool;

/**
 * Set up and enable a developer tool. Returns the developer tool's state.
 */
export function useDeveloperTool(options: UseDeveloperToolOptions): UseDeveloperTool;

// Implementation
export function useDeveloperTool(options: DeveloperToolKey | UseDeveloperToolOptions) {
  const key = typeof options === "string" ? options : options.key;
  const enable = typeof options === "string" ? false : (options.enable ?? false);
  const actionHandler = typeof options === "string" ? null : (options.action ?? null);

  const isDeveloper = useIsDeveloper();
  const [state, setState] = useAtom(developerToolAtom({ key }));
  const [{ action }, setAction] = useAtom(developerToolActionAtom({ key }));

  // If the caller has requested to enable the hook, set it to true
  // Automaticlaly disable the tool on unmount
  useEffect(() => {
    if (!enable) return;
    if (isDeveloper) setState({ key, enabled: true });
    return () => setState({ key, enabled: false });
  }, [key, isDeveloper, enable, setState]);

  // If an action was provided, save it in state
  useEffect(() => {
    if (actionHandler) setAction({ key, action: actionHandler });
  }, [actionHandler, key, setAction]);

  const setOpen = useCallback(
    (open: boolean) => setState((prev) => ({ ...prev, open })),
    [setState],
  );

  return { ...state, setOpen, action: action, isDeveloper };
}

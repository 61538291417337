import { z } from "zod";
import {
  Grid,
  Link,
  MenuItem,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";

import {
  CampusLocation,
  CodeStringNullableSchema,
  DateStringSchema,
  parseDateString,
  SafeStringForProse,
  toDateString,
} from "@packages/types";

import { createAddIssue } from "~/utils";
import { IAdmissionTestType } from "~/api";
import { useAdmissionTestTypes, useReferenceData } from "~/hooks/reference-data";
import { FormSection } from "~/components/form/FormSection";
import { FormRadioGroup } from "~/components/form/FormRadioGroup";
import { FormCheckbox } from "~/components/form/FormCheckbox";
import { FormCheckboxGroup } from "~/components/form/FormCheckbox/FormCheckboxGroup";
import { FormTextField } from "~/components/form/FormTextField";
import { FormSelect } from "~/components/form/FormSelect";

import { ApplicationFields } from "./useApplicationForm";

export interface EnglishLanguageProficiencyFormProps {
  control: Control<ApplicationFields>;
  setValue: UseFormSetValue<ApplicationFields>;
  disabled?: boolean;
  campusLocation: string;
}

export function EnglishLanguageProficiencyForm(
  props: EnglishLanguageProficiencyFormProps,
) {
  const { control, campusLocation, disabled, setValue } = props;

  const [
    englishCourse,
    haveSatEnglishTest,
    willSitEnglishTest,
    hasOtherDocumentation,
    testId,
  ] = useWatch({
    control,
    name: [
      "coursePreferences.englishCourse",
      "englishProficiency.haveSatEnglishTest",
      "englishProficiency.willSitEnglishTest",
      "englishProficiency.hasOtherDocumentation",
      "englishProficiency.englishTest.id",
    ],
  });

  const allAdmissionTests = useAdmissionTestTypes();
  const hadEnglishInstructionOptions = useReferenceData("EnglishLanguageProficiency");

  const admissionTests = allAdmissionTests.data?.filter((test) => !isLOITest(test));
  const loiTest = allAdmissionTests.data?.filter(isLOITest)[0]; // used for "The applicant has other documentation"

  const selectedTest = allAdmissionTests.data?.find((x) => x.id === testId);
  const isLOITestSelected = selectedTest?.id === loiTest?.id;

  function resetEnglishTest() {
    setValue("englishProficiency.englishTest", {
      id: "",
      dateAchieved: "",
      expectedDateOfCompletion: "",
      scores: [],
    });
    setValue("englishProficiency.otherDocumentation", "");
  }

  function setEnglishTestScores(test?: IAdmissionTestType) {
    if (!test) {
      setValue("englishProficiency.englishTest.scores", []);
      return;
    }
    if (test.scores.length === 0)
      setValue("englishProficiency.englishTest.scores", [{ value: "" }]);
    else
      setValue(
        "englishProficiency.englishTest.scores",
        test.scores.map((name) => ({ name, value: "" })),
      );
  }

  function setLOITest() {
    setValue("englishProficiency.englishTest", {
      id: loiTest?.id ?? "",
      dateAchieved: toDateString(new Date()),
      expectedDateOfCompletion: "",
      scores: [],
    });
    setValue("englishProficiency.otherDocumentation", "");
  }

  return (
    <>
      <FormSection.Well>
        <Typography variant="body1" paragraph>
          The applicant must provide proof of their English proficiency to the required
          standard of their chosen course. There are a number of ways to satisfy these
          requirements. Visit the{" "}
          <Link
            href="http://policy.monash.edu/policy-bank/academic/education/admissions/admissions-coursework-courses-units-of-study-procedures.html"
            target="_blank"
            rel="noopener"
          >
            Admission to Coursework Courses and Units of Study Procedures
          </Link>{" "}
          page for more information. Some of the main pathways are:
        </Typography>
        <Typography variant="body1" mb={1}>
          <strong>English as the language of instruction</strong>
        </Typography>
        <FormSection.List pl={3} mb={2}>
          <Typography variant="body1" component="li">
            The applicant has studied in an institution where English is the language of
            instruction, communication and assessment for all aspects of study for the
            whole of the educational institution.
          </Typography>
          <Typography variant="body1" component="li">
            Applicants are required to provide evidence from the institution on official
            letterhead and signed by the registrar (or other authorised person) of the
            educational institution to the satisfaction of the Academic Board.
          </Typography>
        </FormSection.List>
        <Typography variant="body1" mb={1}>
          <strong>Academic IELTS/TOEFL/PEARSON TEST OF ENGLISH (Academic)</strong>
        </Typography>
        <FormSection.List pl={3}>
          <Typography variant="body1" component="li">
            Attach original results or documents (or request results to be sent to Monash
            University by testing centres).
          </Typography>
          <Typography variant="body1" component="li">
            Please note that only results achieved within two years prior to the course
            commencement date will be considered.
          </Typography>
          <Typography variant="body1" component="li">
            If the applicant has not yet sat an Academic IELTS/TOEFL/Pearson Test of
            English (Academic) exam please indicate when they will sit one below.
          </Typography>
        </FormSection.List>
      </FormSection.Well>
      <Stack gap={2}>
        <FormRadioGroup
          control={control}
          name="englishProficiency.hadEnglishInstruction.code"
          disabled={disabled}
          label={
            <>
              Has the applicant completed at least six years of schooling in an English
              medium institution in an{" "}
              <Link
                href="https://www.monash.edu/admissions/entry-requirements/english-language"
                target="_blank"
                rel="noopener"
              >
                English-speaking country
              </Link>{" "}
              prior to the age of 19?
            </>
          }
          loading={hadEnglishInstructionOptions.isPending}
          options={hadEnglishInstructionOptions.data}
        />
        <FormCheckboxGroup
          control={control}
          name="englishProficiency"
          disabled={disabled}
          label="English language proficiency test"
        >
          <FormCheckbox
            control={control}
            name="englishProficiency.haveSatEnglishTest"
            label="The applicant has completed a test"
            onBeforeChange={(_, checked) => {
              if (checked) {
                setValue("englishProficiency.willSitEnglishTest", false);
                setValue("englishProficiency.hasOtherDocumentation", false);
                setValue("englishProficiency.englishTest.expectedDateOfCompletion", "");
                if (isLOITestSelected) resetEnglishTest();
                else setEnglishTestScores(selectedTest);
              } else if (!willSitEnglishTest || hasOtherDocumentation) resetEnglishTest();
            }}
          />
          <FormCheckbox
            control={control}
            name="englishProficiency.willSitEnglishTest"
            label="The applicant will sit a test"
            onBeforeChange={(_, checked) => {
              if (checked) {
                setValue("englishProficiency.haveSatEnglishTest", false);
                setValue("englishProficiency.hasOtherDocumentation", false);
                setValue("englishProficiency.englishTest.dateAchieved", "");
                if (isLOITestSelected) resetEnglishTest();
                else setEnglishTestScores();
              } else if (!haveSatEnglishTest && !hasOtherDocumentation)
                resetEnglishTest();
            }}
          />
          {campusLocation !== CampusLocation.MALAYSIA && (
            <FormControlLabel
              label="The applicant has applied for a Monash English Language Centre course"
              control={<Checkbox checked={Boolean(englishCourse)} />}
              disabled
            />
          )}
          <FormCheckbox
            control={control}
            name="englishProficiency.hasOtherDocumentation"
            label="The applicant has other documentation"
            onBeforeChange={(_, checked) => {
              if (checked) {
                setValue("englishProficiency.haveSatEnglishTest", false);
                setValue("englishProficiency.willSitEnglishTest", false);
                setLOITest();
              } else resetEnglishTest();
            }}
          />
        </FormCheckboxGroup>
      </Stack>
      <Grid container spacing={2}>
        {(haveSatEnglishTest || willSitEnglishTest) && (
          <>
            <Grid item xs={12}>
              <FormSelect
                control={control}
                name="englishProficiency.englishTest.id"
                label="Test Type"
                loading={allAdmissionTests.isPending}
                disabled={disabled}
                onBeforeChange={(e) => {
                  // Don't need to set scores if the test has not been taken
                  if (!haveSatEnglishTest) return;
                  const id = e.target.value;
                  setEnglishTestScores(admissionTests?.find((x) => x.id === id));
                }}
              >
                {admissionTests?.map(({ id, description }) => (
                  <MenuItem key={id} value={id}>
                    {description}
                  </MenuItem>
                ))}
              </FormSelect>
            </Grid>
            {haveSatEnglishTest && (
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  name="englishProficiency.englishTest.dateAchieved"
                  label="Date achieved"
                  disabled={disabled}
                  type="date"
                  fullWidth
                />
              </Grid>
            )}
            {willSitEnglishTest && (
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  name="englishProficiency.englishTest.expectedDateOfCompletion"
                  label="Expected completion date"
                  disabled={disabled}
                  type="date"
                  fullWidth
                />
              </Grid>
            )}
            {(!haveSatEnglishTest || !willSitEnglishTest) && <Grid item xs={6} />}
            {haveSatEnglishTest && selectedTest?.scores.length === 0 && (
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  name={`englishProficiency.englishTest.scores.0.value`}
                  label="Score"
                  disabled={disabled}
                  fullWidth
                />
              </Grid>
            )}
            {haveSatEnglishTest &&
              selectedTest?.scores.map((name, index) => (
                <Grid item key={name ?? index} xs={6}>
                  <FormTextField
                    control={control}
                    name={`englishProficiency.englishTest.scores.${index}.value`}
                    label={name ? `${name} score` : "Score"}
                    disabled={disabled}
                    fullWidth
                  />
                </Grid>
              ))}
          </>
        )}
        {hasOtherDocumentation && (
          <Grid item xs={12}>
            <FormTextField
              control={control}
              name="englishProficiency.otherDocumentation"
              label="Enter details for other documentation that satisfies English proficiency"
              multiline
              minRows={3}
              maxRows={12}
              disabled={disabled}
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

/** Utility function to check if a given admission test is the LOI (Language of Instruction) Test. */
function isLOITest(test: IAdmissionTestType) {
  return test.description === "LOI (Language of Instruction)";
}

export const EnglishTestScoreSchema = z.object({
  name: z.string().optional(),
  value: z.coerce
    .number({ invalid_type_error: "Please provide a valid number" })
    .min(0, "Cannot be less than 0")
    .max(999, "Cannot be greater than 999")
    .transform((score) => score.toString()),
});

export const EnglishTestSchema = z.object({
  recordId: z.string().optional(),
  id: z.string(),
  dateAchieved: DateStringSchema.allowBlank(),
  expectedDateOfCompletion: DateStringSchema.allowBlank(),
  scores: z.array(EnglishTestScoreSchema),
});

EnglishLanguageProficiencyForm.draftSchema = z
  .object({
    hadEnglishInstruction: CodeStringNullableSchema,
    willSitEnglishTest: z.boolean(),
    haveSatEnglishTest: z.boolean(),
    englishTest: EnglishTestSchema,
    hasOtherDocumentation: z.boolean(),
    otherDocumentation: SafeStringForProse.schema(),
  })
  .superRefine(({ willSitEnglishTest, haveSatEnglishTest, englishTest }, ctx) => {
    const addIssue = createAddIssue(ctx);

    if (willSitEnglishTest || haveSatEnglishTest) {
      if (!englishTest.id) addIssue("Please select a test type", "englishTest.id");

      if (haveSatEnglishTest) {
        if (!englishTest.dateAchieved)
          addIssue(
            "Please provide the date the test score was achieved",
            "englishTest.dateAchieved",
          );
        else {
          const date = parseDateString(englishTest.dateAchieved);
          if (date.getTime() > Date.now())
            addIssue("Date achieved must be in the past", "englishTest.conferredDate");
        }

        englishTest.scores.forEach((score, index) => {
          if (!score.value)
            addIssue("Please provide a score", `englishTest.scores.${index}.value`);
        });
      }

      if (willSitEnglishTest) {
        if (!englishTest.expectedDateOfCompletion)
          addIssue(
            "Please provide the expected completion date",
            "englishTest.expectedDateOfCompletion",
          );
        else {
          const date = parseDateString(englishTest.expectedDateOfCompletion);
          if (date.getTime() < Date.now())
            addIssue(
              "Expected date of completion must be in the future",
              "englishTest.expectedDateOfCompletion",
            );
        }
      }
    }
  });

EnglishLanguageProficiencyForm.submitSchema =
  EnglishLanguageProficiencyForm.draftSchema.superRefine(
    (
      {
        hadEnglishInstruction,
        hasOtherDocumentation,
        otherDocumentation,
        willSitEnglishTest,
        haveSatEnglishTest,
      },
      ctx,
    ) => {
      const addIssue = createAddIssue(ctx);

      if (!hadEnglishInstruction?.code)
        addIssue("Please select an answer", "hadEnglishInstruction.code");

      if (!(willSitEnglishTest || haveSatEnglishTest || hasOtherDocumentation))
        addIssue("Please select one of these three options", "englishTest");

      if (hasOtherDocumentation && !otherDocumentation)
        addIssue("Please provide documentation", "otherDocumentation");
    },
  );

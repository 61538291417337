import { Divider, Grid } from "@mui/material";
import { useWatch } from "react-hook-form";

import { CourseType } from "@packages/types";

import { CoursePreferencesFormProps } from "~/components/application/ApplicationForm/CoursePreferencesForm";
import { useFindResidencyStatusCountry } from "~/hooks/reference-data";

import { CourseSelectionForm } from "./CourseSelectionForm";
import { createCourseFields } from "./_utils";

export function MonashCollegePathwayCourseSelectionForm(
  props: CoursePreferencesFormProps,
) {
  const { control, disabled, setValue } = props;

  const [residencyStatus, residencySubStatus, pathwayCourse, preferenceList] = useWatch({
    control,
    name: [
      "citizenship.residencyStatus",
      "citizenship.residencySubStatus",
      "coursePreferences.pathwayCourse",
      "coursePreferences.preferenceList",
    ],
  });

  const campusCountry = useFindResidencyStatusCountry(residencySubStatus);

  return (
    <>
      <CourseSelectionForm
        title="Monash College Pathway Diploma"
        control={control}
        setValue={setValue}
        fields={createCourseFields("pathwayCourse")}
        coursePreference={pathwayCourse}
        residencyStatus={residencyStatus}
        campusCountry={campusCountry}
        courseType={CourseType.Pathway}
        disabled={disabled}
      />
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <CourseSelectionForm
        title="Monash College Pathway Destination Degree"
        control={control}
        setValue={setValue}
        fields={createCourseFields(0)}
        coursePreference={preferenceList[0]}
        residencyStatus={residencyStatus}
        campusCountry={campusCountry}
        courseType={CourseType.Pathway}
        disabled={disabled || !pathwayCourse?.courseCode}
        filterByPathwayCourse
      />
    </>
  );
}

import { useCallback, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
  Theme,
  Alert,
  Typography,
} from "@mui/material";

import { list } from "@packages/utils";

import { parseRequestError } from "~/utils";
import { useApplicationDocumentChecklistItems } from "~/hooks/application";
import { ErrorNotificationMessage } from "~/components/core/ErrorMessage";
import { useLoadResource } from "~/components/core/AppLoadingBar";

import { ApplicationDocumentsModalItem } from "./ApplicationDocumentsModalItem";

const PLACEHOLDERS = list(3).map((index) => (
  <ApplicationDocumentsModalItem key={index} loading />
));

export interface ApplicationDocumentsModalProps {
  applicationId?: string;
  open?: boolean;
  onClose?: () => void;
  isEditable?: boolean;
}

export function ApplicationDocumentsModal(props: ApplicationDocumentsModalProps) {
  const { applicationId, open = false, onClose, isEditable } = props;

  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const checklistItems = useApplicationDocumentChecklistItems(open ? applicationId : "");
  const error = parseRequestError(checklistItems.error);

  useLoadResource(
    useCallback(() => checklistItems.isFetching, [checklistItems.isFetching]),
  );

  // Invalidate the query when the modal is closed so that
  // we refetch when the modal is opened again
  const client = useQueryClient();
  useEffect(() => {
    if (!open)
      client.invalidateQueries({
        queryKey: ["applications", applicationId, "documents"],
      });
  }, [open, client]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      scroll="paper"
      fullScreen={fullScreen}
      // see note below
      // TransitionComponent={fullScreen ? SlideUpTransition : undefined}
    >
      <DialogTitle variant="h5" color="primary">
        Supporting Documents
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ p: 3, background: (theme) => theme.palette.background.default }}
      >
        {error ? (
          <Alert severity="error">
            <ErrorNotificationMessage
              error={error}
              message="Failed to fetch supporting documents"
            />
          </Alert>
        ) : checklistItems.isPending ? (
          PLACEHOLDERS
        ) : (
          <>
            {checklistItems.data?.length === 0 && (
              <Typography variant="body2" color="text.secondary">
                No document checklists available.
              </Typography>
            )}
            {checklistItems.data?.map((group) => (
              <ApplicationDocumentsModalItem
                key={group.id}
                loading={checklistItems.isPending}
                isEditable={isEditable}
                checklistItem={group}
                applicationId={applicationId}
              />
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ mr: 2, my: 1 }}>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// NOTE: Not using this transition component for now due to potential issues
// when changing from a `sm` break point to a wider breakpoint or vice versa.
//
// Changing the transition component between breakpoints causes the dialog to
// remount, which resets the state of the inner ApplicationDocumentUploadModal.
//
// This would mean the user would lose the documents they may have added to
// the upload modal if they change the width of their window (or even when
// they rotate their phone).

// const SlideUpTransition = forwardRef(function Transition(
//   props: TransitionProps & { children: React.ReactElement },
//   ref: React.Ref<unknown>
// ) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

import { Link as RouterLink } from "react-router-dom";
import { Button, Stack } from "@mui/material";

import { IApplicationsPage } from "~/api";
import { useBackLink } from "~/components/core/BackLink";
import { ProfileCard } from "~/components/applicant/ProfileCard";
import { ApplicantApplicationsTable } from "~/components/applicant/ApplicantApplicationsTable";

export interface ApplicantApplicationsCardProps {
  applicantId?: string;
  applications?: IApplicationsPage["results"];
}

export function ApplicantApplicationsCard(props: ApplicantApplicationsCardProps) {
  const { applicantId, applications } = props;
  const backLink = useBackLink();
  return (
    <ProfileCard>
      <Stack gap={2}>
        <Stack direction="row" justifyContent="space-between" flexWrap="wrap" gap={2}>
          <ProfileCard.Subheading>Applications</ProfileCard.Subheading>
          <Button
            component={RouterLink}
            to={applicantId ? `/applicants/${applicantId}/new` : "/applications/new"}
            state={{ backLink }}
            variant="contained"
            sx={{ width: { xs: "100%", md: "auto" } }}
          >
            New Application
          </Button>
        </Stack>
        <ApplicantApplicationsTable applications={applications} />
      </Stack>
    </ProfileCard>
  );
}

import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { ApplicationSectionKey } from "~/components/application/ApplicationForm";

export type ApplicationSectionState = {
  key: ApplicationSectionKey;
  valid?: boolean;
  touched?: boolean;
};

export const sidebarAtom = atomFamily(
  (section: ApplicationSectionState) => atom(section),
  (a, b) => a.key === b.key,
);

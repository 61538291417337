import { ZodSchema } from "zod";
import { useEffect } from "react";
import { Control, FieldPath, useFormState, useWatch } from "react-hook-form";

import {
  ApplicationFields,
  ApplicationSectionKey,
} from "~/components/application/ApplicationForm";

import { useSetApplicationFormSidebar } from "./useApplicationFormSidebar";

export interface WatchApplicationFormSectionProps {
  section: ApplicationSectionKey;
  control: Control<ApplicationFields>;
  schema?: ZodSchema;
  onlyValidateIfDirty?: boolean;
}

/**
 * Watches a form section's values.
 *
 * Sets the sidebar status to valid when the section is complete.
 */
export function WatchApplicationFormSection(props: WatchApplicationFormSectionProps) {
  const { section, control, schema, onlyValidateIfDirty } = props;

  const setState = useSetApplicationFormSidebar(section);

  const state = useFormState({ control, name: section as FieldPath<ApplicationFields> });
  const form = useWatch({ control, name: section as FieldPath<ApplicationFields> });

  useEffect(() => {
    // TODO: isDirty doesn't seem to be scoped by the field names provided to useFormState.
    // as such, changing a field anywhere in the form will cause the whole sidebar to validate
    // We need to replace this with an implementation explicitly checking if this section is dirty.
    if (schema && (!onlyValidateIfDirty || state.isDirty)) {
      const result = schema.safeParse(form);
      setState(({ touched }) => ({ touched, valid: result.success }));
    }
  }, [form, state, onlyValidateIfDirty, schema]);

  // when this component unmounts, reset the sidebar state to blank
  useEffect(() => {
    return () => setState(undefined);
  }, []);

  return null;
}

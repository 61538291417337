export enum CourseCampusCountry {
  Australia = "Australia",
  Malaysia = "Malaysia",
  SouthAfrica = "South Africa",
  Indonesia = "Indonesia",
  Online = "Online",
}

export function isCourseCampusCountry(value: unknown): value is CourseCampusCountry {
  return Object.values(CourseCampusCountry).includes(value as CourseCampusCountry);
}

export enum CourseType {
  Direct = "Direct",
  Pathway = "Pathway",
  English = "English",
}

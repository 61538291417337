import { Link, ListItem, ListItemText } from "@mui/material";
import { useAtomValue, useSetAtom } from "jotai";

import { IEnquiryGroup } from "~/api";
import {
  enquiryApplicantAtom,
  selectedEnquiryTopicAtom,
} from "~/components/enquiry/NewEnquiryModal";

export interface EnquiryListOptionProps {
  topic: IEnquiryGroup["topics"][number];
  enquiryGroup: {
    name: string;
    enquiryType: string;
    recordType: string;
  };
}

export function EnquiryListOption(props: EnquiryListOptionProps) {
  const { enquiryGroup, topic } = props;
  const {
    name: topicName,
    disabled,
    allowAttachments,
    additionalFields,
    messageLabel,
  } = topic;

  const applicantDetails = useAtomValue(enquiryApplicantAtom);
  const { name: applicantName, studentId } = applicantDetails ?? {};

  const level1 = topic.category?.level1 ?? enquiryGroup.name;
  const level2 = topic.category?.level2 ?? topicName;
  const enquiryType = topic.enquiryType ?? enquiryGroup.enquiryType;
  const recordType = topic.recordType ?? enquiryGroup.recordType;

  const setEnquiryTopic = useSetAtom(selectedEnquiryTopicAtom);

  return (
    <ListItem sx={{ display: "list-item", pb: 0.5 }} disablePadding>
      <ListItemText
        disableTypography
        primary={
          <Link
            color={disabled ? "text.secondary" : "common.black"}
            underline={disabled ? "none" : "hover"}
            component="button"
            sx={{ verticalAlign: "top", textAlign: "left" }}
            onClick={() => {
              if (disabled) return;
              const subject = [enquiryGroup.name, topicName];
              if (applicantName) subject.unshift(applicantName);
              if (studentId) subject.unshift(studentId);
              setEnquiryTopic({
                subject: subject.join(" - "),
                enquiryType,
                recordType,
                category: { level1, level2 },
                additionalFields,
                allowAttachments,
                messageLabel,
              });
            }}
          >
            {topicName}
          </Link>
        }
      ></ListItemText>
    </ListItem>
  );
}

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import { isCampusLocation } from "@packages/types";

import { useEnquiryGroups } from "~/hooks/enquiry";
import { EnquiryGroupColumn } from "~/components/enquiry/EnquiryGroupColumn";

export interface EnquiryTopicSelectModalProps {
  open: boolean;
  onClose?: () => void;
  fullScreen: boolean;
  campusLocation?: string;
}

export function EnquiryTopicSelectModal(props: EnquiryTopicSelectModalProps) {
  const { open, onClose, fullScreen, campusLocation } = props;

  const groups = useEnquiryGroups(
    isCampusLocation(campusLocation) ? campusLocation : undefined,
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth fullScreen={fullScreen}>
      <DialogTitle>Select Enquiry Subject</DialogTitle>
      <DialogContent dividers sx={{ py: 0 }}>
        <Grid container spacing={4}>
          {groups.data?.map((group) => (
            <EnquiryGroupColumn key={group.name} enquiryGroup={group} />
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

import { useEffect } from "react";

export const DEFAULT_TITLE = "Monash Partner Portal";

/**
 * Set the document title. Resets it to {@link DEFAULT_TITLE} on unmount.
 */
export function useDocumentTitle(title = "", suffix = DEFAULT_TITLE) {
  useEffect(() => {
    if (suffix) {
      if (title) document.title = `${title} - ${suffix}`;
      else document.title = suffix;
    } else if (title) document.title = title;
    return () => {
      document.title = DEFAULT_TITLE;
    };
  }, [title, suffix]);
}

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

// Import required for `typedi`
import "reflect-metadata";

import { Environment } from "@packages/types";

import { App } from "./App";

let env: Environment | "UNSPECIFIED";
const hostname = window.location.hostname;
if (!hostname) env = "UNSPECIFIED";
else {
  env = hostname.includes("localhost")
    ? Environment.LOCAL
    : hostname.includes("dev")
      ? Environment.DEVELOPMENT
      : hostname.includes("uat")
        ? Environment.UAT
        : Environment.PRODUCTION;
}
const isLocalEnv = env == Environment.LOCAL;
const isProdEnv = env == Environment.PRODUCTION;

try {
  Sentry.init({
    dsn: "https://ee026efb5958475075a35bb94911adf3@o4507699737853952.ingest.us.sentry.io/4507699777503232",

    environment: env,

    // Performance Monitoring
    // Capture 100% of transactions for performance monitoring. Reduce in prod.
    tracesSampleRate: isProdEnv ? 0.1 : 1.0,
    // Set 'tracePropagationTargets' to control the URLs for which distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^\//],

    // Session Replay
    // Capture Replay for 1% of all sessions. Do not capture replays in local env.
    replaysSessionSampleRate: isLocalEnv ? 0 : 0.01,
    // Capture Replay for 100% of sessions with an error. Do not capture replays in local env.
    replaysOnErrorSampleRate: isLocalEnv ? 0 : 1.0,

    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        // Additional Replay configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
  });
} catch (error) {
  console.error(error);
}

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}

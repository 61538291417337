import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Link, Typography, Stack, Grid, Button } from "@mui/material";
import { useSetAtom } from "jotai";

import { IAgentInfo } from "~/api";
import { useSelectedResidencyStatuses } from "~/hooks/reference-data";
import { FormSection } from "~/components/form/FormSection";
import { ButtonLoadingSpinner } from "~/components/core/ButtonLoadingSpinner";
import { NotificationSnackbar } from "~/components/core/Notification";
import { newApplicationIdAtom } from "~/pages/application/EditApplicationPage";

import { ApplicantDetailsForm } from "./ApplicantDetailsForm";
import { MonashStudiesForm } from "./MonashStudiesForm";
import { UseNewApplicationForm } from "./useNewApplicationForm";
import { DelegatedAgencyForm } from "./DelegatedAgencyForm";

export interface NewApplicationFormProps {
  form: UseNewApplicationForm;
  disabled?: boolean;
  isEditable?: boolean;
  isNewApplicant?: boolean;
  agencyPartners?: IAgentInfo["agencyPartners"];
}

export function NewApplicationForm(props: NewApplicationFormProps) {
  const { disabled, isEditable, isNewApplicant, agencyPartners = [] } = props;
  const { form, loading, created, onValidSubmit } = props.form;
  const { control, handleSubmit, setValue } = form;

  const setNewApplicationId = useSetAtom(newApplicationIdAtom);

  const navigate = useNavigate();

  const [campusLocation, residencyStatus] = useWatch({
    control,
    name: ["applicantDetails.campusLocation", "applicantDetails.residencyStatus"],
  });

  // Fetch reference data
  const residency = useSelectedResidencyStatuses(campusLocation);

  const canContinue = useMemo(
    () => !residency.statuses?.[residencyStatus]?.blocked,
    [residencyStatus, residency],
  );

  return (
    <Stack
      gap={3}
      component="form"
      onSubmit={handleSubmit(async (data) => {
        const result = await onValidSubmit(data);
        if (result) {
          // Gives a hint to the application page that this is a brand new application
          setNewApplicationId(result.applicationId);
          navigate(`/applications/${result.applicationId}`);
        }
      })}
    >
      <FormSection>
        <FormSection.Title>New international application</FormSection.Title>
        <FormSection.Well>
          <FormSection.List>
            <Typography component="li">
              This form should only be used for{" "}
              <strong>International Applications</strong> - Australian and NZ citizens
              cannot apply via an Agent. They should apply directly via{" "}
              <Link
                href="https://monashpartner.force.com/admissions"
                target="_blank"
                rel="noopener"
              >
                My.app
              </Link>
              .
            </Typography>
            <Typography variant="body1" component="li">
              Complete this form to submit an application for an undergraduate or
              postgraduate course at Monash University.
            </Typography>
            <Typography variant="body1" component="li">
              <strong>Do not</strong> use this form for Monash research degrees. The
              correct form is available in the future students{" "}
              <Link
                href="http://www.monash.edu/migr/future-students/apply"
                target="_blank"
                rel="noopener"
              >
                how to apply
              </Link>{" "}
              page.
            </Typography>
          </FormSection.List>
        </FormSection.Well>
      </FormSection>
      <FormSection>
        <FormSection.Title>Applicant Details</FormSection.Title>
        <ApplicantDetailsForm
          control={control}
          setValue={setValue}
          disabled={disabled || loading !== "idle"}
          isNewApplicant={isNewApplicant}
        />
      </FormSection>
      <FormSection>
        <FormSection.Title>Current/previous Monash studies</FormSection.Title>
        <MonashStudiesForm
          control={control}
          disabled={disabled || !isEditable || loading !== "idle"}
        />
      </FormSection>
      {agencyPartners.length > 0 && (
        <FormSection>
          <FormSection.Title>Nominate delegate agency branch</FormSection.Title>
          <DelegatedAgencyForm
            control={control}
            disabled={disabled || loading !== "idle"}
            agencyPartners={agencyPartners}
          />
        </FormSection>
      )}
      <Grid container mb={3} justifyContent="flex-end">
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            size="large"
            disabled={disabled || !canContinue || loading !== "idle"}
          >
            Next
            <ButtonLoadingSpinner show={loading !== "idle"} sx={{ ml: 1 }} />
          </Button>
        </Grid>
      </Grid>
      <NotificationSnackbar
        open={loading !== "idle" || created}
        icon="loading"
        message={
          created
            ? "Redirecting you to the new application..."
            : loading === "applicant-create"
              ? "Checking applicant details..."
              : loading === "applicant-update"
                ? "Updating applicant details..."
                : loading === "application"
                  ? "Creating new application..."
                  : "Loading..."
        }
      />
    </Stack>
  );
}

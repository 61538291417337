import { Alert, Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  DocumentUploadModal,
  UseUploadDocuments,
} from "~/components/core/DocumentUploadModal";

export interface EnquiryDocumentUploadModalProps {
  open?: boolean;
  onClose?: () => void;
  uploads?: UseUploadDocuments["uploads"];
  addFiles?: UseUploadDocuments["addFiles"];
  removeUpload?: UseUploadDocuments["removeUpload"];
  uploadInfo?: UseUploadDocuments["uploadInfo"];
}

export function EnquiryDocumentUploadModal(props: EnquiryDocumentUploadModalProps) {
  const { open, onClose, uploads = [], addFiles, removeUpload, uploadInfo } = props;
  const { hasUserErrors } = uploadInfo ?? {};

  return (
    <DocumentUploadModal
      open={open}
      title="Attach files"
      uploads={uploads}
      onDropAll={addFiles}
      onRemoveUpload={removeUpload}
      content={
        <>
          {uploads.length > 0 && !hasUserErrors && (
            <Alert severity="info">
              Your files will be uploaded when the enquiry is created.
            </Alert>
          )}
          {hasUserErrors && (
            <Alert severity="error" icon={<CancelIcon />} sx={{ alignItems: "center" }}>
              Please remove files with errors.
            </Alert>
          )}
        </>
      }
      actions={
        <>
          <Button onClick={onClose} disabled={hasUserErrors}>
            Done
          </Button>
        </>
      }
    />
  );
}

import { z } from "zod";
import { Grid, Link, Typography } from "@mui/material";
import { Control, useWatch } from "react-hook-form";

import { CampusLocation, CodeStringNullableSchema } from "@packages/types";

import { createAddIssue } from "~/utils";
import { useReferenceData } from "~/hooks/reference-data";
import { FormRadioGroup } from "~/components/form/FormRadioGroup";
import { FormSection } from "~/components/form/FormSection";

import { ApplicationFields } from "./useApplicationForm";

export interface DisabilitiesFormProps {
  control: Control<ApplicationFields>;
  disabled?: boolean;
  campusLocation: string;
}

export function DisabilitiesForm(props: DisabilitiesFormProps) {
  const { control, disabled, campusLocation } = props;

  const [hasDisability] = useWatch({
    control,
    name: ["disabilities.hasDisability"],
  });

  const hasDisabilityOptions = useReferenceData("HasDisabilities");
  const existingDisabilitySupportOptions = useReferenceData("ExistingDisabilitySupport");

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormSection.Well>
            <Typography variant="body1">
              Disability Support Services assists students with disabilities, mental
              health conditions and ongoing medical conditions. This includes students
              with learning difficulties, short-term injuries and students on the Autism
              Spectrum.
            </Typography>
          </FormSection.Well>
        </Grid>
        <Grid item xs={12}>
          <FormRadioGroup
            control={control}
            name="disabilities.hasDisability.code"
            label={
              <>
                Does the applicant have a disability, mental health or ongoing medical
                condition that may affect their studies or may prevent them from
                undertaking full-time study?
              </>
            }
            disabled={disabled}
            loading={hasDisabilityOptions.isPending}
            options={hasDisabilityOptions.data}
          />
        </Grid>
        {hasDisability?.code === "Yes" && (
          <Grid item xs={12}>
            <FormSection.Well>
              <Typography variant="body1">
                If yes, Monash will require further information from a relevant health
                professional outlining the potential impact of the applicant's condition
                on their intended study program. Disability Support Services can provide a
                range of study supports for students with disabilities. For more
                information, visit:{" "}
                <Link
                  href="https://www.monash.edu/disability"
                  target="_blank"
                  rel="noopener"
                >
                  monash.edu/disability
                </Link>
                .
              </Typography>
            </FormSection.Well>
          </Grid>
        )}
        {hasDisability?.code === "Yes" && campusLocation === CampusLocation.AUSTRALIA && (
          <Grid item xs={12}>
            <FormRadioGroup
              control={control}
              name="disabilities.existingDisabilitySupport.code"
              label={
                <>
                  Has the applicant registered for disability support in an Australian
                  tertiary institution in the last 5 years?
                </>
              }
              disabled={disabled}
              loading={existingDisabilitySupportOptions.isPending}
              options={existingDisabilitySupportOptions.data}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

DisabilitiesForm.draftSchema = z.object({
  _campusLocation: z.string().optional(),
  hasDisability: CodeStringNullableSchema,
  existingDisabilitySupport: CodeStringNullableSchema,
});

DisabilitiesForm.submitSchema = DisabilitiesForm.draftSchema.superRefine(
  ({ hasDisability, existingDisabilitySupport, _campusLocation }, ctx) => {
    const addIssue = createAddIssue(ctx);

    if (!hasDisability?.code) addIssue("Please select an answer", "hasDisability.code");
    else if (
      hasDisability.code === "Yes" &&
      _campusLocation === CampusLocation.AUSTRALIA &&
      !existingDisabilitySupport?.code
    )
      addIssue("Please select an answer", "existingDisabilitySupport.code");
  },
);

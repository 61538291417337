import { useCallback } from "react";
import { Alert } from "@mui/material";

import { useApplicantSearchResults } from "~/hooks/search";
import { useLoadResource } from "~/components/core/AppLoadingBar";
import { ApplicantsTable, SearchResultsContainer } from "~/components/dashboard";

import { FILTER_MAP } from "./DashboardFilterButtons";

export interface ApplicantNameSearchProps {
  name?: string;
  page?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
}

export function ApplicantNameSearch(props: ApplicantNameSearchProps) {
  const { name, page, setPage } = props;

  const results = useApplicantSearchResults({ name, page });
  const empty = results.data?.pageInfo.totalRecords === 0;
  const pageInfo = results.data?.pageInfo;

  useLoadResource(
    useCallback(() => results.isFetching, [results.isFetching]),
    ApplicantNameSearch.name,
  );

  return (
    <SearchResultsContainer
      title={<>Applicants{pageInfo ? ` (${pageInfo.totalRecords})` : ""}</>}
      titleBackground={FILTER_MAP.Applicants.background}
      currentPage={page}
      pageInfo={pageInfo}
      setCurrentPage={setPage}
    >
      {empty ? (
        <Alert severity="info" sx={{ m: 3 }}>
          No applicants found.
        </Alert>
      ) : (
        <ApplicantsTable loading={results.isPending} resultsPage={results.data} />
      )}
    </SearchResultsContainer>
  );
}

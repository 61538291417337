import { Outlet } from "react-router-dom";
import { EError } from "exceptional-errors";

import { AgentRole, GCPAdminPermission } from "@packages/types";

import { IAgentInfo } from "~/api";
import { AppError, AppNotFoundError } from "~/utils/errors";
import { Loading } from "~/components/core/Loading";
import { useAuthState } from "~/components/auth/AuthProvider";

export class RouteGuardRedirect extends EError<{ path: string }> {}

export interface RouteGuardProps {
  /**
   * Specify which role the user must have to pass.
   * If no role is specified, the user can pass as
   * long as they are authenticated in.
   */
  role?: AgentRole | GCPAdminPermission;

  /**
   * If provided, the function will called to check whether
   * the route can be accessed. The current user is passed as
   * the first argument to the function.
   *
   * Takes precedence over {@link role}.
   */
  guard?: (agent: IAgentInfo) => boolean;

  /**
   * Surface forbidden routes as Not Found errors.
   */
  forbiddenAsNotFound?: boolean;

  /**
   * If `true`, a loading spinner will not be shown
   * when the auth state is loading.
   */
  hideLoading?: boolean;
}

export function RouteGuard(props: RouteGuardProps) {
  const { role, guard, forbiddenAsNotFound, hideLoading } = props;

  const [user, loading] = useAuthState();

  if (loading)
    if (hideLoading) return null;
    else return <Loading />;

  if (!user) throw new RouteGuardRedirect({ info: { path: "/signin" } });

  let routeBlocked = false;

  if (guard && !guard(user)) routeBlocked = true;

  if (role && !user.agent.roles.includes(role)) routeBlocked = true;

  if (routeBlocked) {
    if (forbiddenAsNotFound) throw new AppNotFoundError();
    else
      throw new AppError("You do not have permission to view this page.", {
        code: 403,
        title: "Forbidden",
      });
  }

  return <Outlet />;
}

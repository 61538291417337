import { Controller, FieldValues, ControllerProps } from "react-hook-form";
import {
  FormControl,
  FormControlProps,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";

export interface FormCheckboxGroupProps<TFieldValues extends FieldValues = FieldValues>
  extends Pick<ControllerProps<TFieldValues>, "name" | "control">,
    FormControlProps {
  label?: React.ReactNode;
}

export function FormCheckboxGroup<TFieldValues extends FieldValues = FieldValues>(
  props: FormCheckboxGroupProps<TFieldValues>,
) {
  const { name, control, label, children, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState }) => (
        <FormControl id={name} error={fieldState.invalid} {...rest}>
          <FormLabel>{label}</FormLabel>
          <FormGroup>{children}</FormGroup>
          <FormHelperText sx={{ mt: 1, ml: 1 }}>
            {fieldState.error?.message}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}

import {
  CampusLocation,
  CodeString,
  isCampusLocation,
  PicklistValue,
  toCodeOrNull,
} from "@packages/types";

import {
  IApplicant,
  IApplicantUpdatePayload,
  IApplication,
  IApplicationCoursePreference,
  IApplicationCoursePreferenceUpdate,
  IApplicationPaymentInfoUpdate,
  IApplicationQualification,
  IApplicationQualificationUpdate,
  IApplicationUpdatePayload,
  ICourseDebugInfo,
} from "~/api";

import { AgentDeclarationFields } from "~/components/application/AgentDeclarationForm";

import { AcademicQualification } from "./AcademicQualificationsForm";
import { CoursePreference } from "./CoursePreferencesForm";
import { ApplicationFields } from "./useApplicationForm";

export type CitizenshipField =
  | "countryOfCitizenship"
  | "singleEntryVisa"
  | "visaType"
  | "immigrationOfficeIssued"
  | "visaNumber"
  | "passportNumber"
  | "visaStartDate"
  | "visaEndDate"
  | "isStudentPassAvailable"
  | "yesNoQuestions"
  | "supportingDocumentation";

export function getCitizenshipFields(options: {
  campusLocation: string;
  residencyStatus: string;
  residencySubStatus: string;
}) {
  const { campusLocation, residencyStatus, residencySubStatus } = options;
  const fields = new Set<CitizenshipField>();

  if (campusLocation === CampusLocation.AUSTRALIA) {
    fields.add("countryOfCitizenship");

    if (["INT-TEP", "INTRNTNL.A"].includes(residencySubStatus)) {
      fields.add("immigrationOfficeIssued");
      fields.add("visaType");
      fields.add("visaNumber");
      fields.add("passportNumber");
      fields.add("visaStartDate");
      fields.add("visaEndDate");
    }

    if (residencyStatus !== "DOM-HV") {
      fields.add("yesNoQuestions");
    }
  } else if (campusLocation === CampusLocation.MALAYSIA) {
    fields.add("supportingDocumentation");

    if (residencySubStatus !== "DOM-SUN.A") {
      fields.add("countryOfCitizenship");
    }

    if (residencyStatus === "INT-SUN") {
      fields.add("isStudentPassAvailable");
    }

    if (residencyStatus === "INT-SUN-P") {
      fields.add("singleEntryVisa");
      fields.add("passportNumber");
      fields.add("visaNumber");
      fields.add("visaStartDate");
      fields.add("visaEndDate");
    }

    if (["INT-SUN", "INT-SUN-P"].includes(residencyStatus)) {
      fields.add("yesNoQuestions");
    }
  }

  return fields;
}

function picklistValueToCode(value: PicklistValue | null | undefined): CodeString | null {
  if (value?.value) return { code: value.value };
  return null;
}

export function mapApplicationToForm(data: {
  applicant: IApplicant;
  application: IApplication;
  englishCourseCodes: string[];
  monashCollegeFaculties: string[];
}): ApplicationFields {
  const { englishCourseCodes, monashCollegeFaculties } = data;
  const {
    isEditable,
    title,
    firstName: legalGivenNames,
    lastName: legalFamilyNames,
    preferredName,
    hasMononymousName,
    gender,
    pronouns,
    dateOfBirth,
    address: addresses,
    phone: phones,
    email: emails,
    countryOfBirth,
    isPreviousMonashStudent,
    previousMonashId,
    previousName,
  } = data.applicant;
  const { applicant, application } = data.application;

  const {
    countryOfCitizenship,
    campusLocation,
    existingDisabilitySupport,
    hasDisability,
    passportNumber,
    qualifications,
    visa,
    workExperiences,
  } = applicant;

  const {
    applicationStatus,
    delegatorOrgUnitCode,
    lastModifiedDate,
    applicationType,
    coursePreferences,
    englishLanguageProficiency: hadEnglishInstruction,
    hasRequestForAdvancedStanding,
    hasRequestForScholarship,
    isPrincipalCourseTransfer,
    isStudentPassAvailable,
    payment,
    notes,
    // workflow,
  } = application;

  const mobile = phones.find((x) => x.type === "Mobile")?.phoneNumber ?? "";
  const phone = phones.find((x) => x.type === "Home")?.phoneNumber ?? "";
  const email = emails.find((x) => x.type === "Personal")?.email ?? "";
  const address = addresses.find((x) => x.type === "Postal");

  const {
    street = "",
    city = "",
    state = "",
    postalCode = "",
    country = null,
  } = address ?? {};

  const [residencyStatus = ""] = application.residencyStatus?.value.split(".") ?? [];
  const residencySubStatus = application.residencyStatus?.value ?? residencyStatus;

  const {
    type: visaType,
    visaNumber,
    startDate,
    endDate,
    hasBreachedVisa,
    hasConvictedOfCrime,
    hasMedicalIssues,
    hasProtectionVisa,
    hasRefusedEntry,
    immigrationOfficeIssued,
    singleEntryVisaCountry,
    sponsorScholarship,
    sponsorStatus,
  } = visa;

  const englishCourse = coursePreferences.find((x) =>
    englishCourseCodes.includes(x.course.code),
  );

  const pathwayCourse = coursePreferences.find(
    (x) =>
      applicationType.code === "Pathway" &&
      monashCollegeFaculties.includes(x.course.faculty),
  );

  // non-English, non-Pathway courses
  const otherCourses = coursePreferences.filter(
    (x) =>
      !englishCourseCodes.includes(x.course.code) &&
      // Only exclude pathway courses if the application type is pathway
      (applicationType.code !== "Pathway" ||
        !monashCollegeFaculties.includes(x.course.faculty)),
  );

  const englishTest = qualifications.find((x) => x.type?.type === "English Test");
  const nonEnglishQualifications = qualifications.filter(
    (x) => x.type?.type !== "English Test",
  );

  return {
    applicationStatus: applicationStatus ?? "Draft",
    lastModifiedDate: lastModifiedDate ?? "",
    delegatedAgency: { delegatorOrgUnitCode },

    personalDetails: {
      _isEditable: isEditable,
      title: title?.value ?? "",
      legalGivenNames,
      legalFamilyNames,
      hasMononymousName: hasMononymousName ?? false,
      preferredName,
      gender: gender?.value ?? "",
      pronouns: pronouns?.value ?? "",
      dateOfBirth,
      address: { street, city, state, postalCode, country },
      countryOfBirth,
      mobile,
      phone,
      email,
    },

    monashStudies: {
      _isEditable: isEditable,
      isPreviousMonashStudent,
      previousMonashId,
      hasChangedName: Boolean(previousName),
      previousName,
    },

    citizenship: {
      _campusLocation: campusLocation?.code ?? "",
      residencyStatus,
      residencySubStatus,
      countryOfCitizenship,
      passportNumber,
      visaType,
      visaNumber,
      visaStartDate: startDate,
      visaEndDate: endDate,
      immigrationOfficeIssued,
      singleEntryVisaCountry,
      hasRefusedEntry,
      hasBreachedVisa,
      hasMedicalIssues,
      hasProtectionVisa,
      hasConvictedOfCrime,
      isStudentPassAvailable,
    },

    englishProficiency: {
      hadEnglishInstruction,
      englishTest: {
        id: englishTest?.type?.type === "English Test" ? englishTest.type.crmId : "",
        recordId: englishTest?.recordId,
        dateAchieved: englishTest?.dateAchieved ?? "",
        expectedDateOfCompletion: englishTest?.expectedDateOfCompletion ?? "",
        scores: englishTest?.scores ?? [],
      },
      hasOtherDocumentation: Boolean(englishTest?.comments),
      otherDocumentation: englishTest?.comments ?? "",
      haveSatEnglishTest:
        Boolean(englishTest?.dateAchieved) && !Boolean(englishTest?.comments),
      willSitEnglishTest: Boolean(englishTest?.expectedDateOfCompletion),
    },

    coursePreferences: {
      // ASSUMPTION: All Pathway appications are filtered out by the first condition below.
      // Therefore, we can check for !pathwayCourse in the second condition without accidentally
      // marking a Pathway Application as a Direct Application
      applicationType:
        applicationType.code == "Pathway"
          ? "Pathway"
          : englishCourse && !pathwayCourse && otherCourses.length === 0
            ? "English"
            : "Direct",
      englishCourse: englishCourse ? mapCoursePreferenceToForm(englishCourse) : null,
      pathwayCourse: pathwayCourse ? mapCoursePreferenceToForm(pathwayCourse) : null,
      preferenceList: otherCourses.map((x) => mapCoursePreferenceToForm(x)),
    },

    academicQualifications: {
      isPrincipalCourseTransfer,
      qualifications: nonEnglishQualifications.map((x) => mapQualificationToForm(x)),
    },

    creditTransfer: {
      hasRequestForAdvancedStanding,
    },

    workExperience: workExperiences,

    scholarshipSponsorship: {
      hasRequestForScholarship,
      scholarshipSponsorshipName: sponsorScholarship,
      scholarshipSponsorshipStatus: sponsorStatus?.code ?? "",
    },

    disabilities: {
      _campusLocation: campusLocation?.code,
      existingDisabilitySupport,
      hasDisability,
    },

    documents: {},

    fees: {
      paymentMethod: { code: payment.mode },
      feeWaiverCode: payment.feeWaiverCode,
      _isPaid: payment.isPaid,
    },

    notes,
  };
}

function mapCoursePreferenceToForm({
  recordId,
  course,
  courseOffering,
  courseInfo,
}: IApplicationCoursePreference): CoursePreference {
  return {
    ...(recordId && { recordId }),
    offeringAcademicYear: String(courseOffering.offeringAcademicYear ?? ""),
    location: courseOffering.location?.code ?? "",
    courseCode: course.code,
    studyPeriod: courseOffering.studyPeriod?.code ?? "",
    stream: courseOffering.specialisation?.code ?? "",
    courseInfo,
  };
}

function mapQualificationToForm(
  qualification: IApplicationQualification,
): AcademicQualification | { type: "" } {
  switch (qualification.type?.type) {
    case "Tertiary Education":
      return {
        ...(qualification.recordId && { recordId: qualification.recordId }),
        type: "Tertiary Education",
        name: qualification.type.description,
        institution: qualification.institution,
        country: qualification.countryObtained,
        firstYearEnrolled: qualification.firstYearEnrolled,
        lastYearEnrolled: qualification.lastYearEnrolled,
        levelOfCompletion: qualification.status?.code ?? "",
      } satisfies AcademicQualification;

    case "Secondary Education":
      return {
        ...(qualification.recordId && { recordId: qualification.recordId }),
        type: "Secondary Education",
        country: qualification.countryObtained,
        state: qualification.stateObtained?.code ?? "",
        assessmentType: {
          id: qualification.type.crmId,
          description: qualification.type.description,
        },
        institutionName: qualification.institution?.institutionName ?? "",
        completed: qualification.expectedDateOfCompletion ? false : true,
        lastYearEnrolled: qualification.lastYearEnrolled,
        expectedDateOfCompletion: qualification.expectedDateOfCompletion,
      } satisfies AcademicQualification;

    case "Other Qualification":
      return {
        ...(qualification.recordId && { recordId: qualification.recordId }),
        type: "Other Qualification",
        name: qualification.type.description,
        completed: qualification.expectedDateOfCompletion ? false : true,
        dateAchieved: qualification.dateAchieved,
        expectedDateOfCompletion: qualification.expectedDateOfCompletion,
      } satisfies AcademicQualification;

    default:
      return { type: "" };
  }
}

export function createApplicationUpdatePayload(
  options: {
    applicantId: string;
    applicationId: string;
  },
  data: ApplicationFields,
  declaration?: AgentDeclarationFields,
): {
  applicant: IApplicantUpdatePayload;
  application: IApplicationUpdatePayload;
} {
  const { applicantId, applicationId } = options;
  const {
    applicationStatus,
    delegatedAgency,
    personalDetails,
    monashStudies,
    citizenship,
    academicQualifications,
    coursePreferences,
    creditTransfer,
    disabilities,
    englishProficiency,
    scholarshipSponsorship,
    workExperience,
    fees,
    notes,
  } = data;

  const {
    _isEditable: isEditable,
    title,
    address,
    countryOfBirth,
    dateOfBirth,
    email,
    gender,
    legalFamilyNames: legalLastName,
    legalGivenNames,
    mobile,
    phone,
    preferredName,
    pronouns,
  } = personalDetails;

  const { isPreviousMonashStudent, previousMonashId, previousName } = monashStudies;

  const {
    _campusLocation,
    countryOfCitizenship,
    hasBreachedVisa,
    hasConvictedOfCrime,
    hasMedicalIssues,
    hasProtectionVisa,
    hasRefusedEntry,
    immigrationOfficeIssued,
    isStudentPassAvailable,
    passportNumber,
    residencyStatus,
    residencySubStatus,
    singleEntryVisaCountry,
    visaStartDate,
    visaEndDate,
    visaNumber,
    visaType,
  } = citizenship;

  const citizenshipFields = getCitizenshipFields({
    campusLocation: isCampusLocation(_campusLocation)
      ? _campusLocation
      : CampusLocation.AUSTRALIA,
    residencyStatus,
    residencySubStatus,
  });

  /** Check if the provided field should be sent in the payload. */
  const field = (field: CitizenshipField) => citizenshipFields.has(field);

  // Handle MUM Malaysian citizen applicant edge case (see ECA-5372, SFTG-3696).
  // If the applicant is a Malaysian citizen, and is applying to Monash Malaysia, My.App will add their citizenship country automatically in CRM during applicant review.
  // However, when the agent subsequently submits the application, we need to ensure that we pass this updated country value through in the update payload.
  const resolvedCountryofCitizenship =
    field("countryOfCitizenship") ||
    (residencySubStatus === "DOM-SUN.A" && countryOfCitizenship?.isoCode2 === "MY")
      ? countryOfCitizenship
      : null;

  const {
    hasRequestForScholarship,
    scholarshipSponsorshipName,
    scholarshipSponsorshipStatus,
  } = scholarshipSponsorship;

  const { hasDisability, existingDisabilitySupport } = disabilities;

  const {
    hadEnglishInstruction: englishLanguageProficiency,
    englishTest,
    willSitEnglishTest,
    haveSatEnglishTest,
    hasOtherDocumentation,
    otherDocumentation,
  } = englishProficiency;

  const { hasRequestForAdvancedStanding } = creditTransfer;

  const { isPrincipalCourseTransfer, qualifications } = academicQualifications;

  const { applicationType, englishCourse, pathwayCourse, preferenceList } =
    coursePreferences;

  const { paymentMethod, feeWaiverCode } = fees;

  const allQualifications = qualifications.map<IApplicationQualificationUpdate>(
    (qualification) => {
      switch (qualification.type) {
        case "Secondary Education": {
          const {
            assessmentType,
            country,
            institutionName,
            completed,
            lastYearEnrolled,
            expectedDateOfCompletion,
            state,
            type,
          } = qualification;
          return {
            ...(qualification.recordId && { recordId: qualification.recordId }),
            type: { type, crmId: assessmentType?.id ?? "" },
            countryObtained: country,
            ...(state && { stateObtained: { code: state } }),
            ...(completed && { lastYearEnrolled }),
            ...(!completed && { expectedDateOfCompletion }),
            institution: { institutionName },
          } satisfies IApplicationQualificationUpdate;
        }

        case "Tertiary Education": {
          const {
            type,
            country,
            institution,
            firstYearEnrolled,
            lastYearEnrolled,
            levelOfCompletion,
            name,
          } = qualification;
          return {
            ...(qualification.recordId && { recordId: qualification.recordId }),
            type: { type, description: name },
            countryObtained: country,
            ...(firstYearEnrolled && { firstYearEnrolled }),
            ...(lastYearEnrolled && { lastYearEnrolled }),
            institution: {
              id: institution?.id,
              institutionName: institution?.institutionName ?? "",
            },
            status: { code: levelOfCompletion },
          } satisfies IApplicationQualificationUpdate;
        }

        case "Other Qualification":
          const { name, type, completed, dateAchieved, expectedDateOfCompletion } =
            qualification;
          return {
            ...(qualification.recordId && { recordId: qualification.recordId }),
            type: { type, description: name },
            ...(completed && { dateAchieved }),
            ...(!completed && { expectedDateOfCompletion }),
          } satisfies IApplicationQualificationUpdate;

        default:
          return {
            type: { type: "Other Qualification", description: "" },
          } satisfies IApplicationQualificationUpdate;
      }
    },
  );

  if (
    englishTest.id &&
    (willSitEnglishTest || haveSatEnglishTest || hasOtherDocumentation)
  ) {
    const { recordId, id, dateAchieved, expectedDateOfCompletion, scores } = englishTest;
    const score = scores.find((x) => !x.name);
    allQualifications.unshift({
      ...(recordId && { recordId }),
      type: { type: "English Test", crmId: id },
      ...((haveSatEnglishTest || hasOtherDocumentation) &&
        dateAchieved && { dateAchieved }),
      ...(willSitEnglishTest && expectedDateOfCompletion && { expectedDateOfCompletion }),
      ...(haveSatEnglishTest && score?.value && { score: score.value }),
      ...(haveSatEnglishTest &&
        scores && {
          scores: scores.filter((x): x is { name: string; value: string } =>
            Boolean(x.name),
          ),
        }),
      ...(hasOtherDocumentation && { qualificationComments: otherDocumentation }),
    });
  }

  const allPreferences: IApplicationCoursePreferenceUpdate[] = [];

  let preferenceNumber = 1;

  if (englishCourse) {
    const offering = findCourseOffering(englishCourse);
    if (offering)
      allPreferences.push({
        ...(englishCourse.recordId && { recordId: englishCourse.recordId }),
        courseOffering: { id: offering.id },
        coursePreference: preferenceNumber++,
        _debug: offering._debug,
      });
  }

  if (pathwayCourse) {
    const offering = findCourseOffering(pathwayCourse);
    if (offering)
      allPreferences.push({
        ...(pathwayCourse.recordId && { recordId: pathwayCourse.recordId }),
        courseOffering: { id: offering.id },
        coursePreference: preferenceNumber++,
        _debug: offering._debug,
      });
  }

  preferenceList.forEach((preference) => {
    const offering = findCourseOffering(preference);
    if (offering)
      allPreferences.push({
        ...(preference.recordId && { recordId: preference.recordId }),
        courseOffering: { id: offering.id },
        coursePreference: preferenceNumber++,
        _debug: offering._debug,
      });
  });

  const payment: IApplicationPaymentInfoUpdate = {
    mode: paymentMethod?.code ?? "",
    feeWaiverCode,
  };

  return {
    applicant: isEditable
      ? {
          firstName: legalGivenNames,
          lastName: legalLastName,
          dateOfBirth,
          phone: [
            { type: "Mobile", phoneNumber: mobile },
            { type: "Home", phoneNumber: phone },
          ],
          email: [{ type: "Personal", email: email }],
          title: { value: title, label: "" },
          preferredName,
          gender: { value: gender, label: "" },
          pronouns: { value: pronouns, label: "" },
          address: [{ type: "Postal", ...address }],
          countryOfBirth,
          isPreviousMonashStudent: isPreviousMonashStudent ?? false,
          previousMonashId,
          previousName,
        }
      : // If isEditable is `false`, we can only update the pronouns
        {
          pronouns: { value: pronouns, label: "" },
        },
    application: {
      applicant: {
        countryOfCitizenship: resolvedCountryofCitizenship,
        visa: {
          type: field("visaType") ? picklistValueToCode(visaType) : null,
          visaNumber: field("visaNumber") ? visaNumber : "",
          startDate: field("visaStartDate") ? visaStartDate : "",
          endDate: field("visaEndDate") ? visaEndDate : "",
          singleEntryVisaCountry: field("singleEntryVisa")
            ? singleEntryVisaCountry
            : null,
          immigrationOfficeIssued: field("immigrationOfficeIssued")
            ? immigrationOfficeIssued
            : "",
          hasRefusedEntry: field("yesNoQuestions") ? toCodeOrNull(hasRefusedEntry) : null,
          hasBreachedVisa: field("yesNoQuestions") ? toCodeOrNull(hasBreachedVisa) : null,
          hasMedicalIssues: field("yesNoQuestions")
            ? toCodeOrNull(hasMedicalIssues)
            : null,
          hasProtectionVisa: field("yesNoQuestions")
            ? toCodeOrNull(hasProtectionVisa)
            : null,
          hasConvictedOfCrime: field("yesNoQuestions")
            ? toCodeOrNull(hasConvictedOfCrime)
            : null,
          sponsorScholarship: hasRequestForScholarship ? scholarshipSponsorshipName : "",
          sponsorStatus: hasRequestForScholarship
            ? { code: scholarshipSponsorshipStatus }
            : null,
        },
        passportNumber: field("passportNumber") ? passportNumber : "",
        hasDisability: toCodeOrNull(hasDisability),
        existingDisabilitySupport:
          hasDisability?.code === "Yes" ? toCodeOrNull(existingDisabilitySupport) : null,
        qualifications: allQualifications,
        workExperiences: workExperience.map(({ recordId, ...workExperience }) => ({
          ...(recordId && { recordId }),
          ...workExperience,
        })),
      },
      application: {
        applicantId,
        applicationId,
        applicationStatus,
        applicationType: { code: applicationType === "Pathway" ? "Pathway" : "Default" },
        residencyStatus: { code: residencySubStatus || residencyStatus },
        englishLanguageProficiency: toCodeOrNull(englishLanguageProficiency),
        isStudentPassAvailable: field("isStudentPassAvailable")
          ? toCodeOrNull(isStudentPassAvailable)
          : null,
        hasRequestForAdvancedStanding,
        hasRequestForScholarship,
        isPrincipalCourseTransfer,
        coursePreferences: allPreferences,
        payment,
        notes,
        delegatorOrgUnitCode: delegatedAgency.delegatorOrgUnitCode,
      },
      ...(declaration && { declaration: declaration.checklist }),
    },
  };
}

function findCourseOffering(coursePreference: CoursePreference) {
  const { courseInfo, offeringAcademicYear, location, studyPeriod, stream } =
    coursePreference;
  if (!courseInfo) return null;
  const courseOffering = courseInfo.courseOfferings.find(
    (offering) =>
      offering.location.code === location &&
      offering.calendarDetail.find((x) => x.code === studyPeriod) &&
      offering.unitSets.find((x) => x.code === stream),
  );
  if (!courseOffering) return null;
  return {
    id: courseOffering.id,
    _debug: {
      courseCode: courseInfo.code,
      courseTitle: courseInfo.title,
      courseOffering: {
        id: courseOffering.id,
        offeringAcademicYear,
        location: courseOffering.location,
        calendarDetail: courseOffering.calendarDetail.find((x) => x.code === studyPeriod),
        unitSet: courseOffering.unitSets.find((x) => x.code === stream),
      },
    } satisfies ICourseDebugInfo,
  };
}

import { Stack, Typography } from "@mui/material";

export interface NotificationMessageProps {
  /** The main message to show in the notification. */
  message: React.ReactNode;
  /** Optional title to show above the notification message. */
  title?: React.ReactNode;
  /** Optional subtitle text to show below the notification message. */
  subtitle?: React.ReactNode;
  /** Where the message is being displayed (helps to set some styling defaults). Defaults to "default". */
  context?: "default" | "dialog";
}

export function NotificationMessage(props: NotificationMessageProps) {
  const { title, message, context = "default", subtitle } = props;
  const isDialog = context === "dialog";
  return (
    <Stack pr={isDialog ? 0 : 1} width={isDialog ? "100%" : undefined}>
      {title && (
        <Typography
          mb={isDialog ? 1 : 0.5}
          fontWeight={isDialog ? undefined : "medium"}
          variant={isDialog ? "h6" : "body1"}
          variantMapping={{ h6: "h2" }}
        >
          {title}
        </Typography>
      )}
      {typeof message === "string" ? (
        <Typography
          component="pre"
          variant={isDialog ? "body1" : "body2"}
          sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
        >
          {message}
        </Typography>
      ) : (
        message
      )}
      {subtitle &&
        (typeof subtitle === "string" ? (
          <Typography variant="caption" component="p" mt={1} sx={{ opacity: 0.7 }}>
            {subtitle}
          </Typography>
        ) : (
          subtitle
        ))}
    </Stack>
  );
}

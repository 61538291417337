import { useQueryParam } from "use-query-params";

export function usePage(options: { offset?: number; defaultValue?: number } = {}) {
  const { defaultValue = 0, offset = 0 } = options;
  return useQueryParam<number>("page", {
    default: defaultValue,
    encode: (value) => (value + offset).toString(),
    decode: (value) =>
      // The pipe (||) defaults any NaN values to the default value
      typeof value === "string" ? parseInt(value) - offset || defaultValue : defaultValue,
  });
}

export function usePageSize(options: { defaultValue: number }) {
  const { defaultValue } = options;
  return useQueryParam<number>("pageSize", {
    default: defaultValue,
    encode: (value) => value.toString(),
    decode: (value) =>
      // The pipe (||) defaults any NaN values to the default value
      typeof value === "string" ? parseInt(value) || defaultValue : defaultValue,
  });
}

import { z } from "zod";
import { Grid } from "@mui/material";
import { Control, useWatch } from "react-hook-form";

import { SafeNameString, SafeNumberString } from "@packages/types";

import { createAddIssue } from "~/utils";
import { FormTextField } from "~/components/form/FormTextField";
import { FormYesNo } from "~/components/form/FormYesNo";

import { ApplicationFields } from "./useApplicationForm";

export interface MonashStudiesFormProps {
  control: Control<ApplicationFields>;
  disabled?: boolean;
}

export function MonashStudiesForm(props: MonashStudiesFormProps) {
  const { control, disabled } = props;

  const [isEditable, isPreviousMonashStudent, hasChangedName] = useWatch({
    control,
    name: [
      "personalDetails._isEditable",
      "monashStudies.isPreviousMonashStudent",
      "monashStudies.hasChangedName",
    ],
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormYesNo
            control={control}
            name="monashStudies.isPreviousMonashStudent"
            label={
              <>
                Is the applicant a current student, or has previously been a student, or
                previously applied to Monash University, Monash College, or a previous
                institution that is now part of Monash University?
              </>
            }
            disabled={disabled || !isEditable}
          />
        </Grid>
        {isPreviousMonashStudent && (
          <Grid item xs={12}>
            <FormTextField
              sx={{ minWidth: 400 }}
              control={control}
              name="monashStudies.previousMonashId"
              label="Student ID"
              disabled={disabled || !isEditable}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormYesNo
            control={control}
            name="monashStudies.hasChangedName"
            label={
              <>
                Has the applicant changed their name at any time during or after their
                secondary studies, or since they last applied to / studied at Monash
                University?
              </>
            }
            disabled={disabled || !isEditable}
          />
        </Grid>
        {hasChangedName && (
          <Grid item xs={12}>
            <FormTextField
              sx={{ minWidth: 400 }}
              control={control}
              name="monashStudies.previousName"
              label="Previous name"
              disabled={disabled || !isEditable}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

MonashStudiesForm.draftSchema = z.object({
  _isEditable: z.boolean(),
  isPreviousMonashStudent: z.boolean().nullable(),
  previousMonashId: SafeNumberString.schema(),
  hasChangedName: z.boolean().nullable(),
  previousName: SafeNameString().schema(),
});

MonashStudiesForm.submitSchema = MonashStudiesForm.draftSchema.superRefine(
  (
    {
      _isEditable,
      hasChangedName,
      isPreviousMonashStudent,
      previousMonashId,
      previousName,
    },
    ctx,
  ) => {
    const addIssue = createAddIssue(ctx);

    if (!_isEditable) return;

    if (typeof isPreviousMonashStudent !== "boolean")
      addIssue("Please select Yes or No", "isPreviousMonashStudent");

    if (isPreviousMonashStudent)
      if (!previousMonashId) addIssue("Please enter a student ID", "previousMonashId");

    if (typeof hasChangedName !== "boolean")
      addIssue("Please select Yes or No", "hasChangedName");

    if (hasChangedName && !previousName)
      addIssue("Please provide a previous name", "previousName");
  },
);

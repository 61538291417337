import { Grid, MenuItem } from "@mui/material";
import { Control, useWatch } from "react-hook-form";
import { z } from "zod";

import { SafeStringForProse } from "@packages/types";

import { createAddIssue } from "~/utils";
import { FormSelect } from "~/components/form/FormSelect";
import { FormTextField } from "~/components/form/FormTextField";
import { FormYesNo } from "~/components/form/FormYesNo";
import { useReferenceData } from "~/hooks/reference-data";

import { ApplicationFields } from "./useApplicationForm";

export interface ScholarshipSponsorshipFormProps {
  control: Control<ApplicationFields>;
  disabled?: boolean;
}

export function ScholarshipSponsorshipForm(props: ScholarshipSponsorshipFormProps) {
  const { control, disabled } = props;

  const hasRequestForScholarshipSponsorship = useWatch({
    control,
    name: "scholarshipSponsorship.hasRequestForScholarship",
  });

  const statuses = useReferenceData("ScholarshipSponsorshipStatuses");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormYesNo
          control={control}
          name="scholarshipSponsorship.hasRequestForScholarship"
          label={
            <>
              Has the applicant applied for a scholarship or sponsorship from an
              organisation external to Monash University to support their studies?
            </>
          }
          disabled={disabled}
        />
      </Grid>
      {hasRequestForScholarshipSponsorship && (
        <>
          <Grid item xs={12} lg={9}>
            <FormTextField
              control={control}
              name="scholarshipSponsorship.scholarshipSponsorshipName"
              label="Please state the name of the external scholarship or sponsorship"
              disabled={disabled}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} lg={9}>
            <FormSelect
              control={control}
              name="scholarshipSponsorship.scholarshipSponsorshipStatus"
              label="What is the status of the external scholarship or sponorship application?"
              loading={statuses.isPending}
              disabled={disabled}
            >
              {statuses?.data?.map?.(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {label}
                </MenuItem>
              ))}
            </FormSelect>
          </Grid>
        </>
      )}
    </Grid>
  );
}

ScholarshipSponsorshipForm.draftSchema = z.object({
  hasRequestForScholarship: z.boolean().nullable(),
  scholarshipSponsorshipName: SafeStringForProse.schema(),
  scholarshipSponsorshipStatus: z.string(),
});

ScholarshipSponsorshipForm.submitSchema =
  ScholarshipSponsorshipForm.draftSchema.superRefine(
    (
      {
        hasRequestForScholarship,
        scholarshipSponsorshipName,
        scholarshipSponsorshipStatus,
      },
      ctx,
    ) => {
      const addIssue = createAddIssue(ctx);

      if (hasRequestForScholarship) {
        if (!scholarshipSponsorshipName)
          addIssue(
            "Please provide a scholarship or sponsorship name",
            "scholarshipSponsorshipName",
          );
        if (!scholarshipSponsorshipStatus)
          addIssue("Please select a status", "scholarshipSponsorshipStatus");
      }
    },
  );

import { Container, Stack } from "@mui/material";
import { useSetAtom } from "jotai";
import { useEffect } from "react";

import { joinName } from "@packages/utils";

import { useApplicantRouteData } from "~/hooks/route-data";
import { useDocumentTitle } from "~/hooks/title";
import { useLastDashboardPath } from "~/pages/root/DashboardPage";
import { Timestamp } from "~/components/core/Timestamp";
import { BackLink } from "~/components/core/BackLink";
import { ApplicantTitleCard } from "~/components/applicant/ApplicantTitleCard";
import { ApplicantDetailsCard } from "~/components/applicant/ApplicantDetailsCard";
import { ApplicantApplicationsCard } from "~/components/applicant/ApplicantApplicationsCard";
import { DeveloperJSONInspector } from "~/components/admin/DeveloperJSONInspector";
import { enquiryApplicantAtom } from "~/components/enquiry/NewEnquiryModal";

/**
 * This is the view seen by Agents when clicking a submitted application.
 */
export function ApplicantProfilePage() {
  const data = useApplicantRouteData();
  if (!data) throw new Error("Applicant data not available");

  const { applicant, applications } = data;
  const {
    applicantId,
    studentId,
    firstName,
    lastName,
    hasMononymousName,
    preferredName,
    dateOfBirth,
    email,
    phone,
    address,
    gender,
    title,
    pronouns,
  } = applicant;

  const personalEmail = email.find((x) => x.type === "Personal");
  const mobilePhone = phone.find((x) => x.type === "Mobile");
  const homePhone = phone.find((x) => x.type === "Home");
  const postalAddress = address.find((x) => x.type === "Postal");

  const dashboardPath = useLastDashboardPath();

  const applicantName = joinName(firstName, lastName, preferredName);

  useDocumentTitle(applicantName);

  const setEnquiryApplicant = useSetAtom(enquiryApplicantAtom);

  useEffect(() => {
    setEnquiryApplicant({
      name: applicantName,
      email: personalEmail?.email ?? "",
      studentId,
    });
  }, [setEnquiryApplicant, applicantName, personalEmail, studentId]);

  return (
    <Container sx={{ my: 2 }}>
      <BackLink mb={1.5} defaultTo={dashboardPath}>
        Back
      </BackLink>
      <Stack gap={3} mb={12}>
        <ApplicantTitleCard
          firstName={firstName}
          lastName={lastName}
          preferredName={preferredName}
        />
        <ApplicantDetailsCard
          personalDetails={[
            { label: "Title", value: title?.label },
            ...(hasMononymousName
              ? [{ label: "Name", value: lastName }]
              : [
                  { label: "Given Names", value: firstName },
                  { label: "Family Names", value: lastName },
                ]),
            { label: "Preferred Name", value: preferredName },
            {
              label: "Date Of Birth",
              value: <Timestamp format="d/MM/yyyy">{dateOfBirth}</Timestamp>,
            },
            { label: "Gender", value: gender?.label },
            { label: "Pronouns", value: pronouns?.label },
          ]}
          contactDetails={[
            { label: "Email", value: personalEmail?.email },
            { label: "Mobile", value: mobilePhone?.phoneNumber },
            { label: "Home", value: homePhone?.phoneNumber },
          ]}
          addressDetails={[
            { label: "Street", value: postalAddress?.street },
            { label: "Town / City", value: postalAddress?.city },
            { label: "Region / State", value: postalAddress?.state },
            { label: "Country", value: postalAddress?.country?.isoCountryName },
            { label: "Postcode / Zipcode", value: postalAddress?.postalCode },
          ]}
          // expand the accordion by default if there are 2 applications or less, so the page doesn't look empty
          defaultExpanded={applications.results.length <= 2}
        />
        <ApplicantApplicationsCard
          applicantId={applicantId}
          applications={applications.results}
        />
      </Stack>
      <DeveloperJSONInspector
        id="applicant"
        title="Applicant inspector"
        collapsed={false}
        data={() => applicant}
      />
      <DeveloperJSONInspector
        id="application-list"
        title="Application list inspector"
        collapsed={false}
        data={() => applications}
      />
    </Container>
  );
}

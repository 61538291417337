import { z } from "zod";

export const PHONE_STRING_REGEX = /^(\+\d{1,3})?\d{5,}$/;

export function isPhoneString(value: string) {
  return PHONE_STRING_REGEX.test(value.replace(/[\s()-]/g, ""));
}

/**
 * Validates that a string is a valid phone number with
 * an optional country code and at least 5 digits.
 */
export function PhoneStringSchema(
  message: string = "Please provide a valid phone number",
) {
  return z.string().trim().refine(isPhoneString, message);
}

PhoneStringSchema.allowBlank = (message?: string) =>
  z.union([z.literal(""), PhoneStringSchema(message)]);

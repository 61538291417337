import { z } from "zod";
import { Grid, Typography } from "@mui/material";
import { Control, useWatch } from "react-hook-form";

import { FormSection } from "~/components/form/FormSection";
import { FormYesNo } from "~/components/form/FormYesNo";

import { ApplicationFields } from "./useApplicationForm";

export interface CreditTransferFormProps {
  control: Control<ApplicationFields>;
  disabled?: boolean;
}

export function CreditTransferForm(props: CreditTransferFormProps) {
  const { control, disabled } = props;

  const hasRequestForAdvancedStanding = useWatch({
    control,
    name: "creditTransfer.hasRequestForAdvancedStanding",
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormYesNo
            control={control}
            name="creditTransfer.hasRequestForAdvancedStanding"
            label="Does the applicant wish to apply for credit transfer from their previous course?"
            disabled={disabled}
          />
        </Grid>
        {hasRequestForAdvancedStanding && (
          <>
            <Grid item xs={12}>
              <FormSection.Well>
                <Typography variant="body1" paragraph>
                  Submit detailed course syllabus/unit outlines/curriculum for the units
                  the application is seeking credit for.
                </Typography>
                <Typography variant="body1" paragraph>
                  Monash University will use the information submitted in this application
                  to assess course credit.
                </Typography>
                <Typography variant="body1">
                  Further information may be requested from the applicant if their
                  previous course/institution has not been previously assessed by Monash
                  University for evaluation assessment.
                </Typography>
              </FormSection.Well>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

CreditTransferForm.draftSchema = z.object({
  hasRequestForAdvancedStanding: z.boolean(),
});

CreditTransferForm.submitSchema = CreditTransferForm.draftSchema;

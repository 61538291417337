export type HttpMethod = "get" | "post" | "patch" | "put" | "delete";

export type HttpHeaders = Record<string, string>;

export type HttpParams = Record<
  string,
  string | number | boolean | string[] | number[] | boolean[] | undefined
>;

/**
 * Serialize URL params without encoding them (which is the behaviour of {@link URLSearchParams}).
 */
export const serializeParams = (params: HttpParams = {}) =>
  Object.entries(params)
    .filter(([, value]) => typeof value !== "undefined")
    .map(([key, value]) =>
      Array.isArray(value)
        ? value.map((value) => `${key}=${value}`)
        : [`${key}=${value}`],
    )
    .flat()
    .join("&");

/**
 * Build a URL by joining multiple string segments.
 *
 * The last argument can a params object that will be serialised
 * and appended to the URL as a query string if it is not empty.
 */
export const buildUrl = (...args: [...string[], HttpParams | string]) => {
  const isLastArgParams = typeof args.at(-1) !== "string";
  const params = isLastArgParams ? (args.at(-1) as HttpParams) : {};
  const segments = (isLastArgParams ? args.slice(0, -1) : args) as string[];
  const paramsString = serializeParams(params);
  return segments.join("") + (paramsString ? `?${paramsString}` : "");
};

/**
 * An enumeration of common `Content-Type` header values.
 */
export enum HttpContentType {
  json = "application/json",
  formUrlEncoded = "application/x-www-form-urlencoded",
}

/**
 * Create a header object with some shortcuts for well-known headers.
 */
export const createHeaders = (
  options: {
    /** Adds `Authorization` header with the given token as a Bearer token. */
    bearerToken?: string | undefined;
    /** Sets the `Content-Type` header. */
    contentType?: HttpContentType | undefined;
  } & Record<string, string>,
): Record<string, string> => {
  const { bearerToken, contentType, ...headers } = options;
  if (bearerToken) headers["Authorization"] = `Bearer ${bearerToken}`;
  if (contentType) headers["Content-Type"] = contentType;
  return headers;
};

import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

export type DeveloperToolKey =
  | "query"
  | "idle"
  | "applicant"
  | "application"
  | "application-list"
  | "form"
  | "application-receipt-download"
  | "application-receipt-enqueue"
  | "prefill-new-application"
  | "prefill-edit-application";

export const developerToolAtom = atomFamily(
  (state: { key: DeveloperToolKey; enabled?: boolean; open?: boolean }) => atom(state),
  (a, b) => a.key === b.key,
);

export const developerToolActionAtom = atomFamily(
  (state: { key: DeveloperToolKey; action?: () => void }) => atom(state),
  (a, b) => a.key === b.key,
);

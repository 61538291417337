import { responsiveFontSizes, createTheme, ThemeOptions } from "@mui/material";

import { MONASH_PALETTE } from "@packages/utils";

import "./styles.scss";

const baseThemeConfig: ThemeOptions = {
  palette: {
    background: {
      default: MONASH_PALETTE.PORTAL_BACKGROUND,
    },
    primary: {
      main: MONASH_PALETTE.MONASH_BLUE,
    },
    secondary: {
      main: MONASH_PALETTE.MONASH_MEDIUM_GREY,
    },
    error: {
      main: MONASH_PALETTE.ERROR,
    },
    success: {
      main: MONASH_PALETTE.SUCCESS,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "0.8rem",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "filled",
      },
    },
  },
};

export const defaultTheme = responsiveFontSizes(createTheme(baseThemeConfig));

import { Alert, AlertTitle, Button, Container } from "@mui/material";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { UseFormReturn } from "react-hook-form";

import { joinName } from "@packages/utils";
import { isSubmittedApplicationStatus } from "@packages/types";

import { AppNotFoundError } from "~/utils";
import { useDocumentTitle } from "~/hooks/title";
import { ApplicationApi, GetApi, IApplicant, IApplication } from "~/api";
import { useApplicationRouteData } from "~/hooks/route-data";
import { useDeveloperTool } from "~/components/admin/DeveloperMenu";
import {
  AgentDeclarationFields,
  AgentDeclarationForm,
  useAgentDeclarationForm,
} from "~/components/application/AgentDeclarationForm";
import { useAuthState } from "~/components/auth/AuthProvider";
import { BackLink } from "~/components/core/BackLink";
import { useLoadResource } from "~/components/core/AppLoadingBar";
import { FormSection } from "~/components/form/FormSection";
import { DeveloperJSONInspector } from "~/components/admin/DeveloperJSONInspector";

export function AgentDeclarationPage() {
  const [user] = useAuthState();
  const { applicant, application, englishCourseCodes, monashCollegeFaculties } =
    useApplicationRouteData();
  const { applicationId, applicationStatus } = application.application;
  const { applicantId } = applicant;

  const [submitted, setSubmitted] = useState(
    // Set the default state based on the current application status
    applicationStatus ? isSubmittedApplicationStatus(applicationStatus) : false,
  );

  useDocumentTitle(
    `${applicationId} - ${joinName(
      applicant.firstName,
      applicant.lastName,
      applicant.preferredName,
    )}`,
  );

  const form = useAgentDeclarationForm({
    applicant,
    application,
    englishCourseCodes,
    monashCollegeFaculties,
    user,
  });

  let content: null | React.ReactNode = null;
  let backToDashboard = false;

  // If there is a delegated agency, the application will be transferred and the
  // applicant no longer visible - so redirect the user to the dashboard instead
  const backToDashboardAfterSubmit = application.application.delegatorOrgUnitCode !== "";

  if (submitted) {
    backToDashboard = backToDashboardAfterSubmit;
    content = (
      <FormSection>
        <FormSection.Title>Partner Declaration</FormSection.Title>
        <Alert severity="success">
          <AlertTitle>Application submitted</AlertTitle>
          This application has been submitted to Monash University.
        </Alert>
        {backToDashboard ? (
          <Button variant="outlined" component={Link} to="/">
            Back to dashboard
          </Button>
        ) : (
          <Button variant="outlined" component={Link} to={`/applicants/${applicantId}`}>
            Back to applicant
          </Button>
        )}
      </FormSection>
    );
  } else if (applicationStatus === "Sent Draft Application to Applicant")
    content = (
      <FormSection>
        <FormSection.Title>Partner Declaration</FormSection.Title>
        <Alert severity="warning">
          <AlertTitle>Applicant Declaration required</AlertTitle>
          The applicant must accept the Applicant Declaration before this application can
          be submitted.
        </Alert>
        <Button variant="outlined" component={Link} to={`/applicants/${applicantId}`}>
          Back to applicant
        </Button>
      </FormSection>
    );
  else if (applicationStatus === "Applicant Declaration Accepted") {
    if (submitted) backToDashboard = backToDashboardAfterSubmit;
    content = (
      <AgentDeclarationForm
        form={form}
        disabled={form.loading}
        campusLocation={application.applicant.campusLocation?.code}
        onSuccessfulSubmit={() => setSubmitted(true)}
      />
    );
  }

  // If none of the relevant statuses are applicable, show a not found page
  if (!content) throw new AppNotFoundError();

  return (
    <Container maxWidth="md" sx={{ mt: 2, mb: 8 }}>
      <BackLink
        mb={1.5}
        defaultTo={
          backToDashboard ? "/" : `/applications/${application.application.applicationId}`
        }
        reloadDocument
      >
        Back
      </BackLink>
      {content}
      <AgentDeclarationDeveloperTools
        applicationId={applicationId}
        applicant={applicant}
        application={application}
        form={form.form}
      />
    </Container>
  );
}

function AgentDeclarationDeveloperTools(options: {
  applicationId: string;
  form: UseFormReturn<AgentDeclarationFields>;
  applicant: IApplicant;
  application: IApplication;
}) {
  const { applicationId, form, applicant, application } = options;

  const [loadReceipt, unloadReceipt] = useLoadResource("application-receipt");

  useDeveloperTool({
    key: "application-receipt-enqueue",
    enable: true,
    action: useCallback(async () => {
      loadReceipt();
      try {
        await GetApi(ApplicationApi).enqueueApplicationReceiptGenerationTask(
          applicationId,
          form.getValues("checklist"),
        );
      } finally {
        unloadReceipt();
      }
    }, [loadReceipt, applicationId, unloadReceipt]),
  });

  return (
    <>
      <DeveloperJSONInspector
        id="form"
        title="Form inspector"
        collapsed={false}
        data={() => form.getValues()}
      />
      <DeveloperJSONInspector
        id="applicant"
        title="Applicant inspector"
        collapsed={false}
        data={() => applicant}
      />
      <DeveloperJSONInspector
        id="application"
        title="Application inspector"
        collapsed={false}
        data={() => application}
      />
    </>
  );
}

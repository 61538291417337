import { Link } from "react-router-dom";
import { Badge, IconButton } from "@mui/material";

import PersonIcon from "@mui/icons-material/Person";

import { useAuthState } from "~/components/auth/AuthProvider";

export function ImpersonationButton() {
  const [user] = useAuthState();

  if (!user?.impersonator) return null;

  return (
    <IconButton component={Link} to="/impersonate">
      <Badge variant="dot" color="info">
        <PersonIcon />
      </Badge>
    </IconButton>
  );
}

import { Box } from "@mui/material";

export function ThroughLine() {
  return (
    <Box sx={{ position: "absolute", inset: 0, pl: 3, mt: 3 }}>
      <Box
        sx={{
          height: (theme) => `calc(100% - ${theme.spacing(3)})`,
          display: { xs: "none", md: "block" },
          borderLeft: "3px solid",
          borderColor: "lightgray",
        }}
      />
    </Box>
  );
}

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { IErrorResponse, IRefreshSessionResponse } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class OAuth2Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags OAuth2 API
   * @name Authenticate
   * @summary Redirect to the authentication URL
   * @request GET:/api/auth/oauth2/authenticate
   * @response `302` `void`
   */
  authenticate = (params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/api/auth/oauth2/authenticate`,
      method: "GET",
      ...params,
    });
  /**
   * @description Handle the authorization code for the authorization code flow.
   *
   * @tags OAuth2 API
   * @name GetAuthorizationCode
   * @summary OAuth2 sign in redirect URL
   * @request GET:/api/auth/oauth2/callback
   * @response `302` `void`
   */
  getAuthorizationCode = (params: RequestParams = {}) =>
    this.request<any, void>({
      path: `/api/auth/oauth2/callback`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags OAuth2 API
   * @name RefreshSession
   * @summary Refresh the current session
   * @request POST:/api/auth/oauth2/refresh-session
   * @response `200` `IRefreshSessionResponse`
   * @response `401` `IErrorResponse` Not signed in.
   */
  refreshSession = (params: RequestParams = {}) =>
    this.request<IRefreshSessionResponse, IErrorResponse>({
      path: `/api/auth/oauth2/refresh-session`,
      method: "POST",
      format: "json",
      ...params,
    });
}

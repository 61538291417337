import { Stack, Paper, Typography } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";

import { joinName } from "@packages/utils";

export interface ApplicantTitleCardProps {
  firstName?: string;
  lastName?: string;
  preferredName?: string;
}

export function ApplicantTitleCard(props: ApplicantTitleCardProps) {
  const { firstName, lastName, preferredName } = props;
  return (
    <Paper
      sx={{
        display: "flex",
        p: 2,
        gap: 2,
        borderRadius: 2,
        alignItems: "center",
        justifyContent: { xs: "center", sm: "left" },
      }}
    >
      <SchoolIcon fontSize="large" sx={{ display: { xs: "none", sm: "flex" } }} />
      <Stack textAlign={{ xs: "center", sm: "left" }}>
        <Typography variant="subtitle2">Applicant Profile</Typography>
        <Typography variant="h4">
          {joinName(firstName, lastName, preferredName)}
        </Typography>
      </Stack>
    </Paper>
  );
}

import ExpandLess from "@mui/icons-material/ExpandLess";
import { SvgIconProps } from "@mui/material";

export interface ExpandChevronProps extends SvgIconProps {
  expanded?: boolean;
}

export function ExpandChevron(props: ExpandChevronProps) {
  const { expanded, ...svgProps } = props;
  return (
    <ExpandLess
      {...svgProps}
      sx={(theme) => ({
        transform: expanded ? "rotate(0deg)" : "rotate(-180deg)",
        transition: theme.transitions.create("transform", {
          duration: theme.transitions.duration.shortest,
        }),
      })}
    />
  );
}

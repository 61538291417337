import { z } from "zod";
import { Link as RouterLink } from "react-router-dom";
import { SubmitHandler, UseFormReturn, useWatch } from "react-hook-form";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { SafeNameString } from "@packages/types";

import { createAddIssue } from "~/utils";
import { FormTextField } from "~/components/form/FormTextField";
import { useBackLink } from "~/components/core/BackLink";

export type SearchBoxFields = z.infer<(typeof SearchBox)["schema"]>;

interface SearchBoxProps {
  form: UseFormReturn<SearchBoxFields>;
  onSearch: SubmitHandler<SearchBoxFields>;
}

export function SearchBox(props: SearchBoxProps) {
  const { control, handleSubmit } = props.form;
  const { onSearch } = props;

  const backLink = useBackLink();

  return (
    <Stack component={Paper} p={3} boxShadow={2} gap={2}>
      <Box component="form" onSubmit={handleSubmit(onSearch)}>
        <FormTextField
          control={control}
          name="search"
          label="Search for applicant name or application ID"
          variant="filled"
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton type="submit">
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Grid
          item
          component={Stack}
          alignItems="center"
          gap={1}
          display={{ xs: "none", md: "flex" }}
        >
          <Typography variant="body1" noWrap>
            Searching by:
          </Typography>
          <SearchTypeChips form={props.form} />
        </Grid>
        <Grid item xs={12} md={3} container justifyContent="flex-end">
          <Button
            variant="contained"
            component={RouterLink}
            to="/applications/new"
            state={{ backLink }}
            sx={{ width: { xs: "100%", md: "auto" } }}
          >
            New Application
          </Button>
        </Grid>
      </Stack>
    </Stack>
  );
}

function SearchTypeChips(props: Pick<SearchBoxProps, "form">) {
  const { control } = props.form;
  const search = useWatch({ control, name: "search" });

  const isSearchingApplicationId = isApplicationId(search);

  return (
    <>
      <Chip
        label="Applicant name"
        color={!isSearchingApplicationId ? "primary" : "default"}
      />
      <Chip
        label="Application ID"
        color={isSearchingApplicationId ? "primary" : "default"}
      />
    </>
  );
}

SearchBox.schema = z
  .object({
    search: z.string().trim(),
  })
  .superRefine(({ search }, ctx) => {
    const addIssue = createAddIssue(ctx);
    const safeNameString = SafeNameString();
    if (!isApplicationId(search) && !safeNameString.isValid(search))
      addIssue(safeNameString.defaultMessage, "search");
  });

export function isApplicationId(value: string): value is `${"a" | "A"}-${number}` {
  return /^A-\d+$/i.test(value);
}

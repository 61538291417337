import { useCallback, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { nanoid } from "nanoid";
import prettyBytes from "pretty-bytes";

import { PicklistValue } from "@packages/types";
import {
  ACCEPTED_FILE_TYPES,
  asError,
  MAX_UPLOAD_FILENAME_LENGTH,
  MAX_UPLOAD_FILE_SIZE,
} from "@packages/utils";

import { ApplicationApi, GetApi } from "~/api";

export interface FileError {
  message: string;
  code: string;
}

export interface DocumentUpload {
  id: string;
  file: File;
  type: PicklistValue | null;
  userErrors: FileError[];
  result?:
    | { success: true; error?: never; retrying?: never }
    | { success: false; error: Error; retrying?: boolean };
}

/**
 * This function validates a list of uploads and returns a copy
 * of the list with the `userErrors` field of each upload populated.
 */
function validateUploads(uploads: DocumentUpload[]): DocumentUpload[] {
  // Collect file names so we can check for duplicates
  const filenames: Record<string, number> = {};
  uploads.forEach((upload) => {
    filenames[upload.file.name] ||= 0;
    filenames[upload.file.name]++;
  });

  return uploads.map((upload) => {
    const userErrors: FileError[] = [];

    // Don't need to validate files that were uploaded successfully
    if (upload.result?.success === true) return upload;

    // Check file type
    const extensions = ACCEPTED_FILE_TYPES[upload.file.type];
    if (!extensions || !extensions.some((ext) => upload.file.name.endsWith(ext)))
      userErrors.push({
        code: "invalid-type",
        message: `Invalid file type.`,
      });

    // Check for duplicate names
    if (filenames[upload.file.name] > 1) {
      userErrors.push({
        code: "duplicate-name",
        message: `A file with the same name exists.`,
      });
    }

    // Check file name length
    if (upload.file.name.length > MAX_UPLOAD_FILENAME_LENGTH)
      userErrors.push({
        code: "name-too-long",
        message: `File name cannot be longer than ${MAX_UPLOAD_FILENAME_LENGTH} characters.`,
      });

    // Check file size
    if (upload.file.size > MAX_UPLOAD_FILE_SIZE)
      userErrors.push({
        code: "file-too-large",
        message: `File cannot be larger than ${prettyBytes(MAX_UPLOAD_FILE_SIZE)}.`,
      });

    return { ...upload, userErrors };
  });
}

export type UseUploadDocuments = ReturnType<typeof useUploadDocuments>;

/**
 * Manages the upload of a list of files.
 */
export function useUploadDocuments(defaultDocumentType?: PicklistValue) {
  const client = useQueryClient();

  const [uploads, setUploads] = useState<DocumentUpload[]>([]);
  const [uploading, setUploading] = useState(false);

  // NOTE ON PERFORMANCE: setting the state for a `FileUpload` record will cause the
  // entire list to re-render. In practice, the user typically won't be uploading
  // hundreds of files at a time, so the performance impact is probably an acceptable
  // trade-off for a simpler implementation.
  const setUploadInfo = useCallback(
    (uploadId: string, info: Partial<Pick<DocumentUpload, "type" | "result">>) => {
      setUploads((uploads) =>
        uploads.map((upload) => {
          if (upload.id === uploadId) {
            if (info.type) upload.type = info.type;
            if (info.result) upload.result = info.result;
          }
          return upload;
        }),
      );
    },
    [],
  );

  const addFiles = useCallback(
    (files: File[]) => {
      setUploads((uploads) =>
        validateUploads([
          ...uploads,
          ...files.map<DocumentUpload>((file) => ({
            id: nanoid(),
            file,
            type: defaultDocumentType ?? null,
            userErrors: [], // this will be populated by `validateUploads`
          })),
        ]),
      );
    },
    [defaultDocumentType],
  );

  const removeUpload = useCallback((uploadId: string) => {
    setUploads((uploads) =>
      // Re-run validateUploads to update errors related to duplicate names
      validateUploads(uploads.filter((upload) => upload.id !== uploadId)),
    );
  }, []);

  const uploadDocuments = useCallback(
    async (options: {
      uploads: DocumentUpload[];
      applicationId: string;
      checklistItemId?: string;
    }) => {
      const { uploads, applicationId, checklistItemId } = options;

      setUploading(true);

      const successful: DocumentUpload[] = [];
      const unsuccessful: DocumentUpload[] = [];

      await Promise.all(
        uploads.map(async (upload) => {
          // Don't try to upload successful files again
          if (upload.result?.success) return;

          // Indicate error upload retries
          if (upload.result?.success === false) {
            upload.result.retrying = true;
            setUploadInfo(upload.id, { result: upload.result });
          }

          try {
            const api = GetApi(ApplicationApi);
            const result = await api.uploadApplicationDocument(applicationId, {
              file: upload.file,
              // If a checklist item ID is not provided, omit it from the payload
              // This will send it to the default "Other" checklist item
              ...(checklistItemId && {
                documentChecklistItemId: checklistItemId,
              }),
              // Default to "Other Document Type" if not provided
              documentType: upload.type?.value ?? "OTHERDCTYP",
            });
            if (result.success) {
              setUploadInfo(upload.id, { result: { success: true } });
              successful.push({ ...upload, result: { success: true } });
            } else {
              const error = new Error("File not accepted");
              setUploadInfo(upload.id, { result: { success: false, error } });
              unsuccessful.push({ ...upload, result: { success: false, error } });
            }
          } catch (e) {
            const error = asError(e);
            setUploadInfo(upload.id, { result: { success: false, error } });
            unsuccessful.push({ ...upload, result: { success: false, error } });
          }
        }),
      );

      // Bring failed uploads to the beginning of the list for better visibility
      setUploads((uploads) =>
        uploads.sort((a, b) => {
          const rankA = a.result?.success === false ? 1 : 0;
          const rankB = b.result?.success === false ? 1 : 0;
          return rankB - rankA;
        }),
      );

      setUploading(false);

      // Refetch documents
      if (successful.length > 0)
        client.refetchQueries({ queryKey: ["applications", applicationId, "documents"] });

      return { successful, unsuccessful };
    },
    [setUploadInfo, client],
  );

  const clearUploads = useCallback(() => setUploads([]), []);

  const clearSuccessfulUploads = useCallback(() => {
    setUploads((uploads) =>
      // Re-run validateUploads to update errors related to duplicate names
      validateUploads(uploads.filter((upload) => upload.result?.success !== true)),
    );
  }, []);

  return {
    /** The list of uploads being managed by {@link useUploadDocuments}. */
    uploads,

    uploadInfo: {
      /** True if at least one file has a user error. */
      hasUserErrors: uploads.some((u) => u.userErrors.length > 0),
      /** Files that are ready to be uploaded (i.e. not uploaded and has no user errors) */
      pendingUploads: uploads.filter((u) => !u.result && u.userErrors.length === 0),
      /** Files that failed to be uploaded. */
      unsuccessfulUploads: uploads.filter((u) => u.result?.success === false),
      /** Files that were successfully uploaded. */
      successfulUploads: uploads.filter((u) => u.result?.success === true),
    },

    /** Update the information on a specific upload. */
    setUploadInfo,

    /**
     * Add new files to be managed by {@link useUploadDocuments}.
     * New files are immediately validated and added to {@link uploads}.
     */
    addFiles,

    /** Remove an upload from the list of uploads. */
    removeUpload,

    /** Upload pending uploads in the {@link uploads} list. */
    uploadDocuments,

    /** Indicates that an upload is in progress. */
    uploading,

    /** Clear the {@link uploads} array. */
    clearUploads,

    /** Clear the successful uploads from the {@link uploads} array. */
    clearSuccessfulUploads,
  };
}

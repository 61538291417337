import { Merge, FieldError, FieldErrorsImpl, FieldArrayWithId } from "react-hook-form";
import { ApplicationFields } from "~/components/application/ApplicationForm";
import { TableFieldArrayPath, TableFieldArrayRecord } from "~/types";

/**
 * Check if there are errors in the table that need to be displayed.
 *
 * Ignore errors from last item if we're editing a new record.
 */
export function checkIfErrorsInTable<
  TRecord extends TableFieldArrayRecord<TableFieldArrayPath>,
>(
  isEditing: boolean,
  oldRecord?: TRecord,
  errorList?: Merge<
    FieldError,
    (Merge<FieldError, FieldErrorsImpl<TRecord>> | undefined)[]
  >,
) {
  const editingNew = isEditing && !oldRecord;
  const errorsInTable = errorList?.slice?.(0, editingNew ? -1 : Infinity);
  return errorsInTable?.some(Boolean);
}

/**
 * Handles logic for displaying array field tables in the application form.
 */
export function getTableContent<
  TRecord extends TableFieldArrayRecord<TableFieldArrayPath>,
>(
  fields: FieldArrayWithId<ApplicationFields>[],
  EmptyContent: React.ReactNode,
  recordsList: TRecord[],
  /**
   * This handler function must be supplied by the consuming component.
   *
   * It receives the final computed field values and returns the {@link React.ReactNode} that will be displayed to the user.
   */
  createTableRow: (
    fieldValues: TRecord,
    index: number,
    fieldId: string,
  ) => React.ReactNode,
  editIndex?: number,
  oldRecord?: TRecord,
) {
  // if there are no records, OR the first record is being added,
  // show the empty table state.
  // else, compute and show the values in the table.
  return fields.length === 0 || (fields.length === 1 && editIndex === 0 && !oldRecord)
    ? EmptyContent
    : fields.map((field, index) => {
        // don't show this record if it is currently being edited AND there is no previous data state
        // (i.e. if it is a new record)
        if (index === editIndex && !oldRecord) return null;

        // if this record is currently being edited AND previous data exists
        // show the previous data, instead of the current data from the form.
        // in all other cases, default to the updated values from the form.
        const displayValues =
          index === editIndex && oldRecord ? oldRecord : recordsList[index];

        // else continue as normal
        return createTableRow(displayValues, index, field.id);
      });
}

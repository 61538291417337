import { Typography, TypographyProps } from "@mui/material";

export interface ErrorCorrelationIdProps {
  correlationId?: string | null;
}

export function ErrorCorrelationId(props: ErrorCorrelationIdProps & TypographyProps) {
  const { correlationId, ...rest } = props;
  if (!correlationId) return null;
  return (
    <Typography variant="caption" component="p" sx={{ opacity: 0.7 }} {...rest}>
      Request ID:{" "}
      <Typography
        variant="inherit"
        component="span"
        fontFamily="monospace"
        sx={{ userSelect: "all" }}
      >
        {correlationId}
      </Typography>
    </Typography>
  );
}

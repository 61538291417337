import { z } from "zod";
import { useMemo } from "react";
import { Control, useWatch } from "react-hook-form";
import { Alert, AlertTitle, Grid, Link, MenuItem, Typography } from "@mui/material";

import {
  CountryISOSchema,
  DateStringSchema,
  EmailStringSchema,
  PhoneStringSchema,
  parseDateString,
  asNonMonashEmail,
  SafeNameString,
  SafeStringWithSlashAndColon,
  CampusLocation,
  CountrySACCSchema,
} from "@packages/types";

import { createAddIssue } from "~/utils";
import { useReferenceData } from "~/hooks/reference-data";
import { FormSelect } from "~/components/form/FormSelect";
import { FormTextField } from "~/components/form/FormTextField";
import { FormPhoneField } from "~/components/form/FormPhoneField";
import {
  FormCountryISOSelect,
  FormCountrySACCSelect,
} from "~/components/form/FormCountrySelect";

import { ApplicationFields } from "./useApplicationForm";

export interface PersonalDetailsFormProps {
  control: Control<ApplicationFields>;
  disabled?: boolean;
  campusLocation: string;
}

export function PersonalDetailsForm(props: PersonalDetailsFormProps) {
  const { control, disabled, campusLocation } = props;

  const salutations = useReferenceData("Salutations");
  const genders = useReferenceData("Genders");
  const pronouns = useReferenceData("Pronouns");

  const [applicationStatus, isEditable, hasMononymousName] = useWatch({
    control,
    name: [
      "applicationStatus",
      "personalDetails._isEditable",
      "personalDetails.hasMononymousName",
    ],
  });

  const defaultCountry = useMemo(() => {
    switch (campusLocation) {
      case CampusLocation.AUSTRALIA:
        return "AU";
      case CampusLocation.MALAYSIA:
        return "MY";
      default:
        return undefined;
    }
  }, [campusLocation]);

  return (
    <>
      <Grid container spacing={2}>
        {applicationStatus === "Draft" && (
          <Grid item xs={12}>
            {isEditable ? (
              <Alert severity="info">
                Please enter applicant names as they appear on{" "}
                {campusLocation === CampusLocation.AUSTRALIA && "the passport"}
                {campusLocation === CampusLocation.MALAYSIA &&
                  "the Identification Card (IC) or passport"}
                .
              </Alert>
            ) : (
              <Alert severity="info">
                The personal details of an applicant that has previously submitted an
                application to Monash are locked and cannot be edited in Agent Portal. If
                the applicant wishes to change their personal details from those listed
                below then they will need to complete an Online Enquiry. See{" "}
                <Link
                  href="https://www.monash.edu/students/admin/enrolments/personal-details"
                  target="_blank"
                  rel="noopener"
                >
                  Changing your personal details
                </Link>{" "}
                for more information.
              </Alert>
            )}
          </Grid>
        )}
        <Grid item xs={12} sm={3}>
          <FormSelect
            control={control}
            name="personalDetails.title"
            label="Title"
            loading={salutations.isPending}
            disabled={disabled || !isEditable}
          >
            {salutations.data?.map?.(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        {!hasMononymousName && (
          <Grid item xs={12} sm={5}>
            <FormTextField
              control={control}
              name="personalDetails.legalGivenNames"
              label="Legal given names"
              disabled={disabled || !isEditable}
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} sm={hasMononymousName ? 9 : 4}>
          <FormTextField
            control={control}
            name="personalDetails.legalFamilyNames"
            label={hasMononymousName ? "Legal name" : "Legal family names"}
            disabled={disabled || !isEditable}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            control={control}
            name="personalDetails.preferredName"
            label="Preferred first name"
            disabled={disabled || !isEditable}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSelect
            control={control}
            name="personalDetails.gender"
            label="Gender"
            loading={genders.isPending}
            disabled={disabled || !isEditable}
          >
            {genders.data?.map?.(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSelect
            control={control}
            name="personalDetails.pronouns"
            label="Pronouns"
            loading={pronouns.isPending}
            disabled={disabled}
          >
            {pronouns.data?.map?.(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            control={control}
            name="personalDetails.dateOfBirth"
            label="Date of birth"
            disabled={disabled || !isEditable}
            type="date"
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormCountrySACCSelect
            control={control}
            name="personalDetails.countryOfBirth"
            label="Country of birth"
            disabled={disabled || !isEditable}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight="bold" variant="h6">
            Current Address
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            control={control}
            name="personalDetails.address.street"
            label="Street"
            disabled={disabled || !isEditable}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormTextField
            control={control}
            name="personalDetails.address.city"
            label="Suburb/Town/City"
            disabled={disabled || !isEditable}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormTextField
            control={control}
            name="personalDetails.address.state"
            label="State/Province/Region"
            disabled={disabled || !isEditable}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormTextField
            control={control}
            name="personalDetails.address.postalCode"
            label="Post code/Zip code"
            disabled={disabled || !isEditable}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <FormCountryISOSelect
            control={control}
            name="personalDetails.address.country"
            label="Country"
            disabled={disabled || !isEditable}
          />
        </Grid>
        <Grid item xs={12}>
          {applicationStatus === "Draft" && isEditable && (
            <Alert severity="info">
              <AlertTitle>
                <strong>International Numbers</strong>
              </AlertTitle>
              Please enter mobile phone number with international calling code. For
              example: Australia "+61 4xx xxx xxx"
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <FormPhoneField
            control={control}
            name="personalDetails.mobile"
            label="Mobile Number"
            disabled={disabled || !isEditable}
            defaultCountry={defaultCountry}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormPhoneField
            control={control}
            name="personalDetails.phone"
            label="Home phone"
            disabled={disabled || !isEditable}
            defaultCountry={defaultCountry}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            control={control}
            name="personalDetails.email"
            label="Email Address"
            disabled={disabled || !isEditable}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
}

PersonalDetailsForm.draftSchema = z
  .object({
    _isEditable: z.boolean(),
    title: z.string(),
    legalGivenNames: SafeNameString(40).schema(),
    legalFamilyNames: SafeNameString().schema(),
    hasMononymousName: z.boolean(),
    preferredName: SafeNameString().schema(),
    gender: z.string(),
    pronouns: z.string(),
    dateOfBirth: DateStringSchema(),
    address: z.object({
      street: SafeStringWithSlashAndColon.schema(),
      city: SafeStringWithSlashAndColon.schema(),
      state: SafeStringWithSlashAndColon.schema(),
      postalCode: SafeStringWithSlashAndColon.schema(),
      country: CountryISOSchema.nullable(),
    }),
    countryOfBirth: CountrySACCSchema.nullable(),
    mobile: PhoneStringSchema(),
    phone: PhoneStringSchema.allowBlank(),
    email: asNonMonashEmail(EmailStringSchema()),
  })
  .superRefine(
    (
      { _isEditable, hasMononymousName, legalGivenNames, legalFamilyNames, dateOfBirth },
      ctx,
    ) => {
      const addIssue = createAddIssue(ctx);

      if (!_isEditable) return;

      if (hasMononymousName && !legalFamilyNames)
        addIssue("Please provide legal name", "legalFamilyNames");

      if (!hasMononymousName) {
        if (!legalGivenNames)
          addIssue("Please provide legal given names", "legalGivenNames");
        if (!legalFamilyNames)
          addIssue("Please provide legal family names", "legalFamilyNames");
      }

      if (!dateOfBirth) addIssue("Please provide date of birth", "dateOfBirth");
      else {
        const date = parseDateString(dateOfBirth);
        if (date.getTime() > Date.now())
          addIssue("Date of birth must be in the past", "dateOfBirth");
      }
    },
  );

PersonalDetailsForm.submitSchema = PersonalDetailsForm.draftSchema.superRefine(
  (
    {
      _isEditable,
      gender,
      address: { street, city, state, postalCode, country },
      countryOfBirth,
    },
    ctx,
  ) => {
    const addIssue = createAddIssue(ctx);

    if (!_isEditable) return;

    if (!gender) addIssue("Please specify a gender", "gender");

    if (!street) addIssue("Please provide a street address", "address", "street");

    if (!city) addIssue("Please provide a suburb, town or city", "address", "city");

    if (!state)
      addIssue("Please provide a state, province or region", "address", "state");

    if (!postalCode)
      addIssue("Please provide a postal code or zip code", "address", "postalCode");

    if (!country) addIssue("Please select a country", "address", "country");

    if (!countryOfBirth) addIssue("Please select a country of birth", "countryOfBirth");
  },
);

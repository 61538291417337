import { atom, useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo } from "react";
import { useIsFetching } from "@tanstack/react-query";
import { nanoid } from "nanoid";

const loadingState = atom<string[]>([]);

/**
 * Returns a `load` and `unload` function which can be used
 * to add and remove a loading resource. When there is more
 * than one loading resource, a loading bar will be shown.
 *
 * @param key The resource key. Use the same resource key to add and remove the loading resource in different components.
 */
export function useLoadResource(key?: string): [() => void, () => void];

/**
 * Returns a `load` and `unload` function which can be used
 * to add and remove a loading resource. When there is more
 * than one loading resource, a loading bar will be shown.
 *
 * @param callback Pass a callback function which can be used to evaluate the loading state. NOTE: ensure that this is wrapped in a {@link useCallback} hook.
 * @param resourceKey The resource key. Use the same resource key to add and remove the loading resource in different components.
 */
export function useLoadResource(
  callback: () => boolean,
  resourceKey?: string,
): [() => void, () => void];

// Implementation
export function useLoadResource(
  keyOrCallback?: string | (() => boolean),
  maybeKey?: string,
) {
  const key =
    typeof keyOrCallback === "string"
      ? keyOrCallback
      : typeof maybeKey === "string"
        ? maybeKey
        : "";

  const callback = typeof keyOrCallback === "function" ? keyOrCallback : null;

  const id = useMemo(() => key || nanoid(), [key]);
  const setLoaders = useSetAtom(loadingState);

  const load = useCallback(() => {
    setLoaders((loaders) => [...loaders, id]);
  }, [id]);

  const unload = useCallback(() => {
    setLoaders((loaders) => loaders.filter((x) => x !== id));
  }, [id]);

  useEffect(() => {
    if (!callback) return;

    // Check loading state
    if (callback()) load();
    else unload();

    // Always unload on unmount
    return () => unload();
  }, [callback, load, unload]);

  return [load, unload];
}

/**
 * Returns `true` if there is more than one loading resource.
 * If a `key` is provided, it will return whether the specified
 * key is in a loading state.
 */
export function useIsResourceLoading(resourceKey?: string) {
  const loaders = useAtomValue(loadingState);
  if (resourceKey) return loaders.find((x) => x === resourceKey) !== undefined;
  const fetching = useIsFetching({ stale: false });
  return loaders.length + fetching > 0;
}

import { Typography } from "@mui/material";

export interface ApplicationSectionTitleProps {
  children?: React.ReactNode;
}

export function FormSectionTitle(props: ApplicationSectionTitleProps) {
  const { children } = props;
  return (
    <Typography variant="h5" color="primary">
      {children}
    </Typography>
  );
}

import { Controller, FieldValues, ControllerProps } from "react-hook-form";
import {
  CircularProgress,
  Fade,
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from "@mui/material";

import { ValueSatisfiesType, GetFieldPathToType } from "~/types";

export interface FormSelectProps<TFieldValues extends FieldValues = FieldValues>
  extends Pick<ControllerProps<TFieldValues>, "control">,
    Omit<FormControlProps, "name" | "error" | "onChange"> {
  name: GetFieldPathToType<string | number, TFieldValues>;
  loading?: boolean;
  readOnly?: boolean;
  label?: React.ReactNode;
  children?: React.ReactNode;
  displayEmpty?: boolean;
  onBeforeChange?: SelectProps["onChange"];
}

export function FormSelect<TFieldValues extends FieldValues = FieldValues>(
  props: FormSelectProps<TFieldValues>,
) {
  const {
    name,
    control,
    label,
    children,
    loading,
    readOnly,
    disabled,
    displayEmpty,
    onBeforeChange,
    ...rest
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl
          id={name}
          error={fieldState.invalid}
          disabled={disabled || loading}
          fullWidth
          {...rest}
        >
          <InputLabel shrink={displayEmpty}>{label}</InputLabel>
          <Select
            {...field}
            onChange={(e, child) => {
              onBeforeChange?.(e, child);
              field.onChange(e.target.value as ValueSatisfiesType<TFieldValues, string>);
            }}
            value={loading ? "" : (field.value ?? "")}
            readOnly={readOnly}
            displayEmpty={displayEmpty}
            sx={{ pr: 0 }} // overrides padding added by endAdormnet
            endAdornment={
              <Fade in={loading}>
                <CircularProgress
                  color="primary"
                  sx={{ position: "absolute", right: 0, mr: 5 }}
                  size={20}
                  thickness={5}
                />
              </Fade>
            }
          >
            {children}
          </Select>
          <FormHelperText>{fieldState.error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

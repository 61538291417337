import { Container } from "@mui/material";
import { useNavigation } from "react-router-dom";
import { UseFormReturn } from "react-hook-form";
import { useCallback } from "react";

import { joinName } from "@packages/utils";

import { SampleDataApi, GetApi, IApplicant, IApplicationsPage } from "~/api";
import { useApplicantRouteData } from "~/hooks/route-data";
import { useDocumentTitle } from "~/hooks/title";
import { NewApplicationForm } from "~/components/application";
import { BackLink } from "~/components/core/BackLink";
import { DeveloperJSONInspector } from "~/components/admin/DeveloperJSONInspector";
import { useDeveloperTool } from "~/components/admin/DeveloperMenu";
import { useLoadResource } from "~/components/core/AppLoadingBar";
import {
  useNewApplicationForm,
  mapApplicantToForm,
  NewApplicationFields,
} from "~/components/application/NewApplicationForm";
import { useAuthState } from "~/components/auth/AuthProvider";

/**
 * This page gathers details to create a new contact for the Applicant and leads to the appropriate application form
 */
export function NewApplicationPage() {
  const data = useApplicantRouteData();
  const { applicant } = data ?? {};

  const form = useNewApplicationForm(applicant);

  const { state } = useNavigation();

  const isNewApplicant = !applicant;

  useDocumentTitle(
    isNewApplicant
      ? "New application"
      : `New application - ${joinName(
          applicant.firstName,
          applicant.lastName,
          applicant.preferredName,
        )}`,
  );

  const [user, loading] = useAuthState();

  return (
    <Container maxWidth="lg" sx={{ my: 2 }}>
      <BackLink mb={1.5} defaultTo="/">
        Back
      </BackLink>
      <NewApplicationForm
        form={form}
        disabled={state === "loading" || loading}
        isEditable={applicant?.isEditable ?? true}
        isNewApplicant={isNewApplicant}
        agencyPartners={user?.agencyPartners}
      />
      <NewApplicationDeveloperTools form={form.form} applicant={applicant} />
    </Container>
  );
}

function NewApplicationDeveloperTools(props: {
  form: UseFormReturn<NewApplicationFields>;
  applicant?: IApplicant;
  applications?: IApplicationsPage;
}) {
  const { form, applicant, applications } = props;
  const isNewApplication = !applicant;

  const [load, unload] = useLoadResource("sample-applicant");

  useDeveloperTool({
    key: "prefill-new-application",
    enable: isNewApplication,
    action: useCallback(async () => {
      load();
      try {
        const data = await GetApi(SampleDataApi).getApplicantSample();
        const { applicantDetails, monashStudies } = mapApplicantToForm(data);
        form.setValue("applicantDetails", applicantDetails);
        form.setValue("monashStudies", monashStudies);
      } finally {
        unload();
      }
    }, [load, isNewApplication, form, unload]),
  });

  return (
    <>
      <DeveloperJSONInspector
        id="applicant"
        title="Applicant inspector"
        collapsed={false}
        enable={!isNewApplication}
        data={() => applicant}
      />
      <DeveloperJSONInspector
        id="application-list"
        title="Application list inspector"
        collapsed={false}
        enable={!isNewApplication}
        data={() => applications}
      />
    </>
  );
}

import { Alert, AlertTitle, Button, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import {
  ApplicationCoursePreferenceStatus,
  ApplicationStatus,
  isApplicationCompleteCoursePreferenceStatus,
  PaymentMode,
} from "@packages/types";

import { useIsResourceLoading } from "~/components/core/AppLoadingBar";

import { useApplicationForm } from "./useApplicationForm";

export interface ApplicationStatusInfoProps {
  applicationId?: string;
  applicationStatus?: ApplicationStatus | null;
  applicationCoursePreferenceStatus?: ApplicationCoursePreferenceStatus | null;
  paymentMode?: string | null;
  setToDraft?: () => Promise<void>;
}

export function ApplicationStatusInfo(props: ApplicationStatusInfoProps) {
  const {
    applicationId,
    applicationStatus,
    applicationCoursePreferenceStatus,
    paymentMode,
    setToDraft,
  } = props;
  const loading = useIsResourceLoading(useApplicationForm.name);

  let info = (
    <Alert severity="error">Unknown application status: {applicationStatus}</Alert>
  );

  switch (applicationStatus) {
    case "Sent Draft Application to Applicant":
      info = (
        <>
          <Alert severity="info">
            <Typography variant="body2">
              This application has been sent to the applicant for review.
            </Typography>
          </Alert>
          {paymentMode === PaymentMode.ApplicationFeeWaiverCode &&
            canUpdateFeeWaiverCodeAlert}
        </>
      );
      break;

    case "Applicant Declaration Accepted":
      info = (
        <>
          <Alert severity="warning">
            <AlertTitle>Action required</AlertTitle>
            <Typography variant="body2" paragraph>
              Please review and accept the partner declaration to submit this application
              to Monash University.
            </Typography>
            <Button
              sx={{ width: "fit-content" }}
              variant="contained"
              component={RouterLink}
              to={`/applications/${applicationId}/declaration`}
            >
              Review Partner Declaration
            </Button>
          </Alert>
          {paymentMode === PaymentMode.ApplicationFeeWaiverCode &&
            canUpdateFeeWaiverCodeAlert}
          <Alert severity="info">
            <Typography variant="body2" gutterBottom>
              If you would like to make further edits to this application, click the
              button below to reset the application to "Draft" status.
            </Typography>
            <Typography variant="body2" paragraph>
              Please note that the application will then need to be re-sent to the
              applicant for their approval.
            </Typography>
            <Button
              sx={{ width: "fit-content" }}
              variant="contained"
              disabled={loading}
              onClick={setToDraft}
            >
              Return To Draft For Editing
            </Button>
          </Alert>
        </>
      );
      break;

    case "Agent Declaration Accepted":
    case "Sent for Submission":
    case "Submission on Hold (Duplicate Contact)":
    case "Review":
    case "Submitted":
      info = (
        <Alert severity="success">
          This application has been submitted to Monash University.
        </Alert>
      );
      break;

    case "Cancelled":
      info = <Alert severity="warning">This application has been cancelled.</Alert>;
  }

  if (isApplicationCompleteCoursePreferenceStatus(applicationCoursePreferenceStatus))
    info = (
      <Alert severity="info">
        This application is complete with status{" "}
        <b>{applicationCoursePreferenceStatus}</b>.
      </Alert>
    );

  return info;
}

const canUpdateFeeWaiverCodeAlert = (
  <Alert severity="info">
    <Typography variant="body2">
      You can update this application's fee waiver code at this time by scrolling down to
      the{" "}
      <Link
        component="a"
        sx={{ cursor: "pointer" }}
        color="inherit"
        onClick={() =>
          document.getElementById("fees")?.scrollIntoView({ behavior: "smooth" })
        }
      >
        Application Fees
      </Link>{" "}
      section.
    </Typography>
  </Alert>
);

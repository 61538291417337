import { GetFieldPathToType } from "~/types";
import { ApplicationFields } from "~/components/application/ApplicationForm";
import {
  CoursePreference,
  SelectedCourse,
} from "~/components/application/ApplicationForm/CoursePreferencesForm";

/**
 * Turn the selected course into a mapping of streams,
 * study periods and campuses that the user can select from.
 */
export function getCourseOptions(options: {
  courseInfo: SelectedCourse | null | undefined;
}) {
  const { courseInfo } = options;
  const offerings = courseInfo?.courseOfferings ?? [];

  // Assumption: filter options in this order:
  //  - streams
  //  - study period
  //  - campus
  const streams: {
    [code: string]:
      | {
          label: string;
          studyPeriods: {
            [code: string]:
              | {
                  label: string;
                  locations:
                    | { [code: string]: { label: string; courseId: string } }
                    | undefined;
                }
              | undefined;
          };
        }
      | undefined;
  } = {};

  for (const { id, location, calendarDetail, unitSets } of offerings) {
    // Use the admissions calendar for getting study periods
    const calendar = calendarDetail.find((x) => x.type === "Admission");
    if (!calendar) continue;

    for (const unitSet of unitSets) {
      streams[unitSet.code] ||= {
        label: unitSet.title || "None",
        studyPeriods: {},
      };
      streams[unitSet.code]!.studyPeriods[calendar.code] ||= {
        label: calendar.description ?? "",
        locations: {},
      };
      streams[unitSet.code]!.studyPeriods[calendar.code]!.locations![location.code] = {
        label: location.value,
        courseId: id,
      };
    }
  }

  return streams;
}

export type CoursePreferenceFormFields = {
  courseInfo: GetFieldPathToType<CoursePreference["courseInfo"], ApplicationFields>;
  courseYear: GetFieldPathToType<
    CoursePreference["offeringAcademicYear"],
    ApplicationFields
  >;
  courseCode: GetFieldPathToType<CoursePreference["courseCode"], ApplicationFields>;
  studyPeriod: GetFieldPathToType<CoursePreference["studyPeriod"], ApplicationFields>;
  stream: GetFieldPathToType<CoursePreference["stream"], ApplicationFields>;
  location: GetFieldPathToType<CoursePreference["location"], ApplicationFields>;
};

/**
 * Gets the course preference paths for the specified course preference type.
 * A number indicates a course preference from `coursePreferences.preferenceList`.
 */
export function createCourseFields(
  index: number | "englishCourse" | "pathwayCourse",
): CoursePreferenceFormFields {
  const path =
    index === "englishCourse" || index === "pathwayCourse"
      ? index
      : (`preferenceList.${index}` as const);
  return {
    courseInfo: `coursePreferences.${path}.courseInfo`,
    courseYear: `coursePreferences.${path}.offeringAcademicYear`,
    courseCode: `coursePreferences.${path}.courseCode`,
    stream: `coursePreferences.${path}.stream`,
    studyPeriod: `coursePreferences.${path}.studyPeriod`,
    location: `coursePreferences.${path}.location`,
  } as const;
}

import { useState } from "react";
import {
  Alert,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { IApplicationDocumentChecklistItem } from "~/api";
import { RequestError } from "~/utils/errors";
import { ErrorNotificationMessage } from "~/components/core/ErrorMessage";
import { ApplicationDocumentUploadModal } from "~/components/application/ApplicationDocumentUploadModal";
import { InnerTable } from "~/components/core/TableStyles";
import { SanitisedHTML } from "~/components/core/SanitisedHTML";

const PLACEHOLDER = (
  <>
    <ApplicationDocumentTableRow />
    <ApplicationDocumentTableRow />
    <ApplicationDocumentTableRow />
  </>
);

export interface ApplicationDocumentsTableProps {
  applicationId: string;
  loading?: boolean;
  error?: RequestError | null;
  documentChecklistItems?: IApplicationDocumentChecklistItem[] | undefined;
  isEditable?: boolean;
}

export function ApplicationDocumentsTable(props: ApplicationDocumentsTableProps) {
  const { loading, error, documentChecklistItems, applicationId, isEditable } = props;
  const itemsWithDocuments = documentChecklistItems?.filter(
    ({ supportedDocumentTypes }) =>
      supportedDocumentTypes.map((type) => type.documents).flat().length >= 0,
  );
  return (
    <InnerTable.Container>
      <Table size="small">
        <TableHead>
          <InnerTable.HeaderRow>
            <InnerTable.HeaderCell>Document Category</InnerTable.HeaderCell>
            <InnerTable.HeaderCell>Documents</InnerTable.HeaderCell>
          </InnerTable.HeaderRow>
        </TableHead>
        <TableBody>
          {loading && PLACEHOLDER}
          {itemsWithDocuments?.map((documents) => (
            <ApplicationDocumentTableRow
              applicationId={applicationId}
              key={documents.id}
              data={documents}
              isEditable={isEditable}
            />
          ))}
        </TableBody>
      </Table>
      {!loading && !error && itemsWithDocuments?.length === 0 && (
        <Typography variant="body2" color="text.secondary" textAlign="center" p={6}>
          No documents available
        </Typography>
      )}
      {!loading && error && (
        <Alert severity="error">
          <ErrorNotificationMessage
            error={error}
            message="Failed to load application documents"
          />
        </Alert>
      )}
    </InnerTable.Container>
  );
}

interface ApplicationDocumentTableRowProps {
  applicationId?: string;
  data?: IApplicationDocumentChecklistItem;
  isEditable?: boolean;
}

function ApplicationDocumentTableRow(props: ApplicationDocumentTableRowProps) {
  const { data, applicationId, isEditable } = props;

  if (!data || !applicationId)
    return (
      <>
        <TableRow>
          <TableCell rowSpan={3}>
            <Skeleton width="50%" />
            <Skeleton width="80%" />
            <Skeleton width="80%" />
          </TableCell>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Skeleton />
          </TableCell>
        </TableRow>
      </>
    );

  const { id, name, description, supportedDocumentTypes = [] } = data;

  const documents = supportedDocumentTypes.map((type) => type.documents).flat();

  const [uploadOpen, setUploadOpen] = useState(false);

  const heading = (
    <TableCell
      // we need to leave an extra row for the add document button
      // increment rowSpan by 1 if the documents are editable
      rowSpan={(documents.length || 1) + (isEditable ? 1 : 0)}
      sx={{ width: "50vw", maxWidth: 640 }}
    >
      <Typography variant="body2" fontWeight="bold" color="secondary">
        {name || id}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <SanitisedHTML html={description} />
      </Typography>
    </TableCell>
  );

  const addDocument = (
    <TableRow>
      <TableCell sx={{ verticalAlign: "top" }}>
        <Button
          sx={{ ml: "-5px" }}
          startIcon={<AddIcon />}
          size="small"
          onClick={() => {
            setUploadOpen(true);
          }}
        >
          Add new document
        </Button>
        <ApplicationDocumentUploadModal
          open={uploadOpen}
          title={`Upload ${name ?? "Documents"}`}
          applicationId={applicationId}
          checklistItemId={id}
          onClose={() => setUploadOpen(false)}
          documentTypes={supportedDocumentTypes.map(({ type }) => ({
            label: type.name,
            value: type.code,
          }))}
        />
      </TableCell>
    </TableRow>
  );

  if (documents.length === 0)
    return (
      <>
        <TableRow>
          {heading}
          <TableCell>
            <Typography variant="body2" color="text.secondary" fontStyle="oblique">
              No documents
            </Typography>
          </TableCell>
        </TableRow>
        {isEditable && addDocument}
      </>
    );

  return (
    <>
      {documents.map(({ id: fileId, filename }, index) => (
        <TableRow key={fileId}>
          {index === 0 && heading}
          <TableCell>
            <Typography variant="body2" color="secondary">
              {filename}
            </Typography>
          </TableCell>
        </TableRow>
      ))}
      {isEditable && addDocument}
    </>
  );
}

import { z } from "zod";

export type EnquiryGroupType = z.infer<typeof EnquiryGroupTypeSchema>;
export const EnquiryGroupTypeSchema = z.enum([
  "Document Submission",
  "Request",
  "Enquiry and Advice",
]);

export type EnquiryRecordType = z.infer<typeof EnquiryRecordTypeEnum>;
export const EnquiryRecordTypeEnum = z.enum([
  "Admissions",
  "Post_Application_Enquiry",
  "MUM_Admissions",
]);

export function isEnquiryRecordType(value: string): value is EnquiryRecordType {
  return EnquiryRecordTypeEnum.safeParse(value).success;
}

export type EnquiryType = z.infer<typeof EnquiryTypeEnum>;
export const EnquiryTypeEnum = z.enum([
  "Future Course",
  "Post Application",
  "Late Registration - International",
]);

export function isEnquiryType(value: string): value is EnquiryType {
  return EnquiryTypeEnum.safeParse(value).success;
}

const EnquiryTopicFieldBaseSchema = z.object({
  name: z.string(),
  label: z.string(),
  optional: z.boolean().optional(),
});

export type EnquiryTopicField = z.infer<typeof EnquiryTopicFieldSchema>;
export const EnquiryTopicFieldSchema = z.discriminatedUnion("type", [
  EnquiryTopicFieldBaseSchema.extend({
    type: z.literal("text"),
    multiline: z.boolean().optional(),
  }),
  EnquiryTopicFieldBaseSchema.extend({
    type: z.literal("date"),
    rejectPastDates: z.boolean().optional(),
    rejectFutureDates: z.boolean().optional(),
  }),
]);

import { useQueryParam } from "use-query-params";
import { Button, Grid, Theme, Typography } from "@mui/material";
import { alpha, darken, SystemStyleObject } from "@mui/system";

import AssignmentIcon from "@mui/icons-material/Assignment";
import DraftsIcon from "@mui/icons-material/Drafts";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import { IApplicationStatusType } from "~/api";

export const DashboardFilter = {
  Draft: "Draft",
  InProgress: "In Progress",
  Completed: "Completed",
  ApplicationId: "Application",
  Applicants: "Applicants",
} satisfies Record<IApplicationStatusType | "Applicants" | "ApplicationId", string>;

export type DashboardFilterKey = keyof typeof DashboardFilter;

export function isDashboardFilter(value: string): value is DashboardFilterKey {
  return value in DashboardFilter;
}

export function useDashboardFilter(defaultValue: DashboardFilterKey) {
  return useQueryParam<DashboardFilterKey>("filter", {
    default: defaultValue,
    encode: (value) => value,
    decode: (value) =>
      (isDashboardFilter(String(value))
        ? String(value)
        : defaultValue) as DashboardFilterKey,
  });
}

export function useDashboardSearch(defaultValue: string) {
  return useQueryParam<string>("search", {
    default: defaultValue,
    encode: (value) => value,
    decode: (value) => (typeof value === "string" ? value : defaultValue),
  });
}

export type DashboardFilterButton = {
  title: DashboardFilterKey;
  icon: React.ReactElement;
  background: string;
  sx?: SystemStyleObject<Theme>;
};

export const FILTER_MAP = {
  Applicants: {
    title: "Applicants",
    icon: <AssignmentIcon />,
    background: "#697981",
  },
  Draft: {
    title: "Draft",
    icon: <DraftsIcon />,
    background: "#bf4a58",
    sx: {
      "& .MuiButton-endIcon": {
        right: 0,
      },
    },
  },
  InProgress: {
    title: "InProgress",
    icon: <AvTimerIcon />,
    background: "#006DAE",
    sx: {
      "& .MuiButton-endIcon": {
        right: -5,
        "& .MuiSvgIcon-root": {
          fontSize: 100,
        },
      },
    },
  },
  Completed: {
    title: "Completed",
    icon: <AssignmentTurnedInIcon />,
    background: "#008577",
  },
} satisfies Partial<Record<DashboardFilterKey, DashboardFilterButton>>;

const FILTERS: DashboardFilterButton[] = Object.values(FILTER_MAP);

export interface DashboardFilterButtonsProps {
  selectedFilter?: DashboardFilterKey;
  onClick?: (filter: DashboardFilterKey) => void;
}

export function DashboardFilterButtons(props: DashboardFilterButtonsProps) {
  const { selectedFilter, onClick } = props;
  return (
    <Grid container spacing={2}>
      {FILTERS.map(({ title, icon, background, sx: buttonStyles = null }) => (
        <Grid key={title} item xs={12} md={6} lg={3}>
          <Button
            variant="contained"
            size="large"
            endIcon={icon}
            sx={[
              (theme) => ({
                width: "100%",
                borderRadius: 2,
                height: 75,
                boxShadow: 2,
                overflow: "hidden",

                [theme.breakpoints.down("md")]: {
                  height: "auto",
                },

                "& .MuiButton-endIcon": {
                  color: alpha("#fff", 0.25),
                  display: { xs: "none", md: "block" },
                  position: "absolute",
                  bottom: -26,
                  right: -3,
                  transition: "all 0.5s cubic-bezier(.25, .8, .25, 1)",

                  "& .MuiSvgIcon-root": {
                    fontSize: 92,
                  },
                },

                "&:hover": {
                  background: darken(background, 0.1),
                },
              }),
              buttonStyles,
              selectedFilter === title
                ? {
                    boxShadow: 3,
                    fontWeight: "bold",
                    background: background,
                    "& .MuiButton-endIcon": {
                      color: alpha("#fff", 0.5),
                    },
                  }
                : {
                    color: alpha("#fff", 0.8),
                    background: darken(background, 0.2),
                  },
            ]}
            onClick={() => onClick?.(title)}
          >
            <Typography
              variant="subtitle1"
              fontWeight={selectedFilter === title ? "bold" : "normal"}
            >
              {DashboardFilter[title]}
            </Typography>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}

import { CircularProgress, CircularProgressProps } from "@mui/material";

export interface ButtonLoadingSpinnerProps extends CircularProgressProps {
  show?: boolean;
}

export function ButtonLoadingSpinner(props: ButtonLoadingSpinnerProps) {
  const { show, ...rest } = props;
  if (!show) return null;
  return <CircularProgress color="inherit" size={16} thickness={6} {...rest} />;
}

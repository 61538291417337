import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";

import { ACCEPTED_FILE_TYPES } from "@packages/utils";
import { PicklistValue } from "@packages/types";

import { FormDropzone, FormDropzoneProps } from "~/components/form/FormDropzone";

import { DocumentUploadItem, DocumentUploadItemProps } from "./DocumentUploadItem";
import { DocumentUpload } from "./useUploadDocument";

export interface DocumentUploadModalProps {
  /** The modal's open state. */
  open?: boolean;

  /** Show the loading state for the modal. */
  loading?: boolean;

  /**
   * The title to show on the modal.
   * @default "Upload Document"
   */
  title?: React.ReactNode;

  /**
   * Provide additional content (e.g. error notifications, retry actions etc.)
   * to render in the body of the modal. Content will be placed below the prompt.
   */
  content?: React.ReactNode;

  /** Provide actions (e.g. close button) to be rendered at the bottom of the modal. */
  actions?: React.ReactNode;

  /** The list of uploads to render in the modal. */
  uploads?: DocumentUpload[];

  /**
   * The list of document types that can be selected for each upload.
   * If no document types are provided, the select will not be rendered.
   */
  documentTypes?: DocumentUploadItemProps["documentTypes"];

  /** Callback for when the modal finishes its exit transition. */
  onExited?: () => void;

  /** Callback for when files are dropped on the modal. */
  onDrop?: FormDropzoneProps["onDrop"];

  /**
   * Callback for when files are dropped on the modal. Returns all files,
   * including files that are rejected.
   *
   * This is helpful if we want to receive all files that are dropped to
   * process file errors ourselves.
   */
  onDropAll?: (file: File[]) => void;

  /** Callback for when the user requests an upload to be removed. */
  onRemoveUpload?: (uploadId: string) => void;

  /** Callback for when the user selects a document type for an upload. */
  onSetDocumentType?: (uploadId: string, type: PicklistValue) => void;
}

export function DocumentUploadModal(props: DocumentUploadModalProps) {
  const {
    open = false,
    loading,
    title = "Upload Document",
    content,
    actions,
    uploads = [],
    documentTypes,
    onExited,
    onDrop,
    onDropAll,
    onRemoveUpload,
    onSetDocumentType,
  } = props;

  const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      fullWidth
      fullScreen={fullScreen}
      PaperProps={{ sx: { maxWidth: 700 } }}
      TransitionProps={{ onExited }}
    >
      <DialogTitle variant="h5" color="primary">
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <FormDropzone
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            minHeight: fullScreen ? "100%" : 400,
          }}
          titleText="Drop files here"
          accept={ACCEPTED_FILE_TYPES}
          onDrop={(accepted, rejected, event) => {
            onDrop?.(accepted, rejected, event);
            onDropAll?.([accepted, rejected.map((r) => r.file)].flat());
          }}
          disabled={loading}
          multiple
        >
          {({ getRootProps }) => (
            <>
              <Stack
                gap={2}
                sx={(theme) =>
                  uploads.length === 0
                    ? {
                        borderStyle: "dashed",
                        borderWidth: 2,
                        borderRadius: 3,
                        borderColor: theme.palette.grey[500],
                        flexGrow: 1,
                        alignItems: "center",
                        textAlign: "center",
                        justifyContent: "center",
                        my: 1,
                        px: 8,
                        [theme.breakpoints.down("sm")]: { px: 4 },
                      }
                    : {}
                }
              >
                {uploads.length === 0 && <UploadIcon fontSize="large" color="disabled" />}
                <Typography variant="body1">
                  Drop your files on this window or click the button to browse for files.
                </Typography>
                <Button
                  disabled={loading}
                  variant="outlined"
                  fullWidth
                  onClick={getRootProps().onClick}
                >
                  Browse files
                </Button>
                <Typography variant="body2" color="text.secondary">
                  Only PNG and JPG images, Word documents, and PDFs are accepted. Files
                  must be below 5MB and the file name must be 100 characters or less.
                </Typography>
              </Stack>
              {content}
              {uploads.map((upload) => (
                <DocumentUploadItem
                  key={upload.id}
                  upload={upload}
                  onRemove={() => onRemoveUpload?.(upload.id)}
                  onSetDocumentType={(type) => onSetDocumentType?.(upload.id, type)}
                  documentTypes={documentTypes}
                  loading={
                    loading &&
                    (!upload.result ||
                      (upload.result.success === false && upload.result.retrying))
                  }
                />
              ))}
            </>
          )}
        </FormDropzone>
      </DialogContent>
      <DialogActions sx={{ mr: 2 }}>{actions}</DialogActions>
    </Dialog>
  );
}

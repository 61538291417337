import { Container, Hidden, Box, Stack } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { useCallback } from "react";
import { atom, useAtom } from "jotai";

import { joinName } from "@packages/utils";

import { doesObjectHaveTrueValues, download } from "~/utils";
import { ApplicationApi, SampleDataApi, GetApi, IApplicant, IApplication } from "~/api";
import { useDocumentTitle } from "~/hooks/title";
import { useApplicationRouteData } from "~/hooks/route-data";
import { BackLink } from "~/components/core/BackLink";
import { ApplicationFormSidebar } from "~/components/application/ApplicationFormSidebar";
import { useApplicationForm } from "~/components/application/ApplicationForm/useApplicationForm";
import { FormDirtyModal } from "~/components/application/FormDirtyModal";
import { DeveloperJSONInspector } from "~/components/admin/DeveloperJSONInspector";
import { useDeveloperTool } from "~/components/admin/DeveloperMenu";
import { useLoadResource } from "~/components/core/AppLoadingBar";
import {
  ApplicationForm,
  ApplicationFields,
  mapApplicationToForm,
} from "~/components/application/ApplicationForm";
import { useAuthState } from "~/components/auth/AuthProvider";

export const newApplicationIdAtom = atom<string | null>(null);

export function EditApplicationPage() {
  const { applicant, application, englishCourseCodes, monashCollegeFaculties } =
    useApplicationRouteData();

  const { campusLocation } = application.applicant;
  const { applicationId, applicationCoursePreferenceStatus, payment } =
    application.application;

  useDocumentTitle(
    `${applicationId} - ${joinName(
      applicant.firstName,
      applicant.lastName,
      applicant.preferredName,
    )}`,
  );

  // If a newApplicationId has been set and it is equal to the current application Id,
  // that means this is a new application and the form should only validate if dirty.
  const [newApplicationId, setNewApplicationId] = useAtom(newApplicationIdAtom);
  const isNewApplication = applicationId === newApplicationId;

  const onAfterUpdate = useCallback(
    (info: { saveSuccessful: boolean }) => {
      if (info.saveSuccessful) {
        // Unset newApplicationId after the application is saved successfully
        setNewApplicationId(null);
      }
    },
    [setNewApplicationId],
  );

  const form = useApplicationForm({
    applicant,
    application,
    englishCourseCodes,
    monashCollegeFaculties,
    onAfterUpdate,
  });

  const [user] = useAuthState();

  return (
    <Container maxWidth="xl" sx={{ my: 2 }}>
      <BackLink mb={1.5} defaultTo={`/applicants/${applicant.applicantId}`}>
        Back
      </BackLink>
      <Stack direction="row" gap={3}>
        <Hidden mdDown>
          <Box minWidth={{ md: 240, lg: 300 }}>
            <ApplicationFormSidebar form={form} agencyPartners={user?.agencyPartners} />
          </Box>
        </Hidden>
        <Box maxWidth={960}>
          <ApplicationForm
            form={form}
            applicationId={applicationId}
            applicationCoursePreferenceStatus={applicationCoursePreferenceStatus}
            campusLocation={campusLocation?.code}
            payment={payment}
            onlyValidateIfDirty={isNewApplication}
            agencyPartners={user?.agencyPartners}
          />
        </Box>
      </Stack>
      <EditApplicationDeveloperTools
        application={application}
        applicant={applicant}
        englishCourseCodes={englishCourseCodes}
        monashCollegeFaculties={monashCollegeFaculties}
        form={form.form}
      />
      <FormDirtyModal form={form.form} />
    </Container>
  );
}

function EditApplicationDeveloperTools(props: {
  application: IApplication;
  applicant: IApplicant;
  englishCourseCodes: string[];
  monashCollegeFaculties: string[];
  form: UseFormReturn<ApplicationFields>;
}) {
  const { applicant, application, englishCourseCodes, monashCollegeFaculties, form } =
    props;
  const { applicantId, isEditable } = applicant;
  const { campusLocation } = application.applicant;
  const {
    applicationId,
    applicationStatus,
    residencyStatus,
    lastModifiedDate,
    delegatorOrgUnitCode,
  } = application.application;

  const [loadReceipt, unloadReceipt] = useLoadResource("application-receipt");
  const [loadSample, unloadSample] = useLoadResource("sample-application");

  useDeveloperTool({
    key: "application-receipt-download",
    enable: true,
    action: useCallback(async () => {
      loadReceipt();
      try {
        const file = await GetApi(ApplicationApi).generateApplicationReceipt(
          applicationId,
          {},
          { format: "blob" },
        );
        download(file, `application-receipt-${applicationId}.pdf`);
      } finally {
        unloadReceipt();
      }
    }, [loadReceipt, applicationId, unloadReceipt]),
  });

  useDeveloperTool({
    key: "prefill-edit-application",
    enable: applicationStatus === "Draft",
    action: useCallback(async () => {
      loadSample();
      try {
        const api = GetApi(SampleDataApi);
        const [sampleApplicant, sampleApplication] = await Promise.all([
          // Only request a sample applicant if the applicant is editable
          isEditable ? api.getApplicantSample() : applicant,
          api.getApplicationSample({
            applicantId,
            applicationId,
            campusLocation: campusLocation?.code,
            residencyStatus: residencyStatus?.value,
          }),
        ]);
        const {
          personalDetails,
          monashStudies,
          citizenship,
          coursePreferences,
          englishProficiency,
          disabilities,
          academicQualifications,
          workExperience,
          scholarshipSponsorship,
          creditTransfer,
          notes,
          fees,
          documents,
        } = mapApplicationToForm({
          applicant: sampleApplicant,
          application: sampleApplication,
          englishCourseCodes,
          monashCollegeFaculties,
        });
        form.reset(
          {
            applicationStatus: applicationStatus ?? "Draft",
            delegatedAgency: { delegatorOrgUnitCode },
            lastModifiedDate: lastModifiedDate ?? "",
            personalDetails: {
              ...personalDetails,
              // Don't overwrite these fields
              legalGivenNames: applicant.firstName,
              legalFamilyNames: applicant.lastName,
              hasMononymousName: applicant.hasMononymousName ?? false,
              email: applicant.email.find((x) => x.type === "Personal")?.email ?? "",
              mobile: applicant.phone.find((x) => x.type === "Mobile")?.phoneNumber ?? "",
            },
            monashStudies,
            citizenship,
            coursePreferences,
            englishProficiency,
            disabilities,
            academicQualifications,
            workExperience,
            scholarshipSponsorship,
            creditTransfer,
            notes,
            fees,
            documents,
          },
          { keepDefaultValues: true },
        );
      } finally {
        unloadSample();
      }
    }, [
      applicantId,
      applicationId,
      form,
      applicationStatus,
      lastModifiedDate,
      loadSample,
      unloadSample,
      englishCourseCodes,
      monashCollegeFaculties,
    ]),
  });

  const isDirty = doesObjectHaveTrueValues(form.formState.dirtyFields);

  return (
    <>
      <DeveloperJSONInspector
        id="form"
        title="Form inspector"
        collapsed={false}
        data={() => ({
          state: {
            isDirty,
            dirtyFields: form.formState.dirtyFields,
            isValid: form.formState.isValid,
            errors: form.formState.errors,
          },
          data: form.getValues(),
        })}
      />
      <DeveloperJSONInspector
        id="applicant"
        title="Applicant inspector"
        collapsed={false}
        data={() => applicant}
      />
      <DeveloperJSONInspector
        id="application"
        title="Application inspector"
        collapsed={false}
        data={() => application}
      />
    </>
  );
}

import { Container, Stack } from "@mui/material";

import { BackLink } from "~/components/core/BackLink";
import { EnquiryTitleCard } from "~/components/enquiry/EnquiryTitleCard";
import { EnquiryUpdatesCard } from "~/components/enquiry/EnquiryUpdatesCard";
import { useAgent } from "~/hooks/agency";
import { useEnquiryRouteData } from "~/hooks/route-data";
import { useDocumentTitle } from "~/hooks/title";

export function EnquiryDetailPage() {
  const {
    enquiry,
    enquiry: { agent },
  } = useEnquiryRouteData();

  const authorAgent = useAgent(agent.email);

  useDocumentTitle(`Enquiry ${enquiry.enquiryNumber} - ${enquiry.subject}`);

  return (
    <Container sx={{ my: 2 }}>
      <BackLink mb={1.5} defaultTo={`/applicants/${enquiry.applicantId}`}>
        Back
      </BackLink>
      <Stack gap={3} mb={12}>
        <EnquiryTitleCard enquiry={enquiry} authorAgent={authorAgent} />
        <EnquiryUpdatesCard enquiry={enquiry} authorAgent={authorAgent} />
      </Stack>
    </Container>
  );
}

import { Divider, ListSubheader, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

import PersonIcon from "@mui/icons-material/Person";

interface ImpersonationMenuProps {
  onClick?: () => void;
}

export function ImpersonationMenu({ onClick }: ImpersonationMenuProps) {
  return (
    <>
      <Divider />
      <ListSubheader sx={{ lineHeight: 3 }}>Impersonation</ListSubheader>
      <MenuItem component={Link} to="/impersonate" onClick={onClick}>
        <PersonIcon sx={{ mr: 1 }} color="action" />
        Impersonate user
      </MenuItem>
    </>
  );
}

import { FieldValues } from "react-hook-form";

import { ICountryISO } from "~/api";
import { useCountriesISO } from "~/hooks/reference-data";
import { FormAutocomplete } from "~/components/form/FormAutocomplete";

import { FormCountrySelectProps } from "./shared";

export function FormCountryISOSelect<TFieldValues extends FieldValues = FieldValues>(
  props: FormCountrySelectProps<ICountryISO | null, TFieldValues>,
) {
  const {
    name,
    control,
    label,
    loading,
    autoHighlight = true,
    disabled,
    ...rest
  } = props;

  const countries = useCountriesISO();

  return (
    <FormAutocomplete
      {...rest}
      id={name}
      name={name}
      loading={loading || countries.isPending}
      control={control}
      autoHighlight={autoHighlight}
      options={countries?.data ?? []}
      label={label}
      disabled={disabled || loading || countries.isPending}
      getOptionLabel={(country) => country?.isoCountryName ?? ""}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
    />
  );
}

import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
  TableRowProps,
  Typography,
  TypographyProps,
} from "@mui/material";

export interface DashboardTableProps extends Omit<TableContainerProps, "component"> {
  headings?: { id: string; label: React.ReactNode }[];
  children?: React.ReactNode;
}

export function DashboardTable(props: DashboardTableProps) {
  const { headings = [], children, ...containerProps } = props;
  return (
    <TableContainer component={Paper} {...containerProps}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow sx={{ border: 0 }}>
            {headings.map((heading) => (
              <TableCell key={heading.id}>
                <Typography variant="subtitle2" noWrap>
                  {heading.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}

DashboardTable.Placeholder = function DashboardTablePlaceholder(props: TypographyProps) {
  return <Typography variant="body1" color="text.secondary" {...props} />;
};

DashboardTable.Row = function DashboardTableRow(
  props: TableRowProps & { disableHighlight?: boolean },
) {
  const { disableHighlight, sx, ...rest } = props;
  return (
    <TableRow
      {...rest}
      sx={{
        backgroundColor: (theme) => theme.palette.action.hover,
        transition: "all 0.2s",
        ...(!disableHighlight && {
          "&:hover": {
            background: "#e8f5ff",
          },
        }),
        // hide last border
        "&:last-child td, &:last-child th": {
          border: 0,
        },
        ...sx,
      }}
    />
  );
};

DashboardTable.Cell = function DashboardTableCell(
  props: TableCellProps & { skeleton?: boolean; skeletonRows?: number },
) {
  const { skeleton, skeletonRows = 1, children, ...rest } = props;
  return (
    <TableCell {...rest}>
      {skeleton
        ? Array.from({ length: skeletonRows }).map((_, index) => <Skeleton key={index} />)
        : children}
    </TableCell>
  );
};

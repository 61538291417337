import { ListItem, ListItemProps, ListItemText } from "@mui/material";

export interface ListDisplayValueProps extends Omit<ListItemProps, "label" | "value"> {
  label?: React.ReactNode;
  value?: React.ReactNode;
}

export function ListDisplayValue(props: ListDisplayValueProps) {
  const { label, value, ...labelItemProps } = props;
  return (
    <ListItem {...labelItemProps}>
      <ListItemText
        sx={{ display: "flex", flexDirection: "column-reverse" }}
        secondary={label}
        primary={value}
      />
    </ListItem>
  );
}

export interface SerializeOptions {
  /**
   * The maximum permitted length of any string values.
   * Strings that exceed this length are truncated.
   */
  maxStringLength?: number;
  /**
   * The maximum permitted size of the object in bytes.
   * Object will not be serialized beyond this size.
   */
  maxObjectSize?: number;
}

/**
 * Serialize an object for logging by truncating any string fields that
 * are longer than `options.maxStringLength` and stripping any fields
 * that would make the object being logged exceed `options.maxObjectSize`.
 *
 * @param object The object to serialize.
 * @param options Serialization options.
 */
export function serialize(object: unknown, options: SerializeOptions = {}): unknown {
  const { maxStringLength, maxObjectSize = Infinity } = options;
  const serialized = serializeObject(object, maxObjectSize, { maxStringLength });
  return serialized.value;
}

/**
 * [internal]
 *
 * Converts a given value to a string and calculates the size in bytes.
 */
function sizeInBytes(value: unknown): number {
  const stringified = typeof value === "string" ? value : JSON.stringify(value);
  return Buffer.from(stringified).byteLength;
}

/**
 * [internal]
 *
 * Serializes an object for logging.
 *
 * Once the byte limit is used up, this function will stop serializing
 * any more fields and return the result.
 *
 * @param object The object to serialize.
 * @param bytesRemaining The number of bytes remaining to use for serialization.
 */
function serializeObject(
  object: unknown,
  bytesRemaining: number,
  options: {
    /**
     * The maximum string length. Strings longer than this will be truncated.
     * @default Infinity
     */
    maxStringLength?: number;
    /**
     * The maximum array length. Arrays longer than this will be truncated.
     * @default Infinity
     */
    maxArrayLength?: number;
  } = {},
): { value: unknown; size: number } {
  const { maxStringLength = Infinity, maxArrayLength = Infinity } = options;

  if (typeof object === "undefined") {
    return { value: undefined, size: 0 };
  }

  // numbers, booleans and null
  else if (typeof object === "number" || typeof object === "boolean" || object === null) {
    const size = sizeInBytes(object);
    if (bytesRemaining - size >= 0) return { value: object, size };
  }

  // strings
  else if (typeof object === "string") {
    const truncated =
      object.length > maxStringLength
        ? object.slice(0, maxStringLength - 3) + "..."
        : object;
    const size = sizeInBytes(truncated) + sizeInBytes(`""`);
    if (bytesRemaining - size >= 0) return { value: truncated, size };
  }

  // arrays
  else if (Array.isArray(object)) {
    let bytesUsed = sizeInBytes("[]");
    if (bytesUsed > bytesRemaining) return { value: undefined, size: 0 };

    const result: unknown[] = [];

    for (const item of object.slice(0, maxArrayLength)) {
      if (bytesUsed > bytesRemaining) break;

      const remaining = bytesRemaining - bytesUsed;
      const serializedItem = serializeObject(item, remaining, options);
      if (
        serializedItem.value === undefined ||
        bytesUsed + serializedItem.size > bytesRemaining
      )
        break;

      result.push(serializedItem.value);
      bytesUsed += serializedItem.size + sizeInBytes(",");
    }

    // If there are items in the list, it means we appended a trailing comma for each item
    // So we should remove the size of the last trailing comma since it's not actually there
    if (result.length > 0) bytesUsed -= sizeInBytes(",");

    return { value: result, size: bytesUsed };
  }

  // objects
  else if (typeof object === "object") {
    let bytesUsed = sizeInBytes("{}");
    if (bytesUsed > bytesRemaining) return { value: undefined, size: 0 };

    const result: Record<string, unknown> = {};

    for (const [key, value] of Object.entries(object)) {
      // Account for the size of the key
      bytesUsed += sizeInBytes(key) + sizeInBytes(`"":`);
      if (bytesUsed > bytesRemaining) break;

      // Serialize the value
      const remaining = bytesRemaining - bytesUsed;
      const serializedValue = serializeObject(value, remaining, options);

      // Skip undefined results
      if (serializedValue.value === undefined) continue;

      // Otherwise, store the serialized value and keep going
      result[key] = serializedValue.value;
      bytesUsed += serializedValue.size + sizeInBytes(",");
    }

    return { value: result, size: bytesUsed - sizeInBytes(",") };
  }

  // all other data types
  else {
    const type = `[${typeof object}]`;
    const size = sizeInBytes(type);
    if (bytesRemaining - size >= 0) return { value: type, size };
  }

  return { value: undefined, size: 0 };
}

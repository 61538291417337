import { z } from "zod";

export type PicklistValue = z.infer<typeof PicklistValueSchema>;
export const PicklistValueSchema = z.object({
  value: z.string(),
  label: z.string(),
});

export type CodeValue = z.infer<typeof CodeValueSchema>;
export const CodeValueSchema = z.object({
  code: z.string(),
  value: z.string(),
});

export type CodeString = z.infer<typeof CodeStringSchema>;
export const CodeStringSchema = z.object({
  code: z.string(),
});

export type CodeStringNullable = z.infer<typeof CodeStringNullableSchema>;
export const CodeStringNullableSchema = z
  .object({ code: z.string().nullable() })
  .nullable();

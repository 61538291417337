import { CodeString, CodeStringNullable } from "../schema/Picklist";

/**
 * Transforms a potentially empty code object to a non-empty
 * code object or null otherwise.
 */
export function toCodeOrNull(
  value: Partial<CodeStringNullable> | null | undefined,
): CodeString | null {
  if (value?.code) return { code: value.code };
  return null;
}

import { Divider, ListSubheader, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";

import ErrorIcon from "@mui/icons-material/Error";
import MixIcon from "@mui/icons-material/SettingsEthernet";

interface SupportMenuProps {
  onClick?: () => void;
}

export function SupportMenu({ onClick }: SupportMenuProps) {
  return (
    <>
      <Divider />
      <ListSubheader sx={{ lineHeight: 3 }}>Support</ListSubheader>
      <MenuItem component={Link} to="/support/error-logs" onClick={onClick}>
        <ErrorIcon sx={{ mr: 1 }} color="action" />
        Error logs
      </MenuItem>
      <MenuItem component={Link} to="/support/mix-logs" onClick={onClick}>
        <MixIcon sx={{ mr: 1 }} color="action" />
        MIX logs
      </MenuItem>
    </>
  );
}

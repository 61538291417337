import {
  Accordion,
  AccordionProps,
  AccordionSummary,
  Paper,
  PaperProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export function ProfileCard(props: PaperProps) {
  const { children, ...paperProps } = props;
  return (
    <Paper sx={{ p: 3, borderRadius: 2 }} {...paperProps}>
      {children}
    </Paper>
  );
}

ProfileCard.Accordion = function (props: AccordionProps & { title?: React.ReactNode }) {
  const { children, title, ...accordionProps } = props;
  return (
    <Accordion
      elevation={1}
      sx={{
        p: 1,
        borderRadius: 2,
        "&:before": { display: "none" },
        ...accordionProps.sx,
      }}
      {...accordionProps}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <ProfileCard.Subheading>{title}</ProfileCard.Subheading>
      </AccordionSummary>
      {children}
    </Accordion>
  );
};

ProfileCard.Subheading = function (props: TypographyProps) {
  const { children, ...typographyProps } = props;
  return (
    <Typography variant="h5" color="primary" {...typographyProps}>
      {children}
    </Typography>
  );
};

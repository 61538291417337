import { Badge, Grow, IconButton } from "@mui/material";

import WarningIcon from "@mui/icons-material/Warning";

import { useLatestNotification } from "~/hooks/notification";
import { useAuthState } from "~/components/auth/AuthProvider";
import { useHideNotification } from "~/components/core/AlertNotificationBanner";

export function AlertNotificationButton() {
  const notification = useLatestNotification();

  const [user] = useAuthState();
  const [hideNotification, setHideNotification] = useHideNotification();

  if (!user) return null;
  if (!notification.data) return null;

  return (
    <Grow in={hideNotification === notification.data.id}>
      <IconButton
        onClick={() => {
          setHideNotification("");
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <Badge variant="dot" color="warning">
          <WarningIcon />
        </Badge>
      </IconButton>
    </Grow>
  );
}

import { Link } from "react-router-dom";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import AccountIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";

import { useMenuAnchor } from "~/hooks/state";
import {
  useCanGenerateExternalReceipt,
  useCanImpersonate,
  useIsDeveloper,
  useIsSupport,
} from "~/hooks/admin";
import { useAuthState, useSignOut } from "~/components/auth/AuthProvider";
import { SupportMenu } from "~/components/admin/SupportMenu";
import { DeveloperMenu } from "~/components/admin/DeveloperMenu";
import { ImpersonationMenu } from "~/components/admin/ImpersonationMenu";
import { ExternalReceiptMenu } from "~/components/admin/ExternalReceiptMenu";

export function UserMenu() {
  const [user] = useAuthState();
  const [menu, openMenu, closeMenu] = useMenuAnchor();
  const signOut = useSignOut();

  const isSupport = useIsSupport();
  const isDeveloper = useIsDeveloper();
  const canImpersonate = useCanImpersonate();
  const canGenerateExternalReceipt = useCanGenerateExternalReceipt();

  if (!user) return null;

  const avatar = (
    <Avatar sx={{ background: (theme) => theme.palette.primary.main }}>
      {(user.agent.givenNames ?? user.agent.familyName).at(0)}
    </Avatar>
  );

  return (
    <>
      <IconButton onClick={openMenu}>{avatar}</IconButton>
      <Menu
        open={menu.open}
        onClose={closeMenu}
        anchorEl={menu.anchor}
        disableRestoreFocus
      >
        <Box
          minWidth={240}
          maxWidth={300}
          gap={1.5}
          px={2}
          pt={1}
          pb={2}
          display="flex"
          alignItems="center"
        >
          {avatar}
          <Stack overflow="hidden">
            <Typography
              noWrap
              variant="body1"
              fontWeight="bold"
              fontSize="large"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {user.agent.givenNames} {user.agent.familyName}
            </Typography>
            <Typography
              noWrap
              variant="body2"
              color="text.secondary"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {user.agency.name}
            </Typography>
          </Stack>
        </Box>
        <Divider sx={{ mb: 1 }} />
        <MenuItem component={Link} to="/profile" onClick={closeMenu}>
          <AccountIcon sx={{ mr: 1 }} color="action" />
          Profile
        </MenuItem>
        <MenuItem onClick={() => signOut()}>
          <LogoutIcon sx={{ mr: 1 }} color="action" />
          Sign out
        </MenuItem>
        {isSupport && <SupportMenu onClick={closeMenu} />}
        {canGenerateExternalReceipt && <ExternalReceiptMenu onClick={closeMenu} />}
        {canImpersonate && <ImpersonationMenu onClick={closeMenu} />}
        {isDeveloper && <DeveloperMenu onClick={closeMenu} />}
      </Menu>
    </>
  );
}

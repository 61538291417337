import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  lighten,
  Tooltip,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayRemove,
  useFormState,
  useWatch,
} from "react-hook-form";

import { DisplayTable } from "~/components/core/TableStyles";
import { ErrorIndicatorCell } from "~/components/core/ErrorIndicatorCell";
import { ApplicationFields } from "~/components/application/ApplicationForm";
import { WorkExperience } from "~/components/application/ApplicationForm/WorkExperienceForm";
import { checkIfErrorsInTable, getTableContent } from "~/utils";

export interface WorkExperienceTableProps {
  control: Control<ApplicationFields>;
  fields: FieldArrayWithId<ApplicationFields>[];
  remove: UseFieldArrayRemove;
  onEdit: (index: number) => void;
  isEditing: boolean;
  disabled?: boolean;
  editIndex?: number;
  oldData?: WorkExperience;
}

function formatDates(startDate?: string, endDate?: string) {
  let formattedDates: string = "-";
  if (startDate) {
    formattedDates = new Date(startDate).toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
    });
    formattedDates += " - ";
    formattedDates += endDate
      ? new Date(endDate).toLocaleDateString(undefined, {
          year: "numeric",
          month: "short",
        })
      : "present";
  }
  return formattedDates;
}

export function WorkExperienceTable(props: WorkExperienceTableProps) {
  const { control, fields, remove, onEdit, disabled, editIndex, isEditing, oldData } =
    props;
  // up-to-date values for display in table
  const workExperience = useWatch({ control, name: "workExperience" });
  // up-to-date values for errors
  const { errors } = useFormState({ control, name: "workExperience" });
  const errorList = errors.workExperience;

  // Check if there are errors in the table that need to be displayed.
  const haveErrorsInTable = checkIfErrorsInTable(isEditing, oldData, errorList);

  const createTableRow = (
    displayValues: WorkExperience,
    index: number,
    fieldId: string,
  ) => {
    const formattedRefereeName =
      displayValues?.contact.firstName || displayValues?.contact.lastName ? (
        `${displayValues?.contact.firstName} ${displayValues?.contact.lastName}`
      ) : (
        <Box color="text.secondary">Not listed</Box>
      );
    const fieldErrors = errorList?.[index];

    // NOTE: optional chaining operators used below to avoid potential issues with displayValues being undefined
    // this may occur while values are being appended to the table, etc.
    return (
      <TableRow
        key={fieldId}
        sx={
          fieldErrors && {
            backgroundColor: (theme) => lighten(theme.palette.error.light, 0.9),
          }
        }
      >
        {haveErrorsInTable && <ErrorIndicatorCell errors={fieldErrors} />}
        <TableCell>{displayValues?.position}</TableCell>
        <TableCell>{displayValues?.employer}</TableCell>
        <TableCell>
          {formatDates(displayValues?.startDate, displayValues?.endDate)}
        </TableCell>
        <TableCell>{formattedRefereeName}</TableCell>
        <Tooltip
          title={
            isEditing &&
            "To modify this record, please first confirm any changes to the record currently being edited."
          }
        >
          <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
            <Tooltip title="Edit">
              <span>
                <IconButton
                  size="large"
                  onClick={() => onEdit(index)}
                  disabled={isEditing || disabled}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <span>
                <IconButton
                  size="large"
                  onClick={() => remove(index)}
                  disabled={isEditing || disabled}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </TableCell>
        </Tooltip>
      </TableRow>
    );
  };

  const tableContent = getTableContent(
    fields,
    EmptyTableContent,
    workExperience,
    createTableRow,
    editIndex,
    oldData,
  );

  return (
    <Grid item xs={12}>
      <DisplayTable.Container>
        <Table size="small">
          <TableHead>
            <DisplayTable.HeaderRow>
              {haveErrorsInTable && <DisplayTable.HeaderCell />}
              <DisplayTable.HeaderCell>Position</DisplayTable.HeaderCell>
              <DisplayTable.HeaderCell>Employer</DisplayTable.HeaderCell>
              <DisplayTable.HeaderCell>Duration</DisplayTable.HeaderCell>
              <DisplayTable.HeaderCell>Referee</DisplayTable.HeaderCell>
              <DisplayTable.HeaderCell />
            </DisplayTable.HeaderRow>
          </TableHead>
          <TableBody>{tableContent}</TableBody>
        </Table>
      </DisplayTable.Container>
    </Grid>
  );
}

const EmptyTableContent = (
  <TableRow>
    <TableCell colSpan={5} align="center" sx={{ py: 2, color: "text.secondary" }}>
      No work experience listed.
    </TableCell>
  </TableRow>
);

import { z } from "zod";

export type ApplicationId = `A-${number}`;
export const ApplicationIdSchema = z.string().regex(/^A-\d+$/);

export function isApplicationId(value: string): value is ApplicationId {
  return ApplicationIdSchema.safeParse(value).success;
}

export type ApplicantId = `C-${number}` | `C-A${number}`;
export const ApplicantIdSchema = z.string().regex(/^C-A?\d+$/);

export function isApplicantId(value: string): value is ApplicantId {
  return ApplicantIdSchema.safeParse(value).success;
}

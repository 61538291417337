import { Container, Constructable } from "typedi";

import { TokenApi } from "./__generated__/TokenApi";
import { ImpersonationApi } from "./__generated__/ImpersonationApi";
import { SupportApi } from "./__generated__/SupportApi";
import { SampleDataApi } from "./__generated__/SampleDataApi";
import { AuthApi } from "./__generated__/AuthApi";
import { OAuth2Api } from "./__generated__/OAuth2Api";
import { ReferenceDataApi } from "./__generated__/ReferenceDataApi";
import { ApplicationApi } from "./__generated__/ApplicationApi";
import { ApplicantApi } from "./__generated__/ApplicantApi";
import { AgencyApi } from "./__generated__/AgencyApi";
import { FeeWaiverCodeApi } from "./__generated__/FeeWaiverCodeApi";
import { PaymentApi } from "./__generated__/PaymentApi";
import { NotificationApi } from "./__generated__/NotificationApi";
import { EnquiryApi } from "./__generated__/EnquiryApi";

export type Api =
  | TokenApi
  | ImpersonationApi
  | SupportApi
  | SampleDataApi
  | AuthApi
  | OAuth2Api
  | ReferenceDataApi
  | ApplicationApi
  | ApplicantApi
  | AgencyApi
  | FeeWaiverCodeApi
  | PaymentApi
  | NotificationApi
  | EnquiryApi;

/**
 * Get an instance of an API class.
 *
 * @param api The API class to get.
 */
export function GetApi<T extends Api>(api: Constructable<T>) {
  return Container.get(api);
}

// Register API classes (need to do this manually since codegen can't add @Service() decorators)
Container.set({ id: TokenApi, type: TokenApi });
Container.set({ id: ImpersonationApi, type: ImpersonationApi });
Container.set({ id: SupportApi, type: SupportApi });
Container.set({ id: SampleDataApi, type: SampleDataApi });
Container.set({ id: OAuth2Api, type: OAuth2Api });
Container.set({ id: AuthApi, type: AuthApi });
Container.set({ id: ReferenceDataApi, type: ReferenceDataApi });
Container.set({ id: ApplicationApi, type: ApplicationApi });
Container.set({ id: ApplicantApi, type: ApplicantApi });
Container.set({ id: AgencyApi, type: AgencyApi });
Container.set({ id: FeeWaiverCodeApi, type: FeeWaiverCodeApi });
Container.set({ id: PaymentApi, type: PaymentApi });
Container.set({ id: NotificationApi, type: NotificationApi });
Container.set({ id: EnquiryApi, type: EnquiryApi });

// Export types and API classes
export * from "./__generated__/data-contracts";
export {
  TokenApi,
  ImpersonationApi,
  SupportApi,
  SampleDataApi,
  AuthApi,
  OAuth2Api,
  ReferenceDataApi,
  ApplicationApi,
  ApplicantApi,
  AgencyApi,
  FeeWaiverCodeApi,
  PaymentApi,
  NotificationApi,
  EnquiryApi,
};

export * from "./types";

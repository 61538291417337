import { useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import {
  ApplicationStatus,
  isApplicationStatusValidForEnquiryCreation,
} from "@packages/types";

import { RequestError } from "~/utils";
import { IApplication, IApplicationEnquiryList } from "~/api";
import { NewEnquiryModal } from "~/components/enquiry";
import { ApplicationEnquiriesTable } from "~/components/applicant/ApplicationEnquiriesTable";

export interface ApplicationEnquiriesSectionProps {
  applicationId: string;
  applicantId?: string;
  campusLocation?: string;
  applicationStatus?: ApplicationStatus | null;
  applicationCoursePreferences?: IApplication["application"]["coursePreferences"];
  applicationEnquiryList?: IApplicationEnquiryList;
  loading?: boolean;
  coursePreferencesLoading?: boolean;
  error?: RequestError | null;
}

export function ApplicationEnquiriesSection(props: ApplicationEnquiriesSectionProps) {
  const {
    applicationId,
    applicantId,
    campusLocation,
    applicationStatus,
    applicationCoursePreferences,
    applicationEnquiryList,
    loading,
    coursePreferencesLoading,
    error,
  } = props;
  const applicationHasBeenSubmitted =
    applicationStatus && isApplicationStatusValidForEnquiryCreation(applicationStatus);
  const applicationHasBeenCancelled = applicationStatus === "Cancelled";

  const [newEnquiryModalOpen, setNewEnquiryModalOpen] = useState(false);
  return (
    <Box>
      {applicationHasBeenSubmitted ? (
        <>
          <Typography variant="body2" color="text.secondary" mb={2}>
            Raise a new enquiry regarding this application by clicking the button below.
          </Typography>
          <Button
            variant="contained"
            sx={{ mb: 4 }}
            onClick={() => setNewEnquiryModalOpen(true)}
          >
            New Enquiry
          </Button>
          <NewEnquiryModal
            applicationId={applicationId}
            applicantId={applicantId}
            campusLocation={campusLocation}
            coursePreferences={applicationCoursePreferences}
            coursePreferencesLoading={coursePreferencesLoading}
            open={newEnquiryModalOpen}
            onClose={() => setNewEnquiryModalOpen(false)}
          />
          <ApplicationEnquiriesTable
            applicationEnquiryList={applicationEnquiryList}
            loading={loading}
            error={error}
          />
        </>
      ) : (
        <Typography variant="body2" color="text.secondary" component="div">
          {applicationHasBeenCancelled ? (
            <>
              <Typography variant="inherit" fontWeight="bold" gutterBottom>
                Our records indicate that this application has been cancelled.
              </Typography>
              <Typography variant="inherit">
                You may not open any new enquiries for this application at this time.
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="inherit" fontWeight="bold" gutterBottom>
                Our records indicate that this application has not yet been submitted.
              </Typography>
              <Typography variant="inherit">
                Please complete and submit this application in order to be able to raise
                an enquiry with Monash Admissions.
              </Typography>
            </>
          )}
        </Typography>
      )}
    </Box>
  );
}

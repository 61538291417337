import { useWatch } from "react-hook-form";
import { Button, Stack, StackProps, Typography } from "@mui/material";
import { useHotkeys } from "react-hotkeys-hook";

import { doesObjectHaveTrueValues } from "~/utils";
import { UseApplicationForm } from "~/components/application/ApplicationForm";
import { Timestamp } from "~/components/core/Timestamp";

export interface ApplicationFormActionsProps extends StackProps {
  form: UseApplicationForm;
}

export function ApplicationFormActions(props: ApplicationFormActionsProps) {
  const { onSave, loading, formRef, form } = props.form;
  const { control } = form;

  const [lastModifiedDate, applicationStatus] = useWatch({
    control,
    name: ["lastModifiedDate", "applicationStatus"],
  });

  const isDirty = doesObjectHaveTrueValues(form.formState.dirtyFields);
  const canSave = !loading && applicationStatus === "Draft" && isDirty;

  // Add keyboard shortcut for saving
  useHotkeys(
    ["meta+s", "ctrl+s"],
    () => {
      if (canSave) onSave();
    },
    { enableOnFormTags: true, preventDefault: true },
  );

  return (
    <Stack {...props}>
      {applicationStatus === "Draft" && (
        <>
          <Button fullWidth variant="outlined" onClick={onSave} disabled={!canSave}>
            Save draft
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() => formRef?.current?.requestSubmit()}
            disabled={loading}
          >
            Send to applicant
          </Button>
          {lastModifiedDate && (
            <Typography
              variant="body2"
              color="text.secondary"
              textAlign={{ xs: "center", md: "left" }}
            >
              Last saved <Timestamp>{lastModifiedDate}</Timestamp>
            </Typography>
          )}
        </>
      )}
      {applicationStatus === "Submitted" && <></>}
    </Stack>
  );
}

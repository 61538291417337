import {
  Box,
  CircularProgress,
  IconButton,
  Snackbar,
  SnackbarProps,
  Typography,
  TypographyProps,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";

export interface NotificationSnackbarProps
  extends Omit<SnackbarProps, "children" | "onClose"> {
  disableTypography?: boolean;
  variant?: TypographyProps["variant"];
  icon?: "loading" | "success" | "error" | "warning" | React.ReactNode;
  closable?: boolean;
  onClose?: () => void;
}

export function NotificationSnackbar(props: NotificationSnackbarProps) {
  const {
    message,
    disableTypography,
    variant = "body2",
    icon,
    closable,
    onClose,
    ...rest
  } = props;
  return (
    <Snackbar
      {...rest}
      onClose={(_, reason) => {
        // Don't allow loading snackbars to be closed
        if (icon === "loading") return;
        // Allow snackbar to be closed by esc key or timeout otherwise
        if (reason === "escapeKeyDown" || reason === "timeout") onClose?.();
      }}
      message={
        <Box display="flex" alignItems="center" gap={1.5} pr={4}>
          {icon && (
            <Box
              component="span"
              sx={{ my: 0.5, alignSelf: "start", display: "flex", alignItems: "center" }}
            >
              {icon === "loading" && <CircularProgress size={20} thickness={6} />}
              {icon === "success" && (
                <CheckCircleIcon
                  fontSize="small"
                  sx={{ color: (theme) => theme.palette.success.light }}
                />
              )}
              {icon === "warning" && (
                <WarningIcon
                  fontSize="small"
                  sx={{ color: (theme) => theme.palette.warning.light }}
                />
              )}
              {icon === "error" && (
                <CancelIcon
                  fontSize="small"
                  sx={{ color: (theme) => theme.palette.error.light }}
                />
              )}
              {typeof icon !== "string" && icon}
            </Box>
          )}
          {disableTypography ? (
            message
          ) : (
            <Typography variant={variant} lineHeight="initial">
              {message}
            </Typography>
          )}
          {closable && (
            <IconButton
              sx={{ position: "absolute", right: 12, alignSelf: "start" }}
              color="inherit"
              size="small"
              onClick={onClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      }
    />
  );
}

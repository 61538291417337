import { Box, Button, Container, List, Paper, Typography } from "@mui/material";

import { joinName } from "@packages/utils";

import { AppNotFoundError } from "~/utils";
import { useDocumentTitle } from "~/hooks/title";
import { useAuthState, useSignOut } from "~/components/auth/AuthProvider";
import { ListDisplayValue } from "~/components/core/ListDisplayValue";

export function AgentProfilePage() {
  useDocumentTitle("Profile");

  const signOut = useSignOut();
  const [user] = useAuthState();
  if (!user) throw new AppNotFoundError();

  const { email, agent, agency } = user;
  const name = joinName(agent.givenNames, agent.familyName);

  return (
    <Container sx={{ my: 6 }} maxWidth="md">
      <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" fontWeight="bold" color="primary">
          {name}
        </Typography>
      </Box>
      <Box component={Paper}>
        <List>
          <ListDisplayValue label="NAME" value={name} />
          <ListDisplayValue label="EMAIL" value={email} />
          <ListDisplayValue label="AGENCY NAME" value={agency.name} />
          <ListDisplayValue label="AGENCY ID" value={agency.orgUnitCode} />
        </List>
      </Box>
      <Button variant="contained" sx={{ mt: 4 }} onClick={() => signOut()}>
        Sign out
      </Button>
    </Container>
  );
}

import { z } from "zod";

/**
 * Validates that a string is a valid email address.
 */
export function EmailStringSchema(
  message: string = "Please provide a valid email address",
) {
  return z.string().trim().email(message);
}

EmailStringSchema.allowBlank = (message?: string) =>
  z.union([z.literal(""), EmailStringSchema(message)]);

export function isNonMonashEmail(value: string): value is string {
  return !value.endsWith("@monash.edu");
}

export function asNonMonashEmail(
  schema: z.ZodType<string>,
  message: string = "Emails with the @monash.edu domain are not allowed",
) {
  return schema.refine(isNonMonashEmail, message);
}

import { Alert } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { IApplicationStatusType } from "~/api";
import { useApplicationSearchResults } from "~/hooks/search";
import { useLoadResource } from "~/components/core/AppLoadingBar";
import { ApplicationsTable, SearchResultsContainer } from "~/components/dashboard";

import { DashboardFilter, FILTER_MAP } from "./DashboardFilterButtons";

export interface ApplicationStatusSearchProps {
  statusType?: IApplicationStatusType;
  page?: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
}

export function ApplicationStatusSearch(props: ApplicationStatusSearchProps) {
  const { statusType, page, setPage } = props;

  const results = useApplicationSearchResults({
    statusType,
    page,
    getApplicantNamesAndIds: true,
  });

  const [previousStatusType, setPreviousStatusType] = useState(props.statusType);
  const [pageInfo, setPageInfo] = useState(results.data?.pageInfo);

  // Cache page info so that pagination doesn't disappear on each page change
  // If the status filter changes, clear the saved page info
  useEffect(() => {
    if (previousStatusType !== statusType) {
      setPageInfo(undefined);
      setPreviousStatusType(statusType);
    } else {
      if (results.data?.pageInfo) setPageInfo(results.data?.pageInfo);
    }
  }, [results.data, statusType, previousStatusType]);

  const empty = pageInfo?.totalRecords === 0;

  useLoadResource(
    useCallback(() => results.isFetching, [results.isFetching]),
    ApplicationStatusSearch.name,
  );

  const title = statusType ? DashboardFilter[statusType] : "";
  const background = statusType ? FILTER_MAP[statusType].background : undefined;

  return (
    <SearchResultsContainer
      title={
        <>
          {title} Applications
          {pageInfo ? ` (${pageInfo.totalRecords})` : ""}
        </>
      }
      titleBackground={background}
      currentPage={page}
      pageInfo={pageInfo}
      setCurrentPage={setPage}
    >
      {empty ? (
        <Alert severity="info" sx={{ m: 3 }}>
          No applications found.
        </Alert>
      ) : (
        <ApplicationsTable
          loading={results.isPending}
          namesLoading={results.applicantNamesAndIds.isPending}
          results={results.data?.results}
        />
      )}
    </SearchResultsContainer>
  );
}

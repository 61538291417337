import {
  hoursToMilliseconds,
  minutesToMilliseconds,
  secondsToMilliseconds,
} from "date-fns";

/** Data expected to change frequently. */
export const REALTIME_DATA_STALE_TIME = secondsToMilliseconds(10);

/** Data expected to change occassionally. */
export const DATA_STALE_TIME = minutesToMilliseconds(5);

/** Data expected to change infrequently. */
export const REFERENCE_DATA_STALE_TIME = hoursToMilliseconds(4);

import {
  Stack,
  Typography,
  Fade,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  IconButton,
  Box,
  Tooltip,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import prettyBytes from "pretty-bytes";

import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";
import UploadIcon from "@mui/icons-material/Upload";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { PicklistValue } from "@packages/types";

import { parseRequestError } from "~/utils";
import { ErrorNotificationMessage } from "~/components/core/ErrorMessage";

import { DocumentUpload } from "./useUploadDocument";

export interface DocumentUploadItemProps {
  upload: DocumentUpload;
  onRemove?: () => void;
  onSetDocumentType?: (type: PicklistValue) => void;
  documentTypes?: PicklistValue[];
  disabled?: boolean;
  loading?: boolean;
}

export function DocumentUploadItem(props: DocumentUploadItemProps) {
  const { upload, onRemove, onSetDocumentType, documentTypes, disabled, loading } = props;
  const { file, userErrors, type } = upload;

  const error = upload.result?.error;
  const requestError = parseRequestError(error);

  return (
    <Paper
      key={file.name}
      elevation={1}
      sx={{
        position: "relative",
        border: 1,
        borderColor: (theme) => theme.palette.background.default,
        borderRadius: 2,
        p: 2,
      }}
    >
      {!upload.result?.success && onRemove && (
        <Tooltip title={loading || disabled ? "" : "Remove file"}>
          <Box sx={{ position: "absolute", right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={onRemove} disabled={disabled || loading}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Tooltip>
      )}
      <Stack direction="row" gap={2}>
        {userErrors.length > 0 ? (
          <Tooltip title="Unable to upload this file">
            <CancelIcon color="error" />
          </Tooltip>
        ) : upload.result?.success === true ? (
          <Tooltip title="Upload successful">
            <CheckCircleIcon color="success" />
          </Tooltip>
        ) : !upload.result || loading ? (
          <Tooltip title={loading ? "Uploading" : "Ready for upload"}>
            <Box sx={{ position: "relative", height: "min-content" }}>
              <Fade in={loading}>
                <CircularProgress
                  size={32}
                  thickness={6}
                  sx={{ position: "absolute", inset: -4 }}
                />
              </Fade>
              <UploadIcon color={loading ? "disabled" : "action"} />
            </Box>
          </Tooltip>
        ) : (
          <Tooltip title="Upload failed">
            <WarningIcon color="warning" />
          </Tooltip>
        )}
        {/*
         * The flex-grow and min-width properties work together to make sure that the
         * `Select` component grows to the max size of the container but does not overflow.
         */}
        <Stack flexGrow={1} minWidth={0}>
          <Typography variant="body1" fontWeight="bold" noWrap pr={4}>
            {file.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" noWrap>
            {file.type}, {prettyBytes(file.size)}
          </Typography>
          {userErrors.length > 0 ? (
            <Box mt={1}>
              {userErrors.map((error, index) => (
                <Typography key={index} variant="body2" color="error">
                  {error.message}
                </Typography>
              ))}
            </Box>
          ) : (
            // Only show document types select if they are provided
            documentTypes && (
              <FormControl
                size="small"
                sx={{ mt: 3 }}
                variant="outlined"
                disabled={loading || disabled || upload.result?.success}
              >
                <InputLabel id={file.name}>Document type</InputLabel>
                <Select
                  labelId={file.name}
                  value={type?.value ?? ""}
                  label="Document type"
                  onChange={(e) => {
                    const match = documentTypes.find((x) => x.value === e.target.value);
                    if (match) onSetDocumentType?.(match);
                  }}
                >
                  {documentTypes.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )
          )}
          {!loading && error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {requestError ? (
                <ErrorNotificationMessage message="Upload failed" error={requestError} />
              ) : (
                <>
                  <AlertTitle>Upload failed</AlertTitle>
                  {error.message}
                </>
              )}
            </Alert>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
}

class BaseTimer {
  constructor(protected timerName?: string) {}

  get name() {
    if (this.timerName) return `Timer[${this.timerName}]`;
    return "Timer";
  }
}

/** A new timer that has not been started yet. */
export class Timer extends BaseTimer {
  constructor(timerName?: string) {
    super(timerName);
  }

  start() {
    return new RunningTimer(this);
  }
}

/** A timer that has been started. */
export class RunningTimer extends BaseTimer {
  readonly startTime = performance.now();

  constructor(timer: Timer) {
    super(timer.name);
  }

  stop() {
    return new StoppedTimer(this);
  }
}

/** A timer that has been stopped. */
export class StoppedTimer extends BaseTimer {
  readonly startTime: number;
  readonly endTime = performance.now();

  constructor(timer: RunningTimer) {
    super(timer.name);
    this.startTime = timer.startTime;
  }

  get duration() {
    return this.endTime - this.startTime;
  }
}

type RequiredNonNullable<T> = T extends T
  ? { [P in keyof T]-?: NonNullable<T[P]> }
  : never;

/**
 * Guarantees that the object has non-undefined values for
 * all fields, or returns null otherwise.
 */
export function toNonEmptyObject<T extends { [key: string]: unknown }>(
  value: T | null | undefined,
): RequiredNonNullable<T> | null {
  if (!value) return null;
  const keys = Object.keys(value);
  const empty = keys.some((key) => value[key] === undefined);
  if (empty) return null;
  return value as RequiredNonNullable<T>;
}

import { Controller, FieldValues, ControllerProps } from "react-hook-form";
import { MuiTelInput, MuiTelInputProps } from "mui-tel-input";

import { GetFieldPathToType, ValueSatisfiesType } from "~/types";

export interface FormPhoneFieldProps<TFieldValues extends FieldValues = FieldValues>
  extends Pick<ControllerProps<TFieldValues>, "control">,
    Omit<MuiTelInputProps, "name" | "error" | "contentEditable" | "forceCallingCode"> {
  name: GetFieldPathToType<string, TFieldValues>;
  readOnly?: boolean;
  onBeforeChange?: MuiTelInputProps["onChange"];
}

export function FormPhoneField<TFieldValues extends FieldValues = FieldValues>(
  props: FormPhoneFieldProps<TFieldValues>,
) {
  const { name, control, readOnly, disabled, defaultCountry, onBeforeChange, ...rest } =
    props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <MuiTelInput
          id={name}
          error={fieldState.invalid}
          helperText={fieldState.error?.message}
          InputProps={{ readOnly }}
          disableDropdown={readOnly}
          defaultCountry={
            // If there is no value and the field is disabled, don't show a default country
            (!field.value && disabled) || readOnly ? undefined : defaultCountry
          }
          {...rest}
          {...field}
          disabled={field.disabled || disabled}
          onChange={(value, info) => {
            // NOTE: if reason is "country", and the countryCode is null,
            // this change comes from the default country code after resetting the form.
            // Therefore, we don't need to update the field value in this instance
            if (info.reason === "country" && info.countryCode === null) return;

            // in other cases, update the form as usual
            onBeforeChange?.(value, info);
            field.onChange(value as ValueSatisfiesType<TFieldValues, string>);
          }}
        />
      )}
    />
  );
}

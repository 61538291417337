import { Controller, FieldValues, ControllerProps } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  Switch,
} from "@mui/material";

import { GetFieldPathToType } from "~/types";

export interface FormSwitchProps<TFieldValues extends FieldValues = FieldValues>
  extends Pick<ControllerProps<TFieldValues>, "control">,
    Omit<FormControlLabelProps, "name" | "error" | "control" | "sx">,
    Pick<FormControlProps, "sx"> {
  name: GetFieldPathToType<boolean, TFieldValues>;
}

export function FormSwitch<TFieldValues extends FieldValues = FieldValues>(
  props: FormSwitchProps<TFieldValues>,
) {
  const { name, control, sx, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl id={name} error={fieldState.invalid} sx={sx}>
          <FormControlLabel {...rest} control={<Switch {...field} />} />
          <FormHelperText sx={{ m: 0 }}>{fieldState.error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}

import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { joinName } from "@packages/utils";

import { IApplicantsPage } from "~/api";
import { useBackLink } from "~/components/core/BackLink";
import { Timestamp } from "~/components/core/Timestamp";

import { DashboardTable } from "./DashboardTable";

type ApplicantsTableProps = {
  loading?: boolean;
  resultsPage?: IApplicantsPage | undefined;
};

const headings = [
  { id: "name", label: "Name" },
  { id: "dob", label: "Date of Birth" },
  { id: "studentId", label: "Student ID" },
  { id: "email", label: "Email" },
  { id: "mobile", label: "Mobile" },
];

const PLACEHOLDERS = Array.from({ length: 15 }).map((_, index) => (
  <ApplicantsTableRow key={index} loading />
));

/**
 * Table for displaying applications available in the dashboard
 */
export function ApplicantsTable(props: ApplicantsTableProps) {
  const { loading, resultsPage } = props;
  const { results } = resultsPage ?? {};

  return (
    <DashboardTable headings={headings} sx={{ px: 3, pb: 3 }}>
      {loading
        ? PLACEHOLDERS
        : results?.map((result) => (
            <ApplicantsTableRow key={result.applicantId} {...result} />
          ))}
    </DashboardTable>
  );
}

type ApplicantsTableRowProps = {
  loading?: boolean;
} & Partial<IApplicantsPage["results"][number]>;

function ApplicantsTableRow(props: ApplicantsTableRowProps) {
  const {
    loading,
    applicantId,
    firstName,
    lastName,
    preferredName,
    dateOfBirth,
    studentId,
    email: emails = [],
    phone: phones = [],
  } = props;

  const backLink = useBackLink();

  const email = emails.find((x) => x.type === "Personal");
  const phone = phones.find((x) => x.type === "Mobile");

  return (
    <DashboardTable.Row key={applicantId} disableHighlight={loading}>
      <DashboardTable.Cell skeleton={loading}>
        <Link
          component={RouterLink}
          to={`/applicants/${applicantId}`}
          state={{ backLink }}
          underline="hover"
        >
          {lastName ? (
            <Typography variant="body1" fontWeight="bold">
              {joinName(firstName, lastName, preferredName)}
            </Typography>
          ) : (
            <DashboardTable.Placeholder noWrap color="primary">
              Name not provided
            </DashboardTable.Placeholder>
          )}
        </Link>
      </DashboardTable.Cell>
      <DashboardTable.Cell skeleton={loading}>
        {dateOfBirth ? (
          <Typography variant="body1" noWrap>
            <Timestamp format="d/MM/yyyy">{dateOfBirth}</Timestamp>
          </Typography>
        ) : (
          <DashboardTable.Placeholder noWrap>Not provided</DashboardTable.Placeholder>
        )}
      </DashboardTable.Cell>
      <DashboardTable.Cell skeleton={loading}>
        {studentId ? (
          <Typography variant="body1" noWrap>
            {studentId}
          </Typography>
        ) : (
          <DashboardTable.Placeholder noWrap>Not generated</DashboardTable.Placeholder>
        )}
      </DashboardTable.Cell>
      <DashboardTable.Cell skeleton={loading}>
        {email ? (
          <Typography variant="body1">{email?.email}</Typography>
        ) : (
          <DashboardTable.Placeholder noWrap>Not provided</DashboardTable.Placeholder>
        )}
      </DashboardTable.Cell>
      <DashboardTable.Cell skeleton={loading}>
        {phone ? (
          <Typography variant="body1" noWrap>
            {phone?.phoneNumber}
          </Typography>
        ) : (
          <DashboardTable.Placeholder noWrap>Not provided</DashboardTable.Placeholder>
        )}
      </DashboardTable.Cell>
    </DashboardTable.Row>
  );
}

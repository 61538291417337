import { useCallback, useMemo, useState } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Badge,
  ListSubheader,
  Typography,
  Alert,
} from "@mui/material";
import FilterIcon from "@mui/icons-material/FilterList";
import CheckIcon from "@mui/icons-material/Check";

import { IAgent, IAgentStatusFilter } from "~/api";
import { useMenuAnchor } from "~/hooks/state";
import { RequestError } from "~/utils/errors";
import { useLoadResource } from "~/components/core/AppLoadingBar";
import { ErrorNotificationMessage } from "~/components/core/ErrorMessage";
import { EditUserModal } from "~/components/user/EditUserModal";
import { UpdateUserStatusModal } from "~/components/user/UpdateUserStatusModal";
import { UpdateUserStatusAction } from "~/components/user/UpdateUserStatusModal";

import { UsersTableRow } from "./UsersTableRow";

const FILTERS: {
  value: IAgentStatusFilter;
  label: string;
  description?: string | React.ReactNode;
}[] = [
  {
    value: "all",
    label: "All users",
    description: <>Show active, inactive and deactivated users</>,
  },
  {
    value: "active",
    label: "Active users",
    description: <>Active users can sign in</>,
  },
  {
    value: "inactive",
    label: "Inactive users",
    description: <>Inactive users must be activated before they can sign in</>,
  },
  // {
  //   value: "suspended",
  //   label: "Suspended users",
  //   description: <>Suspended users must be unsuspended before they can sign in</>,
  // },
  {
    value: "deactivated",
    label: "Deactivated users",
    description: <>Deactivated users can not sign in</>,
  },
];

export interface UsersTableProps {
  filter?: IAgentStatusFilter;
  setFilter?: (filter: IAgentStatusFilter) => void;
  page?: number;
  setPage?: (page: number) => void;
  pageSize?: number;
  setPageSize?: (pageSize: number) => void;
  pageSizes?: number[];
  agents?: IAgent[];
  loading?: boolean;
  error?: RequestError | null;
}

export function UsersTable(props: UsersTableProps) {
  const {
    filter = "all",
    setFilter,
    page = 1,
    setPage,
    pageSize = 25,
    setPageSize,
    pageSizes = [pageSize],
    agents = [],
    loading,
    error,
  } = props;

  const [menu, openMenu, closeMenu] = useMenuAnchor();
  const [userToEdit, setUserToEdit] = useState<IAgent>();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [updateStatusModal, setUpdateStatusModal] = useState<{
    open: boolean;
    action: UpdateUserStatusAction;
  }>({ open: false, action: "suspend" });

  const agentsToDisplay = useMemo(() => {
    if (!agents) return [];
    const start = pageSize * page;
    return agents.slice(start, start + pageSize);
  }, [agents, page, pageSize]);

  useLoadResource(
    useCallback(() => Boolean(loading), [loading]),
    UsersTable.name,
  );

  const placeholders = useMemo(
    () =>
      Array.from({ length: pageSize }).map((_, index) => <UsersTableRow key={index} />),
    [pageSize],
  );

  return (
    <>
      <TableContainer component={Paper} elevation={1}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 150 }}>Name</TableCell>
              <TableCell sx={{ minWidth: 150 }}>Email</TableCell>
              <TableCell sx={{ minWidth: 200 }}>Role</TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                Status
                <Badge
                  color="primary"
                  variant="dot"
                  invisible={filter === "all"}
                  // Prevent the badge dot from blocking the button
                  sx={{ "& .MuiBadge-badge": { pointerEvents: "none" } }}
                >
                  <IconButton edge="end" sx={{ ml: 1, my: -1 }} onClick={openMenu}>
                    <FilterIcon />
                  </IconButton>
                </Badge>
                <Menu anchorEl={menu.anchor} open={menu.open} onClose={closeMenu}>
                  <ListSubheader sx={{ lineHeight: 3 }}>Filter by status</ListSubheader>
                  {FILTERS.map(({ value, label, description }) => (
                    <MenuItem
                      key={value}
                      onClick={() => {
                        setFilter?.(value);
                        setPage?.(0);
                        closeMenu();
                      }}
                    >
                      {filter === value && (
                        <ListItemIcon sx={{ alignSelf: "start" }}>
                          <CheckIcon />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        inset={filter !== value}
                        primary={label}
                        secondaryTypographyProps={{ maxWidth: 260, whiteSpace: "normal" }}
                        secondary={description}
                      />
                    </MenuItem>
                  ))}
                </Menu>
              </TableCell>
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && agents.length === 0 && placeholders}
            {agentsToDisplay.map((agent) => (
              <UsersTableRow
                key={agent.contactId}
                agent={agent}
                onEdit={() => {
                  setUserToEdit(agent);
                  setEditModalOpen(true);
                }}
                // NOTE: suspending (freezing) users out of scope for MVP
                // onSuspend={() => {
                //   setUserToEdit(agent);
                //   setUpdateStatusModal({ open: true, action: "suspend" });
                // }}
                // onUnsuspend={() => {
                //   setUserToEdit(agent);
                //   setUpdateStatusModal({ open: true, action: "unsuspend" });
                // }}
                onDeactivate={() => {
                  setUserToEdit(agent);
                  setUpdateStatusModal({ open: true, action: "deactivate" });
                }}
              />
            ))}
          </TableBody>
        </Table>
        {!loading && !error && agentsToDisplay.length === 0 && (
          <Typography py={4} color="text.secondary" variant="body2" textAlign="center">
            No users available {filter !== "all" && "with the current status filter"}
          </Typography>
        )}
        {!loading && error && (
          <Alert severity="error" sx={{ m: 2 }}>
            <ErrorNotificationMessage error={error} message="Failed to load users" />
          </Alert>
        )}
        {!loading && agentsToDisplay.length > 0 && (
          <TablePagination
            rowsPerPageOptions={pageSizes}
            component="div"
            count={agents.length ?? -1}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={(e, page) => setPage?.(page)}
            onRowsPerPageChange={(e) => {
              setPage?.(0);
              setPageSize?.(Number(e.target.value));
            }}
          />
        )}
      </TableContainer>
      <EditUserModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        user={userToEdit}
      />
      <UpdateUserStatusModal
        key={updateStatusModal.action}
        open={updateStatusModal.open}
        action={updateStatusModal.action}
        onClose={() => setUpdateStatusModal((state) => ({ ...state, open: false }))}
        user={userToEdit}
      />
    </>
  );
}

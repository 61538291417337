import { UseQueryResult } from "@tanstack/react-query";
import {
  Accordion,
  AccordionSummary,
  Typography,
  Grid,
  AccordionDetails,
  Divider,
  Stack,
  Link,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { Link as RouterLink } from "react-router-dom";

import { joinName } from "@packages/utils";

import { IAgent, IEnquiryDetails } from "~/api";
import { ApplicantDetailLabel } from "~/components/applicant/ApplicantDetailsCard";
import { Timestamp } from "~/components/core/Timestamp";
import { useApplicant } from "~/hooks/applicant";
import { useBackLink } from "~/components/core/BackLink";

export interface EnquiryTitleCardProps {
  enquiry: IEnquiryDetails;
  authorAgent: UseQueryResult<IAgent, unknown>;
}

export function EnquiryTitleCard(props: EnquiryTitleCardProps) {
  const {
    enquiry: {
      applicantId,
      applicationId,
      subject,
      enquiryNumber,
      category,
      createdDate,
      agent,
      agency,
    },
    authorAgent,
  } = props;

  const enquiryTopic = Object.values(category)
    .filter((x) => x)
    .join(" - ");

  const applicant = useApplicant(applicantId);
  const { firstName, lastName, preferredName, email } = applicant.data || {};
  const applicantName = joinName(firstName, lastName, preferredName);
  const applicantPersonalEmail = email?.find((x) => x.type === "Personal")?.email;

  const authorAgentName = joinName(
    authorAgent.data?.firstName,
    authorAgent.data?.lastName,
  );

  const backLink = useBackLink();
  const applicationLink = (
    <Link
      component={RouterLink}
      to={`/applications/${applicationId}`}
      state={{ backLink }}
      underline="hover"
    >
      <Typography variant="inherit">{applicationId}</Typography>
    </Link>
  );

  return (
    <Accordion
      elevation={1}
      sx={{
        p: 1,
        borderRadius: 2,
        "&:before": { display: "none" },
      }}
      // FIXME: doesn't need to be expanded by default once we render the comments properly
      defaultExpanded
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" gap={3} my={1}>
          <LiveHelpIcon fontSize="large" sx={{ display: { xs: "none", sm: "block" } }} />
          <Typography variant="h4">{subject}</Typography>
        </Stack>
      </AccordionSummary>
      <Grid component={AccordionDetails} container>
        <Grid item xs={12} md={6}>
          <ApplicantDetailLabel label="Enquiry Number" value={enquiryNumber} />
          <ApplicantDetailLabel label="Topic" value={enquiryTopic} />
        </Grid>
        <Grid item xs={12} md={6}>
          <ApplicantDetailLabel
            label="Created at"
            value={<Timestamp>{createdDate}</Timestamp>}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Raised By
          </Typography>
          {agent.email ? (
            <>
              <ApplicantDetailLabel
                label="Agent Name"
                value={authorAgentName}
                loading={authorAgent.isPending}
              />
              <ApplicantDetailLabel label="Agent Email" value={agent.email} />
              <ApplicantDetailLabel label="Agency" value={agency.name} />
            </>
          ) : (
            // ASSUMPTION: If agent.email is empty, this means that the enquiry comes from Monash itself
            <ApplicantDetailLabel label="Organisation" value="Monash University" />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            On Behalf Of
          </Typography>
          <ApplicantDetailLabel
            label="Applicant Name"
            value={applicantName}
            loading={applicant.isPending}
          />
          <ApplicantDetailLabel
            label="Applicant Email"
            value={applicantPersonalEmail}
            loading={applicant.isPending}
          />
          <ApplicantDetailLabel label="Application ID" value={applicationLink} />
        </Grid>
      </Grid>
    </Accordion>
  );
}

import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  lighten,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayRemove,
  useFormState,
  useWatch,
} from "react-hook-form";

import { DisplayTable } from "~/components/core/TableStyles";
import { ErrorIndicatorCell } from "~/components/core/ErrorIndicatorCell";
import { ApplicationFields } from "~/components/application/ApplicationForm";
import { CoursePreference } from "~/components/application/ApplicationForm/CoursePreferencesForm";
import { getCourseOptions } from "~/components/application/CourseSelectionForm/_utils";
import { checkIfErrorsInTable, getTableContent } from "~/utils";

// matches common study period format, for e.g.: "2025 Semester 1 (February - March start)"
const COMMON_STUDY_PERIOD_REGEX = /^(.+)\s(\([^)]+\))$/;

function getCoursePreferenceDisplayValues(coursePreference: CoursePreference) {
  const streams = coursePreference ? getCourseOptions(coursePreference) : {};
  const specialisation = streams[coursePreference.stream]?.label;
  const studyPeriods = streams[coursePreference.stream]?.studyPeriods;
  const studyPeriod = studyPeriods?.[coursePreference.studyPeriod];
  const locations = studyPeriod?.locations ?? {};
  const location = locations[coursePreference.location];

  // if study period is in a common format, break the string into two parts for display
  const matchesArray = COMMON_STUDY_PERIOD_REGEX.exec(studyPeriod?.label ?? "");
  const studyPeriodLabel = matchesArray ? matchesArray[1] : (studyPeriod?.label ?? "");
  const studyPeriodSubLabel = matchesArray ? matchesArray[2] : "";

  const notSelectedNode = (
    <Box component="span" color="text.secondary">
      Not selected
    </Box>
  );

  return {
    courseCode: coursePreference.courseCode || notSelectedNode,
    courseTitle: coursePreference.courseInfo
      ? coursePreference.courseInfo?.title
      : notSelectedNode,
    specialisation,
    studyPeriodLabel: studyPeriod ? studyPeriodLabel : notSelectedNode,
    studyPeriodSubLabel,
    location: location ? location.label : notSelectedNode,
  };
}

export interface CoursePreferencesTableProps {
  control: Control<ApplicationFields>;
  fields: FieldArrayWithId<ApplicationFields>[];
  remove: UseFieldArrayRemove;
  onEdit: (index: number) => void;
  isEditing: boolean;
  editIndex?: number;
  oldData?: CoursePreference;
  disabled?: boolean;
}

export function CoursePreferencesTable(props: CoursePreferencesTableProps) {
  const { control, fields, remove, onEdit, disabled, editIndex, isEditing, oldData } =
    props;
  // up-to-date values for display in table
  const preferenceList = useWatch({ control, name: "coursePreferences.preferenceList" });
  // up-to-date values for errors
  const { errors } = useFormState({ control, name: "coursePreferences.preferenceList" });
  const errorList = errors.coursePreferences?.preferenceList;

  // Check if there are errors in the table that need to be displayed.
  const haveErrorsInTable = checkIfErrorsInTable(isEditing, oldData, errorList);

  const createTableRow = (
    fieldValues: CoursePreference,
    index: number,
    fieldId: string,
  ) => {
    const {
      courseCode,
      courseTitle,
      location,
      studyPeriodLabel,
      studyPeriodSubLabel,
      specialisation,
    } = getCoursePreferenceDisplayValues(fieldValues);
    const fieldErrors = errorList?.[index];
    return (
      <TableRow
        key={fieldId}
        sx={
          fieldErrors && {
            backgroundColor: (theme) => lighten(theme.palette.error.light, 0.9),
          }
        }
      >
        {haveErrorsInTable && <ErrorIndicatorCell errors={fieldErrors} />}
        <TableCell align="center">{index + 1}</TableCell>
        <TableCell scope="row">
          <Typography variant="inherit" fontWeight="bold">
            {courseCode}
          </Typography>
          <Typography variant="inherit">{courseTitle}</Typography>
          <Typography variant="caption">{specialisation}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="inherit">{studyPeriodLabel}</Typography>
          {studyPeriodSubLabel && (
            <Typography variant="inherit">{studyPeriodSubLabel}</Typography>
          )}
          <Typography variant="caption">{location}</Typography>
        </TableCell>
        <Tooltip
          title={
            isEditing &&
            "To modify this record, please first confirm any changes to the record currently being edited."
          }
        >
          <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
            <Tooltip title="Edit">
              <span>
                <IconButton
                  size="large"
                  onClick={() => onEdit(index)}
                  disabled={isEditing || disabled}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <span>
                <IconButton
                  size="large"
                  onClick={() => remove(index)}
                  disabled={isEditing || disabled}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </TableCell>
        </Tooltip>
      </TableRow>
    );
  };

  const tableContent = getTableContent(
    fields,
    EmptyTableContent,
    preferenceList,
    createTableRow,
    editIndex,
    oldData,
  );

  return (
    <Grid item xs={12}>
      <DisplayTable.Container>
        <Table>
          <TableHead>
            <DisplayTable.HeaderRow>
              {haveErrorsInTable && <DisplayTable.HeaderCell size="small" />}
              <DisplayTable.HeaderCell
                size="small"
                align="center"
                sx={{ whiteSpace: "nowrap" }}
              >
                #
              </DisplayTable.HeaderCell>
              <DisplayTable.HeaderCell size="small" sx={{ whiteSpace: "nowrap" }}>
                Course
              </DisplayTable.HeaderCell>
              <DisplayTable.HeaderCell size="small">Course Start</DisplayTable.HeaderCell>
              <DisplayTable.HeaderCell />
            </DisplayTable.HeaderRow>
          </TableHead>
          <TableBody>{tableContent}</TableBody>
        </Table>
      </DisplayTable.Container>
    </Grid>
  );
}

const EmptyTableContent = (
  <TableRow>
    <TableCell colSpan={6} align="center" sx={{ py: 2, color: "text.secondary" }}>
      No course preferences added.
    </TableCell>
  </TableRow>
);

import { sanitize, Config } from "dompurify";
import { Box, BoxProps } from "@mui/material";

export interface SanitisedHTMLProps
  extends Omit<BoxProps, "component" | "dangerouslySetInnerHTML"> {
  /** The HTML to sanitise and render. */
  html?: string;
  config?: Omit<Config, "RETURN_TRUSTED_TYPE" | "RETURN_DOM" | "RETURN_DOM_FRAGMENT">;
}

/**
 * This component sanitises and renders a HTML string.
 */
export function SanitisedHTML(props: SanitisedHTMLProps) {
  const { html = "", config = {}, ...rest } = props;
  const __html = sanitize(html, { ...config, RETURN_TRUSTED_TYPE: true });
  return <Box component="span" dangerouslySetInnerHTML={{ __html }} {...rest} />;
}

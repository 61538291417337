import {
  Alert,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { compareDesc } from "date-fns";

import { joinName } from "@packages/utils";

import { IApplicationEnquiryList } from "~/api";
import { ErrorNotificationMessage } from "~/components/core/ErrorMessage";
import { Timestamp } from "~/components/core/Timestamp";
import { useBackLink } from "~/components/core/BackLink";
import { InnerTable } from "~/components/core/TableStyles";
import { useAgent } from "~/hooks/agency";
import { RequestError } from "~/utils";

const PLACEHOLDER = (
  <>
    <ApplicationEnquiryTableRow />
    <ApplicationEnquiryTableRow />
    <ApplicationEnquiryTableRow />
  </>
);

export interface ApplicationEnquiriesTableProps {
  loading?: boolean;
  error?: RequestError | null;
  applicationEnquiryList?: IApplicationEnquiryList;
}

export function ApplicationEnquiriesTable(props: ApplicationEnquiriesTableProps) {
  const { loading, error, applicationEnquiryList } = props;

  const applicationEnquiries =
    applicationEnquiryList?.enquiries.sort((a, b) =>
      compareDesc(new Date(a.createdDate), new Date(b.createdDate)),
    ) ?? [];

  return (
    <InnerTable.Container>
      <Table>
        <TableHead>
          <InnerTable.HeaderRow>
            <InnerTable.HeaderCell size="small">Enquiry ID</InnerTable.HeaderCell>
            <InnerTable.HeaderCell size="small">Enquiry Subject</InnerTable.HeaderCell>
            <InnerTable.HeaderCell size="small">Created by</InnerTable.HeaderCell>
            <InnerTable.HeaderCell size="small">Created at</InnerTable.HeaderCell>
          </InnerTable.HeaderRow>
        </TableHead>
        <TableBody>
          {loading && PLACEHOLDER}
          {applicationEnquiries.map((enquiry) => (
            <ApplicationEnquiryTableRow key={enquiry.id} data={enquiry} />
          ))}
        </TableBody>
      </Table>
      {!loading && !error && applicationEnquiries.length === 0 && (
        <Typography variant="body2" color="text.secondary" textAlign="center" p={6}>
          No enquiries available
        </Typography>
      )}
      {!loading && error && (
        <Alert severity="error">
          <ErrorNotificationMessage error={error} message="Failed to load enquiries" />
        </Alert>
      )}
    </InnerTable.Container>
  );
}

interface ApplicationEnquiryTableRowProps {
  data?: IApplicationEnquiryList["enquiries"][number];
}

function ApplicationEnquiryTableRow(props: ApplicationEnquiryTableRowProps) {
  const { data } = props;
  const backLink = useBackLink();

  // fetch agent profile by agentEmail field to display agent name
  const { agentEmail } = data ?? {};
  const authorAgent = useAgent(agentEmail);
  const authorAgentName = joinName(
    authorAgent.data?.firstName,
    authorAgent.data?.lastName,
  );

  return (
    <TableRow>
      <TableCell>
        {!data && <Skeleton width="75%" />}
        {data && (
          <Link
            component={RouterLink}
            to={`/applications/${data.applicationId}/enquiries/${data.enquiryNumber}`}
            state={{ backLink }}
          >
            <Typography variant="body2" noWrap>
              {data.enquiryNumber}
            </Typography>
          </Link>
        )}
      </TableCell>
      <TableCell>
        {!data && <Skeleton width="75%" />}
        {data && (
          <Typography variant="body2" color="secondary" noWrap>
            {data.subject}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        {(!data || (agentEmail && authorAgent.isPending)) && <Skeleton width="75%" />}
        {data && (
          // ASSUMPTION: If agentEmail is empty, this means that the enquiry comes from Monash itself
          <Typography variant="body2" color="secondary" noWrap>
            {agentEmail ? authorAgentName : "Monash University"}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="secondary">
          {!data && <Skeleton width="75%" />}
          {data && <Timestamp format="d/MM/yyyy h:mm aaa">{data.createdDate}</Timestamp>}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

import { millisecondsInSecond } from "date-fns";
import { useCallback, useEffect, useState } from "react";

/**
 * Helper for managing the state of a `Menu` from MUI.
 *
 * Returns a `HTMLElement` reference that can be used as an anchor
 * element and the open/close state, along with callbacks to open
 * and close the menu.
 *
 * @returns `[{ anchor, open }, openMenu, closeMenu]`
 */
export function useMenuAnchor() {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const openMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
    setAnchor(e.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchor(null);
  }, []);

  return [{ anchor, open: Boolean(anchor) }, openMenu, closeMenu] as const;
}

/**
 * This hook helps to keep your `onClick` handlers D.R.Y. For example,
 * when you have a `onClose` function that needs to be called for each
 * menu button click, but you also need to call another callback for
 * custom behaviour, instead of doing this:
 *
 * ```jsx
 * <Menu>
 *   <MenuItem onClick={() => {
 *      onClose();
 *      onCreate();
 *   }}>
 *     Create
 *   </Menu>
 *   <MenuItem onClick={() => {
 *      onClose();
 *      onEdit("xxx");
 *   }}>
 *     Edit
 *   </Menu>
 *   <MenuItem onClick={() => {
 *      onClose();
 *      onDelete("yyy");
 *   }}>
 *     Delete
 *   </Menu>
 * </Menu>
 * ```
 *
 * You can pass the common callback you need to keep calling to this hook,
 * and it will return a factory function that will create a function that
 * calls your common callback before/after a custom callback.
 *
 * ```jsx
 * const onClickHandler = useCallbackFactory(onClose, "after");
 *
 * <Menu>
 *   <MenuItem onClick={onClickHandler(onCreate)}>
 *     Create
 *   </Menu>
 *   <MenuItem onClick={onClickHandler(onEdit, "xxx")}>
 *     Edit
 *   </Menu>
 *   <MenuItem onClick={onClickHandler(onDelete, "yyy")}>
 *     Delete
 *   </Menu>
 * </Menu>
 * ```
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useCallbackFactory<TCallback extends () => any>(
  callback?: TCallback | null,
  when: "before" | "after" = "before",
) {
  return useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <THandler extends (...args: any) => any>(
      handler?: THandler | null,
      ...args: Parameters<THandler>
    ) =>
      () => {
        if (when === "before") callback?.();
        handler?.(...args);
        if (when === "after") callback?.();
      },
    [],
  );
}

/**
 * Using this hook inside a component will cause it to
 * re-render at a regular interval.
 *
 * Returns the re-render UNIX timestamp.
 */
export function useRerender(
  options: {
    /** The interval to trigger a re-render. Defaults to 1000ms. */
    interval?: number;
    /** Enable or disable the rerender timer. */
    enabled?: boolean;
  } = {},
) {
  const { enabled = true, interval = millisecondsInSecond } = options;
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (!enabled) return;
    const id = setInterval(() => setTime(Date.now()), interval);
    return () => clearInterval(id);
  }, [enabled, interval]);

  return time;
}

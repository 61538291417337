import {
  Avatar,
  Box,
  Container,
  Divider,
  List,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import { IAgent } from "~/api";
import { AppNotFoundError } from "~/utils";
import { useAgents } from "~/hooks/agency";
import { useDocumentTitle } from "~/hooks/title";
import { useAuthState } from "~/components/auth/AuthProvider";
import { ListDisplayValue } from "~/components/core/ListDisplayValue";

export function AgencyInfoPage() {
  useDocumentTitle("Agency management");

  const agents = useAgents("active");
  const [user] = useAuthState();

  if (!user) throw new AppNotFoundError();

  const { agency } = user;
  const branchManager = agents.data?.find((x) => x.roles.includes("Branch Manager"));
  const principalAgent = agents.data?.find((x) => x.roles.includes("Principal Agent"));

  return (
    <Container sx={{ my: 6 }} maxWidth="md">
      <Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" fontWeight="bold" color="primary">
          {agency.name}
        </Typography>
      </Box>
      <Box component={Paper}>
        <List>
          <ListDisplayValue label="AGENCY NAME" value={agency.name} />
          <ListDisplayValue label="AGENCY ID" value={agency.orgUnitCode} />
          <Divider />
          <ListDisplayValue
            label="PRINCIPAL AGENT"
            value={
              <Stack gap={2} mt={2}>
                {agents.isPending || principalAgent ? (
                  <AgentAvatar agent={principalAgent} />
                ) : (
                  <Typography variant="body1" fontWeight="medium" color="text.secondary">
                    Not available
                  </Typography>
                )}
                <Typography variant="body2" color="text.secondary">
                  The user who is responsible for the overall Agency. This user can only
                  be changed by Monash University via an Enquiry.
                </Typography>
              </Stack>
            }
          />
          <Divider />
          <ListDisplayValue
            label="BRANCH MANAGER"
            value={
              <Stack gap={2} mt={2}>
                {agents.isPending || branchManager ? (
                  <AgentAvatar agent={branchManager} />
                ) : (
                  <Typography variant="body1" fontWeight="medium" color="text.secondary">
                    Not available
                  </Typography>
                )}
                <Typography variant="body2" color="text.secondary">
                  The user who manages the Agency Office. This user can only be changed by
                  Monash University via an Enquiry.
                </Typography>
              </Stack>
            }
          />
        </List>
      </Box>
    </Container>
  );
}

function AgentAvatar(props: { agent?: IAgent }) {
  const { agent } = props;
  const {
    firstName = "",
    lastName = <Skeleton variant="text" />,
    email = <Skeleton variant="text" />,
  } = agent ?? {};
  return (
    <Box display="flex" alignItems="center" gap={2}>
      {firstName ? (
        <Avatar sx={{ background: (theme) => theme.palette.primary.main }}>
          {firstName[0]}
        </Avatar>
      ) : (
        <Avatar>
          <Skeleton variant="circular" />
        </Avatar>
      )}
      <Stack>
        <Typography minWidth={120}>
          {firstName} {lastName}
        </Typography>
        <Typography minWidth={120} variant="body2" color="text.secondary">
          {email}
        </Typography>
      </Stack>
    </Box>
  );
}

import {
  FieldValues,
  ControllerProps,
  useController,
  ControllerRenderProps,
  Path,
  PathValue,
} from "react-hook-form";
import {
  Autocomplete,
  TextField,
  AutocompleteProps,
  ChipTypeMap,
  AutocompleteValue,
} from "@mui/material";

import { ValueSatisfiesType, GetFieldPathToType } from "~/types";

export interface FormAutocompleteProps<
  TValue,
  TFieldValues extends FieldValues = FieldValues,
  TMultiple extends boolean | undefined = false,
  TDisableClearable extends boolean | undefined = false,
  TFreeSolo extends boolean | undefined = false,
  TChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
> extends Pick<ControllerProps<TFieldValues>, "control">,
    Omit<
      AutocompleteProps<
        TValue | null,
        TMultiple,
        TDisableClearable,
        TFreeSolo,
        TChipComponent
      >,
      | "renderInput"
      | "onChange"
      | "options"
      | Exclude<keyof ControllerRenderProps<TFieldValues, Path<TFieldValues>>, "disabled">
    > {
  name: GetFieldPathToType<TValue | null, TFieldValues>;
  options?: readonly TValue[];
  label?: React.ReactNode;
  placeholder?: string;
  readOnly?: boolean;
  error?: boolean;
  defaultValue?: PathValue<TFieldValues, Path<TFieldValues>>;
  helperText?: React.ReactNode;
  onBeforeChange?: (
    value:
      | TValue
      | AutocompleteValue<TValue | null, TMultiple, TDisableClearable, TFreeSolo>,
  ) => void;
  /**
   * Ensure that the value received by the `onChange` event is in the right shape.
   * Most useful when {@link TFreeSolo} is `true` as pressing "enter" will always
   * set `TValue` to a string value and bypass the `filterOptions` function.
   */
  parseInputValue?: (
    value: AutocompleteValue<TValue | null, TMultiple, TDisableClearable, TFreeSolo>,
  ) => TValue;
}

export function FormAutocomplete<
  TValue,
  TFieldValues extends FieldValues = FieldValues,
  TMultiple extends boolean | undefined = false,
  TDisableClearable extends boolean | undefined = false,
  TFreeSolo extends boolean | undefined = false,
  TChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
>(
  props: FormAutocompleteProps<
    TValue,
    TFieldValues,
    TMultiple,
    TDisableClearable,
    TFreeSolo,
    TChipComponent
  >,
) {
  const {
    name,
    control,
    label,
    readOnly,
    error,
    placeholder,
    helperText,
    defaultValue,
    parseInputValue,
    onBeforeChange,
    options = [],
    disabled,
    ...rest
  } = props;

  const {
    field: { onChange, value, ...field },
    fieldState,
  } = useController({ control, name, defaultValue });

  return (
    <Autocomplete
      id={name}
      {...rest}
      {...field}
      options={options}
      value={value}
      onChange={(e, data) => {
        const value = parseInputValue ? parseInputValue(data) : data;
        onBeforeChange?.(value);
        onChange(value as ValueSatisfiesType<TFieldValues, TValue>);
      }}
      renderInput={({ InputProps, ...textFieldProps }) => (
        <TextField
          {...textFieldProps}
          disabled={textFieldProps.disabled || disabled}
          placeholder={placeholder}
          InputProps={{ ...InputProps, readOnly }}
          label={label}
          helperText={fieldState.error?.message || helperText}
          error={fieldState.invalid || error}
        />
      )}
    />
  );
}

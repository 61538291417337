import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { z } from "zod";

import {
  AgentRole,
  asNonMonashEmail,
  EmailStringSchema,
  SafeNameString,
} from "@packages/types";

import { AgencyApi, GetApi, IAgent, IAgentUpdatePayload } from "~/api";
import { useNotification } from "~/components/core/NotificationProvider";
import { useLoadResource } from "~/components/core/AppLoadingBar";

type EditUserFields = z.infer<typeof EditUserSchema>;

const EditUserSchema = z.object({
  firstName: SafeNameString().schema({
    addValidation: (schema) => schema.min(1, "Please provide a preferred first name"),
  }),
  lastName: SafeNameString().schema({
    addValidation: (schema) => schema.min(1, "Please provide a last name"),
  }),
  email: asNonMonashEmail(EmailStringSchema()),
  isCounsellor: z.literal(true),
  isDelegatedAdmin: z.boolean(),
});

function mapUserToForm(user?: IAgent): EditUserFields {
  return {
    email: user?.email ?? "",
    firstName: user?.firstName ?? "",
    lastName: user?.lastName ?? "",
    isCounsellor: true,
    isDelegatedAdmin: user?.roles.includes("Delegated Admin") ?? false,
  };
}

export function useEditUserForm(user?: IAgent) {
  const [loading, setLoading] = useState(false);
  const { showNotification, showNotificationAlert, showErrorAlert } = useNotification();
  const client = useQueryClient();

  const form = useForm<EditUserFields>({
    resolver: zodResolver(EditUserSchema),
    defaultValues: mapUserToForm(user),
  });

  const resetForm = useCallback(() => {
    form.reset(mapUserToForm(user));
  }, [form, user]);

  const onValidSubmit = useCallback(
    async (data: EditUserFields) => {
      setLoading(true);

      const { firstName, lastName, email, isCounsellor, isDelegatedAdmin } = data;
      const roles = [AgentRole.Counsellor];
      if (isDelegatedAdmin) roles.push(AgentRole.DelegatedAdmin);

      try {
        const api = GetApi(AgencyApi);

        if (!user) {
          showNotification({ type: "loading", message: "Creating user..." });

          // Create new agent
          const result = await api.createAgent({ firstName, lastName, email, roles });
          if (result.status === "success") {
            showNotification({ type: "success", message: "Created user" });
          } else {
            showNotificationAlert({
              type: "warning",
              title: "Unable to create user",
              message: result.message,
            });
          }
        } else {
          const payload: IAgentUpdatePayload = {
            firstName: data.firstName,
            lastName: data.lastName,
            roles: [],
          };

          if (isCounsellor) payload.roles.push(AgentRole.Counsellor);
          if (isDelegatedAdmin) payload.roles.push(AgentRole.DelegatedAdmin);

          // Update agent
          if (user.status === "active") {
            showNotification({ type: "loading", message: "Saving user..." });
            await api.updateAgent(user.contactId, payload);
            showNotification({ type: "success", message: "Updated user" });
          } else if (user.status === "inactive") {
            showNotification({ type: "loading", message: "Activating user..." });
            const response = await api.activateAgent(user.contactId, payload);
            if (response.status === "success")
              showNotification({ type: "success", message: "Activated user" });
            else if (response.status === "partial-success" && response.message)
              showNotificationAlert({
                type: "warning",
                title: "Unable to activate user",
                message: response.message,
              });
          }
        }

        // Refetch agent list
        client.refetchQueries({ queryKey: ["agents"] });
      } catch (error) {
        // send error to Sentry
        Sentry.captureException(error, {
          tags: { source: "useEditUserForm.onValidSubmit" },
        });

        showErrorAlert(error);
      } finally {
        setLoading(false);
      }
    },
    [user],
  );

  useLoadResource(
    useCallback(() => loading, [loading]),
    useEditUserForm.name,
  );

  return {
    form,
    loading,
    resetForm,
    onValidSubmit,
  };
}

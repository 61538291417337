import { Paper, StackProps, Stack, PaperProps } from "@mui/material";

import { FormSectionTitle } from "./FormSection.Title";
import { FormSectionWell } from "./FormSection.Well";
import { FormSectionList } from "./FormSection.List";
import { FormSectionSubheading } from "./FormSection.Subheading";

export interface FormSectionProps {
  id?: string;
  children?: React.ReactNode;
  gap?: StackProps["gap"];
  sx?: PaperProps["sx"];
}

export function FormSection(props: FormSectionProps) {
  const { id, children, sx, gap = 2 } = props;
  return (
    <Paper
      id={id}
      sx={{ p: 3, boxShadow: 2, border: "none", borderRadius: 2, ...sx }}
      component={Stack}
      gap={gap}
    >
      {children}
    </Paper>
  );
}

FormSection.Title = FormSectionTitle;
FormSection.Well = FormSectionWell;
FormSection.List = FormSectionList;
FormSection.Subheading = FormSectionSubheading;

/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { IErrorResponse, IPaymentUrl } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PaymentApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Payment API
   * @name CreatePaymentUrl
   * @summary Create a payment URL for the application
   * @request POST:/api/payments/url/{applicationId}
   * @response `201` `IPaymentUrl`
   * @response `401` `IErrorResponse` Not signed in.
   */
  createPaymentUrl = (applicationId: string, params: RequestParams = {}) =>
    this.request<IPaymentUrl, IErrorResponse>({
      path: `/api/payments/url/${applicationId}`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Payment API
   * @name PaymentCallback
   * @summary Webhook callback for processing payment confirmation
   * @request POST:/api/payments/callback/{token}
   * @response `201` `void`
   */
  paymentCallback = (token: string, data: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/payments/callback/${token}`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
}

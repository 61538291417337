import { LoaderFunctionArgs, useRouteLoaderData } from "react-router-dom";

import {
  ApplicantApi,
  ApplicationApi,
  GetApi,
  IApplicant,
  IApplication,
  IApplicationsPage,
  IEnquiryDetails,
  ReferenceDataApi,
} from "~/api";
import { queryClient } from "~/utils";
import { REFERENCE_DATA_STALE_TIME } from "~/config/constants";

export interface ApplicantRouteData {
  applicant: IApplicant;
  applications: IApplicationsPage;
}

export function useApplicantRouteData() {
  return useRouteLoaderData(useApplicantRouteData.id) as ApplicantRouteData | null;
}

useApplicantRouteData.id = "applicant-route";
useApplicantRouteData.loader = async (
  args: LoaderFunctionArgs,
): Promise<ApplicantRouteData | null> => {
  const { applicantId } = args.params;
  if (!applicantId) return null;

  const [applicant, applications] = await Promise.all([
    queryClient.fetchQuery({
      queryKey: ["applicants", applicantId],
      queryFn: () => GetApi(ApplicantApi).getApplicant(applicantId),
    }),
    queryClient.fetchQuery({
      queryKey: ["applicants", applicantId, "applications"],
      queryFn: () =>
        GetApi(ApplicationApi).getApplications({ applicantId, pageSize: 100 }),
    }),
  ]);

  return { applicant, applications };
};

export interface ApplicationRouteData {
  applicant: IApplicant;
  application: IApplication;
  englishCourseCodes: string[];
  monashCollegeFaculties: string[];
}

export function useApplicationRouteData() {
  return useRouteLoaderData(useApplicationRouteData.id) as ApplicationRouteData;
}

useApplicationRouteData.id = "application-route";
useApplicationRouteData.loader = async (args: LoaderFunctionArgs) => {
  const { applicationId } = args.params;
  if (!applicationId) throw new Error("No application ID");
  const application = await queryClient.fetchQuery({
    queryKey: ["applications", applicationId],
    queryFn: () => GetApi(ApplicationApi).getApplication(applicationId),
  });
  const { applicantId } = application.applicant;
  if (!applicantId) throw new Error("No applicant ID");

  const referenceApi = GetApi(ReferenceDataApi);

  const [applicant, englishCourseCodes, monashCollegeFaculties] = await Promise.all([
    queryClient.fetchQuery({
      queryKey: ["applicants", applicantId],
      queryFn: () => GetApi(ApplicantApi).getApplicant(applicantId),
    }),
    queryClient.fetchQuery({
      queryKey: ["reference", "english-course-codes"],
      queryFn: () => referenceApi.getEnglishCourseCodes(),
      staleTime: REFERENCE_DATA_STALE_TIME,
    }),
    queryClient.fetchQuery({
      queryKey: ["reference", "monash-college-faculties"],
      queryFn: () => referenceApi.getMonashCollegeFaculties(),
      staleTime: REFERENCE_DATA_STALE_TIME,
    }),
  ]);

  return { applicant, application, englishCourseCodes, monashCollegeFaculties };
};

export interface EnquiryRouteData {
  enquiry: IEnquiryDetails;
}

export function useEnquiryRouteData() {
  return useRouteLoaderData(useEnquiryRouteData.id) as EnquiryRouteData;
}

useEnquiryRouteData.id = "enquiry-route";
useEnquiryRouteData.loader = async (
  args: LoaderFunctionArgs,
): Promise<EnquiryRouteData> => {
  const { applicationId, enquiryNumber } = args.params;
  if (!applicationId) throw new Error("No application ID");
  if (!enquiryNumber) throw new Error("No enquiry ID");
  const enquiry = await queryClient.fetchQuery({
    queryKey: ["enquiry", enquiryNumber],
    queryFn: () =>
      GetApi(ApplicationApi).getApplicationEnquiry(applicationId, enquiryNumber),
  });

  return { enquiry };
};

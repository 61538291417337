import { z } from "zod";

export type CountryISO = z.infer<typeof CountryISOSchema>;
export const CountryISOSchema = z.object({
  id: z.string(),
  isoCode2: z.string(),
  isoCode3: z.string(),
  isoCountryName: z.string(),
});

export type CountrySACC = z.infer<typeof CountrySACCSchema>;
export const CountrySACCSchema = z.object({
  id: z.string(),
  sacCountryCode: z.string(),
  sacCountryName: z.string(),
  isoCountryName: z.string(),
});

import { z } from "zod";
import { Link as MuiLink, Typography, TypographyProps } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

import { Link, useLocation, useNavigate } from "react-router-dom";

const BackLinkStateSchema = z.object({ backLink: z.string().startsWith("/") });

export interface BackLinkProps extends TypographyProps {
  /**
   * The URL to go to. If not provided, the link will navigate to the
   * `backLink` value from react-router's state if available.
   * Otherwise, the component will navigate back to the previous page
   * using `navigate(-1)` unless {@link defaultTo} is provided.
   */
  to?: string;

  /**
   * The URL to go to if `backLink` from react-router's state is not available.
   */
  defaultTo?: string;

  /**
   * If `true`, the link will be treated as a normal `<a>` and skip client-side routing.
   *
   * See https://reactrouter.com/en/main/components/link#reloaddocument
   */
  reloadDocument?: boolean;

  /**
   * Content to show in the link.
   */
  children?: React.ReactNode;
}

export function BackLink(props: BackLinkProps) {
  const {
    defaultTo,
    to = useBackLinkFromState() ?? defaultTo,
    reloadDocument = false,
    children,
    ...rest
  } = props;

  const navigate = useNavigate();

  return (
    <Typography variant="body2" {...rest}>
      <MuiLink
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          width: "fit-content",
          cursor: "pointer",
        }}
        component={to ? Link : MuiLink}
        underline="hover"
        to={to}
        reloadDocument={reloadDocument}
        onClick={to ? undefined : () => navigate(-1)}
      >
        <ArrowLeftIcon /> {children}
      </MuiLink>
    </Typography>
  );
}

/**
 * Returns the backLink from the react-router state if available.
 */
function useBackLinkFromState(defaultBackLink?: string): string | undefined {
  const { state } = useLocation();
  const result = BackLinkStateSchema.safeParse(state);
  if (result.success) return result.data.backLink;
  return defaultBackLink;
}

/**
 * Returns the concatenated `path` and `search` from
 * {@link useLocation} which can be used in the state
 * of the {@link Link} component.
 */
export function useBackLink(): string {
  const { pathname, search } = useLocation();
  return pathname + search;
}
